import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "Redux/store";
import { ParkingPlace } from "api/parking";
import { Tooltip } from "Components/UI/Tooltip/style";

import { Container, StyledTitle, AddInfo, AddInfoColumns } from "./style";
import { StyledText, StyledTextCustom } from "Components/ParkingCard/style";

export const AdditionalInfo = () => {
  const parkingInfo: ParkingPlace | null | undefined = useSelector(
    (state: RootState) => state?.singleParking?.data
  );
  const { t } = useTranslation(["common"]);

  return (
    <Container>
      <StyledTitle>{t("titles.additional_info")}</StyledTitle>
      <AddInfo>
        <AddInfoColumns>
          <StyledText data-testid="parking-type">
            {t("parking.type")}:{" "}
            {parkingInfo?.parkingType === "UNDEGROUND"
              ? t("parking.underground_park")
              : t("parking.ground_park")}
          </StyledText>
          <StyledText data-testid="parking-level">
            {t("placeholders.parking_level")}: {parkingInfo?.parkingLevel}
          </StyledText>
          <StyledTextCustom data-testid="parking-car-class" data-tip={t("parking.car_class_desc")}>
            <Tooltip />
            {t("parking.car_class")}: {t(`parking.${parkingInfo?.carClass}`)}
          </StyledTextCustom>
        </AddInfoColumns>
        <AddInfoColumns>
          <StyledText data-testid="parking-length">
            {t("parking.p_length")}: {parkingInfo?.placeLength}{" "}
            {t("parking.metres")}
          </StyledText>
          <StyledText data-testid="parking-height">
            {t("parking.entrance_height")}: {parkingInfo?.heightOfParkingEntry}{" "}
            {t("parking.metres")}
          </StyledText>
        </AddInfoColumns>
        <AddInfoColumns>
          <StyledText data-testid="parking-width">
            {t("parking.p_width")}: {parkingInfo?.placeWidth}{" "}
            {t("parking.metres")}
          </StyledText>
        </AddInfoColumns>
      </AddInfo>
    </Container>
  );
};
