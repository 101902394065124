import axios from 'axios';
import {
  GET_USER_BILLS_API_URL,
  GET_PARTNER_BILLS_API_URL,
  GET_AVAILABLE_MONTHS_YEARS,
  GET_ALL_CUSTOMERS_AVAILABLE_MONTHS_YEARS
} from 'config.js';

type userBillsProps = {
  token: string;
  page?: number;
  limit?: number;
};

type partnerBillsProps = {
  token: string;
  userId: number | string;
  page?: number;
  limit?: number;
};

type partnerPrepaidProps = {
  token: string;
  userId: string;
  body: { reason: string; amount: number; date?: string };
  prepaidId?: string;
};

type partnerEditBillsProps = {
  token: string;
  userId: string;
  body: { type: string; paidAmount: number; paidDate: string };
  billId: string;
};

export const getUerBills = async ({
  token,
  page = 1,
  limit = 25
}: userBillsProps): Promise<any> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return await axios.get(`${GET_USER_BILLS_API_URL}?limit=${limit}&page=${page}`, { headers });
};

export const getPartnerBillsAdmin = async ({
  token,
  userId,
  page = 1,
  limit = 25
}: userBillsProps): Promise<any> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return await axios.get(
    `${GET_PARTNER_BILLS_API_URL}/${userId}/finance/bills?limit=${limit}&page=${page}`,
    { headers }
  );
};

export const getPartnerPrepaidDataAdmin = async ({
  token,
  userId,
  page = 1,
  limit = 25
}: partnerBillsProps): Promise<any> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return await axios.get(
    `${GET_PARTNER_BILLS_API_URL}/${userId}/finance/prepaid?limit=${limit}&page=${page}`,
    { headers }
  );
};

export const createPartnerPrepaidAdmin = async ({
  token,
  userId,
  body
}: partnerPrepaidProps): Promise<any> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return await axios.post(`${GET_PARTNER_BILLS_API_URL}/${userId}/finance/prepaid`, body, {
    headers
  });
};

export const editPartnerPrepaidAdmin = async ({
  token,
  userId,
  body,
  prepaidId
}: partnerPrepaidProps): Promise<any> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return await axios.put(
    `${GET_PARTNER_BILLS_API_URL}/${userId}/finance/prepaid/${prepaidId}`,
    body,
    {
      headers
    }
  );
};

export const editPartnerBillAdmin = async ({
  token,
  userId,
  body,
  billId
}: partnerEditBillsProps): Promise<any> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return await axios.put(`${GET_PARTNER_BILLS_API_URL}/${userId}/finance/bills/${billId}`, body, {
    headers
  });
};

export type MonthsYearsResponse = {
  years: { year: number; month: number }[];
};

type AvailableMonthsProps = {
  token: string;
  year?: number | string;
  page?: number;
  limit?: number;
};

export const getAvailableMonthsYears = async ({
  token,
  year,
  page = 1,
  limit = 25
}: AvailableMonthsProps): Promise<MonthsYearsResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return await axios.get(
    `${GET_AVAILABLE_MONTHS_YEARS}?limit=${limit}&page=${page}${
      Boolean(year) ? `&year=${year}` : ''
    }`,
    { headers }
  );
};

type CustomersAvailableMonthsProps = {
  token: string;
  userId: string;
  year?: number | string;
  page?: number;
  limit?: number;
};

export const getCustomersAvailableMonthsYears = async ({
  token,
  userId,
  year,
  page = 1,
  limit = 25
}: CustomersAvailableMonthsProps): Promise<MonthsYearsResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return await axios.get(
    `${GET_ALL_CUSTOMERS_AVAILABLE_MONTHS_YEARS}?limit=${limit}&page=${page}&userId=${userId}${
      Boolean(year) ? `&year=${year}` : ''
    }`,
    { headers }
  );
};
