import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';
import { setSearchValue } from 'Redux/reducers/citySearch/citySearchReducer';
import { SellParkingFilterContext } from 'context/sellParkingContext';
import { SellParkingFilterContextType } from 'context/types';
import { paths } from 'config';
import queryString from 'query-string';

import { Button } from 'Components/UI/Button/Button';
import { GoogleLocationAutoComplete } from 'Components/GoogleAutoComplete/GoogleAutoCompleteSearch';
import { SellParkingFilterDropdown } from './SellParkingFilterDropdown';
import { SellCarClassFilter } from './SellCarClassFilter';
import { SellParkingSelectedFiltersBar } from './SellParkingSelectedFiltersBar';
import { DirectionIcon, MapMarkedIcon } from 'assets/svg';
import { CoordinatesType } from 'types/parkingTypes.ts';

import { color } from 'theme';
import { DesktopVisibleText } from 'Components/UI/Text/style.js';
import {
  BarContainer,
  LeftSide,
  IconWrapper,
  RightSideBar,
  MapButtonWrapper,
  AutoCompleteContainer
} from '../style.js';

export const SellParkingFiltersPanel = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { searchingLocation } = queryString.parse(location.search);

  const { setCoordinates, setPositionDetected, setIsMobileMapOpen, isMobileMapOpen } = useContext(
    SellParkingFilterContext
  ) as SellParkingFilterContextType;

  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false);
  const [radius, setRadius] = useState<number | undefined>();
  const [newCoordinates, setNewCoordinates] = useState<CoordinatesType>({});
  const [newLocation, setLocation] = useState<string>(searchingLocation);

  const getMyPosition = () => {
    if (!navigator.geolocation) {
      toast.error(t('notifications.not_support_position'), NOTIFICATION_OPTIONS);
    } else {
      setIsButtonDisable(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
          dispatch(
            setSearchValue({
              coordinates: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              },
              chosenLabel: t('placeholders.your_location'),
              value: { label: t('placeholders.your_location') }
            })
          );

          setPositionDetected(true);
          setIsButtonDisable(false);
        },
        (error) => {
          toast.error(`ERROR(${error.code}): ${error.message}`, NOTIFICATION_OPTIONS);
          setIsButtonDisable(false);
        }
      );
    }
  };

  useEffect(() => {
    if (radius && newCoordinates) {
      history.replace(
        `${paths.parkingsSale}?lat=${newCoordinates.lat}&lng=${newCoordinates.lng}&searchRadius=${radius}&searchingLocation=${newLocation}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radius, newCoordinates, newLocation]);

  return (
    <BarContainer isWide>
      <LeftSide>
        <AutoCompleteContainer>
          <GoogleLocationAutoComplete
            setRadius={setRadius}
            setNewCoordinates={setNewCoordinates}
            setLocation={setLocation}
            newLocation={newLocation}
          />
        </AutoCompleteContainer>
      </LeftSide>

      <RightSideBar>
        <SellParkingSelectedFiltersBar />
        <SellCarClassFilter />
        <SellParkingFilterDropdown />

        <Button
          primary={false}
          width="auto"
          height="50"
          onClick={getMyPosition}
          disabled={isButtonDisable}>
          <IconWrapper>
            <DirectionIcon fill={color.blue} />
          </IconWrapper>
          <DesktopVisibleText>{t('buttons.get_position')}</DesktopVisibleText>
        </Button>

        <MapButtonWrapper>
          <Button
            primary={isMobileMapOpen ? true : false}
            width="auto"
            height="50"
            onClick={() => {
              setIsMobileMapOpen((prevState) => !prevState);
            }}>
            <IconWrapper>
              <MapMarkedIcon fill={isMobileMapOpen ? '#fff' : `${color.blue}`} />
            </IconWrapper>
          </Button>
        </MapButtonWrapper>
      </RightSideBar>
    </BarContainer>
  );
};
