import styled from 'styled-components';

import { color, fontSize, lineHeight, device } from 'theme';

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  border-radius: 10px;
  background: ${color.white};
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-between;

  @media ${device.tablet} {
    padding: 0 10px;
    height: 50px;
  }
`;

export const Table = styled.div`
  margin-top: 10px;
  border-radius: 10px;
  background: ${color.white};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid ${color.lightGrey};
`;

export const Column = styled.div`
  width: 20%;
`;

export const HeaderColumn = styled(Column)`
  transform: rotate(-90deg);

  & > p {
    text-align: center;
  }

  @media ${device.tablet} {
    transform: rotate(0deg);
  }
`;

export const Text = styled.p`
  font-size: ${fontSize.small};
  line-height: ${lineHeight.small};
  color: ${(props) => props.color};

  @media ${device.tablet} {
    font-size: ${fontSize.regular};
    line-height: ${lineHeight.regular};
  }
`;

export const Title = styled.h2`
  font-family: Roboto;
  font-size: ${fontSize.m_large};
  line-height: 23px;
  margin-top: 30px;
`;

export const CellWithButton = styled.td`
  padding: 0 !important;
  background: ${({ color }) => (color ? color : 'transparent')};
`;

export const inlineStyles = {
  modal: {
    content: {
      width: 'fit-content',
      height: 'fit-content',
      overflow: 'hidden',
      padding: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      border: `2px solid ${color.black}`,
      boxShadow:
        'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
    },
    overlay: {
      background: color.modalBackground
    }
  }
};

export const StyledSelect = {
  control: (styles, { isFocused }) => ({
    ...styles,
    border: '1px soled hsl(0, 0%, 80%)',
    borderRadius: 0,
    width: '200px',
    height: '50px',
    outline: 'none !important',
    boxShadow: 'none',
    background: '#fff',
    cursor: 'pointer'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      cursor: 'pointer',
      color: `${color.darkGrey}`,
      maxWidth: '200px',
      minWidth: '200px',
      width: '100%',
      backgroundColor: isFocused && `${color.blue}`,
      boxShadow:
        'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      display: 'flex',
      maxWidth: '200px',
      minWidth: '200px',
      width: '100%',
      zIndex: 10
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      overflow: 'visible'
    };
  }
};

export const UpperPanel = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SelectorPanel = styled.div`
  display: flex;
  gap: 20px;
  margin: 10px 0 20px 0;
`;
