import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import styles from "./Tooltip.module.css";

import { color, fontSize } from "theme";

export const StyledTooltip = styled(ReactTooltip)`
  max-width: 300px;
  border-radius: 6px;
  font-size: ${fontSize.small};
  color: ${color.greayText};
  background: #ffffff;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    max-width: 70vw;
  }
`;

export const Tooltip = ({ ...props }) => (
  <StyledTooltip
    {...props}
    place="top"
    html={true}
    className={styles.myClass}
  />
);
