import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { MainHeader } from 'Components/Header/MainHeader';
import { ParkingPlaces } from "Components/Profile/ParkingPlaces/ParkingPlaces";
import { OnDemand } from "Components/Profile/OnDemand/OnDemand";

import "./react-tabs.css";
import { Background } from "Pages/Parking/style";
import { MainContainer } from "Pages/AddParking/style";
import { Calendar } from "Components/Profile/Calendar/Calendar";
import { Discount } from "Components/Profile/Discount/Discount";
import { Commission } from 'Components/Profile/Commission/Commission';
import { Ratings } from 'Components/Profile/Ratings/Ratings';
import { Bookings } from 'Components/Profile/Bookings/Bookings';
import { useHistory, useLocation } from 'react-router';
import Modal from 'react-modal';
import styles from 'Components/Profile/Calendar/style.module.css';
import { Button } from 'Components/UI/Button/Button';
import { AddParkingLink } from 'Components/AddParking/AddParkingLink';
import { IS_SELL_PARKING_AVAILABLE } from 'config';

const customStyles = {
  overlay: {
    background: 'rgba(51, 51, 51, 0.5)'
  }
};

export const PartnerProfile = () => {
  const { t } = useTranslation(['common']);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const location = useLocation();
  const history = useHistory();
  //@ts-expect-error
  const [show, setShow] = useState(location?.state?.show);

  return (
    <Background>
      <Modal
        isOpen={show}
        onRequestClose={() => setShow(false)}
        style={customStyles}
        contentLabel="Example Modal"
        className={styles.EmailModal}>
        <p style={{ textAlign: 'center', marginBottom: '10px' }}>
          {t('contents.sent_for_moderation')}
        </p>
        <Button
          onClick={() => {
            setShow(false);
            history.push({ state: {} });
          }}
          primary={true}>
          {t('buttons.close')}
        </Button>
      </Modal>
      <MainHeader />
      <MainContainer>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>{t('titles.calendar')}</Tab>
            <Tab>{t('titles.approved_booking')}</Tab>
            <Tab>{t('titles.commission_payment')}</Tab>
            <Tab>{t('titles.feedbacks')}</Tab>
            <Tab>{t('titles.requests')}</Tab>
            <Tab>{t('titles.discount')}</Tab>
            <Tab>{t('titles.parking_places')}</Tab>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
              <AddParkingLink />
              {IS_SELL_PARKING_AVAILABLE && <AddParkingLink rent={false} />}
            </div>
          </TabList>
          <TabPanel>
            <Calendar />
          </TabPanel>
          <TabPanel>
            <Bookings />
          </TabPanel>
          <TabPanel>
            <Commission />
          </TabPanel>
          <TabPanel>
            <Ratings />
          </TabPanel>
          <TabPanel>
            <OnDemand />
          </TabPanel>
          <TabPanel>
            <Discount />
          </TabPanel>
          <TabPanel>
            <ParkingPlaces />
          </TabPanel>
        </Tabs>
      </MainContainer>
    </Background>
  );
};
