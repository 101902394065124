import { RatingCard } from 'Components/RatingCard/RatingCard';
import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Container, Row, Selector, List, SelectorContainer, FilterList } from './style';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-down.svg';
import {
  getMyParkings,
  getParkingReviews,
  getRatings,
  ParkingPlace,
  RequestsParams,
  Status
} from 'api/parking';
import { Rating } from 'Components/RatingCard/RatingCard';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkExpiry } from 'api/auth';
import { logout } from 'Redux/reducers/login/loginReducer';
import { MoreButton, SelectItem } from '../Bookings/style';

const ALL = 'Все';

export const Ratings = () => {
  const [list, setList] = useState<Rating[]>();
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const history = useHistory();
  const [params, setParams] = useState<RequestsParams>({});
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  useEffect(() => {
    (async function () {
      try {
        const token = await checkExpiry(localStorage.getItem('accessToken'));
        if (token) {
          let res;
          if (!params.parkingPlaceId) {
            res = await getRatings(params, token);
          } else {
            res = await getParkingReviews({
              parkingPlaceId: params.parkingPlaceId as number
            });
          }
          setList(res.data.results);
        } else {
          dispatch(logout());
          history.push('/');
        }
      } catch (error: AxiosError) {
        if (error?.response?.status === 401) dispatch(logout());
      }
    })();
  }, [dispatch, history, params]);

  useEffect(() => {
    (async function () {
      try {
        const token = await checkExpiry(localStorage.getItem('accessToken'));
        if (token) {
          const { data } = await getMyParkings(
            {
              page,
              limit: 25,
              sortingType: 'DATE_DESCENDING',
              statuses: [Status.ApprovedVerified, Status.ApprovedUnverified]
            },
            token
          );
          setParkings([...parkings, ...data.results]);
          setPages(Math.ceil(data.count / 25));
        } else {
          dispatch(logout());
          history.push('/');
        }
      } catch (error: AxiosError) {
        if (error?.response?.status === 401) dispatch(logout());
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function onParkingClick(current: string, parkingPlaceId?: number) {
    setParams({ parkingPlaceId });
    setCurrent(current);
    setMore(false);
  }

  const [more, setMore] = useState(false);
  const [parkings, setParkings] = useState<ParkingPlace[]>([]);
  const [current, setCurrent] = useState(t('profile.all') as string);
  return (
    <Container>
      <Row>
        <SelectorContainer>
          <Selector onClick={() => setMore((more) => !more)} ml>
            {current}
            <ArrowIcon />
          </Selector>
          <FilterList show={more}>
            <SelectItem onClick={() => onParkingClick(ALL)}> {t('profile.all')}</SelectItem>
            {parkings?.map((parking) => (
              <SelectItem
                key={parking.id}
                onClick={() =>
                  onParkingClick(`${parking.id}: ${parking.street} ${parking.house}`, parking.id)
                }>{`${parking.id}: ${parking.street} ${parking.house}`}</SelectItem>
            ))}
            {page < pages && (
              <MoreButton onClick={() => setPage(page + 1)}>
                {t('placeholders.load_more')}
              </MoreButton>
            )}
          </FilterList>
        </SelectorContainer>
        {/* <Button>За рейтингом</Button>
        <Button>Спочатку нові</Button>
        <Button>Спочатку нові</Button> */}
      </Row>
      <List>
        {list?.map((rating: Rating) => {
          return <RatingCard key={rating.id} rating={rating} />;
        })}
      </List>
    </Container>
  );
};
