import styled from "styled-components";

import { device, fontSize } from "theme";

export const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`;

export const BannerWrapper = styled.div`
  width: 100%;
  height: 300px;
  outline: none !important;

  @media ${device.laptopL} {
    width: 1200px;
    height: 600px;
    margin-bottom: 20px;
  }
`;

export const ImgWrapper = styled.div`
  width: 100%;
  height: 300px;
  outline: none !important;

  @media ${device.laptopL} {
    width: 1200px;
    height: 600px;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  src: ${(props) => props.src};
  outline: none !important;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

export const ParkingInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;

  @media ${device.laptop} {
    gap: 20px;
  }
`;

export const LeftContainer = styled.div`
  flex: 1 0 95%;
  margin: 0 auto;

  @media ${device.laptop} {
    flex: 1 0 780px;
  }
`;

export const RightCont = styled.div`
  flex: 1 0 380px;
  margin-top: 10px;

  @media ${device.laptop} {
    margin-top: 0px;
  }
`;

export const BlockTitle = styled.h3`
  margin-top: 15px;
  font-size: ${fontSize.large};
  line-height: 22px;
  text-align: center;

  @media ${device.laptop} {
    margin-top: 30px;
    font-size: ${fontSize.x_large};
    line-height: 28px;
    text-align: left;
  }
`;

export const MapContainer = styled.div`
  height: 400px;
  margin-top: 20px;
`;
