import React from 'react';

export const FiltersIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
      <g strokeMiterlimit="10" strokeWidth="1">
        <path
          d="M85.813 59.576H55.575a3 3 0 110-6h30.237a3 3 0 01.001 6z"
          transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
        <path
          d="M48.302 66.849c-5.664 0-10.272-4.608-10.272-10.272 0-5.665 4.608-10.273 10.272-10.273 5.665 0 10.273 4.608 10.273 10.273 0 5.663-4.608 10.272-10.273 10.272zm0-14.546a4.277 4.277 0 00-4.272 4.273 4.278 4.278 0 004.272 4.272 4.278 4.278 0 004.273-4.272 4.278 4.278 0 00-4.273-4.273z"
          transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
        <path
          d="M41.029 59.576H4.188a3 3 0 110-6H41.03a3 3 0 11-.001 6zM85.813 36.424h-57.79a3 3 0 110-6h57.79a3 3 0 110 6z"
          transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
        <path
          d="M20.75 43.697c-5.665 0-10.273-4.608-10.273-10.273s4.608-10.273 10.273-10.273 10.273 4.608 10.273 10.273-4.609 10.273-10.273 10.273zm0-14.546c-2.356 0-4.273 1.917-4.273 4.273s1.917 4.273 4.273 4.273 4.273-1.917 4.273-4.273-1.918-4.273-4.273-4.273z"
          transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
        <path
          d="M13.477 36.424H4.188a3 3 0 110-6h9.289a3 3 0 110 6zM57.637 13.273H4.188a3 3 0 110-6h53.449a3 3 0 110 6z"
          transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
        <path
          d="M64.909 20.546c-5.664 0-10.272-4.608-10.272-10.273S59.245 0 64.909 0c5.665 0 10.273 4.608 10.273 10.273s-4.608 10.273-10.273 10.273zm0-14.546c-2.355 0-4.272 1.917-4.272 4.273s1.917 4.273 4.272 4.273c2.356 0 4.273-1.917 4.273-4.273S67.266 6 64.909 6z"
          transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
        <path
          d="M85.813 13.273h-13.63a3 3 0 110-6h13.63a3 3 0 110 6zM85.813 82.728h-57.79a3 3 0 110-6h57.79a3 3 0 110 6z"
          transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
        <path
          d="M20.75 90c-5.665 0-10.273-4.608-10.273-10.272 0-5.665 4.608-10.273 10.273-10.273s10.273 4.608 10.273 10.273C31.022 85.392 26.414 90 20.75 90zm0-14.546a4.278 4.278 0 00-4.273 4.273c0 2.355 1.917 4.272 4.273 4.272s4.273-1.917 4.273-4.272a4.28 4.28 0 00-4.273-4.273z"
          transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
        <path
          d="M13.477 82.728H4.188a3 3 0 110-6h9.289a3 3 0 110 6z"
          transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
      </g>
    </svg>
  );
};
