import styled from 'styled-components';

import { color, fontSize } from 'theme';

export const TableWrapper = styled.div`
  margin-top: 40px;
`;

export const StyledTable = styled.table`
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid ${color.greayText};
    padding: 8px;
  }

  td {
    font-size: ${fontSize.small};
  }
`;

export const StyledTBody = styled.tbody`
  & tr:hover {
    background-color: ${color.disabled};
  }
`;

export const HeaderRaw = styled.tr`
  background: ${color.lightGrey};
`;

export const TableRaw = styled.tr`
  background: ${({ isEven }) => !isEven && color.disabled};
`;

export const Label = styled.label`
  color: ${color.blue};
`;