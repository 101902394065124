import React from 'react';
import { MainHeader } from 'Components/Header/MainHeader';
import {
  Block,
  Container,
  Paragraph,
  SubParagraph,
  SubDivParagraph,
  Title,
  ParagraphTitle,
  Number
} from './styles';
import { useTranslation } from 'react-i18next';
import { convertIntoRomanNumbers } from 'helpers/convertIntoRomanNumbers.js';

const hostname = window.location.hostname;

export const Conditions = () => {
  const { t } = useTranslation(['common']);

  const paragraphs = t('paragraphs.paragraphA', {
    returnObjects: true,
    website: `https://${hostname}`
  });
  const paragraphsB = t('paragraphs.paragraphB', { returnObjects: true });
  const paragraphsC = t('paragraphs.paragraphC', { returnObjects: true });
  const paragraphsD = t('paragraphs.paragraphD', { returnObjects: true });
  const paragraphsE = t('paragraphs.paragraphE', { returnObjects: true });
  const paragraphsF = t('paragraphs.paragraphF', { returnObjects: true });
  const paragraphsG = t('paragraphs.paragraphG', { returnObjects: true });
  const paragraphsH = t('paragraphs.paragraphH', { returnObjects: true });
  const paragraphsI = t('paragraphs.paragraphI', { returnObjects: true });
  const subA = t('paragraphs.subA', { returnObjects: true });
  const subB = t('paragraphs.subB', { returnObjects: true });
  const subC = t('paragraphs.subC', { returnObjects: true });

  return (
    <>
      <MainHeader />
      <Container>
        <Title>{t('paragraphTitles.title_main')}</Title>
        <Paragraph style={{ textAlign: 'center' }}>{t('paragraphTitles.paragraph_main')}</Paragraph>
        <Block>
          <ParagraphTitle>{t('paragraphTitles.title_1')}</ParagraphTitle>
          <Paragraph>
            {paragraphs.map((item, index) => (
              <React.Fragment key={index}>
                <Number>1.{index + 1}. </Number>
                <span>{item}</span> <br />
                <br />
                {index === 3 &&
                  subA.map((item: any, indexSub) => (
                    <SubDivParagraph key={indexSub}>
                      <SubParagraph>
                        <Number>
                          1.{index + 1}.{indexSub + 1}.
                        </Number>{' '}
                        <span>{item}</span> <br />
                        <br />
                      </SubParagraph>
                    </SubDivParagraph>
                  ))}
              </React.Fragment>
            ))}
          </Paragraph>
        </Block>

        <Block>
          <ParagraphTitle>{t('paragraphTitles.title_2')}</ParagraphTitle>
          {paragraphsB.map((item, index) => (
            <Paragraph key={index}>
              <Number>2.{index + 1}. </Number>
              <span>{item} </span>
              <br />
              <br />
            </Paragraph>
          ))}
        </Block>

        <Block>
          <ParagraphTitle>{t('paragraphTitles.title_3')}</ParagraphTitle>
          {paragraphsC.map((item, index) => (
            <Paragraph key={index}>
              <Number>3.{index + 1}. </Number>
              <span>{item} </span>
              <br />
              <br />
            </Paragraph>
          ))}
        </Block>

        <Block>
          <ParagraphTitle>{t('paragraphTitles.title_4')}</ParagraphTitle>
          {paragraphsD.map((item, index) => (
            <div key={index}>
              <Number>4.{index + 1}. </Number>
              <span>{item}</span> <br />
              <br />
              {index === 0 &&
                subB.map((item: any, indexSub: number) => (
                  <div
                    style={{ display: 'flex', marginLeft: '20px', alignItems: 'center' }}
                    key={indexSub}>
                    <Paragraph>
                      <Number>
                        4.{index + 1}.{indexSub + 1}.
                      </Number>{' '}
                      <span>{item}</span> <br />
                      <br />
                    </Paragraph>
                  </div>
                ))}
            </div>
          ))}
        </Block>

        <Block>
          <ParagraphTitle>{t('paragraphTitles.title_5')}</ParagraphTitle>
          {paragraphsE.map((item, index) => (
            <div key={index}>
              <Number>5.{index + 1}. </Number>
              <span>{item}</span> <br />
              <br />
              {index === 2 && (
                <ul style={{ marginLeft: '30px' }}>
                  {subC.map((item: any, indexSub: number) => (
                    <Paragraph key={indexSub}>
                      <Number>({convertIntoRomanNumbers(indexSub + 1)})</Number> {'  '}
                      {item}
                    </Paragraph>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </Block>

        <Block>
          <ParagraphTitle>{t('paragraphTitles.title_6')}</ParagraphTitle>
          {paragraphsF.map((item, index) => (
            <Paragraph key={index}>
              <Number>6.{index + 1}. </Number>
              <span>{item}</span> <br />
              <br />
            </Paragraph>
          ))}
        </Block>

        <Block>
          <ParagraphTitle>{t('paragraphTitles.title_7')}</ParagraphTitle>
          {paragraphsG.map((item, index) => (
            <Paragraph key={index}>
              <Number>7.{index + 1}. </Number>
              <span>{item}</span> <br />
              <br />
            </Paragraph>
          ))}
        </Block>

        <Block>
          <ParagraphTitle>{t('paragraphTitles.title_8')}</ParagraphTitle>
          {paragraphsH.map((item, index) => (
            <Paragraph key={index}>
              <Number>8.{index + 1}. </Number>
              <span>{item}</span> <br />
              <br />
            </Paragraph>
          ))}
        </Block>

        <Block>
          <ParagraphTitle>{t('paragraphTitles.title_9')}</ParagraphTitle>
          {paragraphsI.map((item, index) => (
            <Paragraph key={index}>{item}</Paragraph>
          ))}
        </Block>
      </Container>
    </>
  );
};
