import React from 'react';

export const CalendarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="99"
      fill="none"
      viewBox="0 0 104 99"
      {...props}>
      <path
        fill="#B4ACBC"
        d="M0 24.501l48.922-9.95a13.583 13.583 0 015.406 0l48.922 9.95v55.3c0 4.827-2.02 9.456-5.616 12.87C94.038 96.084 89.16 98 84.075 98h-64.9c-5.086 0-9.963-1.917-13.559-5.33C2.02 89.257 0 84.627 0 79.8v-55.3z"></path>
      <path
        fill="#F3EEF8"
        d="M3.688 31.169V78.75c0 4.177 1.748 8.183 4.86 11.137 3.112 2.954 7.332 4.613 11.733 4.613h59l20.281-19.834V31.169L51.626 24.5 3.687 31.17z"></path>
      <path
        fill="#998EA4"
        d="M22.863 38.501a.758.758 0 00-.522.205.683.683 0 00-.216.495v9.1c0 .385.332.7.738.7h9.587a.758.758 0 00.521-.205.682.682 0 00.217-.495v-9.1a.683.683 0 00-.217-.495.758.758 0 00-.521-.205h-9.588zm23.968 0a.758.758 0 00-.521.205.682.682 0 00-.216.495v9.1c0 .385.332.7.737.7h9.588a.758.758 0 00.521-.205.682.682 0 00.216-.495v-9.1a.683.683 0 00-.216-.495.758.758 0 00-.521-.205H46.83zm23.231.7c0-.385.332-.7.738-.7h9.588c.405 0 .737.315.737.7v9.1a.682.682 0 01-.216.495.758.758 0 01-.522.205H70.8a.758.758 0 01-.522-.205.682.682 0 01-.216-.495v-9.1zm-47.2 16.8a.758.758 0 00-.521.205.683.683 0 00-.216.495v9.1c0 .385.332.7.738.7h9.587a.758.758 0 00.521-.205.682.682 0 00.217-.495v-9.1a.683.683 0 00-.217-.495.758.758 0 00-.521-.205h-9.588zm23.232.7c0-.385.332-.7.737-.7h9.588c.405 0 .737.315.737.7v9.1a.682.682 0 01-.216.495.758.758 0 01-.521.205H46.83a.758.758 0 01-.521-.205.682.682 0 01-.216-.495v-9.1zm-23.232 16.8a.758.758 0 00-.521.205.683.683 0 00-.216.495v9.1c0 .385.332.7.738.7h9.587a.758.758 0 00.521-.205.682.682 0 00.217-.495v-9.1a.683.683 0 00-.217-.495.758.758 0 00-.521-.205h-9.588zm23.232.7c0-.385.332-.7.737-.7h9.588c.405 0 .737.315.737.7v9.1a.682.682 0 01-.216.495.758.758 0 01-.521.205H46.83a.758.758 0 01-.521-.205.682.682 0 01-.216-.495v-9.1zm24.706-.7a.758.758 0 00-.522.205.683.683 0 00-.216.495v9.1c0 .385.332.7.738.7h9.588a.758.758 0 00.521-.205.682.682 0 00.216-.495v-9.1a.683.683 0 00-.216-.495.758.758 0 00-.522-.205H70.8zm32.487 11.34v-11.34l-16.63 8.75-9.183 15.75h11.948c7.67 0 13.865-5.915 13.865-13.16z"></path>
      <path
        fill="#E1D8EC"
        d="M77.474 86.661v11.34l25.813-24.5H91.339c-7.633 0-13.865 5.915-13.865 13.16z"></path>
      <path
        fill="#0084CE"
        d="M103.25 23.749V31.5H0V23.75c0-7.33 6.232-13.283 13.861-13.248h71.084l2.685-1.995 2.847 2.037c7.124.525 12.773 6.23 12.773 13.206zM70.8 56a.758.758 0 00-.522.205.682.682 0 00-.216.495v9.1c0 .385.332.7.738.7h9.588a.758.758 0 00.521-.205.683.683 0 00.216-.495v-9.1a.682.682 0 00-.216-.495.758.758 0 00-.522-.205H70.8z"></path>
      <path
        fill="#CDC4D6"
        d="M35.105 10.501a5.93 5.93 0 00.8 4.044 6.445 6.445 0 003.293 2.676c1.21.448 2.286 1.41 2.286 2.64 0 1.669-1.456 3.062-3.152 2.617-2.726-.719-5.099-2.324-6.704-4.535-1.605-2.211-2.34-4.887-2.076-7.56a6.059 6.059 0 00-1.412-2.898 6.533 6.533 0 00-2.745-1.846 6.856 6.856 0 00-3.348-.304 6.695 6.695 0 00-3.063 1.32 6.217 6.217 0 00-1.964 2.591 5.9 5.9 0 00-.344 3.176 6.048 6.048 0 001.368 2.916 6.52 6.52 0 002.717 1.883c1.21.445 2.286 1.407 2.286 2.636 0 1.67-1.457 3.062-3.153 2.618-2.805-.745-5.231-2.429-6.83-4.74-1.6-2.31-2.264-5.092-1.871-7.831.393-2.74 1.818-5.251 4.01-7.071C17.395 1.013 20.208.007 23.121 0c3.698 0 7.006 1.579 9.218 4.067a11.983 11.983 0 014.135-3.003A12.576 12.576 0 0141.558.001c3.699 0 7.006 1.579 9.219 4.067a11.983 11.983 0 014.134-3.003A12.576 12.576 0 0159.996.001c3.698 0 7.006 1.579 9.218 4.067 1.54-1.732 3.585-2.992 5.879-3.62A12.676 12.676 0 0182.1.542c2.274.689 4.282 2.003 5.769 3.776a11.126 11.126 0 012.582 6.183h-5.568a6.119 6.119 0 00-2.178-3.738 6.733 6.733 0 00-4.21-1.512 6.745 6.745 0 00-4.239 1.434 6.134 6.134 0 00-2.254 3.697l.011.12h-.033a5.93 5.93 0 00.8 4.043 6.445 6.445 0 003.293 2.676c1.21.448 2.286 1.41 2.286 2.64 0 1.669-1.456 3.062-3.152 2.617-2.726-.719-5.099-2.324-6.704-4.535-1.605-2.211-2.34-4.887-2.076-7.56A6.131 6.131 0 0064.198 6.7a6.74 6.74 0 00-4.202-1.455A6.74 6.74 0 0055.793 6.7a6.132 6.132 0 00-2.228 3.682l.01.12h-.033a5.93 5.93 0 00.8 4.043 6.445 6.445 0 003.294 2.676c1.21.448 2.286 1.41 2.286 2.64 0 1.669-1.457 3.062-3.153 2.617-2.725-.719-5.098-2.324-6.703-4.535-1.605-2.211-2.34-4.887-2.077-7.56A6.131 6.131 0 0045.761 6.7a6.74 6.74 0 00-4.203-1.455A6.74 6.74 0 0037.355 6.7a6.132 6.132 0 00-2.228 3.682l.011.12h-.033z"></path>
    </svg>
  );
};
