import React, { FC } from "react";
import Loader from "react-loader-spinner";

import { color } from "theme";
import { Wrapper, LoaderContainer } from "./style";

export const Loading: FC = () => {
  return (
    <Wrapper>
      <LoaderContainer>
        <Loader
          type="TailSpin"
          color={color.blue}
          height={100}
          width={100}
        />
      </LoaderContainer>
    </Wrapper>
  );
};

export const SmallLoader: FC = () => {
  return (
    <LoaderContainer>
      <Loader type="TailSpin" color={color.blue} height={100} width={100} />
    </LoaderContainer>
  );
};
