import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SellParkingFilterContext } from 'context/sellParkingContext';
import { SellParkingFilterContextType } from 'context/types';
import axios, { AxiosError } from 'axios';
import { useInfiniteQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { PARKING_PLACES_FOR_SALE_API_URL } from 'config';
import { NOTIFICATION_OPTIONS, RESULTS_PER_PAGE } from 'Constants/constants';
import { ErrorResponse } from 'types/parkingTypes';
import { RootState } from 'Redux/store';

import { SmallLoader } from 'Components/Loader/Loader';
import { SellParkingsList } from '../components/SellParkingsList';
import { SellParkingsListMap } from 'Components/GoogleMap/SellParkingsListMap';
// import { ShortenFooter } from 'Components/Footer/ShortenFooter';

import { StyledNotification } from 'Components/Profile/OnDemand/style';

import {
  ParkingSearchContainer,
  ParkingSearchResultsWrapper,
  MapWithParkingsWrapper,
  MobileMapContainer
} from '../style.js';

export const SellParkingSearchResults = () => {
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const {
    eCharger,
    sortType,
    parkingType,
    minPrice,
    maxPrice,
    carClass,
    isMobileMapOpen,
    setCoordinates
  } = useContext(SellParkingFilterContext) as SellParkingFilterContextType;
  const chosenCoordinates = useSelector(
    (state: RootState) => state.citySearchValue.searchValue.coordinates
  );
  const searchRangeRadius = useSelector(
    (state: RootState) => state.citySearchValue.searchValue.range
  );

  const urlParams = new URLSearchParams(location.search);

  const lng = urlParams.get('lng');
  const lat = urlParams.get('lat');
  const radius = urlParams.get('searchRadius');

  const getParkingPlaces = async ({ pageParam = 1 }) => {
    if (!lat && !lng) {
      return {
        data: {
          count: '0',
          results: []
        },
        nextPage: 1,
        pageParam: 1
      };
    } else {
      const queryUrl = `${PARKING_PLACES_FOR_SALE_API_URL}?limit=${RESULTS_PER_PAGE}&page=${pageParam}
      ${searchRangeRadius || radius ? `&range=${searchRangeRadius || radius}` : ''}
      &lat=${chosenCoordinates?.lat || lat}
      &lng=${chosenCoordinates?.lng || lng}
      ${!!minPrice ? `&minPrice=${minPrice}` : ''}
      ${!!maxPrice ? `&maxPrice=${maxPrice}` : ''}
      ${eCharger ? `&electricCharge=${eCharger}` : ''}
      ${
        parkingType === 'UNDEGROUND' || parkingType === 'OVERGROUND'
          ? `&parkingType=${parkingType}`
          : ''
      }
      ${carClass ? `&carClass=${carClass}` : ''}&sortingType=${sortType}`;

      const res = await axios.get(queryUrl.replace(/\s/g, ''));

      return {
        data: res.data,
        nextPage: pageParam + 1,
        pageParam: pageParam + 1
      };
    }
  };

  const { status, data, isSuccess, fetchNextPage, isFetching, hasNextPage } = useInfiniteQuery(
    [
      eCharger,
      parkingType,
      carClass,
      minPrice,
      maxPrice,
      sortType,
      chosenCoordinates,
      searchRangeRadius,
      lat,
      lng,
      radius
    ],
    getParkingPlaces,
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        const totalPages = Math.ceil(Number(allPages[0].data.count) / RESULTS_PER_PAGE);
        const currentPage = lastPage.nextPage;
        const nextPage = lastPage.nextPage <= totalPages ? currentPage : undefined;
        return nextPage;
      },
      onError: (response: AxiosError<ErrorResponse>) => {
        if (response.response?.status === 500) {
          toast.error(t('notifications.server_error'), NOTIFICATION_OPTIONS);
        }
      }
    }
  );

  useEffect(() => {
    if (chosenCoordinates) {
      setCoordinates(chosenCoordinates);
    }
  }, [chosenCoordinates, setCoordinates]);

  if (status === 'loading') return <SmallLoader />;

  if (status === 'error') return <div>{t('notifications.connection_failed')}</div>;

  if (!lat && !lng)
    return <StyledNotification>{t('pagesParking.sell_main_title')}</StyledNotification>;

  return (
    <ParkingSearchContainer>
      <MobileMapContainer isOpen={isMobileMapOpen}>
        {isSuccess && data && <SellParkingsListMap places={data.pages} />}
      </MobileMapContainer>

      <ParkingSearchResultsWrapper className="parkings-list">
        {isSuccess && data && (
          <SellParkingsList
            data={data.pages}
            fetchNextPage={fetchNextPage}
            isFetching={isFetching}
            hasNextPage={Boolean(hasNextPage)}
            searchRangeRadius={searchRangeRadius || radius}
          />
        )}
        {/* <ShortenFooter /> */}
      </ParkingSearchResultsWrapper>
      <MapWithParkingsWrapper style={{ height: '100%', width: '100%' }}>
        {isSuccess && data && <SellParkingsListMap places={data.pages} radius={radius} />}
      </MapWithParkingsWrapper>
    </ParkingSearchContainer>
  );
};
