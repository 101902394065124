import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'Components/UI/Button/Button';

import { Container, Form, Input, Balance } from './style';

function AddBalance({ onPopup, balance }: { onPopup: (amount: number) => void; balance?: number }) {
  const { t } = useTranslation(['admin']);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm<{ balance: number }>();

  function onSubmit(data: { balance: number }) {
    onPopup(data.balance);
    reset();
  }

  return (
    <Container>
      <Balance>
        <span>{t('admin.balance')}</span>
        {balance}
      </Balance>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input {...register('balance')} placeholder={t('payments.topup_sum')} />
        <Button primary disabled={!isDirty}>
          {t('admin.topup')}
        </Button>
      </Form>
    </Container>
  );
}

export default AddBalance;
