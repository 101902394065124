import React, { useMemo, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AxiosResponse, AxiosError } from "axios";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "Redux/store";
import {
  getOneParkingPlace,
  getParkingReviews,
  ParkingPlace,
} from "api/parking";
import { getAllParkingsError } from "Redux/reducers/parking/actions";
import { getSingleParking } from "Redux/reducers/singleParking/actions";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { MainHeader } from 'Components/Header/MainHeader';
import { ParkingDescription } from "Components/ParkingDescription/ParkingDescription";
import { UserCard } from "Components/UserCard/UserCard";
import { BookParking } from "Components/BookParking/BookParking";
import { Loading } from "Components/Loader/Loader";
import { Rating, RatingCard } from "Components/RatingCard/RatingCard";
import { GoogleMap } from "Components/GoogleMap/GoogleMap";
import { Background } from "Pages/Parking/style";
import { StyledNotification } from "Components/Profile/OnDemand/style";
import {
  MainContainer,
  BannerWrapper,
  ParkingInfoContainer,
  RightCont,
  StyledImage,
  ImgWrapper,
  MapContainer,
  BlockTitle,
  LeftContainer
} from './style';
import avaImage from 'assets/img/ava.png';
import { setSearchValue } from 'Redux/reducers/citySearch/citySearchReducer';
export type UserProps = {
  name: string;
  src?: string;
  isVerified?: boolean | null;
};

export type ParkingPrice = {
  price: number | undefined;
};

export const AddParking = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const [reviews, setReviews] = useState<Rating[]>();
  const [carNames, setCarNames] = useState<string[]>([]);
  const params = new URLSearchParams(useLocation().search);
  const id = params.get('parkingId');
  const from = params.get('from');
  const to = params.get('to');
  const cityId = Number(params.get('cityId'));
  const searchLat = params.get('searchLat');
  const searchLng = params.get('searchLng');
  const chosenLabel = params.get('searchingLocation');
  const searchRangeRadius = params.get('searchRadius');
  const { data, status } = useQuery(
    'getOneParkingPlace',

    () => getOneParkingPlace({ id }),
    {
      onSuccess: (response: AxiosResponse<ParkingPlace>) => {
        dispatch(getSingleParking(response.data));
      },
      onError: (error: AxiosError) => {
        dispatch(getAllParkingsError(t('notifications.connection_failed')));
      }
    }
  );

  const coordinates = { lat: Number(searchLat), lng: Number(searchLng) };

  dispatch(setSearchValue({ coordinates, chosenLabel, value: { label: chosenLabel } }));

  const userInfo: ParkingPlace | null | undefined = useSelector(
    (state: RootState) => state?.singleParking?.data
  );

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { results, carNamesArr }
        } = await getParkingReviews({
          parkingPlaceId: Number(id)
        });
        setReviews(results);
        carNamesArr?.length && setCarNames(carNamesArr);
      } catch (error) {}
    })();
  }, [id]);

  const images = useMemo(
    () =>
      data?.data?.files
        .filter((file) => file.type === 'IMAGE' || file.type === 'MAIN_IMAGE')
        .sort((a, b) => (a.type === 'MAIN_IMAGE' ? -1 : 1))
        .map((image) => (
          <ImgWrapper key={image.id}>
            <StyledImage src={`${image.path}`} />
          </ImgWrapper>
        )),
    [data]
  );

  if (status === 'loading') return <Loading />;
  if (status === 'error') {
    return <div>{t('notifications.connection_failed')}</div>;
  }

  return (
    <Background height="auto">
      <MainHeader />
      <MainContainer>
        {images && images.length > 0 && (
          <BannerWrapper>
            <Carousel showThumbs={false} showStatus={false}>
              {images}
            </Carousel>
          </BannerWrapper>
        )}
        <ParkingInfoContainer>
          <LeftContainer>
            <ParkingDescription />
          </LeftContainer>
          <RightCont>
            <UserCard
              isVerified={userInfo?.status === 'APPROVED_VERIFIED'}
              name={userInfo!.user.name}
              src={avaImage}
            />
            <BookParking
              from={new Date(from as string)}
              to={new Date(to as string)}
              id={Number(id)}
              cityId={cityId}
              currency={data?.data.currency}
              searchRangeRadius={searchRangeRadius}
              searchLat={searchLat}
              searchLng={searchLng}
              chosenLabel={chosenLabel}
            />
          </RightCont>
        </ParkingInfoContainer>
        <BlockTitle>{t('titles.map')}</BlockTitle>
        <MapContainer>
          <GoogleMap
            notChange={true}
            //@ts-expect-error
            lat={data?.data?.positionOfEntry.coordinates[0]}
            //@ts-expect-error
            lng={data?.data?.positionOfEntry.coordinates[1]}
          />
        </MapContainer>
        <BlockTitle>{t('titles.feedbacks')}</BlockTitle>
        {reviews?.map((review) => {
          const carName = carNames[reviews.indexOf(review)];
          return <RatingCard key={review.id} rating={review} carName={carName} />;
        })}
        {!reviews?.length && (
          <StyledNotification>{t('notifications.no_feedbacks')}</StyledNotification>
        )}
      </MainContainer>
    </Background>
  );
};
