import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { paths } from 'config';
import { RootState } from 'Redux/store';
import { createNewParking, CreateParkingResponse } from 'api/parking';
import { useModalRegister } from 'hooks/useModal';
import { shortenFileName } from 'helpers/shortenFileName';
import { MainHeader } from 'Components/Header/MainHeader';
import { ModalAuth } from 'Components/Modal/ModalAuth';
import { GoogleMap } from 'Components/GoogleMap/GoogleMap';
import { ImagePreview } from 'Components/ImagePreview/ImagePreview';
import {
  GoogleAutoCompleteStreet,
  GoogleCityAutoCompleteCreate
} from 'Components/GoogleAutoComplete/GoogleAutoCompleteSearch';
import { Background } from 'Pages/Parking/style';
import { MainContainer } from 'Pages/AddParking/style';
import { StyledInput } from 'Components/UI/Input/style';
import { RequiredLabel } from 'Components/UI/StyledLabel/style';
import { MapWrapper } from 'Components/UI/Map/style';
import { Button } from 'Components/UI/Button/Button';
import { Tooltip } from 'Components/UI/Tooltip/style';
import { Notification } from 'Components/UI/Notification/Notification';
import pdfIcon from 'assets/img/pdf.png';
import {
  StyledTitle,
  InfoContainer,
  ContentWrapper,
  Select,
  TextArea,
  UploadFile,
  UploadLabel,
  SmallContainer,
  TextP,
  PreviewImgContainer,
  FilesCont
} from './style';
import { UpperPlaceholder, DownPlaceholder } from 'Pages/EditParking/style';
import { color } from 'theme';
import { checkExpiry } from 'api/auth';
import { logout } from 'Redux/reducers/login/loginReducer';
import { Loading } from 'Components/Loader/Loader';
import * as yup from 'yup';
import { createCity } from 'api/cities';
import {
  regexHouseNumber,
  regexPlaceNumber,
  regexDimensionsValue,
  regexNumberValue,
  regexOnlyNumber,
  regexLevel,
  NOTIFICATION_OPTIONS
} from 'Constants/constants';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Auto } from 'types/parkingTypes';

export type FormValues = {
  cityId: number;
  street: string;
  house: string;
  placeNumber: string;
  parkingLevel: string;
  placeWidth: number | string;
  placeLength: number | string;
  heightOfParkingEntry: number | string;
  pricePerDay: number;
  pricePerMonth?: number;
  carClass: string;
  security?: boolean | null | string | number;
  parkingType?: string | null;
  electricCharge?: boolean | null | string | number;
  elevator?: boolean | null | string | number;
  sector?: string;
  owner?: boolean;
  parkingName?: string;
  description?: string;
  familyPlace?: boolean | null | string | number;
  lat?: number;
  lng?: number;
  entrance?: boolean;
};

export interface ICoordinates {
  lat: number;
  lng: number;
}

export const CreateParking = () => {
  const history = useHistory();

  const token = useSelector((state: RootState) => state.auth.accessToken);
  const { t } = useTranslation(['common']);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({
    resolver: yupResolver(
      yup.object().shape({
        house: yup.string().required().matches(regexHouseNumber, t('validation.houseNumber')),
        placeNumber: yup.string().required().matches(regexPlaceNumber, t('validation.placeNumber')),
        parkingLevel: yup.string().required().matches(regexLevel, t('validation.placeNumber')),
        placeWidth: yup
          .string()
          .required()
          .matches(regexDimensionsValue, t('validation.dimensionsNumber')),
        placeLength: yup
          .string()
          .required()
          .matches(regexDimensionsValue, t('validation.dimensionsNumber')),
        heightOfParkingEntry: yup
          .string()
          .required()
          .matches(regexDimensionsValue, t('validation.dimensionsNumber')),
        pricePerDay: yup.string().required().matches(regexOnlyNumber, t('validation.onlyNumber')),
        carClass: yup.string().required(),
        security: yup.boolean().nullable(),
        parkingType: yup.string().required(),
        electricCharge: yup.boolean().nullable(),
        elevator: yup.boolean().nullable(),
        sector: yup.string().nullable(),
        owner: yup.boolean().nullable(),
        parkingName: yup.string().nullable(),
        description: yup.string().nullable(),
        familyPlace: yup.boolean().nullable(),
        lat: yup.number(),
        lng: yup.number()
      })
    )
  });
  const { isShownRegister, toggleRegister } = useModalRegister();

  const [filesSelected, setFilesSelected] = useState<File[]>([]);
  const [imagesSelected, setImageSelected] = useState<File[]>([]);
  const [imgUrls, setImgUrls] = useState<string[]>([]);
  const [cityId, setCityId] = useState<number>(0);
  const [street, setStreet] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [chosenCity, setChosenCity] = useState<{ [key: string]: any } | null>(null);
  const [coordinates, setCoordinates] = useState<ICoordinates | null>(null);
  const [newCoordinates, setNewCoordinates] = useState<ICoordinates | null>(null);
  const [mainIndex, setMain] = useState(0);
  const [loading, setLoading] = useState(false);
  const [docsError, setDocsError] = useState(false);
  const [countError, setCountError] = useState(false);
  const [inputValueHouseNumber, setInputValueHouseNumber] = useState<string>('');
  const [inputValuePlaceNumber, setInputValuePlaceNumber] = useState<string>('');
  const [inputChanceLevel, setInputChanceLevel] = useState<string>('');
  const [inputWidthValue, setInputWidthValue] = useState<string>('');
  const [inputLengthValue, setInputLengthValue] = useState<string>('');
  const [inputHeightValue, setInputHeightValue] = useState<string>('');
  const [pricePerDayValue, setPricePerDayValue] = useState<string>('');
  const [autocompleteCityErrors, setAutocompleteCityErrors] = useState(false);
  const [autocompleteStreetErrors, setAutocompleteStreetErrors] = useState(false);
  const [customStyle, setCustomStyle] = useState<{} | null>(null);
  const inputPhotosRef = useRef<any>(null);

  useEffect(() => {
    if (chosenCity?.serverCityId) {
      setCityId(chosenCity?.serverCityId);
    }
    setNewCoordinates(coordinates);
  }, [chosenCity, coordinates]);

  const classAuto: Auto[] = [
    { value: 'FIRST', label: t('parking.FIRST') },
    { value: 'SECOND', label: t('parking.SECOND') },
    { value: 'THIRD', label: t('parking.THIRD') }
  ];
  const handleRegister = useCallback(() => {
    toggleRegister();
  }, [toggleRegister]);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const token = await checkExpiry(localStorage.getItem('accessToken'));
      if (!token) {
        dispatch(logout());
        history.push('/');
      }
    })();
  }, [dispatch, history]);

  useEffect(() => {
    if (!token) {
      handleRegister();
    }
    // eslint-disable-next-line
  }, [token]);

  const { mutateAsync } = useMutation(createNewParking, {
    onError: (error: AxiosError) => {
      setLoading(false);
      let message: string = '';
      if (error.response?.status === 500) {
        message = t('notifications.created_error');
      } else if (error.response?.status === 403 || error.response?.status === 401) {
        message = t('notifications.need_auth');
      } else if (error.response?.status === 409 || error.response?.status === 404) {
        message = t('notifications.parking_already_exists');
      } else {
        message = t('notifications.something_wrong');
      }

      toast.error(message, NOTIFICATION_OPTIONS);

      setIsDisabled(false);
    },
    onSuccess: (response: AxiosResponse<CreateParkingResponse>) => {
      if (response.status === 201) {
        setIsDisabled(false);
        setLoading(false);
        history.push({
          pathname: `${paths.partnerprofile}`,
          state: { cityId: cityId, show: true }
        });
      }
    }
  });

  const handleDocsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    const arr = Array.from(fileList);

    const err = arr.some((element) => {
      if (element.size / 1024 ** 2 > 10) {
        return true;
      }
      return false;
    });

    setDocsError(err);
    if (err) return;

    const newFilesList = [...filesSelected, ...arr].slice(0, 5);

    setFilesSelected(newFilesList);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageList = e.target.files;
    if (!imageList) return;

    const imagesUrls: string[] = [];

    const arr = Array.from(imageList);

    arr.forEach((element) => {
      const img = new Image();
      let blob = URL.createObjectURL(element);
      img.src = blob;

      imagesUrls.push(img.src);
    });

    const images = [...imagesSelected, ...arr];
    setImageSelected(images);

    // if (images.length > 5) {
    //   setCountError(true);
    //   setIsDisabled(true);
    // }

    setImgUrls([...imgUrls, ...imagesUrls]);
  };

  const deleteFile = (id: number) => {
    const newFilesList = filesSelected.filter((item, index) => index !== id);

    setFilesSelected(newFilesList);
  };

  const deleteImage = (id: number) => {
    if (id < mainIndex) setMain(mainIndex - 1);
    if (id === mainIndex) setMain(0);

    const deletePhotoArr = imgUrls.filter((item, index) => index !== id);

    setImgUrls(deletePhotoArr);

    const newImagesList = imagesSelected.filter((item, index) => index !== id);
    setImageSelected(newImagesList);

    if (newImagesList.length === 0) {
      inputPhotosRef.current.value = '';
    }

    if (newImagesList.length <= 5) {
      setCountError(false);
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    if (!countError) setIsDisabled(false);
  }, [countError]);

  const onChanceHouseNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexHouseNumber)) {
      setInputValueHouseNumber(e.target.value);
    }
  };
  const onChancePlaceNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexPlaceNumber)) {
      setInputValuePlaceNumber(e.target.value);
    }
  };
  const onChanceLevel = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexLevel)) {
      setInputChanceLevel(e.target.value);
    }
  };
  const onChanceWidth = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexDimensionsValue)) {
      setInputWidthValue(e.target.value);
    }
  };
  const onChanceLength = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexDimensionsValue)) {
      setInputLengthValue(e.target.value);
    }
  };
  const onChanceHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexDimensionsValue)) {
      setInputHeightValue(e.target.value);
    }
  };
  const onChancePricePerDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexNumberValue)) {
      setPricePerDayValue(e.target.value);
    }
  };

  const onSubmit = useCallback(
    async (data: FormValues) => {
      if (!chosenCity || !street) {
        if (!chosenCity) setAutocompleteCityErrors(true);
        if (!street) setAutocompleteStreetErrors(true);
        window.scrollTo(0, 0);
        return;
      }

      setIsDisabled(true);
      let formData = new FormData();

      if (filesSelected) {
        Array.from(filesSelected).forEach((file) => {
          formData.append('document', file);
        });
      }

      if (cityId === 0) {
        const res = await createCity(chosenCity?.placeId);
        formData.append('cityId', res.id);
      } else {
        formData.append('cityId', cityId.toString());
      }

      if (imagesSelected.length > 0) {
        formData.append('mainImage', imagesSelected[mainIndex]);
        Array.from(imagesSelected).forEach((file, index) => {
          if (index !== mainIndex) formData.append('image', file);
        });
      }

      Object.keys(data).forEach(
        (el: string) =>
          data[el as keyof FormValues] != null &&
          data[el as keyof FormValues] !== '' &&
          formData.append(el, data[el as keyof FormValues]?.toString() || '')
      );

      const newPricePerMonth = 30 * +data.pricePerDay;

      formData.set('pricePerMonth', newPricePerMonth.toString());
      newCoordinates && formData.append('lat', newCoordinates.lat.toString());
      newCoordinates && formData.append('lng', newCoordinates.lng.toString());
      formData.append('street', `${chosenCity?.cityTitle}, ${street}`);
      // @ts-ignore
      formData.append('streetLat', coordinates?.lat);
      // @ts-ignore
      formData.append('streetLng', coordinates?.lng);

      setLoading(true);
      mutateAsync({
        token: token,
        formData
      });
    },
    [
      filesSelected,
      cityId,
      imagesSelected,
      newCoordinates,
      street,
      coordinates?.lat,
      coordinates?.lng,
      mutateAsync,
      token,
      mainIndex,
      chosenCity
    ]
  );

  const handleCoordinates = (data: any) => {
    setCoordinates(data);
  };

  const renderOptions = (arr: Auto[]) => {
    const options = arr.map((item) => {
      return (
        <option key={item.value} value={item.value}>
          {t(`parking.${item.value}`)}
        </option>
      );
    });
    return options;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedAuto = useMemo(() => renderOptions(classAuto), []);

  useEffect(() => {
    if (street) {
      setAutocompleteStreetErrors(false);
    }

    if (chosenCity) {
      setAutocompleteCityErrors(false);
    }

    if (Object.keys(errors).length !== 0) {
      if (!street) {
        setAutocompleteStreetErrors(true);
      }
      if (!chosenCity) {
        setAutocompleteCityErrors(true);
      }
    }
  }, [street, chosenCity, errors]);

  async function getNewCoordinates(address: string) {
    const result = await geocodeByAddress(address);
    const latLng = await getLatLng(result[0]);
    if (latLng) {
      handleCoordinates(latLng);
    }
  }
  // Set new google map coordinates when house number input
  useEffect(() => {
    if (street && inputValueHouseNumber && chosenCity?.formattedCityAddress) {
      getNewCoordinates(`${street}, ${inputValueHouseNumber}, ${chosenCity?.formattedCityAddress}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueHouseNumber, street, chosenCity?.formattedCityAddress]);

  const mapWrapperStyle = useCallback(() => {
    if (!customStyle) {
      setCustomStyle({ position: 'fixed', left: 0, top: 0 });
    } else {
      setCustomStyle(null);
    }
  }, [customStyle]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', mapWrapperStyle);
    return () => {
      document.removeEventListener('fullscreenchange', mapWrapperStyle);
    };
  }, [mapWrapperStyle]);

  return (
    <>
      <ModalAuth isShown={isShownRegister} hide={handleRegister} />
      <Background height="auto">
        <MainHeader />
        <MainContainer>
          {loading && <Loading />}
          <InfoContainer autocomplete="off">
            <StyledTitle>{t('titles.general_info')}</StyledTitle>
            <ContentWrapper height={isMobile ? '380' : ' '}>
              <RequiredLabel htmlFor="cityId" required={true} marginBottom={isMobile ? '10' : '0'}>
                <UpperPlaceholder>{t('placeholders.city')}</UpperPlaceholder>
                <GoogleCityAutoCompleteCreate
                  setChosenCity={setChosenCity}
                  error={autocompleteCityErrors}
                />
                <Notification isAccent={true}>
                  {autocompleteCityErrors && t('login.required')}
                </Notification>
              </RequiredLabel>

              <RequiredLabel htmlFor="street" required={true} marginBottom={isMobile ? '50' : '20'}>
                <UpperPlaceholder>{t('placeholders.street')}</UpperPlaceholder>
                <GoogleAutoCompleteStreet
                  setStreet={setStreet}
                  city={chosenCity?.formattedCityAddress}
                  handleCoordinates={handleCoordinates}
                  error={autocompleteStreetErrors}
                />
                <Notification isAccent={true}>
                  {autocompleteStreetErrors && t('login.required')}
                </Notification>
                <DownPlaceholder>{t('notifications.wrong_street')}</DownPlaceholder>
              </RequiredLabel>

              <RequiredLabel htmlFor="house" required={true} marginBottom={isMobile ? '10' : '0'}>
                <UpperPlaceholder>{t('placeholders.house')}</UpperPlaceholder>
                <StyledInput
                  {...register('house')}
                  value={inputValueHouseNumber}
                  onChange={onChanceHouseNumber}
                  islight={true}
                  width={isMobile ? '300px' : '350px'}
                  error={errors.house}
                />
                <Notification isAccent={true}>
                  {errors?.house?.type === 'required' && t('login.required')}
                  {errors?.house?.type === 'matches' && errors.house.message}
                </Notification>
              </RequiredLabel>

              <RequiredLabel
                htmlFor="placeNumber"
                required={true}
                marginBottom={isMobile ? '10' : '0'}>
                <UpperPlaceholder>{t('placeholders.place_number')}</UpperPlaceholder>
                <StyledInput
                  {...register('placeNumber')}
                  value={inputValuePlaceNumber}
                  onChange={onChancePlaceNumber}
                  islight={true}
                  width={isMobile ? '300px' : '350px'}
                  refs={register}
                  error={errors.placeNumber}
                />
                <Notification isAccent={true}>
                  {errors?.placeNumber?.type === 'required' && t('login.required')}
                  {errors?.placeNumber?.type === 'matches' && errors.placeNumber.message}
                </Notification>
              </RequiredLabel>

              <RequiredLabel
                htmlFor="parkingLevel"
                required={true}
                marginBottom={isMobile ? '10' : '0'}>
                <UpperPlaceholder>{t('placeholders.parking_level')}</UpperPlaceholder>
                <StyledInput
                  {...register('parkingLevel')}
                  value={inputChanceLevel}
                  onChange={onChanceLevel}
                  islight={true}
                  width={isMobile ? '300px' : '350px'}
                  refs={register}
                  error={errors.parkingLevel}
                />
                <Notification isAccent={true}>
                  {errors?.parkingLevel?.type === 'required' && t('login.required')}
                  {errors?.parkingLevel?.type === 'matches' && errors.parkingLevel.message}
                </Notification>
              </RequiredLabel>
            </ContentWrapper>
          </InfoContainer>

          <InfoContainer>
            <StyledTitle>{t('titles.size')}</StyledTitle>
            <ContentWrapper height={isMobile ? '210' : ' '}>
              <RequiredLabel
                htmlFor="placeWidth"
                required={true}
                marginBottom={isMobile ? '10' : '0'}>
                <UpperPlaceholder>{t('placeholders.place_width')}</UpperPlaceholder>
                <StyledInput
                  {...register('placeWidth')}
                  value={inputWidthValue}
                  onChange={onChanceWidth}
                  islight={true}
                  placeholder={`${t('placeholders.example')} 4.20 м.`}
                  width={isMobile ? '300px' : '350px'}
                  error={errors.placeWidth}
                />
                <Notification isAccent={true}>
                  {errors?.placeWidth?.type === 'required' && t('login.required')}
                  {errors?.placeWidth?.type === 'matches' && errors.placeWidth.message}
                </Notification>
              </RequiredLabel>
              <RequiredLabel
                htmlFor="placeLength"
                required={true}
                marginBottom={isMobile ? '10' : '0'}>
                <UpperPlaceholder>{t('placeholders.place_length')}</UpperPlaceholder>
                <StyledInput
                  {...register('placeLength')}
                  value={inputLengthValue}
                  onChange={onChanceLength}
                  islight={true}
                  placeholder={`${t('placeholders.example')} 6.10 м.`}
                  width={isMobile ? '300px' : '350px'}
                  error={errors.placeLength}
                />
                <Notification isAccent={true}>
                  {errors?.placeLength?.type === 'matches' && errors.placeLength.message}
                </Notification>
              </RequiredLabel>
              <RequiredLabel
                htmlFor="heightOfParkingEntry"
                required={true}
                marginBottom={isMobile ? '10' : '0'}>
                <UpperPlaceholder>{t('placeholders.entrance_height')}</UpperPlaceholder>
                <StyledInput
                  {...register('heightOfParkingEntry')}
                  value={inputHeightValue}
                  onChange={onChanceHeight}
                  islight={true}
                  placeholder={`${t('placeholders.example')} 7.50 м.`}
                  width={isMobile ? '300px' : '350px'}
                  error={errors.heightOfParkingEntry}
                />
                <Notification isAccent={true}>
                  {errors?.heightOfParkingEntry?.type === 'required' && t('login.required')}
                  {errors?.heightOfParkingEntry?.type === 'matches' &&
                    errors.heightOfParkingEntry.message}
                </Notification>
              </RequiredLabel>
            </ContentWrapper>
          </InfoContainer>

          <InfoContainer>
            <StyledTitle>{t('titles.price')}</StyledTitle>
            <ContentWrapper align={!isMobile && 'start'} height={isMobile ? '120' : ' '}>
              <RequiredLabel
                htmlFor="pricePerDay"
                required={true}
                marginRight={isMobile ? '0' : '70'}>
                <UpperPlaceholder>{t('placeholders.price_day')}</UpperPlaceholder>
                <StyledInput
                  {...register('pricePerDay')}
                  value={pricePerDayValue}
                  onChange={onChancePricePerDay}
                  islight={true}
                  placeholder={t('placeholders.price_day')}
                  width={isMobile ? '300px' : '350px'}
                  marginRight={isMobile ? '0' : '70px'}
                  error={errors.pricePerDay}
                />
                <Notification isAccent={true}>
                  {errors?.pricePerDay?.type === 'required' && t('login.required')}
                  {errors?.pricePerDay?.type === 'matches' && errors.pricePerDay.message}
                </Notification>
              </RequiredLabel>
            </ContentWrapper>
          </InfoContainer>

          <InfoContainer>
            <StyledTitle>{t('titles.additional_info')}</StyledTitle>
            <ContentWrapper margin_b="20" height={isMobile ? '440' : ' '}>
              <Tooltip />
              <RequiredLabel
                htmlFor="carClass"
                required={true}
                marginBottom={isMobile ? '10' : '20'}
                data-tip={t('parking.car_class_desc')}>
                <UpperPlaceholder>{t('parking.car_class')}</UpperPlaceholder>
                <Select required {...register('carClass')} error={errors.carClass}>
                  <option value="" hidden>
                    {t('parking.car_class')}
                  </option>
                  {memoizedAuto}
                </Select>
              </RequiredLabel>
              <RequiredLabel htmlFor="security" marginBottom={isMobile ? '10' : '0'}>
                <UpperPlaceholder>{t('placeholders.security')}</UpperPlaceholder>
                <Select defaultValue="0" required {...register('security')}>
                  <option value="0">
                    {t('placeholders.security')} {t('placeholders.not_exists')}
                  </option>
                  <option value="1">
                    {t('placeholders.security')} {t('placeholders.exists')}
                  </option>
                </Select>
              </RequiredLabel>
              <RequiredLabel required htmlFor="parkingType" marginBottom={isMobile ? '10' : '0'}>
                <UpperPlaceholder>{t('placeholders.parking_types')}</UpperPlaceholder>
                <Select
                  error={errors.parkingType}
                  required
                  {...register('parkingType')}
                  refs={register}>
                  <option value="" hidden>
                    {t('placeholders.parking_types')}
                  </option>
                  <option value="UNDEGROUND">{t('placeholders.underground')}</option>
                  <option value="OVERGROUND">{t('placeholders.ground')}</option>
                </Select>
              </RequiredLabel>
              <RequiredLabel htmlFor="electricCharge" marginBottom={isMobile ? '10' : '20'}>
                <UpperPlaceholder>{t('placeholders.electric_charger')}</UpperPlaceholder>
                <Select required {...register('electricCharge')}>
                  <option value="0">
                    {t('placeholders.electric_charger')} {t('placeholders.not_exists')}
                  </option>
                  <option value="1">
                    {t('placeholders.electric_charger')} {t('placeholders.exists')}
                  </option>
                </Select>
              </RequiredLabel>
              <RequiredLabel htmlFor="elevator" marginBottom={isMobile ? '10' : '0'}>
                <UpperPlaceholder>{t('placeholders.elevator')}</UpperPlaceholder>
                <Select required defaultValue="0" {...register('elevator')}>
                  <option value="1">
                    {t('placeholders.elevator')} {t('placeholders.exists')}
                  </option>
                  <option value="0">
                    {t('placeholders.elevator')} {t('placeholders.not_exists')}
                  </option>
                </Select>
              </RequiredLabel>
              <RequiredLabel htmlFor="sector" marginBottom={isMobile ? '10' : '0'}>
                <UpperPlaceholder>{t('placeholders.section')}</UpperPlaceholder>
                <StyledInput
                  type="text"
                  islight={true}
                  placeholder={t('placeholders.section')}
                  width={isMobile ? '300px' : '350px'}
                  {...register('sector')}
                />
              </RequiredLabel>
              <RequiredLabel htmlFor="parkingName">
                <UpperPlaceholder>{t('placeholders.park_name')}</UpperPlaceholder>
                <StyledInput
                  type="text"
                  islight={true}
                  placeholder={t('placeholders.park_name')}
                  width={isMobile ? '300px' : '350px'}
                  {...register('parkingName')}
                />
              </RequiredLabel>
            </ContentWrapper>
            <ContentWrapper>
              <SmallContainer>
                <StyledTitle>{t('placeholders.description')}</StyledTitle>
                <RequiredLabel maxHeight="220" htmlFor="description">
                  <TextArea
                    placeholder={t('placeholders.description')}
                    rows="12"
                    {...register('description')}
                  />
                </RequiredLabel>
              </SmallContainer>
              <SmallContainer>
                <StyledTitle>{t('titles.parking_docs')}</StyledTitle>
                <TextP margin_b="15">{`${filesSelected.length} ${t('titles.file_length')}`}</TextP>
                <UploadLabel htmlFor="documents">
                  {t('buttons.upload')}
                  <UploadFile
                    name="documents"
                    id="uploadDocs"
                    type="file"
                    accept=".jpeg, .jpg, .png, .pdf"
                    multiple
                    onChange={handleDocsChange}
                  />
                </UploadLabel>
                {!!filesSelected.length && (
                  <PreviewImgContainer>
                    {filesSelected?.map((item, index) => (
                      <FilesCont key={item.name}>
                        <ImagePreview
                          document={true}
                          width="100"
                          src={pdfIcon}
                          onClick={() => deleteFile(index)}
                        />
                        <TextP>{shortenFileName(item.name)}</TextP>
                      </FilesCont>
                    ))}
                  </PreviewImgContainer>
                )}
                {docsError && (
                  <p style={{ color: color.red }}>{t('notifications.file_size_big')}</p>
                )}
                <TextP>{t('texts.docs_text')}</TextP>
              </SmallContainer>

              <SmallContainer>
                <StyledTitle>{t('titles.additional_info')}</StyledTitle>
                <RequiredLabel htmlFor="familyPlace">
                  <UpperPlaceholder>{t('placeholders.family_place')}</UpperPlaceholder>
                  <Select required defaultValue="0" {...register('familyPlace')}>
                    <option value="1">
                      {t('placeholders.family_place')} {t('placeholders.yes')}
                    </option>
                    <option value="0">
                      {t('placeholders.family_place')} {t('placeholders.no')}
                    </option>
                  </Select>
                </RequiredLabel>
              </SmallContainer>
            </ContentWrapper>
            <ContentWrapper>
              <SmallContainer>
                <UploadLabel htmlFor="photos">
                  {t('buttons.add_horizontal_photo')}
                  <UploadFile
                    name="photos"
                    id="uploadPhoto"
                    type="file"
                    accept="image/*"
                    multiple={true}
                    onChange={handleImageChange}
                    ref={inputPhotosRef}
                  />
                </UploadLabel>
                <TextP>{t('texts.photo_text')}</TextP>
                {countError && (
                  <p style={{ color: color.red }}>{t('notifications.img_cnt_high')}</p>
                )}
                {!!imgUrls.length && (
                  <PreviewImgContainer>
                    {imgUrls?.map((item, index) => (
                      <ImagePreview
                        primary={index === mainIndex}
                        src={item}
                        key={item}
                        onClick={() => deleteImage(index)}
                        onPrime={() => setMain(index)}
                      />
                    ))}
                  </PreviewImgContainer>
                )}
              </SmallContainer>
            </ContentWrapper>
            <StyledTitle>{t('titles.map')}</StyledTitle>
            {newCoordinates && (
              <>
                <TextP>{t('titles.choose_location')}</TextP>
                <MapWrapper style={customStyle && customStyle}>
                  <GoogleMap
                    lat={newCoordinates.lat}
                    lng={newCoordinates.lng}
                    setNewCoordinates={setNewCoordinates}
                  />
                </MapWrapper>
              </>
            )}
            <Button
              dataTestid="submit"
              primary={true}
              width="226"
              height="50"
              onClick={handleSubmit(onSubmit)}
              disabled={isDisabled}
              margin_right={isMobile ? 'auto' : '0'}
              margin_left={isMobile ? 'auto' : '0'}>
              {t('buttons.send_check')}
            </Button>
          </InfoContainer>
        </MainContainer>
      </Background>
    </>
  );
};
