import { createSlice } from '@reduxjs/toolkit';

export const citySearchSlice = createSlice({
  name: 'searchValue',
  initialState: {
    searchValue: { value: null, coordinates: { lat: 0, lng: 0 }, chosenLabel: '', range: undefined }
  },
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = {
        ...state.searchValue,
        ...action.payload
      };
      // state.searchValue = action.payload;
    }
  }
});

export const { setSearchValue } = citySearchSlice.actions;