import React, { useCallback, useEffect, useState } from 'react';
import { StyledP } from 'Components/ParkingDescription/style';
import { StreetTitle } from 'Components/ParkingCard/style';
import {
  InfoBlock,
  MainContainer,
  TextArea,
  Comment,
  Buttons,
  Button,
  InfoContainer,
  StyledSpan,
  StyledPWrapper,
  StyledPWrapperCustom,
  StyledSelect,
} from './style';
import { color } from 'theme';
import { useParams, useLocation } from 'react-router-dom';
import { getOneParkingPlace, getOneParkingPlaceForSale, ParkingPlace } from 'api/parking';
import { FileType } from 'api/parking';
import { useTranslation } from 'react-i18next';
import { Img, Error, MapContainer } from './style';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { setParkingPlaceStatus, StatusChangeBody, setParkingSellPlaceStatus } from 'api/admin';
import { Status as StatusEnum } from 'api/parking';
import Modal from 'react-modal';
import { GoogleMap } from 'Components/GoogleMap/GoogleMap';
import { SmallLoader } from 'Components/Loader/Loader';
import { UserInfo } from '../UserParkings/style';
import { ADMIN_TOKEN_KEY, Params } from '../constants.ts';

const customStyles = {
  content: {
    width: 'fit-content',
    height: 'fit-content',
    margin: '0 auto'
  },
  overlay: {
    background: 'rgba(51, 51, 51, 0.5)'
  }
};

Modal.setAppElement('#root');

export const Parking = () => {
  const { t } = useTranslation(['common']);
  const options = [
    { value: 'APPROVED_VERIFIED', label: t('admin.verified') },
    { value: 'APPROVED_UNVERIFIED', label: t('admin.unverified') }
  ];

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [curImg, setCurImg] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = useState<string>('');
  const [selected, setSelected] = useState(options[1]);
  const [message, setMessage] = useState<string>('');
  const [parking, setParking] = useState<ParkingPlace | null>(null);
  const [images, setImages] = useState<FileType[]>();
  const [documents, setDocuments] = useState<FileType[]>([]);
  const [cityName, setCityName] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const token = localStorage.getItem(ADMIN_TOKEN_KEY) || '';

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const { id } = useParams<Params>();
  const { state } = useLocation();

  const fetchSellParkingInfo = async ({ id, token }) => {
    setIsLoading(true);
    const { data } = await getOneParkingPlaceForSale({ id, token });
    setParking(data);
    if (data && data?.files) {
      setImages(
        data.files.filter((file: FileType) => file.type === 'IMAGE' || file.type === 'MAIN_IMAGE')
      );
      setDocuments(data.files.filter((file: FileType) => file.type === 'DOCUMENT'));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!state) {
      (async function () {
        setIsLoading(true);
        const { data } = await getOneParkingPlace({ id, token });
        setParking(data);
        setImages(
          data.files.filter((file: FileType) => file.type === 'IMAGE' || file.type === 'MAIN_IMAGE')
        );
        setDocuments(data.files.filter((file: FileType) => file.type === 'DOCUMENT'));

        setIsLoading(false);
      })();
    } else {
      fetchSellParkingInfo({ id, token });
    }
  }, [id, token, state]);

  const { mutateAsync } = useMutation(setParkingPlaceStatus, {
    onError: (error: AxiosError) => {
      // setResponseError(error.response?.data.message);
    },
    onSuccess: (response: AxiosResponse) => {
      (async function () {
        const { data } = await getOneParkingPlace({ id, token });
        setParking(data);
        setImages(data.files.filter((file: FileType) => file.type === 'IMAGE'));
        setDocuments(data.files.filter((file: FileType) => file.type === 'DOCUMENT'));
      })();
    }
  });

  const { mutateAsync: sellParkingChangeStatus } = useMutation(setParkingSellPlaceStatus, {
    onError: (error: AxiosError) => {
      // setResponseError(error.response?.data.message);
    },
    onSuccess: (response: AxiosResponse) => {
      fetchSellParkingInfo({ id, token });
    }
  });

  const handleImageClick = (path: string) => {
    setCurImg(path);
    openModal();
  };

  const onClick = useCallback(
    (status: string) => {
      setError(!message && status === 'REJECTED' ? true : false);
      if (state && state === 'sell') {
        sellParkingChangeStatus({
          body: {
            status,
            message
          },
          id,
          token
        });
      } else {
        const body: StatusChangeBody = {
          parkingPlaceId: id,
          status,
          message
        };
        mutateAsync({ body, token });
      }
    },
    [mutateAsync, message, id, token, sellParkingChangeStatus, state]
  );

  function handleSelect(selected: any) {
    setSelected(selected);
    setStatus(selected.value);
  }

  function getStatus(status: string) {
    switch (status) {
      case StatusEnum.ApprovedUnverified:
      case StatusEnum.ApprovedVerified:
        return t('admin.parking_status_approved');
      case StatusEnum.BLOCKED:
        return t('status.blocked');
      case StatusEnum.Rejected:
        return t('admin.parking_status_rejected');
    }
  }

  function handleStatusParking(parkingOption: boolean | null | undefined) {
    if (parkingOption) {
      return parkingOption === null ? t('status.not_exist') : t('status.exist');
    }
    return t('status.not_chosen');
  }

  useEffect(() => {
    if (parking) {
      const city = parking?.street?.substring(0, parking?.street?.indexOf(',')) || '';
      if (city) {
        setCityName(city);
      } else if (typeof parking?.city === 'string') {
        setCityName(parking?.city);
      }
    }
  }, [parking]);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <img
          src={curImg}
          alt="img"
          style={{ objectFit: 'contain', maxWidth: '75vw', maxHeight: '75vh' }}
        />
      </Modal>
      {parking && (
        <>
          <StreetTitle>
            {t('admin.parking_id')} {parking.id}
          </StreetTitle>
          <UserInfo>
            <p>{`ID: ${parking.user?.id}`}</p>
            <p>{`${t('admin.parking_user_name')} ${parking.user?.name} ${
              parking.user?.surname
            }`}</p>
            <p>{`${t('admin.parking_user_phone')} ${
              parking.user?.phone || t('admin.parking_info_not_specified')
            }`}</p>
            <p>{`Email: ${parking.user?.email || t('admin.parking_info_not_specified')}`}</p>
          </UserInfo>
          {images?.map((img) => (
            <Img key={img.id} src={img.path} alt="img" onClick={() => handleImageClick(img.path)} />
          ))}
          <InfoBlock>
            <StreetTitle>{t('admin.parking_info1_base_title')}</StreetTitle>
            <InfoContainer>
              <div>
                <StyledSpan>{t('admin.parking_info1_street')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{parking.street}</StyledP>
                </StyledPWrapper>
              </div>
              <div>
                <StyledSpan>{t('admin.parking_info1_house')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{parking.house}</StyledP>
                </StyledPWrapper>
              </div>
              <div>
                <StyledSpan>{t('admin.parking_info1_number')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{parking.placeNumber}</StyledP>
                </StyledPWrapper>
              </div>
              <div>
                <StyledSpan>{t('admin.parking_info1_level')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{parking.parkingLevel}</StyledP>
                </StyledPWrapper>
              </div>
              <div>
                <StyledSpan>{t('admin.parking_info1_city')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{cityName}</StyledP>
                </StyledPWrapper>
              </div>
            </InfoContainer>
          </InfoBlock>
          <InfoBlock>
            <StreetTitle>{t('admin.parking_info2_title')}</StreetTitle>
            <InfoContainer>
              <div>
                <StyledSpan>{t('admin.parking_info2_width')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{parking.placeWidth}</StyledP>
                </StyledPWrapper>
              </div>
              <div>
                <StyledSpan>{t('admin.parking_info2_length')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{parking.placeLength}</StyledP>
                </StyledPWrapper>
              </div>
              <div>
                <StyledSpan>{t('admin.parking_info2_height')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{parking.heightOfParkingEntry}</StyledP>
                </StyledPWrapper>
              </div>
            </InfoContainer>
          </InfoBlock>
          <InfoBlock>
            <StreetTitle>{t('admin.parking_info3_title')}</StreetTitle>
            <InfoContainer>
              <div>
                <StyledSpan>
                  {parking.pricePerDay
                    ? t('admin.parking_info3_per_day')
                    : t('placeholders.sell_parking_price')}
                </StyledSpan>
                <StyledPWrapper>
                  <StyledP>{parking.pricePerDay || `${parking.price} ${parking.currency}`}</StyledP>
                </StyledPWrapper>
              </div>
              {parking.pricePerMonth && (
                <div>
                  <StyledSpan>{t('admin.parking_info3_per_month')}</StyledSpan>
                  <StyledPWrapper>
                    <StyledP>{parking.pricePerMonth}</StyledP>
                  </StyledPWrapper>
                </div>
              )}
            </InfoContainer>
          </InfoBlock>
          <InfoBlock>
            <StreetTitle>{t('admin.parking_info4_title')}</StreetTitle>
            <InfoContainer>
              <div>
                <StyledSpan>{t('admin.parking_info4_car_class')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{t(`parking.${parking.carClass}`)}</StyledP>
                </StyledPWrapper>
              </div>
              <div>
                <StyledSpan>{t('admin.parking_info4_security')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{handleStatusParking(parking.security)}</StyledP>
                </StyledPWrapper>
              </div>
              <div>
                <StyledSpan>{t('admin.parking_info4_type')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>
                    {(parking.parkingType === 'OVERGROUND' && t('placeholders.ground')) ||
                      (parking.parkingType === 'UNDEGROUND' && t('placeholders.underground')) ||
                      t('admin.parking_info_not_specified')}
                  </StyledP>
                </StyledPWrapper>
              </div>
              <div>
                <StyledSpan>{t('admin.parking_info4_electric_charge')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{handleStatusParking(parking.electricCharge)}</StyledP>
                </StyledPWrapper>
              </div>
              <div>
                <StyledSpan>{t('admin.parking_info4_elevator')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{handleStatusParking(parking.elevator)}</StyledP>
                </StyledPWrapper>
              </div>
              <div>
                <StyledSpan>{t('admin.parking_info4_sector')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{parking.sector || t('admin.parking_info_not_specified')}</StyledP>
                </StyledPWrapper>
              </div>
              <div>
                <StyledSpan>{t('admin.parking_info4_parking_name')}</StyledSpan>
                <StyledPWrapper>
                  <StyledP>{parking.parkingName || t('admin.parking_info_not_specified')}</StyledP>
                </StyledPWrapper>
              </div>
            </InfoContainer>
          </InfoBlock>
          <InfoBlock>
            <StreetTitle>{t('admin.parking_info5_title')}</StreetTitle>
            <InfoContainer>
              <div>
                <StyledPWrapperCustom>
                  <div>
                    {documents.length > 0
                      ? documents.map((document) => (
                          <a
                            style={{ display: 'block' }}
                            key={document.id}
                            href={document.path}
                            target="_blank"
                            rel="noopener noreferrer">
                            {t('admin.parking_info5_ref')}
                          </a>
                        ))
                      : 'Нет'}
                  </div>
                </StyledPWrapperCustom>
              </div>
            </InfoContainer>
          </InfoBlock>
          <InfoBlock>
            <StreetTitle>{t('admin.parking_info6_family_place')} </StreetTitle>
            <InfoContainer>
              <div>
                <StyledPWrapper>
                  <StyledP>{t(parking.familyPlace ? 'status.exist' : 'status.not_exist')}</StyledP>
                </StyledPWrapper>
              </div>
            </InfoContainer>
          </InfoBlock>
        </>
      )}
      <StreetTitle>{t('admin.parking_desc')}</StreetTitle>
      <StyledPWrapperCustom>
        <p>{parking?.description || t('admin.parking_desc_missing')}</p>
      </StyledPWrapperCustom>
      <MapContainer>
        <StreetTitle>{t('admin.parking_map')}</StreetTitle>
        <GoogleMap
          notChange={true}
          //@ts-expect-error
          lat={parking?.positionOfEntry.coordinates[0] || undefined}
          // lat={parking?.streetLat}
          //@ts-expect-error
          // lng={parking?.streetLng}
          lng={parking?.positionOfEntry.coordinates[1] || undefined}
        />
      </MapContainer>
      {parking?.status === StatusEnum.Pending ? (
        <>
          <StreetTitle>{t('admin.parking_comments')}</StreetTitle>
          <Comment>
            <div style={{ width: '100%' }}>
              <TextArea
                error={error}
                type="text"
                value={message}
                onChange={(e: any) => setMessage(e.target.value)}
              />
              {error && <Error>{t('admin.parking_add_comment')}</Error>}
            </div>
            <Buttons>
              <div data-testid="select">
                <StyledSelect value={selected} onChange={handleSelect} options={options} />
              </div>
              <Button
                data-testid="accept"
                color={color.darkGreen}
                onClick={() => onClick(selected.value)}>
                {t('admin.parking_btn_accepted')}
              </Button>
              <Button data-testid="reject" color={color.red} onClick={() => onClick('REJECTED')}>
                {t('admin.parking_btn_rejected')}
              </Button>
            </Buttons>
          </Comment>
        </>
      ) : (
        <InfoBlock>
          <StreetTitle>{t('admin.parking_status')}</StreetTitle>
          <InfoContainer>
            <div>
              <StyledPWrapper>
                <StyledP>{getStatus(parking?.status as string)}</StyledP>
              </StyledPWrapper>
            </div>
          </InfoContainer>
        </InfoBlock>
      )}
    </MainContainer>
  );
};
