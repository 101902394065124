import React, { FC, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import * as QueryString from 'querystring';
import { isMobile } from 'react-device-detect';

import { regex } from 'config';
import { restorePassword, validateRestorePasswordToken } from 'api/auth';

import { MainHeader } from 'Components/Header/MainHeader';
import { StyledInput } from 'Components/UI/Input/style';
import { StyledLabel } from 'Components/UI/StyledLabel/style';
import { Notification } from 'Components/UI/Notification/Notification';
import { Button } from 'Components/UI/Button/Button';
import { StyledH4 } from 'Components/UI/H4/style';
import { Link } from 'Components/UI/Link/Link';
import { Loading } from 'Components/Loader/Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { StyledI } from 'Components/Modal/style';
import {
  MainImg,
  FormWrapper,
  StyledForm,
  LinkWrapper,
  ButtonWrapper,
  ErrorContainer
} from './style';
import * as yup from 'yup';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';

type FormData = {
  password: string;
};

const eye = <FontAwesomeIcon icon={faEye} />;
const slashedEye = <FontAwesomeIcon icon={faEyeSlash} />;

export const RestorePassword: FC = () => {
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const parsedToken = QueryString.parse(location.search);

  const history = useHistory();

  const [passwordShown, setPasswordShown] = useState<boolean>(false);

  const { isLoading: tokenValidationLoading, status: tokenValidationStatus } = useQuery(
    'validateRestorePasswordToken',
    () => validateRestorePasswordToken(parsedToken['?token'].toString())
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        password: yup.string().matches(regex, t('validation.password')).required(),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null])
      })
    )
  });

  const { mutateAsync } = useMutation(restorePassword, {
    onError: () => {
      toast.error(t('notifications.new_pass_set_error'), NOTIFICATION_OPTIONS);
    },
    onSuccess: () => {
      toast.success(t('notifications.new_pass_set'), NOTIFICATION_OPTIONS);

      reset();

      history.push('/');
    }
  });

  const onSubmit = useCallback(
    async (data: FormData) => {
      mutateAsync({
        token: parsedToken['?token'],
        password: data.password
      });
    },
    [mutateAsync, parsedToken]
  );

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <>
      <MainHeader />
      {tokenValidationLoading ? (
        <Loading />
      ) : (
        <FormWrapper>
          {!isMobile && <MainImg />}
          {!tokenValidationLoading && tokenValidationStatus === 'error' ? (
            <ErrorContainer>
              <LinkWrapper>
                <Link to="/">{t('titles.back_home')}</Link>
              </LinkWrapper>

              <StyledH4> {t('notifications.password_link_invalid')}</StyledH4>
            </ErrorContainer>
          ) : (
            <>
              <LinkWrapper>
                <Link to="/">{t('titles.back_home')}</Link>
              </LinkWrapper>

              <StyledForm data-testid="reset-password-container" onSubmit={handleSubmit(onSubmit)}>
                <StyledH4>{t('titles.enter_new_password')}</StyledH4>
                <StyledLabel htmlFor="password">
                  <StyledInput
                    type={passwordShown ? 'text' : 'password'}
                    placeholder={t('login.password')}
                    maxLength="50"
                    width={!isMobile && '400px'}
                    {...register('password', { required: true })}
                    islight
                  />
                  <Notification isAccent={true}>
                    {errors?.password?.message && t('login.password_req')}
                  </Notification>
                  {passwordShown ? (
                    <StyledI onClick={togglePasswordVisiblity}>{slashedEye}</StyledI>
                  ) : (
                    <StyledI onClick={togglePasswordVisiblity}>{eye}</StyledI>
                  )}
                </StyledLabel>

                <StyledH4>{t('titles.confirm_new_password')}</StyledH4>
                <StyledLabel htmlFor="password">
                  <StyledInput
                    type={passwordShown ? 'text' : 'password'}
                    placeholder={t('login.password')}
                    maxLength="50"
                    width={!isMobile && '400px'}
                    {...register('confirmPassword', { required: true })}
                    islight
                  />
                  <Notification isAccent={true}>
                    {/* {errors?.confirmPassword?.message && t("login.required")} */}
                    {errors?.confirmPassword?.message ===
                      'confirmPassword must be one of the following values: , Ref(password)' &&
                      t('login.should_match')}
                  </Notification>
                  {passwordShown ? (
                    <StyledI onClick={togglePasswordVisiblity}>{slashedEye}</StyledI>
                  ) : (
                    <StyledI onClick={togglePasswordVisiblity}>{eye}</StyledI>
                  )}
                </StyledLabel>
                <ButtonWrapper>
                  <Button dataTestid="submit" primary={true} width="230">
                    {t('buttons.submit_new_pass')}
                  </Button>
                </ButtonWrapper>
              </StyledForm>
            </>
          )}
        </FormWrapper>
      )}
    </>
  );
};
