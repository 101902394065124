import styled from "styled-components";

import { color, fontSize } from "theme";

export const StyledLabel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fontSize.regular};
  height: ${(props) => (props.height ? `${props.height}px` : '30px')};
  width: ${(props) => (props.width ? `${props.width}px` : 'fit-content')};
  border-radius: 25px;
  color: #ffffff;
  background: ${(props) => (props.status ? color.yellow : color.green)};
  border: none;
  padding: ${(props) => (props.padding ? `${props.padding}px ${props.padding}px` : '0 10px')};
  margin-top: ${(props) => (props.margin ? `${props.margin}px` : '10px')};
`;
