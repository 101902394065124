
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginResponse, loginUser } from 'api/auth';
import { AxiosError, AxiosResponse } from 'axios';
import { Button } from 'Components/UI/Button/Button';
import { loginSchema, LOGIN_ADMIN_API_URL } from 'config';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  Container,
  FormContainer,
  Input,
  Error,
  ButtonWrapper,
} from './style';
 
import { ADMIN_TOKEN_KEY, ADMIN_TOKEN_EXPIRATION_KEY } from '../constants.ts';
import { FormValues } from '../types.ts';

interface Props {
  onLogin: () => void;
}

export const Login = ({ onLogin }: Props) => {
  const [error, setError] = useState<string>();

  const { t } = useTranslation('common');

  const { register, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(loginSchema)
  });

  const history = useHistory();

  const { mutateAsync } = useMutation(loginUser, {
    onError: (error: AxiosError) => {
      // setResponseError(error.response?.data.message);
      setError(t('login.incorrect'));
    },
    onSuccess: (response: AxiosResponse<LoginResponse>) => {
      localStorage.setItem(ADMIN_TOKEN_KEY, response.data.accessToken);
      localStorage.setItem(ADMIN_TOKEN_EXPIRATION_KEY, response.data.refreshToken.expiresAt);
      history.push('/admin');
      onLogin();
    }
  });

  const onSubmit = useCallback(
    (data: FormValues) => {
      const { email, password } = data;

      mutateAsync({
        email,
        password,
        url: LOGIN_ADMIN_API_URL
      });
    },
    [mutateAsync]
  );

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <label>Email</label>
          <Input data-testid="email" type="email" {...register('email')} />
          <label>Password</label>
          <Input data-testid="password" type="password" {...register('password')} />
          {error && <Error>{error}</Error>}
          <ButtonWrapper>
            <Button dataTestid="login" primary>
              {t('login.login')}
            </Button>
          </ButtonWrapper>
        </FormContainer>
      </form>
    </Container>
  );
};