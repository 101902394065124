import styled from "styled-components";

import { color, device } from "theme";

export const StyledLabel = styled.label`
  position: relative;
  width: 100%;
  margin-bottom: ${(props) => (props.marginBottom ? `${props.marginBottom}px` : ``)};
`;

export const RequiredLabel = styled.label`
  position: relative;
  display: block;
  width: ${(props) => (props.width ? `${props.width}` : '100%')};
  max-width: ${(props) => (props.maxWidth ? '100%' : ' 350px;')};
  max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}px` : `70px`)};
  margin-bottom: ${(props) => (props.marginBottom ? `${props.marginBottom}px` : ``)};
  margin-right: ${(props) => (props.marginRight ? `${props.marginRight}px` : ``)};
  border: ${(props) => (props.error ? `1px solid ${color.red}` : 'none')};

  ${(props) =>
    props.required &&
    `&:before {
    color: #e32;
    content: "*  ";
    display: inline;
    position: absolute;
    top: 25%;
    left: -20px;
  }`};
`;

export const StyledRequiredLabel = styled(RequiredLabel)`
  margin-right: 10px;
  margin-bottom: 10px;

  @media ${device.laptop} {
    margin-bottom: 0;
  }
`;

