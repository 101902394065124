import styled from "styled-components";

import ImgSrc from "assets/img/tesla_background.jpg";

import { device, color } from 'theme';

export const MainImg = styled.div`
  @media ${device.tablet} {
    height: 100vh;
    width: 40%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${ImgSrc});
  }

  @media ${device.laptop} {
    width: 60%;
  }
`;

export const FormWrapper = styled.div`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 7%;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
    padding: 0;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  max-height: 320px;
  height: 100%;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    align-items: center;
    width: 300px;
  }
`;

export const LinkWrapper = styled.div`
  position: absolute;
  top: 20%;
  right: 20%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ErrorContainer = styled.div`
  width: 30%;
  & > h4 {
    color: ${color.red};
  }
`;