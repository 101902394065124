import styled, { keyframes } from 'styled-components';
import { color, fontSize } from 'theme';

const appearance = keyframes`{
    0% {  opacity: 0;
        visibility: hidden; }
    100% {  opacity: 1;
    visibility: visible;}
  }`;

export const TooltipWrapper = styled.span`
  position: absolute;
  right: -65%;
  top: 4px;
  height: 40px;
  width: 200px;
  padding: 5px;
  z-index: 100;
  background: ${(props) => (props.isError ? `${color.red}` : `${color.backgroundGrey}`)};
  color: ${color.white};
  font-size: ${fontSize.small};
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;

  animation-name: ${appearance};
  animation-duration: 1s;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid
      ${(props) => (props.isError ? `${color.red}` : `${color.backgroundGrey}`)};
    left: -8px;
    top: 12px;
  }
`;
