import styled from "styled-components";

import { font, color, fontSize } from "theme";

export const StyledH3 = styled.h3`
  font-family: ${font.primary};
  font-weight: 500;
  font-style: normal;
  font-size: ${fontSize.x_large};
  line-height: 18px;
  color: ${color.blue};
  margin: 0 auto;
`;
