import styled from "styled-components";

import { color, fontSize, device } from "theme";

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 200px;
  border-radius: 10px;
  background: #ffffff;
  padding: 10px 20px;
  position: relative;
  margin-bottom: 10px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    height: auto;
  }
`;

export const LeftSideCont = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  height: 120px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    height: auto;
    width: 100%;
  }
`;

export const ImgWrapper = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 5px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 30%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 70%;
`;

export const StyledColumns = styled.div`
  width: 33%;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`;

export const ParkingInfoCont = styled.div`
  display: flex;
  width: 70%;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const ParkingImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StreetTitle = styled.p`
  font-size: ${fontSize.large};
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const StyledText = styled.p`
  color: ${color.greayText};
  font-size: ${fontSize.regular};
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 5px;
`;

export const StyledNotification = styled(StyledText)`
  text-align: center;
  text-transform: uppercase;
  font-size: ${fontSize.medium};
  margin-top: 10px;
  line-height: 16px;

  @media ${device.tablet} {
    font-size: ${fontSize.large};
    margin-top: 20px;
    line-height: 18px;
  }

  @media ${device.laptop} {
    font-size: ${fontSize.x_large};
    margin-top: 50px;
    line-height: 40px;
  }
`;

export const StyledPrice = styled.div`
  display: flex;
  color: ${color.blue};
  font-size: ${fontSize.x_large};
  line-height: 28px;
  font-weight: 700;
  justify-content: space-between;
`;

export const StyledPriceNote = styled.div`
  margin-left: 10px;
  font-size: ${fontSize.small};
  color: ${color.blue};
  line-height: 14px;
  font-weight: 500;
  width: 50px;
`;

export const StyledNote = styled.span`
  color: ${color.greayText};
  font-size: ${fontSize.regular};
  line-height: 16px;
  font-weight: 400;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.height ? `${props.height}px` : "30px")};
  width: ${(props) => (props.width ? `${props.width}px` : "fit-content")};
  border-radius: 5px;
  color: #ffffff;
  background: ${(props) =>
    props.status === "APPROVED"
      ? color.lightGreen
      : props.status === "PENDING"
      ? color.yellow
      : color.red};
  border: none;
  padding: 0 10px;
  margin-top: ${(props) => (props.margin ? `${props.margin}px` : "5px")};
`;

export const RightSideCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-top: 10px;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
`;
