export type TOptions = {
  label: string;
  value: string;
};

export const PAYMENT_METHOD_OPTIONS: TOptions[] = [
  { label: 'Карта', value: 'CARD' },
  { label: 'Перевод', value: 'TRANSFER' },
  { label: 'Аванс', value: 'PREPAID' }
];

export const ADVANCE_REASONS: TOptions[] = [
  { label: 'Флаер', value: 'flayer' },
  { label: 'Привел друга', value: 'refer_friend' }
];

export const MONTHS_OPTIONS = {
  ru: [
    { label: 'Январь', value: 'январь' },
    { label: 'Февраль', value: 'февраль' },
    { label: 'Март', value: 'март' },
    { label: 'Апрель', value: 'апрель' },
    { label: 'Май', value: 'май' },
    { label: 'Июнь', value: 'июнь' },
    { label: 'Июль', value: 'июль' },
    { label: 'Август', value: 'август' },
    { label: 'Сентябрь', value: 'сентябрь' },
    { label: 'Октябрь', value: 'октябрь' },
    { label: 'Ноябрь', value: 'ноябрь' },
    { label: 'Декабрь', value: 'декабрь' }
  ]
};

export enum PAYMENT_STATUS {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING'
}

export const MAX_PAGE_NUMBER_RESULTS = 25;

export const ADMIN_TOKEN_KEY = 'adminAccessToken';
export const ADMIN_TOKEN_EXPIRATION_KEY = 'adminAccessTokenExpiration';