import { Status as StatusEnums } from 'api/parking';

export const getActiveStatus = (status: StatusEnums): number => {
  switch (status) {
    case StatusEnums.Pending:
      return 0;
    case StatusEnums.Rejected:
      return 1;
    case StatusEnums.ApprovedVerified:
      return 2;
    case StatusEnums.ApprovedUnverified:
      return 3;
    default:
      return 0;
  }
};
