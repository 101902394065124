import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "Redux/store";
import { ParkingPlace } from "api/parking";
import { AdditionalInfo } from "./AdditionalInfo";
import { Advantages } from "./Advantages";

import { ratingRercent } from "helpers/ratingCalculation";

import {
  Wrapper,
  Container,
  StyledTitle,
  StyledP,
  RatingCont,
  RatingNumbers,
  Row,
} from "./style";
import { StyledText } from "Components/ParkingCard/style";

import { Stars } from "Components/UI/Stars/Stars";

type Props = {
  width: string;
  showUserInfo?: boolean;
};

export const ParkingDescription = ({ width, showUserInfo }: Props) => {
  const { t } = useTranslation(["common"]);
  const parkingInfo: ParkingPlace | null | undefined = useSelector(
    (state: RootState) => state?.singleParking?.data
  );
  return (
    <Wrapper width={width}>
      <Row>
        <StyledText>
          {parkingInfo?.street} {parkingInfo?.house}
        </StyledText>
        {!!parkingInfo?.rate && (
          <RatingCont>
            <RatingNumbers>{parkingInfo.rate}</RatingNumbers>
            <Stars rating={ratingRercent(parkingInfo.rate)} />
          </RatingCont>
        )}
      </Row>
      <AdditionalInfo />
      <Advantages
        electricCharge={parkingInfo?.electricCharge}
        elevator={parkingInfo?.elevator}
        security={parkingInfo?.security}
        familyPlace={parkingInfo?.familyPlace}
        owner={parkingInfo?.owner}
      />
      {parkingInfo?.description && (
        <Container>
          <StyledTitle>{t('placeholders.description')}</StyledTitle>
          <StyledP>{parkingInfo.description}</StyledP>
        </Container>
      )}
    </Wrapper>
  );
};
