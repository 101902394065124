import styled from "styled-components";

import { color, fontSize, lineHeight } from "theme";

export const CustomScrollBar = styled.div`
  &::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    background-color: #fff;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${color.blue};
    border: none;
    border-radius: 2px;
  }
`;

export const Container = styled.div`
  background: #fff;
  border-radius: 10px;
  padding-top: 10px;
`;

export const Content = styled(CustomScrollBar)`
  width: 100%;
  display: flex;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    overflow: scroll;
  }
`;

export const Column = styled.div`
  width: calc(100% - 32*30px - 31px);
  box-sizing: border-box;
  padding: 0 5px 0 20px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 60%;
  }
`;

export const CalendarContaner = styled.div`
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(${(props) => props.days}, 30px);
  grid-template-rows: repeat(5, 50px);
  /* overflow: hidden; */
  grid-gap: 1px;
  /* overflow: scroll; */
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  margin-bottom: 1px;
  width: 100%;
`;

export const Text = styled.p`
  font-size: ${fontSize.regular};
  line-height: ${lineHeight.regular};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};
  white-space: ${(props) => (props.nowrap ? "nowrap" : "unset")};
  max-width: ${(props) => props.notFull && "calc(100% - 22px)"};
  word-break: break-word;
`;

export const Footer = styled(CustomScrollBar)`
  display: flex;
  padding: 17px 20px;
  justify-content: flex-end;
  flex-wrap: wrap;

  /* box-sizing: border-box; */
  @media screen and (min-width: 320px) and (max-width: 767px) {
    justify-content: start;
    overflow-x: scroll;
  }
`;

export const Img = styled.img`
  width: 24px;
  height: 24px;
`;

export const Header = styled.div`
  grid-column-start: 1;
  grid-column-end: ${(props) => props.days + 1};
  grid-template-columns: repeat(${(props) => props.days}, 30px);
  grid-template-rows: 50px;
  display: grid;
  grid-gap: 1px;
  color: ${color.white};
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

export const CellWrapper = styled.div`
  :first-child {
    border-radius: ${(props) => (props.border ? "0 0 0 10px" : "0")};
  }
  :last-child {
    border-radius: ${(props) => (props.border ? "0 0 10px 0" : "0")};
  }
  position: relative;
  background: ${(props) => props.color || color.backgroundGrey};
`;

export const Cell = styled.div`
  width: 30px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background: ${(props) => props.color};
  &.ch {
    background: ${color.greayText};
  }
`;

export const Info = styled.div`
  padding: 10px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 230px;
  height: 150px;
  background: ${color.white};
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.5s, opacity 0.5s linear;
  ${(props) =>
    props.visible &&
    `
    visibility: visible;
    opacity: 1;
  `}
  z-index: 1;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  overflow: visible;
  ::after {
    display: block;
    width: 20px;
    height: 20px;
    content: '';
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, 0) rotate(45deg);
    background: ${color.white};
  }
`;

export const Days = styled.div`
  grid-column-start: 1;
  grid-column-end: ${(props) => props.days + 1};
  grid-template-columns: repeat(${(props) => props.days}, 30px);
  grid-template-rows: 50px;
  display: grid;
  grid-gap: 1px;
`;

export const Title = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`;

export const ModalRow = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};
`;

export const Card = styled.div`
  background: #f2f2f2;
  border-radius: 10px;
  width: 515px;
  min-height: 80px;
  padding: 20px;
  margin-top: 10px;
  box-sizing: border-box;
  :first-child {
    margin-top: 22px;
  }
  display: ${(props) => props.flex && 'flex'};
  justify-content: ${(props) => props.flex && 'space-between'};

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    display: block;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 360px;
  border-radius: 10px;
  margin: 20px auto 10px auto;
`;

export const CardTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

export const CalendarColumn = styled.div`
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 40%;
  }
`;

export const Buttons = styled(CustomScrollBar)`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    justify-content: start;
    overflow-x: scroll;
  }
`;

export const Button = styled.button`
  border: none;
  border-radius: 5px;
  background: ${(props) => (props.current ? color.lightGreen : color.blue)};
  padding: 5px 10px;
  color: ${color.white};
  margin-left: 10px;
`;

export const Tip = (styled.Tip = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  ::before {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${(props) => props.color};
    margin-right: 10px;
  }
`);

export const InfoText = styled.p`
  font-size: 12px;
  line-height: 14px;
  text-align: ${(props) => props.center && `center`};
  padding: 5px 0;
  border-top: ${(props) => props.border && `1px solid ${color.backgroundGrey}`};
  color: ${(props) => props.color};
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Label = styled.label`
  margin: 0 20px 0 10px;
`;

export const Input = styled.input`
  background: ${color.white};
  border: none;
  text-align: center;
  border-radius: 10px;
  height: 40px;
  width: 80px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-top: 10px;
  }
`;

export const Selector = styled.div`
  border-radius: 5px;
  width: 250px;
  display: flex;
  cursor: pointer;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-top: 10px;
  }
`;

const Inner = styled.div`
  width: 50%;
  padding: 5px;
  text-align: center;
`;

export const Left = styled(Inner)`
  border-radius: 5px 0 0 5px;
  background: ${color.blue};
  background: ${(props) => (props.selected ? color.blue : color.white)};
`;

export const Right = styled(Inner)`
  border-radius: 0 5px 5px 0;
  background: ${(props) => (props.selected ? color.blue : color.white)};
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const InputIntContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${color.white};
  border-radius: 10px;
  padding: 0 10px;
  height: 40px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-top: 10px;
    width: 150px;
  }
`;

export const InputInt = styled(Input)`
  width: 50px;
  margin: 0 5px;
  height: fit-content;
`;

export const PopupContainer = styled.div``;

export const ButtonWrapper = styled.div`
  margin-top: 10px;
  padding-left: 70%;
  display: flex;
  justify-content: flex-end;
`;

export const Error = styled.span`
  color: ${color.red};
  font-size: 11px;
  display: block;
  margin-top: 5px;
`;

export const Email = styled.div`
  display: flex;
  padding: 20px 20px 10px;
`;

export const EmailText = styled.p``;

export const EmailStatus = styled.span`
  color: ${(props) => (props.status ? color.green : color.red)};
`;

export const ChangeEmailBtn = styled.button`
  margin-left: 10px;
  border: none;
  background: none;
  color: ${color.blue};
  cursor: pointer;
`;

export const EmailButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const EmailButton = styled.button`
  border: none;
  background: ${color.blue};
  border-radius: 10px;
  padding: 10px 20px;
  color: ${color.white};
  // modificator
  background: ${(props) => props.cancel && color.backgroundGrey};
  color: ${(props) => props.cancel && '#000'};
  font-size: ${fontSize.large};
`;

export const EmailInput = styled.input`
  width: 100%;
  margin-top: 20px;
  border: none;
  border-bottom: 1px solid ${color.blue};
  outline: none;
  font-size: 16px;
`;

export const EmailTitle = styled.h3`
  font-weight: normal;
`;