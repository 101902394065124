import styled from "styled-components";

import { color, lineHeight, fontSize, device } from "theme";

export const Title = styled.h2`
  font-size: ${fontSize.regular};
  line-height: ${lineHeight.regular};
  font-weight: 400;
  margin: 10px 0 10px 0;
  text-align: center;

  @media ${device.tablet} {
    font-size: ${fontSize.x_large};
    line-height: ${lineHeight.x_large};
    margin: 30px 0 20px 0;
  }

  @media ${device.laptopL} {
    text-align: left;
  }
`;

export const BookingInfoCont = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    margin-bottom: 20px;
    flex-direction: row;
  }
`;

export const BookingDetails = styled.span`
  font-size: ${fontSize.regular};
  line-height: 14px;
  font-weight: 400;
  color: ${color.blue};
  margin-bottom: 5px;

  @media ${device.tablet} {
    font-size: ${fontSize.large};
    line-height: 21px;
    margin-right: 20px;
  }
`;

export const FeedbackForm = styled.form`
  width: 100%;
  padding: 10px 25px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 10px;

  @media ${device.tablet} {
    padding: 20px 30px;
    margin-bottom: 20px;
  }
`;

export const TextArea = styled.textarea`
  background-color: ${color.backgroundGrey};
  border-radius: 10px;
  padding: 10px;
  font-size: ${fontSize.regular};
  border: none;
  outline: none;
  width: 100%;
  resize: none;
  color: ${color.black};
`;

export const BottomCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const RatingCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;

  @media ${device.tablet} {
    flex-direction: row;
    width: 50%;
    margin-bottom: 0;
  }
`;

export const StarsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: ${fontSize.x_large};

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const RatingTitle = styled.span`
  font-size: ${fontSize.regular};
  line-height: 14px;
  font-weight: 400;
  color: ${color.blue};

  @media ${device.tablet} {
    font-size: ${fontSize.medium};
    line-height: 18px;
  }
`;

export const ParkingInfoContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  justify-content: center;
`;
