import React from 'react';

export const FlagIconRomania = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 512 512"
      {...props}>
      <g fillRule="nonzero">
        <path
          fill="#999"
          d="M256 0c70.68 0 134.69 28.66 181.01 74.99C483.34 121.31 512 185.32 512 256c0 70.68-28.66 134.69-74.99 181.01C390.69 483.34 326.68 512 256 512c-70.68 0-134.69-28.66-181.01-74.99C28.66 390.69 0 326.68 0 256c0-70.68 28.66-134.69 74.99-181.01C121.31 28.66 185.32 0 256 0z"></path>
        <path
          fill="#fff"
          d="M256 19.48c65.3 0 124.46 26.48 167.25 69.27l1.09 1.18c42.14 42.71 68.18 101.37 68.18 166.06 0 65.31-26.5 124.46-69.29 167.25l-1.18 1.09c-42.73 42.16-101.4 68.19-166.05 68.19-65.23 0-124.37-26.51-167.18-69.33-42.84-42.74-69.33-101.89-69.33-167.2 0-65.31 26.48-124.45 69.27-167.24C131.55 45.96 190.7 19.48 256 19.48z"></path>
        <path
          fill="#002B7F"
          d="M163.17 60.48v391.04C90.12 416.76 39.6 342.29 39.6 255.99c0-86.29 50.52-160.77 123.57-195.51z"></path>
        <path
          fill="#FCD116"
          d="M363.45 68.1v375.79C331.8 462.04 295.1 472.41 256 472.41c-33.22 0-64.69-7.5-92.83-20.89V60.48C191.31 47.1 222.78 39.59 256 39.59c39.1 0 75.8 10.36 107.45 28.51z"></path>
        <path
          fill="#CE1126"
          d="M363.45 443.89V68.1c65.11 37.31 108.96 107.48 108.96 187.89 0 80.42-43.85 150.59-108.96 187.9z"></path>
      </g>
    </svg>
  );
};
