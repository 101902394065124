import React from "react";

import { StyledStars } from "./style";

type Props = {
  rating?: number;
};

export const Stars = ({ rating }: Props) => {
  return <StyledStars rating={rating} />;
};
