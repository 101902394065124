export const convertIntoRomanNumbers = (num) => {
  if (num < 1) {
    return '';
  }
  if (num >= 40) {
    return 'XL' + convertIntoRomanNumbers(num - 40);
  }
  if (num >= 10) {
    return 'X' + convertIntoRomanNumbers(num - 10);
  }
  if (num >= 9) {
    return 'IX' + convertIntoRomanNumbers(num - 9);
  }
  if (num >= 5) {
    return 'V' + convertIntoRomanNumbers(num - 5);
  }
  if (num >= 4) {
    return 'IV' + convertIntoRomanNumbers(num - 4);
  }
  if (num >= 1) {
    return 'I' + convertIntoRomanNumbers(num - 1);
  }
};
