import React, { ReactNode } from "react";

import { StyledLink } from "./style";

type Props = {
  children: ReactNode | string;
  to: string;
  primary?: boolean;
  display?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  target?: string;
  rel?: string;
};

export const Link = ({ children, to, primary, display, onClick , target, rel }: Props) => {
  return (
    <StyledLink to={to} display={display} primary={primary} onClick={onClick}>
      {children}
    </StyledLink>
  );
};
