import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { FilterContext } from 'context/filterContext';
import { FilterContextType } from 'context/types';
import { ParkingsListPropsType } from '../types';
import { SingleParkingType, FileTypes } from 'types/parkingTypes';
import { paths } from 'config';
import { useModal } from 'hooks/useModal';
import { useRefs } from 'hooks/useRefs';

import { ParkingCard } from 'Components/ParkingCard/ParkingCard';
import { ModalBooking } from 'Components/Modal/ModalBooking';
import { Button } from 'Components/UI/Button/Button';

import { StyledNotification } from 'Components/Profile/OnDemand/style';
import { InvisibleLink, LoadMoreContainer } from '../style.js';

export const getImageUrl = (images: FileTypes[]) => {
  if (!images || images.length === 0) {
    return '';
  }

  if (images.length === 1) {
    return images[0]?.path;
  } else {
    return images.find((el: FileTypes) => el.type === 'MAIN_IMAGE')?.path || images[0]?.path;
  }
};

export const ParkingsList = ({
  data,
  fetchNextPage,
  isFetching,
  hasNextPage,
  searchRangeRadius
}: ParkingsListPropsType) => {
  const { t } = useTranslation(['common']);
  const { setHoveredCard, hoveredPin } = useContext(FilterContext) as FilterContextType;

  const { search } = useLocation();
  const { lat, lng, searchRadius, searchingLocation, from, to } = queryString.parse(search);

  const { isShown, toggle } = useModal();
  const [parkingId, setParkingId] = useState<number>();
  const { refs, setRefFromKey } = useRefs();

  const handleBookParking = useCallback(
    (id: number | string) => {
      setParkingId(Number(id));
      toggle();
    },
    [toggle]
  );

  const handleHover = useCallback(
    (id) => {
      setHoveredCard(id);
    },
    [setHoveredCard]
  );

  const handleHoverOut = useCallback(() => {
    setHoveredCard(null);
  }, [setHoveredCard]);

  useEffect(() => {
    if (hoveredPin !== -1) {
      //@ts-expect-error
      refs[hoveredPin].scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [hoveredPin, refs]);

  if (!Boolean(data[0].data.results.length))
    return <StyledNotification>{t('notifications.no_searching_results')}</StyledNotification>;

  return (
    <>
      <ModalBooking
        isShown={isShown}
        hide={toggle}
        startDate={from}
        endDate={to}
        parkingId={parkingId}
      />
      {data.map((page, i) => {
        return (
          <React.Fragment key={page.nextPage + i}>
            {page.data.results.map((item: SingleParkingType) => {
              const imageUrl = getImageUrl(item?.files);

              const link = `/${paths.oneparking}?parkingId=${item.id}&from=${from}&to=${to}&searchLat=${lat}&searchLng=${lng}&searchingLocation=${searchingLocation}&searchRadius=${searchRadius}`;

              return (
                <InvisibleLink
                  key={item.id}
                  target="_blank"
                  rel="noopener noreferrer"
                  to={link}
                  ref={setRefFromKey(item.id)}>
                  <ParkingCard
                    onMouseOver={handleHover}
                    onMouseOut={handleHoverOut}
                    onClick={() => {}}
                    id={item.id}
                    street={item.street}
                    house={item.house}
                    pricePerDay={item.pricePerDay}
                    price={item.price}
                    carClass={item.carClass}
                    status={item.bookingType}
                    images={imageUrl}
                    key={item.id}
                    rate={item.rate}
                    parkingName={item.parkingName}
                    hasBookButton
                    handleBookParking={handleBookParking}
                    isAccent={hoveredPin === item.id}
                    security={item.security}
                    electricCharge={item.electricCharge}
                    parkingStatus={item.status}
                    parkingType={item.parkingType}
                    currency={item.currency}
                  />
                </InvisibleLink>
              );
            })}
          </React.Fragment>
        );
      })}

      {hasNextPage && (
        <LoadMoreContainer>
          <Button
            primary={true}
            onClick={() => fetchNextPage()}
            width="200"
            height={isMobile ? '40' : '50'}
            padding="0"
            margin_right="auto"
            margin_left="auto">
            {t('placeholders.load_more')}
            {isFetching && (
              <FontAwesomeIcon
                icon={faSync}
                className="fa-spin"
                style={{ marginLeft: '20px', color: '#ffffff' }}
              />
            )}
          </Button>
        </LoadMoreContainer>
      )}
    </>
  );
};
