import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DD_MM_YYYY } from 'Constants/constants';
import { UserBookingInfoType } from '../types.ts';

import { StyledH2 } from 'Components/UI/H2/style.js';
import { StyledTable, StyledTBody, HeaderRaw, TableWrapper, TableRaw } from './style.js';
import { EmptyPlaceholder } from '../style.js';

type UserBookingContentProps = {
  data: UserBookingInfoType[];
};

export const UserBookingContent = ({ data }: UserBookingContentProps) => {
  const { t } = useTranslation(['admin']);

  const totalCommission: number = useMemo(() => {
    if (data.length) {
      return data.reduce((acc, item) => acc + item.commission, 0);
    } else {
      return 0;
    }
  }, [data]);

  if (data.length === 0) {
    return (
      <EmptyPlaceholder>
        <StyledH2>{t('notifications.no_partner_bookings')}</StyledH2>
      </EmptyPlaceholder>
    );
  }

  const headerTitles = Object.keys(data[0]) || [];

  const renderHeader = (): JSX.Element => {
    return (
      <HeaderRaw>
        {headerTitles.map((item) => (
          <th key={item}>{item}</th>
        ))}
      </HeaderRaw>
    );
  };

  return (
    <>
      <TableWrapper>
        <StyledTable>
          <thead>{renderHeader()}</thead>
          <StyledTBody>
            <>
              {data.length === 0 && (
                <TableRaw>
                  {' '}
                  <td colSpan={headerTitles.length} style={{ textAlign: 'center' }}>
                    {t('notifications.no_table_data')}
                  </td>
                </TableRaw>
              )}
              {data.map((item, index) => (
                <TableRaw key={item.id} isEven={index % 2 === 0}>
                  {headerTitles.map((header, index) => {
                    if (header === 'from' || header === 'to' || header === 'requestCreated') {
                      return (
                        <td key={`${item[header].toString()}-${index}`}>
                          {moment(item[header].toString()).format(DD_MM_YYYY)}
                        </td>
                      );
                    } else if (header === 'commission') {
                      return (
                        <td key={`${item[header].toString()}-${index}`}>
                          {item['commission'].toString()} {item['currency']}
                        </td>
                      );
                    } else {
                      return (
                        <td key={`${item[header].toString()}-${index}`}>
                          {item[header].toString()}
                        </td>
                      );
                    }
                  })}
                </TableRaw>
              ))}
              {Boolean(data.length) && (
                <TableRaw>
                  <td colSpan={14} style={{ textAlign: 'right' }}>
                    {t('payments.total_commission')}:
                  </td>
                  <td>
                    {totalCommission} {data[0]?.currency || ''}
                  </td>
                  <td colSpan={5} />
                </TableRaw>
              )}
            </>
          </StyledTBody>
        </StyledTable>
      </TableWrapper>
    </>
  );
};
