import React from 'react';
import { useTranslation } from 'react-i18next';
import { Status as StatusEnums } from 'api/parking';

import { Header, Title } from '../style.js';

type HeaderWithFiltersProps = {
  active: boolean;
  onClick: () => { number; StatusEnums };
};

export const HeaderWithFilters = ({ active, onClick }: HeaderWithFiltersProps) => {
  const { t } = useTranslation(['admin']);

  const filters = [
    {
      id: 0,
      status: StatusEnums.Pending,
      label: t('admin.parkings_on_moderation'),
      value: 'pending'
    },
    {
      id: 1,
      status: StatusEnums.Rejected,
      label: t('admin.parkings_rejected'),
      value: 'rejected'
    },
    {
      id: 2,
      status: StatusEnums.ApprovedVerified,
      label: t('admin.parkings_approved_verified'),
      value: 'approved-verified'
    },
    {
      id: 3,
      status: StatusEnums.ApprovedUnverified,
      label: t('admin.parkings_approved_unverified'),
      value: 'approved-unverified'
    }
  ];

  return (
    <Header>
      {filters.map(({ id, status, label, value }) => {
        return (
          <Title
            data-testid={`filter-${value}`}
            active={active === id}
            onClick={() => onClick(id, status)}>
            {label}
          </Title>
        );
      })}
    </Header>
  );
};
