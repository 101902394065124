import React, { useEffect, useState, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {
  REGISTER_API_URL,
  phoneVerificationSchema,
  PHONE_VERIFICATION_API_URL,
  regex,
  REGISTER_API_URL_TEST,
  PHONE_VERIFICATION_API_URL_TEST,
  TEST_PHONE_NUMBER,
  TEST_SMS,
  TEST_PHONE_NUMBER_2
} from 'config';
import { registerUser, RegisterResponse, phoneVerification } from 'api/auth';
import { getCountriesList, GetCountriesType } from 'api/countries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as ArrowLeft } from 'assets/svg/left-arrow.svg';
import {
  Wrapper,
  Header,
  StyledModal,
  HeaderText,
  Content,
  Backdrop,
  Form,
  AgreeContainer,
  AgreeNote,
  LinkContainerLeft,
  StyledP,
  StyledI,
  StyledText,
  BackHomeCont,
  ArrowContainer,
  colorStyles
} from './style';
import { Timer } from './Timer';
import { LoginModal } from './LoginModal';
import { SuccessModal } from './SuccessModal';
import { Button } from 'Components/UI/Button/Button';
import { Link } from 'Components/UI/Link/Link';
import { StyledLabel } from 'Components/UI/StyledLabel/style';
import { Notification } from 'Components/UI/Notification/Notification';
import { StyledInput } from 'Components/UI/Input/style';
import * as yup from 'yup';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  primary?: boolean;
}

type FormValuesReg = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  password: string;
  checkbox: boolean;
  url?: string;
  country: GetCountriesType;
};
type FormValuesPhone = { code: string };

const eye = <FontAwesomeIcon icon={faEye} />;
const slashedEye = <FontAwesomeIcon icon={faEyeSlash} />;

export const ModalAuth = ({ isShown, hide, primary }: ModalProps) => {
  const { t, i18n } = useTranslation(['common']);
  const location = useLocation();

  const [responseError, setResponseError] = useState<number | null>(null);
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [loginDisplay, setLoginDisplay] = useState<boolean>(false);
  const [registerModal, setRegisterModal] = useState<boolean>(false);
  const [phoneModal, setPhoneModal] = useState<boolean>(false);
  const [display, setDisplay] = useState<boolean>(true);
  const [number, setNumber] = useState<string>(' ');
  const [token, setToken] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const {
    status: countriesListStatus,
    isLoading: countriesListIsLoading,
    data: countriesList
  } = useQuery('getCountriesList', () => getCountriesList(), {
    enabled: registerModal,
    onError: (error: AxiosError) => {
      toast.error(t('notifications.countries_list_error'), NOTIFICATION_OPTIONS);
    }
  });

  const {
    register: registerReg,
    formState: { errors: errorsReg },
    handleSubmit: handleSubmitReg,
    reset,
    control
  } = useForm<FormValuesReg>({
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        name: yup
          .string()
          .required()
          .matches(/^[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ'\s]*$/, t('validation.string')),
        surname: yup
          .string()
          .required()
          .matches(/^[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ'\s]*$/, t('validation.string')),
        phone: yup
          .string()
          .required()
          .matches(/^\+?[0-9]+$/, t('validation.phone')),
        country: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required()
          })
          .nullable()
          .required(),
        email: yup
          .string()
          .required()
          .matches(
            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
            t('validation.email')
          )
          .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, t('validation.email')),
        password: yup.string().min(8).matches(regex, t('validation.password')).required(),
        checkbox: yup.boolean().oneOf([true], t('validation.checkbox'))
      })
    )
  });

  const {
    register: registerPhone,
    handleSubmit: handleSubmitPhone,
    formState: { errors: errorsPhone }
  } = useForm<FormValuesPhone>({
    mode: 'onBlur',
    resolver: yupResolver(phoneVerificationSchema)
  });

  const registrationForm = useMutation(registerUser, {
    onError: (error: AxiosError) => {
      setResponseError(error.response?.data.statusCode);
    },
    onSuccess: (response: AxiosResponse<RegisterResponse>) => {
      setToken(response.data.accessToken);
      setPhoneModal(true);
      reset();
      setRegisterModal(false);
    }
  });

  const phoneForm = useMutation(phoneVerification, {
    onError: (error: AxiosError) => {
      setResponseError(error.response?.data.statusCode);
      let message;
      if (error.response?.data.statusCode === 400) {
        message = t('notifications.sms_phone_expired');
      } else if (error.response?.data.statusCode === 401) {
        message = t('notifications.sms_error');
      } else {
        message = t('notifications.sms_error');
      }
      toast.error(message, NOTIFICATION_OPTIONS);
    },
    onSuccess: () => {
      setPhoneModal(false);
      setSuccess(true);
      sessionStorage.clear();
    }
  });

  const resendAuth = useMutation(registerUser, {
    onError: (error: AxiosError) => {
      toast.error(t('notifications.sms_phone_expired'), NOTIFICATION_OPTIONS);
    },
    onSuccess: () => {
      toast.success(t('notifications.sms_success'), NOTIFICATION_OPTIONS);
    }
  });

  const onSubmitReg = useCallback(
    (data: FormValuesReg) => {
      const { phone, email, name, surname, password, country } = data;
      sessionStorage.setItem('phone', phone);
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('name', name);
      sessionStorage.setItem('surname', surname);
      sessionStorage.setItem('password', password);
      sessionStorage.setItem('country', country.value);

      registrationForm.mutateAsync({
        email: email.toLowerCase(),
        password,
        name,
        surname,
        phone,
        country: country.value,
        url:
          phone === TEST_PHONE_NUMBER || phone === TEST_PHONE_NUMBER_2
            ? REGISTER_API_URL_TEST
            : REGISTER_API_URL,
        language: i18n.language.toUpperCase()
      });

      setNumber(phone);
    },
    [registrationForm, i18n.language]
  );

  const onSubmitPhone = useCallback(
    (data: FormValuesPhone) => {
      const { code } = data;

      phoneForm.mutateAsync({
        token: token,
        code,
        url: code === TEST_SMS ? PHONE_VERIFICATION_API_URL_TEST : PHONE_VERIFICATION_API_URL
      });
    },
    [phoneForm, token]
  );

  const onFocusHandler = useCallback(() => {
    setResponseError(null);
  }, []);

  const openRegister = () => {
    setDisplay(!display);
    setRegisterModal(!registerModal);
    if (loginDisplay) {
      setLoginDisplay(false);
    }

    setDisplay(false);
  };

  const openLogin = () => {
    setDisplay(!display);
    setLoginDisplay(!loginDisplay);

    if (registerModal) {
      setRegisterModal(false);
    }

    setDisplay(false);
  };

  const resendSMS = useCallback(() => {
    const email = sessionStorage.getItem('email') || ' ';
    const password = sessionStorage.getItem('password') || ' ';
    const name = sessionStorage.getItem('name') || ' ';
    const surname = sessionStorage.getItem('surname') || ' ';
    const phone = sessionStorage.getItem('phone') || ' ';
    const country = sessionStorage.getItem('country') || ' ';

    resendAuth.mutateAsync({
      email,
      password,
      name,
      surname,
      phone,
      country,
      url:
        phone === TEST_PHONE_NUMBER || phone === TEST_PHONE_NUMBER_2
          ? REGISTER_API_URL_TEST
          : REGISTER_API_URL,
      language: i18n.language.toUpperCase()
    });
  }, [resendAuth, i18n.language]);

  const backToLogin = () => {
    setSuccess(false);
    setLoginDisplay(true);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 27 && isShown) {
      sessionStorage.clear();
      hide();
      setPhoneModal(false);
      setRegisterModal(false);
      setDisplay(true);
      setLoginDisplay(false);
      reset();
      setResponseError(null);
    }
  };

  useEffect(() => {
    if (primary) {
      document.addEventListener('keydown', onKeyDown, false);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown]);

  const hideAll = () => {
    hide();
    setPhoneModal(false);
    setRegisterModal(false);
    setDisplay(true);
    setLoginDisplay(false);
    reset();
    sessionStorage.clear();
    setResponseError(null);
    // window.location.reload();
  };

  const backToHome = () => {
    setDisplay(true);
    setRegisterModal(false);
  };

  const onBack = () => {
    setNumber('');
    setPhoneModal(false);
    setRegisterModal(true);
  };

  const renderOptions = (arr: GetCountriesType[]) => {
    if (!arr) return [];

    const options = arr.map((item) => {
      return {
        value: `${item['countryCode']}`,
        label: `${item['countryName']}`
      };
    });

    return options;
  };

  const memoizedCountriesList = useMemo(
    () => renderOptions(countriesList && countriesList?.data && Object.values(countriesList?.data)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countriesList]
  );

  const customFilter = (option, searchText) => {
    if (
      option.data.label.toLowerCase().startsWith(searchText.toLowerCase()) ||
      option.data.value.toLowerCase().startsWith(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  const modal = (
    <React.Fragment>
      <Backdrop onClick={primary && !phoneModal && !registerModal ? hideAll : () => {}} />
      <Wrapper aria-modal tabIndex={-1} role="dialog">
        {registerModal && (
          <StyledModal data-testid="registration-container">
            {location.pathname !== '/' && (
              <BackHomeCont>
                <Link to="/">{t('titles.back_home')}</Link>
              </BackHomeCont>
            )}
            {location.pathname === '/' && (
              <ArrowContainer onClick={backToHome}>
                <ArrowLeft style={{ width: '100%', height: '100%' }} />
              </ArrowContainer>
            )}
            <Header>
              <HeaderText>{t('titles.register_title')}</HeaderText>
            </Header>
            <Content>
              <Form height="550" onSubmit={handleSubmitReg(onSubmitReg)}>
                <StyledLabel htmlFor="name">
                  <StyledInput
                    data-testid="name"
                    placeholder={t('login.name')}
                    maxLength="50"
                    {...registerReg('name', { required: true })}
                  />
                  <Notification isAccent={true}>
                    {errorsReg?.name?.type === 'required' && t('login.required')}
                    {errorsReg?.name?.type !== 'required' && errorsReg?.name?.message}
                  </Notification>
                </StyledLabel>
                <StyledLabel htmlFor="surname">
                  <StyledInput
                    data-testid="surname"
                    placeholder={t('login.surname')}
                    maxLength="50"
                    {...registerReg('surname', { required: true })}
                  />
                  <Notification isAccent={true}>
                    {errorsReg?.surname?.type === 'required' && t('login.required')}{' '}
                    {errorsReg?.surname?.type !== 'required' && errorsReg?.surname?.message}
                  </Notification>
                </StyledLabel>
                <StyledLabel htmlFor="phone">
                  <StyledInput
                    data-testid="phone"
                    placeholder={t('login.phone')}
                    maxLength="14"
                    {...registerReg('phone', { required: true })}
                  />
                  <Notification isAccent={true}>
                    {errorsReg?.phone?.type === 'required' && t('login.required')}
                    {errorsReg?.phone?.type !== 'required' && errorsReg?.phone?.message}
                    {responseError === 400 && t('login.Invalid_number_format')}
                    {(responseError === 403 || responseError === 409) &&
                      t('notifications.phone_exist')}
                  </Notification>
                </StyledLabel>
                <StyledLabel htmlFor="country">
                  <Controller
                    name="country"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        inputRef={ref}
                        isSearchable
                        value={memoizedCountriesList.find((c) => c.value === value)}
                        isDisabled={countriesListIsLoading || countriesListStatus !== 'success'}
                        onChange={(val) => {
                          onChange(memoizedCountriesList.find((c) => c.value === val.value));
                        }}
                        options={memoizedCountriesList}
                        styles={colorStyles}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        placeholder={t('placeholders.choose_country')}
                        filterOption={customFilter}
                      />
                    )}
                  />
                  <Notification isAccent={true}>
                    {errorsReg?.country?.label.type === 'required' && t('login.required')}
                  </Notification>
                </StyledLabel>
                <StyledLabel htmlFor="email">
                  <StyledInput
                    data-testid="email"
                    placeholder="Email"
                    maxLength="50"
                    autoComplete="new-email"
                    {...registerReg('email', { required: true })}
                    onFocus={onFocusHandler}
                  />
                  <Notification isAccent={true}>
                    {errorsReg?.email?.type === 'required' && t('login.required')}
                    {errorsReg?.email?.type !== 'required' && errorsReg?.email?.message}
                    {responseError === 409 && t('notifications.email_exist')}
                    {responseError === 403 && t('notifications.email_exist')}
                  </Notification>
                </StyledLabel>
                <StyledLabel htmlFor="password">
                  <StyledInput
                    data-testid="password"
                    type={passwordShown ? 'text' : 'password'}
                    maxLength="50"
                    autoComplete="new-password"
                    placeholder={t('login.password')}
                    {...registerReg('password', { required: true })}
                  />
                  <Notification isAccent={true}>
                    {errorsReg?.password?.message && t('login.password_req')}
                  </Notification>
                  {passwordShown ? (
                    <StyledI onClick={togglePasswordVisiblity}>{slashedEye}</StyledI>
                  ) : (
                    <StyledI onClick={togglePasswordVisiblity}>{eye}</StyledI>
                  )}
                </StyledLabel>
                <AgreeContainer>
                  <label htmlFor="checkbox">
                    <input
                      data-testid="user-terms"
                      type="checkbox"
                      {...registerReg('checkbox', { required: true })}
                    />

                    <AgreeNote>
                      {t('login.accept_note')}&nbsp;
                      <a href="/partner" target="_blank" rel="noopener noreferrer">
                        {t('login.agreement')}
                      </a>
                    </AgreeNote>
                    <Notification isAccent={true}>
                      {errorsReg?.checkbox?.message && t('login.agreement_warning')}
                    </Notification>
                  </label>
                </AgreeContainer>
                <Button dataTestid="verify-phone" primary={true}>
                  {t('buttons.verify_phone')}
                </Button>
                <Button dataTestid="login" primary={true} onClick={openLogin}>
                  {t('buttons.login')}
                </Button>
              </Form>
            </Content>
          </StyledModal>
        )}
        {phoneModal && (
          <StyledModal data-testid="phone-confirmation-container">
            <Header>
              <span onClick={onBack}>Назад</span>
              <HeaderText>{t('titles.phone_confirm')}</HeaderText>
            </Header>
            <StyledP>
              {t('titles.sms_sent')} {number}
            </StyledP>
            <Content>
              <Form onSubmit={handleSubmitPhone(onSubmitPhone)}>
                <StyledLabel htmlFor="code">
                  <StyledInput
                    data-testid="confirmation-code"
                    placeholder={t('placeholders.enter_code')}
                    {...registerPhone('code', { required: true })}
                  />
                  <Notification isAccent={true}>
                    {errorsPhone?.code?.message && t('titles.enter_code')}
                  </Notification>
                </StyledLabel>
                <LinkContainerLeft>
                  <Timer onClick={resendSMS} />
                </LinkContainerLeft>
                <Button dataTestid="confirm" disabled={phoneForm.isLoading} primary={true}>
                  {t('buttons.confirm')}
                </Button>
              </Form>
            </Content>
          </StyledModal>
        )}
        {success && <SuccessModal backToLogin={backToLogin} isRegister={true} />}
        {loginDisplay && <LoginModal openRegister={openRegister} />}
        {display && (
          <StyledModal>
            <Header>
              <HeaderText>{t('titles.need_auth')}</HeaderText>
            </Header>
            <Content>
              <Button dataTestid="to-login" primary={true} margin_b="50" onClick={openLogin}>
                {t('buttons.login')}
              </Button>

              <Button dataTestid="to-register" primary={true} margin_b="20" onClick={openRegister}>
                {t('buttons.register')}
              </Button>
              {primary ? (
                <StyledText onClick={hide}>{t('titles.back_home')}</StyledText>
              ) : (
                <Link to="/">{t('titles.back_home')}</Link>
              )}
            </Content>
          </StyledModal>
        )}
      </Wrapper>
    </React.Fragment>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
