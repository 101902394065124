import {
  CarLargeIcon,
  CarMediumIcon,
  CarSmallIcon,
  CarEChargerIcon,
  CarUndergroundIcon,
  CarOvergroundIcon,
  CarDefaultParkingIcon,
  CarRatingIcon,
  CarDistanceIcon,
  PriceDownIcon,
  PriceUpIcon
} from 'assets/svg';

export const iconMap: Record<string, React.FunctionComponent<React.SVGAttributes<SVGElement>>> = {
  FIRST: CarSmallIcon,
  SECOND: CarMediumIcon,
  THIRD: CarLargeIcon,
  eCharger: CarEChargerIcon,
  UNDEGROUND: CarUndergroundIcon,
  OVERGROUND: CarOvergroundIcon,
  DEFAULT: CarDefaultParkingIcon,
  RATING: CarRatingIcon,
  DISTANCE: CarDistanceIcon,
  CHEEP: PriceDownIcon,
  EXPENSIVE: PriceUpIcon
};
