import React from "react";

import { ReactComponent as Shield } from "assets/svg/Shield.svg";
import { IconWrapper, AdvCont, AdvTitle } from "./style";

type Title = { title: string };

export const SingleAdvantage = ({ title }: Title) => {
  return (
    <AdvCont>
      <IconWrapper>
        <Shield style={{ width: "100%", height: "100%", objectFit: "cover" }} />
      </IconWrapper>
      <AdvTitle>{title}</AdvTitle>
    </AdvCont>
  );
};
