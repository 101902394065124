import React, { useState, useCallback } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { SingleDatePicker } from 'react-dates';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { createPartnerPrepaidAdmin, editPartnerPrepaidAdmin } from 'api/finance.ts';
import { NOTIFICATION_OPTIONS, regexOnlyNumber } from 'Constants/constants';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-dates/lib/css/_datepicker.css';
import './styles.css';

import { Button } from 'Components/UI/Button/Button';
import { StyledInput } from 'Components/UI/Input/style';

import { EditPaymentWrapper, InfoCell, StyledSelect } from '../style.js';
import { color } from 'theme';
import { ADVANCE_REASONS, ADMIN_TOKEN_KEY } from '../../constants.ts';
import { EditAdvancePaymentsProps } from '../../types.ts';

export const EditAdvancePayment = ({ onClose, userId, prepaidId }: EditAdvancePaymentsProps) => {
  const { t, i18n } = useTranslation(['admin']);
  const [focused, setFocused] = useState(false);
  const [date, setDate] = useState(moment().locale(i18n.language));
  const [reason, setReason] = useState(null);
  const [amount, setAmount] = useState<string>('');

  const token = localStorage.getItem(ADMIN_TOKEN_KEY) || '';

  const createPartnerPrepaidAmount = useMutation(createPartnerPrepaidAdmin, {
    onError: (error: AxiosError) => {
      toast.error(t('notifications.prepaid_amount_error'), NOTIFICATION_OPTIONS);
    },
    onSuccess: () => {
      toast.success(t('notifications.prepaid_amount_sent'), NOTIFICATION_OPTIONS);
    }
  });

  const editPartnerPrepaidAmount = useMutation(editPartnerPrepaidAdmin, {
    onError: (error: AxiosError) => {
      toast.error(t('notifications.prepaid_amount_edit_error'), NOTIFICATION_OPTIONS);
    },
    onSuccess: () => {
      toast.success(t('notifications.prepaid_amount_edited'), NOTIFICATION_OPTIONS);
    }
  });

  const handleChangeAmount = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      setAmount('');
    }

    if (inputValue.match(regexOnlyNumber)) {
      setAmount(inputValue);
    }
  }, []);

  const handleSubmitAdvancePayment = useCallback(() => {
    const payload = {
      token,
      userId,
      body: {
        reason: reason?.label,
        amount: Number(amount),
        date: date.format('YYYY-MM-DD')
      }
    };

    if (Boolean(prepaidId)) {
      editPartnerPrepaidAmount.mutateAsync({ prepaidId, ...payload });
    } else {
      createPartnerPrepaidAmount.mutateAsync(payload);
    }

    setTimeout(() => {
      onClose(false);
    }, 1000);
  }, [
    date,
    onClose,
    amount,
    prepaidId,
    reason,
    token,
    userId,
    editPartnerPrepaidAmount,
    createPartnerPrepaidAmount
  ]);

  const isDisabled = !reason?.value || !amount;

  return (
    <EditPaymentWrapper>
      <InfoCell>
        {t('payments.payment_date')}:{' '}
        <SingleDatePicker
          numberOfMonths={1}
          date={date} // momentPropTypes.momentObj or null,
          id="your_unique_date_id" // PropTypes.string.isRequired
          onDateChange={(date) => {
            setDate(date);
          }} // PropTypes.func.isRequired,
          focused={focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired,
          hideKeyboardShortcutsPanel
          readOnly
          showDefaultInputIcon
          anchorDirection="right"
          isOutsideRange={() => false}
        />
      </InfoCell>
      <InfoCell>{t('payments.admin_name')}: данных об имени админа нет</InfoCell>
      <InfoCell>
        {t('payments.reason')}:
        <CreatableSelect
          isClearable
          options={ADVANCE_REASONS}
          placeholder={t('payments.choose_reason')}
          styles={StyledSelect}
          onChange={(value) => setReason(value)}
        />
      </InfoCell>
      <InfoCell>
        {t('payments.top_up_amount')}:
        <StyledInput value={amount} onChange={handleChangeAmount} />
      </InfoCell>
      <InfoCell color={color.green}>
        <Button
          color={color.green}
          dataTestid="top-up-payment-button"
          primary
          onClick={handleSubmitAdvancePayment}
          disabled={isDisabled}>
          {t('buttons.top_up')}
        </Button>
      </InfoCell>
    </EditPaymentWrapper>
  );
};
