import React, { useMemo, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/store';
import GoogleMapReact from 'google-map-react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { FilterContext } from 'context/filterContext';
import { FilterContextType } from 'context/types';
import { GOOGLE_API_KEY, paths } from 'config';
import { ParkingsListMapType } from 'Pages/Parking/types';
import { CoordinatesType } from '../../types/parkingTypes';
import { GOOGLE_PLACES_MARKERS } from 'Constants/constants';
// import { getBoundsRadius } from 'helpers/getRangeRadius.js';
// import { setSearchValue } from 'Redux/reducers/citySearch/citySearchReducer';  TODO: uncomment when needed for additional fetch

import { PointMarker } from './PointMarker';
import { MyLocationIcon, CarIcon } from 'assets/svg';

const MyPosition = ({ children }: any) => children;

export const ParkingsListMap = ({ places }: ParkingsListMapType) => {
  const { t, i18n } = useTranslation(['common']);
  const { pathname, search } = useLocation();
  const { lat, lng } = queryString.parse(search);
  // const dispatch = useDispatch();
  const { coordinates, chosenLabel, value } = useSelector(
    (state: RootState) => state.citySearchValue.searchValue
  );
  const { dates, hoveredCard, positionDetected, setPositionDetected, setHoveredPin } = useContext(
    FilterContext
  ) as FilterContextType;

  const [current, setCurrent] = useState<number>(-1);
  const [zoom, setZoom] = useState<number>(13);
  const [centerMap, setCenterMap] = useState<CoordinatesType>({
    lat: !!coordinates.lat ? coordinates.lat : Number(lat),
    lng: !!coordinates.lng ? coordinates.lng : Number(lng)
  });

  const parkingList = useMemo(() => {
    return places.map((place) => place.data.results.map((result: any) => result)).flat();
  }, [places]);

  // Return map bounds based on list of places
  //@ts-expect-error
  const getMapBounds = (map, maps, parkingList) => {
    const bounds = new maps.LatLngBounds();

    //@ts-expect-error
    parkingList.forEach((place) => {
      bounds.extend(
        new maps.LatLng(place.positionOfEntry.coordinates[0], place.positionOfEntry.coordinates[1])
      );
    });
    return bounds;
  };

  // Re-center map when resizing the window
  //@ts-expect-error
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  // Fit map to its bounds after the api is loaded
  //@ts-expect-error
  const apiIsLoaded = (map, maps, parkingList) => {
    if (parkingList.length === 1) {
      setCenterMap({
        lat: parkingList[0]?.positionOfEntry?.coordinates[0],
        lng: parkingList[0]?.positionOfEntry?.coordinates[1]
      });
    } else {
      // Get bounds by our places
      const bounds = getMapBounds(map, maps, parkingList);
      // Fit map to bounds
      map.fitBounds(bounds);
      // Bind the resize listener
      bindResizeListener(map, maps, bounds);
    }
  };

  useEffect(() => {
    if (chosenLabel !== t('placeholders.your_location')) {
      setPositionDetected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenLabel]);

  useEffect(() => {
    if (hoveredCard) {
      const hoveredParking = parkingList.find((parking) => parking.id === hoveredCard);
      if (hoveredParking) {
        const coordinates = hoveredParking.positionOfEntry.coordinates;
        setCenterMap({ lat: coordinates[0], lng: coordinates[1] });
      }
      setZoom(15);
    } else {
      setZoom(13);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredCard]);

  const checkIsPlaceCity = (placesArr: string[]): boolean =>
    !GOOGLE_PLACES_MARKERS.some((element) => placesArr.includes(element));

  useEffect(() => {
    //@ts-expect-error
    if (!!value && value.value && value.value.types) {
      //@ts-expect-error
      checkIsPlaceCity(value.value.types) ? setZoom(13) : setZoom(15);
    }
  }, [value]);

  useEffect(() => {
    if (parkingList.length === 1) {
      setCenterMap({
        lat: parkingList[0]?.positionOfEntry?.coordinates[0],
        lng: parkingList[0]?.positionOfEntry?.coordinates[1]
      });
    } else {
      setCenterMap({
        lat: !!coordinates.lat ? coordinates.lat : Number(lat),
        lng: !!coordinates.lng ? coordinates.lng : Number(lng)
      });
    }
  }, [coordinates, parkingList, lat, lng]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_API_KEY, language: i18n.language, libraries: ['places'] }}
      center={centerMap}
      defaultZoom={13}
      zoom={isMobile ? 12 : zoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) =>
        !isMobile && !!parkingList.length && apiIsLoaded(map, maps, parkingList)
      }
      onChange={({ zoom, bounds, center, size, marginBounds }) => {
        if (!!bounds.ne.lat && !!bounds.ne.lng && !!center.lat && !!center.lng) {
          // TODO: Find out the best way to fetch new parking with bounds changed on map
          // dispatch(setSearchValue({ range: getBoundsRadius({ bounds, center }) }));
        }

        // setZoom(zoom);
        // setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
      }}>
      {parkingList.map((parking) => (
        <PointMarker
          key={parking.id}
          lat={parking.positionOfEntry.coordinates[0]}
          lng={parking.positionOfEntry.coordinates[1]}
          data={parking}
          from={dates.from.format('MM/DD/yyyy')}
          to={dates.to.format('MM/DD/yyyy')}
          selected={hoveredCard}
          onMouseOver={(id: number) => {
            setCurrent(id);
            setHoveredPin(id);
          }}
          id={parking.id}
          current={current}
        />
      ))}

      {positionDetected && (
        <MyPosition {...{ lat: coordinates.lat, lng: coordinates.lng }}>
          {pathname === `/${paths.parking}` ? (
            <CarIcon
              style={{ transform: 'translate(-50%, -100%)', width: '50px', height: '50px' }}
            />
          ) : (
            <MyLocationIcon style={{ transform: 'translate(-50%, -100%)' }} />
          )}
        </MyPosition>
      )}
    </GoogleMapReact>
  );
};
