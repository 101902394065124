import styled from "styled-components";

import { color, fontSize, device } from "theme";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 170px;
  border-radius: 10px;
  background: #ffffff;
  padding: 5px;
  position: relative;
  margin-bottom: 10px;

  @media ${device.tablet} {
    flex-direction: row;
    padding: 10px;
  }
`;

export const LeftSideCont = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    width: 80%;
    min-height: 120px;
  }
`;

export const ImgWrapper = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 5px;

  @media ${device.tablet} {
    width: 140px;
    height: 140px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 20px;
  width: 70%;

  @media ${device.tablet} {
    margin-bottom: 0px;
  }
`;

export const StyledColumns = styled.div`
  width: 100%;

  @media ${device.tablet} {
    width: 33%;
  }
`;

export const ParkingInfoCont = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ParkingImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StreetTitle = styled.p`
  font-size: ${fontSize.medium};
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 10px;

  @media ${device.laptop} {
    font-size: ${fontSize.large};
    line-height: 21px;
  }
`;

export const StyledText = styled.p`
  color: ${color.greayText};
  font-size: ${fontSize.regular};
  line-height: ${fontSize.medium};
  font-weight: 400;
  margin-bottom: 5px;
`;

export const StyledPrice = styled.span`
  color: ${color.blue};
  font-size: ${fontSize.x_large};
  line-height: 28px;
  font-weight: 700;
`;

export const StyledNote = styled.span`
  color: ${color.greayText};
  font-size: ${fontSize.regular};
  line-height: 16px;
  font-weight: 400;
`;

export const LabelCont = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.height ? `${props.height}px` : "30px")};
  width: ${(props) => (props.width ? `${props.width}px` : "fit-content")};
  border-radius: 5px;
  color: #ffffff;
  font-size: ${fontSize.small};
  background: ${(props) =>
    props.status === "APPROVED_VERIFIED" ||
    props.status === "APPROVED_UNVERIFIED"
      ? color.lightGreen
      : props.status === "PENDING"
      ? color.yellow
      : color.red};
  border: none;
  padding: 0 10px;
  margin-top: ${(props) => (props.margin ? `${props.margin}px` : "5px")};
  margin-right: 10px;
  white-space: nowrap;

  @media ${device.laptop} {
    font-size: ${fontSize.medium};
  }
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 10px;
`

export const ButtonsWrapperModal = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`

export const TabsButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;