import { createSlice } from '@reduxjs/toolkit';

export interface Profile {
  id?: number;
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;
  emailConfirmed? : boolean;
}

interface LoginState extends Profile{
  accessToken?: string | null;
  refreshToken?: string | null;
}

const initialState: LoginState = {
  accessToken: localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : "",
  refreshToken: localStorage.getItem("refreshToken")
    ? localStorage.getItem("refreshToken")
    : "",
  ...(localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo") || '') : null)
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      return {
        ...action.payload,
      }
    },
    logout: () => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userInfo');
      return {};
    },
    setUserInfo: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    }
  }
})

export const { login, logout, setUserInfo } = authSlice.actions;