import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import image from "assets/img/no-parking-photo.png";
import { Label } from "Components/UI/Label/Label";
import {
  ToltipWrapper,
  ImgContainer,
  Img,
  InfoContainer,
  StyledText,
  PriceNote,
  BottomContainer,
} from "./style";

type ParkingInfo = {
  status?: string;
  price: string;
  street: string;
  house: string;
  url: string;
  carClass: string;
  currency: string;
};

export const Tooltip: FC<ParkingInfo> = ({ status, price, street, house, url, carClass, currency }) => {
  const { t } = useTranslation(['common']);
  return (
    <ToltipWrapper>
      <ImgContainer>
        <Img src={url ? `${url}` : image} alt="parking photo" />
      </ImgContainer>
      <InfoContainer>
        <StyledText>
          {street} {house}
        </StyledText>

        <StyledText>{`${t('parking.car_class')}: ${t(`parking.${carClass}`)}`}</StyledText>
        <BottomContainer>
          <PriceNote>
            {price} {currency}
          </PriceNote>
          {status && (
            <Label height="25" margin="0" status={status === 'MANUAL_APPROVE'}>
              {status === 'MANUAL_APPROVE' ? t('status.ondemand') : t('status.online')}
            </Label>
          )}
        </BottomContainer>
      </InfoContainer>
    </ToltipWrapper>
  );
};
