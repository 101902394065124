import styled from 'styled-components';

import { color, fontSize, lineHeight, device } from 'theme';

export const Text = styled.p`
  color: ${color.darkGrey};
  font-size: ${fontSize.regular};
  line-height: ${lineHeight.medium};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : ``)};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : ``)};
`;

export const DesktopVisibleText = styled.p`
  display: none;

  @media ${device.tablet} {
    display: inline-block;
    font-size: ${fontSize.small};
    color: #fff;
  }

  @media ${device.laptop} {
    font-size: ${fontSize.regular};
  }
`;
