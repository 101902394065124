import styled from "styled-components";

import { color } from "theme";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${color.backgroundGrey};
`;

export const FormContainer = styled.div`
  border-radius: 10px;
  width: 400px;
  padding: 20px;
  height: 300px;
  background: ${color.white};
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  height: 25px;
  margin: 10px 0 30px;
`;

export const Error = styled.span`
  color: ${color.red};
`;

export const ButtonWrapper = styled.div`
  margin-top: 10px;
`;