import { createSlice } from '@reduxjs/toolkit';

export const citiesSlice = createSlice({
  name: 'cities',
  initialState: { cityId: 0 },
  reducers: {
    setCityId: (state, action) => {
      state.cityId = action.payload;
    },
  }
})

export const { setCityId } = citiesSlice.actions;