import React from 'react';
import { TooltipWrapper } from './style.js';

type NotificationTooltipType = {
  text: string;
  isError: boolean;
};

export const NotificationTooltip = ({ text, isError = false }: NotificationTooltipType) => {
  return <TooltipWrapper isError={isError}>{text}</TooltipWrapper>;
};
