import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from 'react-query';
import { GOOGLE_API_KEY, paths } from 'config';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './App.css';
import { useTranslation } from 'react-i18next';
import { Loader } from '@googlemaps/js-api-loader';

import {
  ParkingPage,
  SellParkingPlace,
  PrivacyPolicy,
  Partners,
  Conditions,
  Contacts,
  AboutUs,
  Info,
  Admin,
  HomePage,
  Feedback,
  AddParking,
  CreateParking,
  PartnerProfile,
  EditParking,
  EditParkingForSale,
  ParkingForSalePage,
  SingleParkingForSale
} from 'Pages';
import { ForgotPassword } from 'Pages/Password/ForgotPassword';
import { RestorePassword } from 'Pages/Password/RestorePassword';

import Footer from 'Components/Footer';

export const MapContext = React.createContext(false);

function App() {
  const queryClient = new QueryClient();
  const location = useLocation();
  const { i18n } = useTranslation(['common']);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loader = new Loader({
      apiKey: GOOGLE_API_KEY,
      libraries: ['places'],
      language: i18n.language
    });
    loader.load().then(() => {
      setLoaded(true);
    });
  }, [i18n.language]);

  const showFooter =
    !location.pathname.includes('admin') && !location.pathname.includes('/parkings-for-rent');

  return (
    <MapContext.Provider value={loaded}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <div className="App">
          <Switch>
            <Route path="/" exact component={HomePage}></Route>
            <Route path={`/${paths.oneparking}/`} component={AddParking}></Route>
            <Route path={`/${paths.createparking}`} exact component={CreateParking}></Route>
            <Route path={`/${paths.partnerprofile}`} exact component={PartnerProfile} />
            <Route path={`/${paths.forgotPassword}`} exact component={ForgotPassword}></Route>
            <Route path={`/${paths.resetPassword}`} exact component={RestorePassword}></Route>
            <Route path={`/${paths.feedback}`} exact component={Feedback}></Route>
            <Route
              path={`/${paths.emailConfirmed}`}
              exact
              render={(props) => <Info info={{ type: 'email', success: true }} />}></Route>
            <Route
              path={`/${paths.emailConfirmationDenied}`}
              exact
              render={(props) => <Info info={{ type: 'email', success: false }} />}></Route>
            <Route
              path={`/${paths.bookingConfirmation}`}
              exact
              render={(props) => <Info info={{ type: 'booking' }} />}></Route>
            <Route
              path={`/${paths.editParkingForSale}`}
              exact
              component={EditParkingForSale}></Route>
            <Route path={`/${paths.editParking}`} exact component={EditParking}></Route>
            <Route path={`/${paths.aboutUs}`} exact component={AboutUs}></Route>
            <Route path={`/${paths.contacts}`} exact component={Contacts}></Route>
            <Route path={`/${paths.conditions}`} exact component={Conditions}></Route>
            <Route path={`/${paths.privacyPolicy}`} exact component={PrivacyPolicy} />
            <Route path={`/${paths.partners}`} exact component={Partners}></Route>
            <Route path={`/${paths.sellParkingPlace}`} exact component={SellParkingPlace}></Route>
            <Route path={`/${paths.parkingsSale}/`} component={ParkingForSalePage}></Route>
            <Route
              path={`/${paths.selectedParkingForSale}/`}
              component={SingleParkingForSale}></Route>
            <Route path={`/${paths.parking}/`} component={ParkingPage}></Route>
            <Route path={`/${paths.admin}`} component={Admin} />
          </Switch>
          {showFooter && <Footer />}
        </div>
      </QueryClientProvider>
    </MapContext.Provider>
  );
}

export default App;
