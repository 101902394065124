import React from "react";
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { DD_MM_YYYY } from 'Constants/constants';

import { Stars } from 'Components/UI/Stars/Stars';

import {
  Container,
  Info,
  ID,
  Title,
  Text,
  RatingTitles,
  RatingTitle,
  RatingContainer,
  Rating as RatingInfo,
  Score,
  ScoreNumbers
} from './style';

export interface Rating {
  id: number;
  size: number;
  comfort: number;
  talk: number;
  description: string;
  createdAt: string;
  updatedAt: string;
  request: {
    id: number;
    car: string;
    color: string;
    price: number;
    pricePerDay: number;
    from: string;
    to: string;
    status: string;
    transactionCreated: boolean;
    commission: number;
    requestCreated: string;
    reviewMessageSent: boolean;
    autoApproved: boolean;
    parkingPlaceId: number;
    email: string;
    phoneNumber: string;
    name: string;
    language: string;
  };
}

interface Props {
  rating: Rating;
  carName?: string;
}

export const RatingCard = ({ rating, carName = '' }: Props) => {
  const { t } = useTranslation(['common']);

  const { comfort, talk, size, description } = rating;
  const { id, name, car, from, to, parkingPlaceId } = rating?.request;

  let totalScore = ((comfort + talk + size) / 3).toFixed(1);

  return (
    <Container>
      <Info>
        <ID>{`ID ${id}`}</ID>
        <Title>
          {name && `${name},`} {car && ` автомобіль: ${car}`}
        </Title>
        {parkingPlaceId && <ID>{`Id парковки: ${parkingPlaceId}`}</ID>}
        <Text>
          Даты: {moment.tz(new Date(from), 'Europe/Kiev').format(DD_MM_YYYY)} -{' '}
          {moment.tz(new Date(to), 'Europe/Kiev').format(DD_MM_YYYY)}
        </Text>
        <Text>{description}</Text>
      </Info>
      <RatingInfo>
        <RatingTitles>
          <RatingTitle>{t('titles.comfort')}</RatingTitle>
          <RatingTitle>{t('titles.place_sizes')}</RatingTitle>
          <RatingTitle>{t('titles.contacting')}</RatingTitle>
        </RatingTitles>
        <RatingContainer>
          <Stars rating={comfort * 20}></Stars>
          <Stars rating={size * 20}></Stars>
          <Stars rating={talk * 20}></Stars>
        </RatingContainer>
        <Score>
          <ScoreNumbers>{totalScore}</ScoreNumbers>
        </Score>
      </RatingInfo>
    </Container>
  );
};
