import styled from 'styled-components';

import { font, device, fontSize } from 'theme';

export const StyledH1 = styled.h1`
  font-family: ${font.primary};
  font-weight: 500;
  font-style: normal;
  font-size: ${fontSize.m_large};
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;

  @media ${device.laptop} {
    font-size: ${fontSize.x_large};
  }

  @media ${device.laptopL} {
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 40px;
  }
`;
