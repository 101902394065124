import { MainHeader } from 'Components/Header/MainHeader';
import { Container, Text, Title } from './styles';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

export const AboutUs = () => {
  const { t } = useTranslation(['common']);

  return (
    <>
      <Helmet>
        <title>{t('titles.helmet_about')}</title>
      </Helmet>
      <MainHeader />
      <Container>
        <Title>{t('titles.helmet_about')}</Title>
        <Text>{t('contents.about_desc')}</Text>
      </Container>
    </>
  );
};