import styled from "styled-components";
import { color } from "theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${color.backgroundGrey};
  height: 100%;
  padding: 100px;
  @media (max-width: 768px) {
    padding: 100px 16px 60px;
  }
`;

export const Title = styled.h2`
  font-weight: 400;
  font-size: 40px;
  margin-left: -40px;
`;

export const Text = styled.p`
  max-width: 800px;
  margin-top: 50px;
`;