import { blockUser, BlockUserBody, getUsers } from 'api/admin';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { color } from 'theme';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../UI/Pagination/Pagination';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';
import Modal from 'react-modal';
import styles from './style.module.css';
import { Button as ModalButton } from 'Components/UI/Button/Button';
import { SmallLoader } from 'Components/Loader/Loader';
import { SearchBar } from './components/SearchBar';
import { StyledH2 } from 'Components/UI/H2/style.js';
import { UserInfo } from './components/UserInfo';

import {
  Container,
  User,
  Button,
  Wrapper,
  ButtonWrapper,
  ButtonsWrapperModal,
  inlineStyles
} from './style';
import { totalRemoveUserById } from 'api/parking';
import { toast } from 'react-toastify';
import { ADMIN_TOKEN_KEY, TOptions } from '../constants.ts';
import { UserType } from '../types.ts';

Modal.setAppElement('#root');

export const Users = () => {
  const { t } = useTranslation(['admin']);
  const token = localStorage.getItem(ADMIN_TOKEN_KEY);
  const history = useHistory();
  const { url } = useRouteMatch();

  const params = new URLSearchParams(history.location.search);

  const options = [
    { value: 'id', label: t('admin.user_info_id') },
    { value: 'name', label: t('admin.user_info_name') },
    { value: 'phone', label: t('admin.user_info_phone') }
  ];
  const [selected, setSelected] = useState<TOptions>(options[1]);
  const [users, setUsers] = useState<UserType[]>([]);
  const [pages, setPages] = useState<number>(1);
  const [query, setQuery] = useState<string>('');
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [page, setPage] = useState(+(params.get('page') ?? 1));

  const getAllPartnersList = async () => {
    setIsLoading(true);
    let params;
    if (selected.value === 'name') {
      params = { name: query };
    } else if (selected.value === 'id') {
      params = { userId: query };
    } else {
      params = { phoneNumber: query };
    }
    const { data } = await getUsers(token, query ? { ...params, page } : { page });
    const key = selected.value as keyof UserType;
    setUsers(data.results.sort((a, b) => (a[key] > b[key] ? 1 : -1)));
    setPages(Math.ceil(data.count / 10));
    setIsLoading(false);
  };

  useEffect(() => {
    try {
      getAllPartnersList();
    } catch (error) {
      toast.error(t('notifications.partners_list_error'), NOTIFICATION_OPTIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query, selected]);

  const handleClick = (user: any) => {
    history.push({
      pathname: `${url}/${user.id}`,
      state: user
    });
  };

  const { mutateAsync } = useMutation(blockUser, {
    onError: (error: AxiosError) => {
      const errorMessage = error?.response?.data?.message.join(', ');
      toast.error(
        `${t('notifications.partner_block_error')} ${errorMessage}`,
        NOTIFICATION_OPTIONS
      );
    },
    onSuccess: (response: AxiosResponse) => {
      toast.success(t('notifications.partner_block_success'), NOTIFICATION_OPTIONS);
      getAllPartnersList();
    }
  });

  function onBlock(userId: number, blocked: boolean) {
    const body: BlockUserBody = {
      userId,
      blocked
    };

    mutateAsync({ body, token });
  }

  function handleSelect(selected: any) {
    setSelected(selected);
  }

  const handlePageClick = (page: any) => {
    setPage(page.selected + 1);
    history.push({
      pathname: 'users',
      search: `?page=${page.selected + 1}`
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setPage(1);
  };

  function handleRemove(id: number) {
    setDeleteUserId(id);
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const handleCheckBookings = (e, data) => {
    e.stopPropagation();
    history.push({
      pathname: `user-all-bookings/${e.target.value}`,
      state: data || { userName: '', userId: '', userEmail: '', userSurname: '' }
    });
  };

  const handleCheckBillsAndPayments = (e) => {
    e.stopPropagation();
    history.push(`bills-and-payments/${e.target.value}`);
  };

  async function onSubmitRemove() {
    try {
      !!deleteUserId && (await totalRemoveUserById(deleteUserId, token));
      if (users?.length) {
        const newArr = users.filter((el) => el.id !== deleteUserId);
        setUsers(newArr);
      }

      setModalIsOpen(false);
    } catch (error) {
      let message;
      if (error.response.statusCode === 403 && error.response.message === 'Feature is disabled') {
        message = error.response.message;
      } else {
        message = t('notifications.user_isn_t_deleted');
      }
      toast.error(message, NOTIFICATION_OPTIONS);
      setModalIsOpen(false);
    }
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={inlineStyles.modal}
        contentLabel="Deletion confirmation Modal"
        className={styles.Modal}>
        <p>{t('notifications.deleting_confirmation')}</p>
        <ButtonsWrapperModal>
          <ModalButton
            dataTestid="confirm-delete"
            remove
            width="126"
            height="50"
            onClick={onSubmitRemove}>
            {t('buttons.delete')}
          </ModalButton>
          <ModalButton
            dataTestid="confirm-cancel"
            primary={true}
            width="126"
            height="50"
            onClick={closeModal}>
            {t('buttons.cancel')}
          </ModalButton>
        </ButtonsWrapperModal>
      </Modal>
      <Container>
        <SearchBar
          selected={selected}
          handleSelect={handleSelect}
          handleChange={handleChange}
          query={query}
          options={options}
        />
        {isLoading && <SmallLoader />}

        {!isLoading && users.length > 0 && (
          <>
            {users?.map((user) => (
              <Wrapper key={user.id}>
                <User onClick={() => handleClick(user)}>
                  <UserInfo
                    id={user.id}
                    email={user.email}
                    name={user.name}
                    surname={user.surname}
                    phone={user.phone}
                    parkings={user.countOfParkingPlaces}
                  />

                  <ButtonWrapper>
                    <Button
                      color={color.yellow}
                      onClick={(e) =>
                        handleCheckBookings(e, {
                          userId: user.id,
                          userName: user.name,
                          userSurname: user.surname,
                          userEmail: user.email
                        })
                      }
                      value={user.id}>
                      {t('partner_info.partners_bookings')}
                    </Button>
                    <Button
                      color={color.orange}
                      onClick={(e) => handleCheckBillsAndPayments(e)}
                      value={user.id}>
                      {t('titles.bills_and_payments')}
                    </Button>
                  </ButtonWrapper>

                  <ButtonWrapper>
                    <Button
                      color={user.isBlocked ? color.green : color.red}
                      onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.stopPropagation();
                        onBlock(user.id, !user.isBlocked);
                      }}>
                      {user.isBlocked ? t('admin.user_unblock') : t('admin.user_block')}
                    </Button>
                    <Button
                      color={color.red}
                      onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.stopPropagation();
                        handleRemove(user.id);
                      }}>
                      {t('buttons.delete')}
                    </Button>
                  </ButtonWrapper>
                </User>
              </Wrapper>
            ))}

            {users && users.length && (
              <Pagination page={page} pages={pages} handlePageClick={handlePageClick} />
            )}
          </>
        )}

        {!isLoading && users.length === 0 && (
          <StyledH2>{t('notifications.no_user_to_display')}</StyledH2>
        )}
      </Container>
    </>
  );
};
