import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MainHeader } from 'Components/Header/MainHeader';
import { Link } from 'Components/UI/Link/Link';
import { StyledNotification } from 'Components/Profile/OnDemand/style';
import { MainImg, FormWrapper, LinkWrapper } from 'Pages/Password/style';
import { StyledRightSide } from './style';
import { confirmBooking } from 'api/booking';
import { useLocation } from 'react-router';
import { userApprovedRequest } from 'api/parking';

export const Info = ({ info }: { info: any }) => {
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['common']);
  const { type, success } = info;
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const requestId = params.get('requestId');
    const status = params.get('status');
    if (token) {
      const authToken = localStorage.getItem('acceessToken') || '';
      (async function () {
        try {
          setLoading(true);
          await confirmBooking(token, authToken);
          setLoading(false);
          setBookingSuccess(true);
        } catch (error) {
          setLoading(false);
          setBookingSuccess(false);
        }
      })();
    }
    if (status === 'APPROVED' && token && requestId) {
      userApprovedRequest(requestId);
    }
  }, [location.search]);

  return (
    <>
      <MainHeader />
      <FormWrapper>
        <MainImg />
        <LinkWrapper>
          <Link to="/">{t('titles.back_home')}</Link>
        </LinkWrapper>
        <StyledRightSide>
          {' '}
          <StyledNotification>
            {type === 'email'
              ? success
                ? t('notifications.email_confirmed')
                : t('notifications.email_not_confirmed')
              : loading
              ? t('status.loading_info')
              : bookingSuccess
              ? t('notifications.reply_thanks')
              : t('notifications.error')}
          </StyledNotification>
        </StyledRightSide>
      </FormWrapper>
    </>
  );
};
