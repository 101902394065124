import styled from "styled-components";
import { color, device } from 'theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 100px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 100px 16px;
  }
`;

export const Title = styled.h2`
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  max-width: 800px;
  margin-top: 50px;
`;

export const Icons = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
`;

export const Icon = styled.div``;

export const Email = styled.a`
  text-align: center;
  margin-top: 10px;
  color: ${color.black};
`;

export const ContactsWrapper = styled.div`
  margin-top: auto;

  @media ${device.tablet} {
    margin-left: auto;
  }
`;

export const ContactsIconsContainer = styled.div`
  display: flex;
  gap: 5px;

  @media ${device.tablet} {
    gap: 10px;
  }

  & > a,
  img {
    width: 40px;
    height: 40px;
    border-radius: 30px;
  }
`;
