import styled from "styled-components";
import { color } from "theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${color.backgroundGrey};
  padding: 40px;
  max-width: 1280px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 70px 16px;
  }
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  max-width: 800px;
  margin-top: 50px;
`;

export const ParagraphTitle = styled.h3`
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
`;

export const Paragraph = styled.p`
  margin-top: 5px;
  text-align: justify;

  &.info {
    width: 100%;
    justify-content: space-between;
    display: flex;
    margin-bottom: 20px;
  }
`;

export const Block = styled.div`
  margin-top: 20px;
`;

export const Number = styled.span`
  font-weight: 500;
`;