import React from "react";
import { useTranslation } from "react-i18next";

import { ImageWrapper, Image, StyledButtton, MakePrimary, PrimaryImg } from "./style.js";

type Props = {
  primary?: boolean;
  src: string;
  width?: string;
  onClick: () => void;
  onPrime?: () => void;
  document?: boolean;
};

export const ImagePreview = ({
  src,
  onClick,
  width,
  primary,
  onPrime,
  document,
}: Props) => {
  const { t } = useTranslation(["common"]);

  return (
    <ImageWrapper width={width}>
      {!document ? (primary ? (
        <PrimaryImg>{t("images.primary_img")}</PrimaryImg>
      ) : (
        <MakePrimary onClick={onPrime}>{t("images.make_primary")}</MakePrimary>
      )) : null}
          <StyledButtton type="button" onClick={() => onClick()}>X</StyledButtton>
      <Image src={src} />
    </ImageWrapper>
  );
};
