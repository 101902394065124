import React from 'react';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

import styles from './style.module.css';

type PaginationPropsType = {
  pages: number;
  page: number;
  handlePageClick: () => void;
};

export const Pagination = ({ page, pages, handlePageClick }: PaginationPropsType) => {
  const { t } = useTranslation(['admin']);

  return (
    <ReactPaginate
      previousLabel={t('pagination.previous')}
      nextLabel={t('pagination.next')}
      breakLabel={'...'}
      pageCount={pages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      containerClassName={styles.pagination}
      activeClassName={styles.active}
      pageClassName={styles.page}
      previousClassName={styles.nextPrev}
      nextClassName={styles.nextPrev}
      disabledClassName={styles.disabled}
      breakClassName={styles.break}
      initialPage={page - 1}
      renderOnZeroPageCount={null}
    />
  );
};
