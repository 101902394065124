import styled from "styled-components";

import { color } from "theme";

export const Container = styled.div`
  padding: 20px 12px;
  background: ${color.backgroundGrey};
`;

export const Parking = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  opacity: ${(props) => (props.isDeleted ? 0.5 : 1)};
`;

export const UserInfo = styled.div`
  background-color: ${color.white};
  border-radius: 10px;
  padding: 10px 20px;
`;