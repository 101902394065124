import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { SecondaryButton } from "Components/UI/SecondaryButton/SecondaryButton";
import { TimerContainer } from "./style";

interface Props {
  onClick?: () => void;
  timerNumber?: number;
  disabled?: boolean;
}

export const Timer = ({ onClick }: Props) => {
  const { t } = useTranslation(["common"]);

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [count, setCount] = useState<number>(45);

  const resendSms = () => {
    //@ts-expect-error
    onClick();
    setCount(45);
    setIsDisabled(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDisabled(false);
    }, count * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [count, isDisabled]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count > 0) {
        setCount((prevState) => prevState - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [count]);

  return (
    <>
      <SecondaryButton disabled={isDisabled} onClick={resendSms}>
        {t("buttons.resend")}
      </SecondaryButton>
      {count > 0 && <TimerContainer>{count}</TimerContainer>}
    </>
  );
};
