import React from 'react';

export const CarRatingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="163.839"
      height="163.839"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 1706.66 1706.66"
      {...props}>
      <g>
        <path
          d="M817.22 1499.13h-309.3c-11.04 0-20-8.97-20-20l.98-392.84c16.25-49.68 60.1-158.26 82.65-201.2 52.49-99.98 159.25-11.58 126.48 76.04l-41.64 111.4h278.63c30.24 0 60.33 27.54 49.47 57.51l-117.8 325.24c-3.93 10.83-15.9 43.85-49.48 43.85zm-289.3-40.02h289.3c1.51 0 5.54 0 11.87-17.48l117.33-323.91c-1.68-2.08-6.72-5.21-11.39-5.21H627.58c-13.92 0-23.61-13.98-18.73-27l51.74-138.41c14.15-37.85-24.37-99.21-53.61-43.44-20.92 39.83-62.8 143.39-79.06 192.06v363.4z"
          className="fil0"></path>
        <path
          d="M507.93 1499.13H385.97c-11.04 0-20-8.97-20-20v-386.62c0-11.04 8.96-20 20-20h121.96c11.04 0 20 8.97 20 20v386.62c0 11.04-8.96 20-20 20zm-101.96-40.02h81.96V1112.5h-81.96v346.61z"
          className="fil0"></path>
        <path
          d="M491.74 789.9c-9.68 0-95.13-42.45-110.61-49.69l-102.14 47.8c-14.16 6.62-30.25-5.07-28.33-20.58l13.9-111.91-77.03-82.37c-10.74-11.48-4.57-30.32 10.82-33.29l110.74-21.36 54.54-98.71c7.59-13.75 27.41-13.75 35 0l54.54 98.71 110.72 21.36c15.42 2.99 21.54 21.83 10.82 33.29l-77.02 82.37 13.9 111.91c1.48 11.99-7.96 22.47-19.86 22.47zm-110.62-91.78c9.18 0 73.26 32.22 86.3 38.32l-10.6-85.28c-.72-5.89 1.2-11.79 5.23-16.13l58.69-62.78-84.37-16.28a19.977 19.977 0 01-13.71-9.97l-41.57-75.22L339.53 546c-2.88 5.2-7.89 8.84-13.7 9.97l-84.37 16.28 58.69 62.78a19.943 19.943 0 015.24 16.13l-10.6 85.28c13.05-6.11 77.13-38.32 86.32-38.32zM742.7 789.9c-11.9 0-21.35-10.46-19.87-22.47l13.9-111.91-77.01-82.37c-10.7-11.45-4.61-30.32 10.82-33.29l110.72-21.36 54.54-98.71c7.59-13.75 27.41-13.75 35.01 0l54.54 98.71 110.72 21.36c15.42 2.99 21.54 21.83 10.83 33.29l-77.03 82.37 13.9 111.91c1.92 15.54-14.17 27.21-28.32 20.58l-102.14-47.8c-15.5 7.25-100.92 49.69-110.62 49.69zm110.62-91.78c9.18 0 73.26 32.22 86.3 38.32l-10.6-85.28a19.981 19.981 0 015.23-16.13l58.69-62.78-84.36-16.28a19.996 19.996 0 01-13.72-9.97l-41.55-75.21-41.56 75.22c-2.87 5.2-7.89 8.84-13.7 9.97l-84.37 16.28 58.69 62.78a19.936 19.936 0 015.23 16.13L767 736.45c13-6.09 77.19-38.34 86.3-38.34zM1436.14 789.9c-9.68 0-95.13-42.45-110.61-49.69l-102.13 47.8c-14.15 6.63-30.24-5.06-28.32-20.58l13.9-111.91-77.03-82.37c-10.69-11.44-4.62-30.31 10.83-33.29l110.72-21.36 54.54-98.71c7.61-13.76 27.43-13.74 35.02 0l54.53 98.71 110.72 21.36c15.42 2.99 21.54 21.83 10.83 33.29l-77.03 82.37 13.9 111.91c1.48 12.04-7.97 22.47-19.88 22.47zm-250.26-217.63l58.69 62.78a19.943 19.943 0 015.24 16.13l-10.6 85.28 77.83-36.43a20.041 20.041 0 0116.96 0l77.83 36.43-10.6-85.28a19.981 19.981 0 015.23-16.13l58.69-62.78-84.36-16.28a19.996 19.996 0 01-13.72-9.97l-41.54-75.22-41.55 75.22c-2.88 5.2-7.89 8.84-13.72 9.97l-84.37 16.28z"
          className="fil1"></path>
        <path
          d="M1314.48 991.84H672.7c-13.92 0-23.61-13.97-18.74-27 9.15-24.48 17.2-44.28-.15-69.31-34.32-49.52-63.06 40.71-81.19 84.02-3.11 7.43-10.39 12.3-18.46 12.3h-162C175.92 991.85.01 815.92.01 599.68c0-216.25 175.93-392.16 392.15-392.16h922.32c216.24 0 392.17 175.93 392.17 392.17 0 216.22-175.93 392.15-392.17 392.15zm-613.52-40.02h613.52c194.18 0 352.15-157.97 352.15-352.15 0-194.18-157.97-352.17-352.15-352.17H392.16c-194.18 0-352.15 157.98-352.15 352.17 0 194.18 157.98 352.16 352.15 352.16h148.73c22.97-53.96 43.24-110.94 91.23-110.94 47.76 0 82.07 59.17 68.83 110.93z"
          className="fil0"></path>
      </g>
    </svg>
  );
};
