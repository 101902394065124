import styled from "styled-components";

import { color, font, fontSize, device } from "theme";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;

  @media ${device.tablet} {
    margin-right: 10px;
  }
`;

export const StyledA = styled.div`
  font-family: ${font.primary};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize.small};
  line-height: 16px;
  color: ${color.black};

  @media ${device.tablet} {
    font-size: ${fontSize.regular};
  }
`;

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 10px;

  @media ${device.tablet} {
    width: 24px;
    height: 24px;
  }
`;
