import React from 'react';

export const GeoPositionDetectIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="150"
      fill="none"
      viewBox="0 0 150 150"
      {...props}>
      <g fill="orange" clipPath="url(#clip0_9_111)">
        <path d="M9.375 75C9.375 38.437 38.438 9.375 75 9.375S140.625 38.438 140.625 75 111.562 140.625 75 140.625c-36.563 0-65.625-29.063-65.625-65.625zM0 75c0 41.25 33.75 75 75 75s75-33.75 75-75S116.25 0 75 0 0 33.75 0 75z"></path>
        <path d="M18.75 84.375L112.5 37.5l-46.875 93.75V84.375H18.75z"></path>
      </g>
      <defs>
        <clipPath id="clip0_9_111">
          <path fill="#fff" d="M0 0H150V150H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
