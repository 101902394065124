import { MainHeader } from 'Components/Header/MainHeader';
import {
  Block,
  Container,
  Paragraph,
  Title,
  ParagraphTitle,
  SubParagraph,
  SubDivParagraph,
  Number
} from './styles';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicy = () => {
  const { t } = useTranslation(['common']);

  const paragraphs = [
    {
      title: t('privacy.title_personal_data'),
      content: [
        [
          {
            main: t('privacy.personal_data_main1'),
            sub: [t('privacy.personal_data_sub1', { returnObjects: true })]
          }
        ],
        [
          {
            main: t('privacy.personal_data_main2'),
            sub: [t('privacy.personal_data_sub2', { returnObjects: true })]
          },
          {
            main: t('privacy.personal_data_main3'),
            sub: [t('privacy.personal_data_sub3', { returnObjects: true })]
          }
        ],
        [
          {
            main: t('privacy.personal_data_main4'),
            sub: [t('privacy.personal_data_sub4', { returnObjects: true })]
          }
        ]
      ]
    },
    {
      title: t('privacy.title_purpose'),
      content: [
        [
          {
            main: t('privacy.purpose_main1'),
            sub: [t('privacy.purpose_sub1', { returnObjects: true })]
          }
        ],
        [
          {
            main: t('privacy.purpose_main2')
          }
        ],
        [
          {
            main: t('privacy.purpose_main3')
          }
        ]
      ]
    },
    {
      title: t('privacy.title_transmission'),
      content: [
        [
          {
            main: t('privacy.transmission_main1'),
            sub: [t('privacy.transmission_sub1', { returnObjects: true })]
          }
        ],
        [
          {
            main: t('privacy.transmission_main2')
          }
        ],
        [
          {
            main: t('privacy.transmission_main3')
          }
        ]
      ]
    },
    {
      title: t('privacy.title_rights'),
      content: [
        [
          {
            main: t('privacy.rights_main1')
          }
        ],
        [
          {
            main: t('privacy.rights_main2')
          }
        ],
        [
          {
            main: t('privacy.rights_main3')
          }
        ],
        [
          {
            main: t('privacy.rights_main4')
          }
        ],
        [
          {
            main: t('privacy.rights_main5')
          }
        ],
        [
          {
            main: t('privacy.rights_main6')
          }
        ],
        [
          {
            main: t('privacy.rights_main7')
          }
        ],
        [
          {
            main: t('privacy.rights_main8')
          }
        ],
        [
          {
            main: t('privacy.rights_main9')
          }
        ]
      ]
    },
    {
      title: t('privacy.title_security'),
      content: [
        [
          {
            main: t('privacy.security_main1')
          }
        ]
      ]
    },
    {
      title: t('privacy.title_contacts')
    }
  ];

  const addParagraph = t('privacy.add_paragraph');

  return (
    <>
      <MainHeader />
      <Container>
        <Title>{t('paragraphTitles.privacy_policy')}</Title>
        <Paragraph>{t('paragraphDetails.privacy_policy_desc')}</Paragraph>
        {paragraphs.map((item: any, indexA) => (
          <Block key={indexA}>
            <ParagraphTitle>
              {indexA + 1}. {item.title}
            </ParagraphTitle>
            {item?.content?.map((item: any, indexB: number) => (
              <div style={{ marginTop: '20px' }} key={indexB}>
                {item.map((item: any, index: number) => (
                  <Paragraph key={index}>
                    <SubParagraph>
                      {index === 0 && (
                        <Number>
                          {indexA + 1}.{indexB + 1}{' '}
                        </Number>
                      )}
                      {item.main}
                    </SubParagraph>
                    {indexA === 3 && indexB === 6 && <SubParagraph>{addParagraph}</SubParagraph>}
                    <SubDivParagraph>
                      {item?.sub?.map((item: any) => {
                        return item?.map((i) => <SubParagraph key={i}>- {i}</SubParagraph>);
                      })}
                    </SubDivParagraph>
                  </Paragraph>
                ))}
              </div>
            ))}
          </Block>
        ))}
        <Paragraph>{t('paragraphDetails.privacy_policy_consent')}</Paragraph>
      </Container>
    </>
  );
};
