import styled from "styled-components";

import { color, fontSize } from "theme";
import Select from 'react-select';
export const MainContainer = styled.div`
  padding: 20px 50px;
  background: ${color.backgroundGrey};
`;

export const TextArea = styled.textarea`
  width: 60%;
  min-height: 240px;
  padding:12px;
  border-color: ${props => props.error && color.red};
`;

export const Error = styled.p`
  color: red;
`;

export const Comment = styled.div`
  display: flex;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 50px;
  width: 300px;
`;

export const Button = styled.button`
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  height: 50px;
  width: 100px;
  border: none;
  font-size: 16px;
  line-height: 18px;
  color: ${color.white};
  background: ${props => props.color};
`;

export const StyledPWrapper = styled.div`
  margin-top: 10px;
  border-radius: 10px;
  height: 50px;
  width: 200px;
  background: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
`;

export const StyledSpan = styled.span`
  margin-top: 10px;
  font-size: ${fontSize.regular};
  line-height: 16px;
  font-weight: 400;
  color: ${color.black};
  margin-left: 20px;
`;

export const InfoBlock = styled.div`
  margin-top: 50px;
`;

export const StyledPWrapperCustom = styled(StyledPWrapper)`
  width: 100%;
  height: auto;
  padding: 20px 10px;
`;

export const Img = styled.img`
  width: 100px;
  object-fit: contain;
`;

export const MapContainer = styled.div`
  margin-top: 50px;
  height: 600px;
  margin-bottom: 50px;
`;

export const StyledSelect = styled(Select)`
width: 224px`; 