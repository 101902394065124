import { types } from "./types";
import { ParkingPlace } from "api/parking";

interface ParkingState {
  results?: ParkingPlace[];
  count?: number | null;
  error?: string | null;
}

interface ParkingAction {
  type: string;
  payload?: ParkingState;
  error?: string | null;
}

const initState: ParkingState = {
  results: [],
  count: null,
  error: null,
};

export const parkingReducer = (
  state = initState,
  action: ParkingAction
): ParkingState => {
  switch (action.type) {
    case types.GET_ALL_PARKINGS_SUCCESS:
      return {
        ...state,
        // @ts-expect-error
        results: [...state.results, ...action.payload?.results],
        count: action.payload?.count,
        error: null,
      };
    case types.GET_ALL_PARKINGS_ERROR:
      return {
        results: [],
        count: null,
        error: action.payload?.error,
      };
    default:
      return state;
  }
};
