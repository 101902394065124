import React from 'react';
import { MainHeader } from 'Components/Header/MainHeader';
import { Block, Container, Paragraph, Title, ParagraphTitle, Number } from './styles';
import { useTranslation } from 'react-i18next';

const hostname = window.location.hostname;

export const Partners = () => {
  const { t } = useTranslation(['common']);

  const paragraphs = [
    {
      title: t('partners.title_general'),
      content: [
        [
          {
            main: t('partners.general_content1', { website: `https://${hostname}` })
          }
        ],
        [
          {
            main: t('partners.general_content2', { website: `https://${hostname}` })
          }
        ],
        [
          {
            main: t('partners.general_content3')
          }
        ],
        [
          {
            main: t('partners.general_content4')
          }
        ],
        [
          {
            main: t('partners.general_content5')
          }
        ],
        [
          {
            main: t('partners.general_content6')
          }
        ]
      ]
    },
    {
      title: t('partners.title_subject'),
      content: [
        [
          {
            main: t('partners.subject_content1')
          }
        ],
        [
          {
            main: t('partners.subject_content2')
          }
        ],
        [
          {
            main: t('partners.subject_content3')
          }
        ]
      ]
    },
    {
      title: t('partners.title_amount'),
      content: [
        [
          {
            main: t('partners.amount_content1')
          }
        ],
        [
          {
            main: t('partners.amount_content2')
          }
        ],
        [
          {
            main: t('partners.amount_content3')
          }
        ],
        [
          {
            main: t('partners.amount_content4')
          }
        ],
        [
          {
            main: t('partners.amount_content5')
          }
        ],
        [
          {
            main: t('partners.amount_content6')
          }
        ]
      ]
    },
    {
      title: t('partners.title_obligations'),
      content: [
        [
          {
            main: t('partners.obligations_content1'),
            sub: [t('partners.obligations_sub1', { returnObjects: true })]
          }
        ],
        [
          {
            main: t('partners.obligations_content2'),
            sub: [t('partners.obligations_sub2', { returnObjects: true })]
          }
        ]
      ]
    },
    {
      title: t('partners.title_guarantees'),
      content: [
        [
          {
            main: t('partners.guarantees_content1')
          }
        ],
        [
          {
            main: t('partners.guarantees_content2')
          }
        ],
        [
          {
            main: t('partners.guarantees_content3')
          }
        ],
        [
          {
            main: t('partners.guarantees_content4')
          }
        ],
        [
          {
            main: t('partners.guarantees_content5')
          }
        ],
        [
          {
            main: t('partners.guarantees_content6')
          }
        ],
        [
          {
            main: t('partners.guarantees_content7')
          }
        ],
        [
          {
            main: t('partners.guarantees_content8')
          }
        ],
        [
          {
            main: t('partners.guarantees_content9')
          }
        ]
      ]
    },
    {
      title: t('partners.title_dispute'),
      content: [
        [
          {
            main: t('partners.dispute_content1')
          }
        ],
        [
          {
            main: t('partners.dispute_content2')
          }
        ]
      ]
    },
    {
      title: t('partners.title_terms'),
      content: [
        [
          {
            main: t('partners.terms_content1')
          }
        ],
        [
          {
            main: t('partners.terms_content2')
          }
        ],
        [
          {
            main: t('partners.terms_content3')
          }
        ],
        [
          {
            main: t('partners.terms_content4')
          }
        ],
        [
          {
            main: t('partners.terms_content5')
          }
        ],
        [
          {
            main: t('partners.terms_content6')
          }
        ],
        [
          {
            main: t('partners.terms_content7')
          }
        ],
        [
          {
            main: t('partners.terms_content8')
          }
        ]
      ]
    }
  ];

  return (
    <>
      <MainHeader />
      <Container>
        <Title>{t('paragraphTitles.partners_main')}</Title>
        <Paragraph className="info">
          <span>{t('paragraphDetails.paragraph1_span1')}</span>
          <span>{t('paragraphDetails.paragraph1_span2')}</span>
        </Paragraph>
        <Paragraph style={{ width: '100%' }}>
          <Number>{t('paragraphDetails.paragraph1_span3')}</Number>
          {t('paragraphDetails.paragraph1_span4')}
        </Paragraph>
        <Paragraph>
          <Number>{t('paragraphDetails.paragraph1_span5')}</Number>
          {t('paragraphDetails.paragraph1_span6')}
        </Paragraph>
        <Paragraph style={{ width: '100%' }}>{t('paragraphDetails.paragraph1_span7')}</Paragraph>
        {paragraphs.map((item: any, indexA: number) => (
          <Block key={indexA}>
            <ParagraphTitle>
              {indexA + 1}. {item.title}
            </ParagraphTitle>
            {item?.content.map((item: any, indexB: number) =>
              item.map((item: any, index: number) => (
                <React.Fragment key={indexB}>
                  <Paragraph key={index}>
                    <Number>
                      {indexA + 1}.{indexB + 1}.{' '}
                    </Number>
                    {item.main}
                  </Paragraph>
                  {item?.sub?.map((item: any, indexC: number) => (
                    <Paragraph key={indexC}>
                      {indexA + 1}.{indexB + 1}.{indexC + 1}. {item}
                    </Paragraph>
                  ))}
                </React.Fragment>
              ))
            )}
          </Block>
        ))}
        <Block>
          <ParagraphTitle>{t('paragraphTitles.partners_address')}</ParagraphTitle>

          <Paragraph>{t('paragraphDetails.parters_performer')}</Paragraph>
          <Paragraph style={{ fontStyle: 'italic', width: '100%' }}>
            {t('paragraphDetails.parters_performer_span1')}
          </Paragraph>
          <Paragraph style={{ fontStyle: 'italic', width: '100%' }}>
            {t('paragraphDetails.parters_performer_span2')}
          </Paragraph>
          <Paragraph style={{ fontStyle: 'italic', width: '100%' }}>
            {t('paragraphDetails.parters_performer_span3')}
          </Paragraph>

          <Paragraph>{t('paragraphDetails.parters_placement')}</Paragraph>
          <Paragraph style={{ fontStyle: 'italic', width: '100%' }}>
            {t('paragraphDetails.parters_placement_span1')}
          </Paragraph>
          <Paragraph style={{ fontStyle: 'italic', width: '100%' }}>
            {t('paragraphDetails.parters_placement_span2')}
          </Paragraph>
          <Paragraph style={{ fontStyle: 'italic', width: '100%' }}>
            {t('paragraphDetails.parters_placement_span3')}
          </Paragraph>
          <Paragraph style={{ fontStyle: 'italic', width: '100%' }}>
            {t('paragraphDetails.parters_placement_span4')}
          </Paragraph>

          <Paragraph>{t('paragraphDetails.parters_placement_span5')}</Paragraph>
        </Block>
      </Container>
      {/* <Footer /> */}
    </>
  );
};
