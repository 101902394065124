import React from 'react';

export const CarEChargerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512pt"
      height="512pt"
      viewBox="0 0 512 512"
      {...props}>
      <path d="M45 452h258.508c6.969 34.191 37.273 60 73.492 60h75v-30h60v-30h-60v-30h60v-30h-60v-30h-75c-36.219 0-66.523 25.809-73.492 60H45c-8.27 0-15-6.73-15-15s6.73-15 15-15h181c24.813 0 45-20.188 45-45v-75h60v60h90v-60h60V122h-50.906l15.246-31H481V61h-54.34l-15.933 32.402L387.757 0H124.243l-22.969 93.402L85.34 61H31v30h35.66l15.246 31H31v150h60v60h90v-60h60v75c0 8.27-6.73 15-15 15H45c-24.812 0-45 20.188-45 45s20.188 45 45 45zm332-60h45v90h-45c-24.813 0-45-20.188-45-45s20.188-45 45-45zm14-90h-30v-30h30zm60-60h-50.73l15-30H451zM147.758 30h216.484l22.621 92h-27.347c-6.77-33.887-36.664-61-73.516-61-36.855 0-66.746 27.121-73.516 61h-87.347zm180.672 92h-84.86c6.243-17.832 23.149-31 42.43-31 19.285 0 36.188 13.168 42.43 31zM61 242v-30h35.73l15 30zm90 60h-30v-30h30zm30-60h-35.73l-30-60H61v-30h390v30h-54.27l-30 60H331v-60H181zm30 0v-30h90v30zm0 0"></path>
      <path d="M362 422h30v30h-30zm0 0"></path>
    </svg>
  );
};
