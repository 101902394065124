import styled from "styled-components";
import { color, fontSize, device } from "theme";

export const StyledPlaceholder = styled.span`
  color: ${color.greayText};
  font-size: ${fontSize.regular};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
`;

export const UpperPlaceholder = styled.span`
  font-size: ${fontSize.small};
  line-height: 12px;
  color: ${color.greayText};
  display: block;
  margin-bottom: 10px;
`;

export const DownPlaceholder = styled.span`
  font-size: ${fontSize.small};
  line-height: 12px;
  color: ${color.greayText};
  display: block;
  margin-top: 5px;
  max-width: 300px;

  @media ${device.laptop} {
    max-width: 350px;
  }
`;
