import React, { ReactNode } from "react";

import { StyledButton } from "./style";

type Props = {
  disabled?: boolean;
  children: ReactNode | string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const SecondaryButton = ({ children, disabled, onClick }: Props) => {
  return (
    <StyledButton disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  );
};
