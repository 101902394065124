import React, { ReactNode } from 'react';

import {
  CheckboxWrapper,
  StyledInput,
  IconCheckboxWrapper,
  StyledRoundCheckbox,
  DescriptionText
} from './style';

type CheckboxPropsType = {
  id: string;
  label: string;
  checked: boolean;
  value?: string;
  name?: string;
  isSmall?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox = ({
  id,
  label,
  checked,
  onChange,
  value,
  name,
  isSmall = false
}: CheckboxPropsType) => {
  return (
    <CheckboxWrapper isSmall={isSmall}>
      <StyledInput
        value={value}
        name={name}
        checked={checked}
        id={id}
        type="checkbox"
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </CheckboxWrapper>
  );
};

type IconCheckboxPropsType = CheckboxPropsType & {
  icon: ReactNode;
  text?: string;
};

export const IconCheckbox = ({
  id,
  label,
  checked,
  onChange,
  value,
  name,
  isSmall = false,
  icon,
  text
}: IconCheckboxPropsType) => {
  return (
    <IconCheckboxWrapper checked={checked}>
      <StyledRoundCheckbox
        value={value}
        name={name}
        checked={checked}
        id={id}
        type="checkbox"
        onChange={onChange}
      />
      <label htmlFor={id}>
        {icon}
        <div>
          {label}
          <DescriptionText>{text}</DescriptionText>
        </div>
      </label>
    </IconCheckboxWrapper>
  );
};
