/* eslint-disable camelcase */
import common_ua from "./ua/common.json";
import common_ru from "./ru/common.json";
import common_ro from './ro/common.json';
import common_pl from './pl/common.json';
import common_en from './en/common.json';

import admin_ru from './ru/admin.json';

// eslint-disable-next-line
export default {
  uk: {
    common: common_ua
  },
  ru: {
    common: common_ru,
    admin: admin_ru
  },
  ro: {
    common: common_ro
  },
  pl: {
    common: common_pl
  },
  en: {
    common: common_en
  }
};
