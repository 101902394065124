import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { SingleDatePicker } from 'react-dates';
import Select from 'react-select';
import { toast } from 'react-toastify';
import moment from 'moment';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { editPartnerBillAdmin } from 'api/finance.ts';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-dates/lib/css/_datepicker.css';
import './styles.css';

import { Button } from 'Components/UI/Button/Button';
import { StyledInput } from 'Components/UI/Input/style';

import { EditPaymentWrapper, InfoCell, StyledSelect } from '../style.js';
import { color } from 'theme';
import { PAYMENT_METHOD_OPTIONS, ADMIN_TOKEN_KEY } from '../../constants.ts';
import { regexOnlyNumber } from 'Constants/constants.ts';

export const EditPaymentInfo = ({ paymentInfo, onClose }) => {
  const { t, i18n } = useTranslation(['admin']);
  const [focused, setFocused] = useState(false);
  const [date, setDate] = useState(moment().locale(i18n.language));
  const [method, setMethod] = useState(null);
  const [amount, setAmount] = useState<string>('');

  const token = localStorage.getItem(ADMIN_TOKEN_KEY) || '';

  const { currency, amount: debt, month, partnerId, id } = paymentInfo;

  const editPartnerBill = useMutation(editPartnerBillAdmin, {
    onError: (error: AxiosError) => {
      toast.error(t('notifications.commission_amount_edit_error'), NOTIFICATION_OPTIONS);
    },
    onSuccess: () => {
      toast.success(t('notifications.commission_amount_edited'), NOTIFICATION_OPTIONS);
    }
  });

  const handleChangeAmount = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      setAmount('');
    }

    if (inputValue.match(regexOnlyNumber)) {
      setAmount(inputValue);
    }
  }, []);

  const handleEditPaymentInfo = useCallback(() => {
    const payload = {
      token,
      userId: partnerId,
      billId: id,
      body: {
        type: method?.value,
        paidAmount: Number(amount),
        paidDate: date.format('MM-DD-yyyy')
      }
    };
    editPartnerBill.mutateAsync(payload);
    setTimeout(() => {
      onClose(false);
    }, 1000);
  }, [onClose, amount, editPartnerBill, id, method?.value, token, partnerId, date]);

  const isDisabled = !method || !amount;

  return (
    <EditPaymentWrapper>
      <InfoCell>
        {t('payments.month')}: {month}
      </InfoCell>
      <InfoCell>
        {t('payments.payment_date')}:{' '}
        <SingleDatePicker
          numberOfMonths={1}
          date={date} // momentPropTypes.momentObj or null,
          id="your_unique_date_id" // PropTypes.string.isRequired
          onDateChange={(date) => {
            setDate(date);
          }} // PropTypes.func.isRequired,
          focused={focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired,
          hideKeyboardShortcutsPanel
          readOnly
          showDefaultInputIcon
          anchorDirection="right"
          isOutsideRange={() => false}
        />
      </InfoCell>
      <InfoCell>
        {t('payments.payment_method')}:
        <Select
          options={PAYMENT_METHOD_OPTIONS}
          isSearchable={false}
          isMulti={false}
          value={method}
          placeholder={t('payments.choose_method')}
          styles={StyledSelect}
          onChange={(value) => setMethod(value)}
        />
      </InfoCell>
      <InfoCell>
        {t('payments.debt')}: {debt} {currency}
      </InfoCell>
      <InfoCell>
        {t('payments.top_up_amount')}:
        <StyledInput value={amount} onChange={handleChangeAmount} />
      </InfoCell>
      <InfoCell color={color.green}>
        <Button
          color={color.green}
          dataTestid="top-up-payment-button"
          primary
          onClick={handleEditPaymentInfo}
          disabled={isDisabled}>
          {t('buttons.top_up')}
        </Button>
      </InfoCell>
    </EditPaymentWrapper>
  );
};
