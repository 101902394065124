import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getOneParkingPlaceForSale } from 'api/parking';
import { AxiosError } from 'axios';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';

import { Loading } from 'Components/Loader/Loader';
import { MainHeader } from 'Components/Header/MainHeader';
import { SellParkingDescription } from 'Components/ParkingDescription/SellParkingDescription';
import { UserCard } from 'Components/UserCard/UserCard';
import { BookParkingForSale } from 'Components/BookParking/BookParkingForSale';
import { GoogleMap } from 'Components/GoogleMap/GoogleMap';
import avaImage from 'assets/img/ava.png';

import { Background } from 'Pages/Parking/style';
import {
  MainContainer,
  BannerWrapper,
  ParkingInfoContainer,
  RightCont,
  StyledImage,
  ImgWrapper,
  MapContainer,
  BlockTitle,
  LeftContainer
} from './style';

export const SingleParkingForSale = () => {
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const { parkingId } = queryString.parse(location.search);

  const { status, data } = useQuery(
    'getOneParkingPlaceForSale',
    () => getOneParkingPlaceForSale({ id: parkingId }),
    {
      enabled: Boolean(parkingId),
      onError: (error: AxiosError) => {
        let message: string;
        // toast.error(t('notifications.not_found'), NOTIFICATION_OPTIONS);

        if (error.response.status === 500) {
          message = t('notifications.server_error');
        }

        toast.error(message, NOTIFICATION_OPTIONS);
      }
    }
  );

  const images = useMemo(
    () =>
      data?.data?.files &&
      data?.data?.files
        .filter((file) => file.type === 'IMAGE' || file.type === 'MAIN_IMAGE')
        .sort((a, b) => (a.type === 'MAIN_IMAGE' ? -1 : 1))
        .map((image) => (
          <ImgWrapper key={image.id}>
            <StyledImage src={`${image.path}`} />
          </ImgWrapper>
        )),
    [data]
  );

  return (
    <Background height="auto">
      <MainHeader />
      {status === 'loading' && <Loading />}

      {status === 'success' && (
        <MainContainer>
          {images && images.length > 0 && (
            <BannerWrapper>
              <Carousel showThumbs={false} showStatus={false}>
                {images}
              </Carousel>
            </BannerWrapper>
          )}
          <ParkingInfoContainer>
            <LeftContainer>
              <SellParkingDescription parkingInfo={data?.data} />
            </LeftContainer>
            <RightCont>
              <UserCard
                isVerified={data?.data?.status === 'APPROVED_VERIFIED'}
                name={`${data?.data?.user?.name} ${data?.data?.user?.surname}`}
                src={avaImage}
              />
              <BookParkingForSale
                currency={data?.data.currency}
                price={data?.data.price}
                telephone={data?.data?.user?.phone}
              />
            </RightCont>
          </ParkingInfoContainer>
          <BlockTitle>{t('titles.map')}</BlockTitle>
          <MapContainer>
            <GoogleMap
              notChange={true}
              //@ts-expect-error
              lat={data?.data?.positionOfEntry.coordinates[0]}
              //@ts-expect-error
              lng={data?.data?.positionOfEntry.coordinates[1]}
            />
          </MapContainer>
        </MainContainer>
      )}
    </Background>
  );
};
