import axios from "axios";
import { GET_CITY_BY_TITLE_URL, POST_CITY_URL } from 'config';
import { GET_CITY_BY_ID_URL } from 'config';

export const getCityByLocationId = async ( title: string ) => {
    let queryUrl = `${GET_CITY_BY_TITLE_URL}?title=${title}`;
    const res = await axios.get(queryUrl);
    return res.data;
};
  
export const getCityById = async ( id: number ) => {
    let queryUrl = `${GET_CITY_BY_ID_URL}?id=${id}`;
    const res = await axios.get(queryUrl);
    return res.data;
};
        
export const createCity = async ( placeId: string ) => {
    let queryUrl = `${POST_CITY_URL}?placeId=${placeId}`
    const res = await axios.post(queryUrl);
    return res.data;
};