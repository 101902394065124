import React from 'react';
import { useTranslation } from 'react-i18next';

import { SingleAdvantage } from './SingleAdvantage';
import { Container, StyledTitle, AddInfo } from './style';

type AdvantagesProps = {
  electricCharge?: boolean;
  elevator?: boolean;
  security?: boolean;
  familyPlace?: boolean;
  owner?: boolean;
};

export const Advantages = ({
  electricCharge,
  elevator,
  security,
  familyPlace,
  owner
}: AdvantagesProps) => {
  const { t } = useTranslation(['common']);

  return (
    <Container>
      {(electricCharge || elevator || security || familyPlace || owner) && (
        <StyledTitle>{t('titles.advantages')} </StyledTitle>
      )}
      <AddInfo>
        {electricCharge && <SingleAdvantage title={t('placeholders.electric_charger')} />}
        {elevator && <SingleAdvantage title={t('placeholders.elevator')} />}
        {security && <SingleAdvantage title={t('placeholders.security')} />}
        {familyPlace && <SingleAdvantage title={t('placeholders.family_place')} />}
        {/* {owner && (
          <SingleAdvantage title={t("placeholders.owner")} />
        )} */}
      </AddInfo>
    </Container>
  );
};
