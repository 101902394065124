import styled from 'styled-components';
import { color } from 'theme';

export const SmallTableWrapper = styled.div`
  width: 600px;
  height: 120px;

  & > table {
    text-align: center;
  }
`;

export const MainContent = styled.div`
  padding: 30px 0 10px 0;
`;

export const CellWithButton = styled.td`
  padding: 0 !important;
  background-color: ${color.green};
`;

export const StatusCell = styled.td`
  background-color: ${({ isPayed }) => (isPayed ? color.blue : color.red)};
`;

export const inlineStyles = {
  modal: {
    content: {
      width: 'fit-content',
      height: 'fit-content',
      overflow: 'hidden',
      padding: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      border: `2px solid ${color.black}`,
      boxShadow:
        'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
    },
    overlay: {
      background: color.modalBackground
    }
  }
};

export const EditPaymentWrapper = styled.div`
  width: 340px;
`;

export const InfoCell = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  border-bottom: 2px solid ${color.black};
  height: 90px;
  padding: 0 10px;
  background-color: ${({ color }) => color && color};
`;

export const StyledSelect = {
  control: (styles, { isFocused }) => ({
    ...styles,
    border: '1px soled hsl(0, 0%, 80%)',
    borderRadius: 0,
    width: '200px',
    height: '50px',
    outline: 'none !important',
    boxShadow: 'none',
    background: '#fff',
    cursor: 'pointer'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      cursor: 'pointer',
      color: `${color.darkGrey}`,
      maxWidth: '200px',
      minWidth: '200px',
      width: '100%',
      backgroundColor: isFocused && `${color.blue}`,
      boxShadow:
        'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      display: 'flex',
      maxWidth: '200px',
      minWidth: '200px',
      width: '100%',
      zIndex: 10
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      overflow: 'visible'
    };
  }
};
