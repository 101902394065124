import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { balancePopup, getParkingPlaces, getUserInfo, ParkingPlace, Status } from 'api/parking';
import { setParkingPlaceStatus, StatusChangeBody } from 'api/admin';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { Button } from '../Users/style';
import { ParkingCard } from '../Parkings/style';
import { color } from 'theme';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Pagination } from '../UI/Pagination/Pagination';
import { SmallLoader } from 'Components/Loader/Loader';
import Balance from '../Balance';
import { UserType } from '../Users/Users';
import { PENDING, REJECTED, BLOCKED, DOCUMENT, NOTIFICATION_OPTIONS } from 'Constants/constants';
import { ADMIN_TOKEN_KEY, Params } from '../constants.ts';
import { Container, Parking, UserInfo } from './style';

export const UserParkings = () => {
  const [parkings, setParkings] = useState<ParkingPlace[]>();
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const { t } = useTranslation(['admin']);

  const params = new URLSearchParams(history.location.search);

  const [page, setPage] = useState(+(params.get('page') ?? 1));
  const [pages, setPages] = useState<number>(1);
  const [user, setUser] = useState<UserType>();

  const { id: userId } = useParams<Params>();
  const token = localStorage.getItem(ADMIN_TOKEN_KEY) || '';

  useEffect(() => {
    (async function () {
      try {
        const res = await getParkingPlaces({
          params: { userId, page, sortingType: 'DATE_DESCENDING' },
          token
        });
        setPages(Math.ceil(res.data.count / 10));
        setParkings(res.data.results.length > 0 ? res.data.results : undefined);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [page, userId, token]);

  useEffect(() => {
    (async function () {
      try {
        const user = await getUserInfo(Number(userId), token);
        setUser(user.data);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (id?: number) => {
    history.push(`/admin/${id}`);
  };

  const { mutateAsync } = useMutation(setParkingPlaceStatus, {
    onError: (error: AxiosError) => {},
    onSuccess: (response: AxiosResponse) => {
      (async function () {
        const res = await getParkingPlaces({
          params: { userId, page, sortingType: 'DATE_DESCENDING' },
          token
        });
        setParkings(res.data.results);
      })();
    }
  });

  const { mutateAsync: balanceTopUp } = useMutation(balancePopup, {
    onError: (error: AxiosError) => {
      const message = `${t('notifications.partner_balance_not_set')} ${
        error?.response?.data?.message
      }`;
      toast.error(message, NOTIFICATION_OPTIONS);
    },
    onSuccess: () => {
      toast.success(t('notifications.partner_balance_set'), NOTIFICATION_OPTIONS);
    }
  });

  function onBlock(id: string, status: string) {
    const body: StatusChangeBody = {
      parkingPlaceId: id,
      status,
      message: status === 'PENDING' || status === BLOCKED ? '' : status
    };

    mutateAsync({ body, token });
  }

  const handlePopup = async (amount: number) => {
    const body = {
      userId: user!.id,
      amount: Number(amount),
      reason: 'top up balance'
    };

    balanceTopUp({ body, token });

    const userResponse = await getUserInfo(Number(userId), token);
    setUser(userResponse.data);
  };

  const getColor = (status: string) => {
    switch (status) {
      case (Status.Rejected, Status.Blocked):
        return color.red;
      case Status.ApprovedVerified:
      case Status.ApprovedUnverified:
        return color.green;
    }
  };

  const handlePageClick = (page: any) => {
    setPage(page.selected + 1);
    history.push({
      pathname: '',
      search: `?page=${page.selected + 1}`
    });
  };

  if (isLoading)
    return (
      <Container>
        <SmallLoader />
      </Container>
    );

  return (
    <Container>
      <UserInfo>
        <p>{`${t('admin.user_info_id')} ${user?.id}`}</p>
        <p>{`${t('admin.user_info_name')} ${user?.name} ${user?.surname}`}</p>
        <p>
          {t('admin.user_info_phone')} {user?.phone || t('partner_info.not_set')}
        </p>
        <p>{`${t('admin.user_info_count_parking')} ${user?.countOfParkingPlaces}`}</p>
      </UserInfo>
      <Balance onPopup={handlePopup} balance={user?.balance}></Balance>
      {parkings ? (
        <>
          {parkings.map(({ id, street, house, status, files, deletedAt }) => {
            const date = new Date(deletedAt);
            return (
              <Parking key={id} isDeleted={!!deletedAt}>
                <ParkingCard color={getColor(status)} onClick={() => handleClick(id)}>
                  <p>{`${t('admin.parkings_card_id')} ${id}`}</p>
                  <p>{`${t('admin.parkings_card_address')} ${street} ${house}`}</p>
                  <p>{`${t('admin.parkings_card_docs')} ${t(
                    files.filter((el) => el.type === DOCUMENT).length
                      ? 'status.exist'
                      : 'status.not_exist'
                  )}`}</p>
                  <p>{`${t('admin.parkings_card_status')} ${status}`}</p>
                  {deletedAt && (
                    <p>
                      {t('admin.deleted_parking')}: {date.toLocaleDateString()}
                    </p>
                  )}
                </ParkingCard>

                {!deletedAt && (
                  <Button
                    color={status === REJECTED || status === BLOCKED ? color.green : color.red}
                    onClick={() =>
                      onBlock(
                        id.toString(),
                        status === BLOCKED || status === REJECTED ? PENDING : BLOCKED
                      )
                    }>
                    {status !== BLOCKED
                      ? t('admin.parkings_card_btn_block')
                      : t('admin.parkings_card_btn_unblock')}
                  </Button>
                )}
              </Parking>
            );
          })}
          <Pagination page={page} pages={pages} handlePageClick={handlePageClick} />
        </>
      ) : (
        <h2>{t('admin.user_parkings_no_adds')}</h2>
      )}
    </Container>
  );
};
