import React, { FC } from "react";

import { StyledNotification } from "./style";

type Props = {
  isAccent?: boolean;
};

export const Notification: FC<Props> = ({ isAccent, children }) => {
  return (
    <StyledNotification isAccent={isAccent}>{children}</StyledNotification>
  );
};
