import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/store';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { SellParkingContextProvider } from 'context/sellParkingContext';

import { MainHeader } from 'Components/Header/MainHeader';
import { SellParkingFiltersPanel } from './components/SellParkingFiltersPanel';
import { SellParkingSearchResults } from './components/SellParkingSearchResults';

import { Background, HiddenText } from './style';

export const ParkingForSalePage = () => {
  const { t } = useTranslation(['common']);
  const { chosenLabel } = useSelector((state: RootState) => state.citySearchValue.searchValue);

  return (
    <SellParkingContextProvider>
      <Background>
        <Helmet>
          <title>{chosenLabel}</title>
          <meta name="description" content={t('admin.sell_parking_desc_general')} />
        </Helmet>
        <MainHeader />
        {!!chosenLabel && (
          <HiddenText>
            {t('pagesParking.sell_title_first_half')}
            {chosenLabel}
            {t('pagesParking.sell_title_second_half')}
          </HiddenText>
        )}
        <SellParkingFiltersPanel />
        <SellParkingSearchResults />
      </Background>
    </SellParkingContextProvider>
  );
};
