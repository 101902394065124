import styled from "styled-components";

import { fontSize, color } from "theme";

export const ToltipWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 112px;
  min-width: 290px;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  z-index: 1000000;
  top: -250%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 1px 9px 4px rgba(213, 213, 213, 0.93);

  transition: all 0.3s ease-in-out;
  pointer-events: none;

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;

    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #ffffff;
    left: 50%;
    transform: translateX(-50%);
    transform: rotate(90deg);
    bottom: -10px;
  }
`;

export const ImgContainer = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 10px;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  display: block;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

export const StreetTitle = styled.span`
  font-weight: 500;
  font-size: ${fontSize.regular};
  line-height: 16px;
  margin-bottom: 5px;
`;

export const StyledText = styled.p`
  color: ${color.greayText};
  font-size: ${fontSize.small};
  line-height: 14px;
  font-weight: 400;
  margin-bottom: 10px;
`;

export const PriceNote = styled.span`
  font-weight: 700;
  font-size: ${fontSize.x_large};
  color: ${color.blue};
  line-height: 29px;
`;

export const BottomContainer = styled.div`
  /* display: flex; */
  /* align-items: center;
  justify-content: space-between; */
`;
