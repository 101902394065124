import axios, { AxiosResponse } from "axios";
import { UserType } from "Components/Admin/Users/Users";
import { RequestInterface } from "Components/Profile/Bookings/Bookings";
import { Rating } from "Components/RatingCard/RatingCard";

import {
  ADD_BOOKING_DAYS,
  GET_DAYS,
  GET_MY_PARKINGS,
  ADD_DISCOUNT,
  GET_DISCOUNTS,
  GET_COMMISSIONS_REQUESTS,
  GET_REQUESTS,
  GET_RATINGS,
  GET_PARKING_REVIEWS_URL,
  DELETE_DISCOUNT_URL,
  NOT_ARRIVED_URL,
  DELETE_PARKING_URL,
  CHECK_AVAILABILITY_URL,
  MAIN_IMAGE_URL,
  UPDATE_EMAIL_URL,
  CHANGE_BANK_INFO_URL,
  GET_BANK_INFO_URL,
  BALANCE_POPUP_URL,
  GET_USER_URL,
  USER_APPROVED_URL,
  DELETE_PARKING_BY_ID_URL,
  DELETE_PARKINGS_BY_USER_ID_URL,
  ADD_PARKING_FILES_URL,
  TOTAL_DELETE_USER_BY_ID_URL,
  VALIDATE_FEEDBACK_TOKEN,
  MY_PARKING_PLACE_FOR_SALE_API_URL,
  DELETE_PARKING_PLACE_FOR_SALE_API_URL,
  PARKING_PLACES_FOR_SALE_API_URL
} from 'config';

import {
  PARKING_PLACES_API_URL,
  NEW_PARKING_PLACE_API_URL,
  GET_ONE_PARKING_PLACE_URL,
  GET_PARKINGS_ONDEMAND_URL,
  APPROVE_PARKING_URL,
  GET_MY_PARKINGS_URL,
  POST_REVIEW_URL,
  UPDATE_PARKING_PLACE_URL,
  DELETE_FILE_URL,
  NEW_PARKING_PLACE_FOR_SALE_API_URL
} from 'config';
import { APPROVED, REJECTED_NOT_ARRIVED } from 'Constants/constants';

export type AddBookingDaysResponse = {
  accessToken: string;
};

export type FileType = {
  id: number;
  path: string;
  type: string;
};

export type ParkingPlace = {
  id: number;
  street: string;
  streetLat: number;
  streetLng: number;
  house: string;
  placeNumber: string;
  parkingLevel: string;
  microdistrict: {
    id: number;
    district: {
      id: number;
      district: string;
    };
    microDistrict: string;
  };
  district: {
    id: number;
    district: string;
  };
  placeWidth: number;
  rate: number;
  placeLength: number;
  heightOfParkingEntry: number;
  pricePerDay: number;
  pricePerMonth: number;
  price: number;
  carClass: string;
  security: boolean;
  parkingType: string;
  electricCharge: boolean;
  elevator: boolean;
  sector: string;
  owner: boolean;
  parkingName: string;
  description: string;
  familyPlace: boolean;
  positionOfEntry: {
    type: 'Point';
    coordinates: [number];
  };
  status: string;
  images: [
    {
      id: number;
      image: string;
    }
  ];
  document: string;
  files: FileType[];
  city: {
    title: string;
    id: number;
  };
  user: {
    email: string;
    id: number;
    name: string;
    passwordRestoring: boolean;
    phone: string;
    surname: string;
    countOfParkingPlaces: string;
    country?: string;
  };
  bookingType: string;
  currency: string;
  country?: string;
};

type IdProps = {
  id: string | number | undefined | null;
  token?: string;
};

const headers = {
  'Content-Type': 'application/json'
};

export type ApproveParkingProps = {
  parkingPlaceRequestId: number;
  approved: boolean;
};

export interface ParkingPlacesResponse {
  results: ParkingPlace[];
  count: number;
}

export interface CityListResponse {
  id: number;
  title: string;
}

export interface NewParkingPlace {
  cityId: number;
  street: string;
  house: string;
  placeNumber: string;
  parkingLevel: string;
  placeWidth: number;
  placeLength: number;
  heightOfParkingEntry: number;
  pricePerDay: number;
  pricePerMonth?: number;
  carClass: string;
  security?: boolean;
  parkingType?: string;
  electricCharge?: boolean;
  elevator?: boolean;
  sector?: string;
  owner?: boolean;
  parkingName?: string;
  description?: string;
  familyPlace?: boolean;
  lat?: number | string;
  lng?: number | string;
  token?: string | null | undefined;
}

export type CreateParkingResponse = {
  statusCode: number;
  message: string;
  error: string;
};

export type OneParkingPlaceResponse = {
  statusCode?: number;
  data?: ParkingPlace;
  message?: string;
};

export enum Status {
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  ApprovedVerified = 'APPROVED_VERIFIED',
  ApprovedUnverified = 'APPROVED_UNVERIFIED',
  BLOCKED = 'BLOCKED'
}

export interface ParkingPlacesParams {
  statuses?: Status[];
  page: number;
  limit?: number;
  userId?: string;
  sortingType: string;
}

export interface ParkingPlaces {
  params: ParkingPlacesParams;
  token?: string;
}

export interface PostReviewProps {
  size: number;
  comfort: number;
  talk: number;
  description: string;
  token: string;
}

export const getParkingPlaces = ({
  params,
  token
}: ParkingPlaces): Promise<AxiosResponse<ParkingPlacesResponse>> => {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`
  };
  return axios.get(PARKING_PLACES_API_URL, {
    params,
    headers
  });
};

export const getParkingPlacesForSale = ({
  params,
  token
}: ParkingPlaces): Promise<AxiosResponse<ParkingPlacesResponse>> => {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`
  };
  return axios.get(PARKING_PLACES_FOR_SALE_API_URL, {
    params,
    headers
  });
};

export const getOneParkingPlace = ({
  id,
  token
}: IdProps): Promise<AxiosResponse<ParkingPlace>> => {
  if (token) {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    };

    return axios.get(`${GET_ONE_PARKING_PLACE_URL}${id}`, {
      headers: headers
    });
  }

  return axios.get(`${GET_ONE_PARKING_PLACE_URL}${id}`);
};

export const getOneParkingPlaceForSale = ({
  id,
  token
}: IdProps): Promise<AxiosResponse<ParkingPlace>> => {
  if (token) {
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return axios.get(`${PARKING_PLACES_FOR_SALE_API_URL}/${id}`, {
      headers: headers
    });
  }

  return axios.get(`${PARKING_PLACES_FOR_SALE_API_URL}/${id}`);
};

export const createNewParking = async ({ ...body }: any): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${body.token}`
  };

  delete body.token;

  return axios.post(NEW_PARKING_PLACE_API_URL, body.formData, {
    headers: headers
  });
};

export const getParkingsOnDemand = async (
  token: string | undefined | null
): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  const response = await axios.get(GET_PARKINGS_ONDEMAND_URL, {
    headers,
    params: { statuses: ['PENDING'] }
  });

  return response;
};

export interface AddBookingDays {
  token?: string;
  firstDate: string;
  secondDate: string;
  daysOfWeak: number;
  price: number;
  bookingType: string;
  minDays: number;
  parkingPlaceId: number;
  delete: boolean;
}
export interface BookingParams {
  parkingPlaceId: number;
}

export const addBookingDays = async ({ ...body }: AddBookingDays): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${body.token}`
  };
  delete body.token;

  return axios.post(ADD_BOOKING_DAYS, body, { headers: headers });
};

export const getDays = async (params: BookingParams, token: string): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json'
  };
  return await axios.get(GET_DAYS, { params: { ...params }, headers });
};

interface Params {
  limit?: number;
  page?: number;
  // sortingType?:
}

export const getMyParkings = async (
  params: ParkingPlacesParams,
  token: string
): Promise<AxiosResponse<ParkingPlacesResponse>> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  const response = await axios.get(GET_MY_PARKINGS, {
    headers,
    params
  });

  return response;
};

interface DiscountsParams extends Params {
  parkingPlaceId: number;
}

export const getDiscounts = async (
  params: DiscountsParams,
  token: string
): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json'
    // Authorization: `Bearer ${token}`,
  };
  return await axios.get(GET_DISCOUNTS, { params, headers });
};

interface AddDiscount {
  range: number;
  discount: number;
  parkingPlaceId: number;
}
interface Data {
  body: AddDiscount;
  token: string;
}

export const addDiscount = async ({ body, token }: Data): Promise<any> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return axios.post(ADD_DISCOUNT, body, { headers: headers });
};

type CommissionRequestType = {
  token: string;
  month?: number;
  year?: number;
  limit?: number;
  page?: number;
};

export const getCommissionRequests = async ({
  token,
  month,
  year,
  limit = 25,
  page = 1
}: CommissionRequestType): Promise<any> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return await axios.get(
    `${GET_COMMISSIONS_REQUESTS}?limit=${limit}&page=${page}${
      Boolean(month) ? `&month=${month}` : ''
    }${Boolean(year) ? `&year=${year}` : ''}`,
    {
      headers
    }
  );
};

interface RequestData {
  results: RequestInterface[];
  count: number;
}

export interface RequestsParams {
  parkingPlaceId?: number;
}

export const getRequests = async (
  myParams: RequestsParams,
  token: string
): Promise<AxiosResponse<RequestData>> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  const params = {
    ...myParams,
    statuses: [APPROVED, REJECTED_NOT_ARRIVED]
  };
  return await axios.get(GET_REQUESTS, { params, headers });
};

export const getRatings = async (params: RequestsParams, token: string): Promise<any> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return await axios.get(GET_RATINGS, { headers, params });
};

export const approveParking = async ({
  body,
  token
}: {
  body: ApproveParkingProps;
  token: string | null;
}): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return axios.post(APPROVE_PARKING_URL, body, { headers: headers });
};

export const getMyParkingPlaces = async (
  pageParams: number,
  token: string | undefined | null
): Promise<AxiosResponse<ParkingPlacesResponse>> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  const response = await axios.get(GET_MY_PARKINGS_URL, {
    headers: headers
  });

  return response;
};

export const getMyParkingPlacesForSale = async (
  pageParams: number,
  token: string | undefined | null
): Promise<AxiosResponse<ParkingPlacesResponse>> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  const response = await axios.get(
    `${MY_PARKING_PLACE_FOR_SALE_API_URL}?limit=10&page=${pageParams}`,
    {
      headers: headers
    }
  );

  return response;
};

export const postReview = async ({ ...body }: PostReviewProps): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json'
  };
  return axios.post(POST_REVIEW_URL, body, { headers: headers });
};

export const updateParkingPlaceInfo = async ({ ...body }: any): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${body.token}`
  };

  delete body.token;
  delete body.user;

  return axios.put(UPDATE_PARKING_PLACE_URL, body, { headers: headers });
};

export const updateParkingPlaceForSaleInfo = async ({ ...body }: any): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${body.token}`
  };

  const id = body.parkingPlaceId;

  delete body.token;
  delete body.user;
  delete body.parkingPlaceId;
  delete body.street;

  return axios.patch(`${PARKING_PLACES_FOR_SALE_API_URL}/${id}`, body, { headers: headers });
};

type DeleteFileProps = {
  fileId: number;
  token?: string | null;
};

export const deleteFile = async ({ token, fileId }: DeleteFileProps): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  const data = {
    fileId
  };
  return axios.delete(DELETE_FILE_URL, { headers, data });
};

export const updateParkingFiles = async ({ ...body }: any): Promise<void> => {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${body.token}`
  };
  delete body.token;
  await axios.post(ADD_PARKING_FILES_URL, body.formData, {
    headers: headers
  });
};

export const updateParkingForSaleFiles = async ({ ...body }: any): Promise<void> => {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${body.token}`
  };

  await axios.post(
    `${PARKING_PLACES_FOR_SALE_API_URL}/${body.parkingPlaceId}/files`,
    body.formData,
    {
      headers: headers
    }
  );
};

export interface ReviewsResponse {
  results: Rating[];
  counts: number;
  carNamesArr?: string[];
}

interface ParkingReviewsParams {
  parkingPlaceId: number;
}

export const getParkingReviews = (
  params: ParkingReviewsParams
): Promise<AxiosResponse<ReviewsResponse>> => {
  return axios.get(GET_PARKING_REVIEWS_URL, {
    headers: headers,
    params
  });
};

export const deleteDiscount = (id: number, token: string): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return axios.delete(DELETE_DISCOUNT_URL, { headers, data: { id } });
};

export const notArrivedRequest = (requestId: number, token: string): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return axios.put(NOT_ARRIVED_URL, { requestId }, { headers });
};

export interface CheckAvailabilityParams {
  id: number;
  from: string;
  to: string;
}

export const removeParking = (id: number, token: string): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return axios.delete(`${DELETE_PARKING_URL}?id=${id}`, { headers });
};

export const removeParkingForSale = (id: number, token: string): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return axios.delete(`${DELETE_PARKING_PLACE_FOR_SALE_API_URL}/${id}`, { headers });
};

export const removeParkingImageForSale = ({ id, token, parkingId }): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return axios.delete(`${DELETE_PARKING_PLACE_FOR_SALE_API_URL}/${parkingId}/files/${id}`, {
    headers
  });
};

export const checkAvailability = (params: CheckAvailabilityParams): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json'
  };
  return axios.get(CHECK_AVAILABILITY_URL, { headers, params });
};

export const mainImage = (body: any, token: string): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return axios.post(MAIN_IMAGE_URL, body, { headers: headers });
};

export const parkingForSaleMainImage = ({ fileId, parkingId, token }): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return axios.patch(
    `${PARKING_PLACES_FOR_SALE_API_URL}/${parkingId}/files/${fileId}/make-file-main`,
    { fileId, salesId: parkingId },
    { headers: headers }
  );
};

export const updateEmail = (body: any, token: string): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return axios.post(UPDATE_EMAIL_URL, body, { headers: headers });
};

export interface BankInfo {
  reciver: string;
  recieverCode: string;
  recieverBank: string;
  iban: string;
}

export interface BankInfoData extends BankInfo {
  id: number;
}

export const changeBankInfo = (body: BankInfo, token: string): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return axios.post(CHANGE_BANK_INFO_URL, body, { headers });
};

export const getBankInfo = (token: string): Promise<AxiosResponse<BankInfoData>> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return axios.get(GET_BANK_INFO_URL, { headers });
};

interface BALANCE_POPUP {
  userId: number;
  amount: number;
  reason: 'top up balance';
}

type BalancePropsType = {
  body: BALANCE_POPUP;
  token: string;
};

export const balancePopup = ({ body, token }: BalancePropsType): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return axios.post(BALANCE_POPUP_URL, body, { headers });
};

export const getUserInfo = (id: number, token: string): Promise<AxiosResponse<UserType>> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return axios.get(GET_USER_URL, { headers, params: { id } });
};

export const userApprovedRequest = (requestId: string): Promise<AxiosResponse> => {
  return axios.post(USER_APPROVED_URL, { requestId });
};

export const removeParkingById = (id: number): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json'
  };
  return axios.delete(DELETE_PARKING_BY_ID_URL, { headers, params: { id } });
};

export const removeParkingsByUserId = (id: number): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json'
  };
  return axios.delete(DELETE_PARKINGS_BY_USER_ID_URL, { headers, params: { id } });
};

export const totalRemoveUserById = (id: number, token: string): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return axios.delete(TOTAL_DELETE_USER_BY_ID_URL, { headers, params: { id } });
};

export const validateToken = (token: string): Promise<AxiosResponse> => {
  return axios.get(`${VALIDATE_FEEDBACK_TOKEN}${token}`);
};

export const createNewParkingForSale = async ({ ...body }: any): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${body.token}`
  };

  delete body.token;

  return axios.post(NEW_PARKING_PLACE_FOR_SALE_API_URL, body.formData, {
    headers: headers
  });
};