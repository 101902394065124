import axios, { AxiosResponse } from 'axios';
import { GET_COUNTRIES_LIST } from 'config.js';

export type GetCountriesType = {
  [key: string]: Record<{ countryName: string; currencyCode: string; countryCode: string }>;
};

export const getCountriesList = async (): Promise<AxiosResponse<GetCountriesType>> => {
  return axios.get(GET_COUNTRIES_LIST);
};
