import styled from "styled-components";

import { color, fontSize, device } from "theme";

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 170px;
  height: fit-content;
  border-radius: 10px;
  background: ${(props) => (props.current ? color.blue : '#ffffff')};
  padding: 10px;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  border: ${({ isAccent }) => (isAccent ? `1px solid ${color.blue}` : `1px solid #fff`)};

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    border: 1px solid ${color.blue};
  }

  > div > * {
    color: ${(props) => (props.current ? '#ffffff' : '')};
  }
`;

export const ImgWrapper = styled.div`
  min-width: 75px;
  width: 75px;
  height: 75px;

  @media ${device.tablet} {
    width: 130px;
    height: 130px;
    min-width: 130px;
  }
`;

export const ContentWrapper = styled.div`
  margin: 0 0 0 5px;
  width: 100%;

  @media ${device.tablet} {
    margin: 0 0 0 10px;
  }
`;

export const ParkingImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

export const StreetTitle = styled.p`
  font-size: ${fontSize.regular};
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #000000;

  @media ${device.laptop} {
    font-size: ${fontSize.large};
    line-height: 21px;
  }
`;

export const StyledText = styled.div`
  color: ${color.greayText};
  font-size: ${fontSize.small};
  line-height: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  width: fit-content;

  @media ${device.laptop} {
    font-size: ${fontSize.regular};
    line-height: 16px;
  }
`;

export const StyledTextCustom = styled(StyledText)`
  color: ${color.black};
  font-weight: 500;
`;

export const StyledPrice = styled.span`
  color: ${color.blue};
  font-size: ${fontSize.x_large};
  line-height: 28px;
  font-weight: 700;
`;

export const StyledNote = styled.span`
  display: inline-block;
  color: ${color.greayText};
  font-size: ${fontSize.small};
  line-height: 14px;
  font-weight: 400;
  margin-right: 10px;

  @media ${device.laptop} {
    font-size: ${fontSize.regular};
    line-height: 16px;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 10px auto;
`;

export const StyledTextRatingWrapper = styled.div`
  display: flex;
`;

export const RatingText = styled.span`
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
  color: ${color.blue};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const ParkingsAttributesWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 15px;
`;

export const ParkingAttribute = styled.div`
  width: 25px;
  height: 25px;

  @media ${device.tablet} {
    width: 35px;
    height: 35px;
  }

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & > svg {
    display: block;
    width: 80%;
    height: 80%;
    object-fit: contain;
  }
`;

export const PartnersCardWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 170px;
  height: fit-content;
  border-radius: 10px;
  background: ${color.white};
  padding: 10px;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  border: ${({ current }) =>
    current ? `1px solid ${color.blue}` : `1px solid ${color.lightGrey}`};

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    border: 1px solid ${color.blue};
  }
`;
