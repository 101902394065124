import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { toast } from 'react-toastify';
import {
  regexHouseNumber,
  regexPlaceNumber,
  regexDimensionsValue,
  regexOnlyNumber,
  regexLevel,
  NOTIFICATION_OPTIONS
} from 'Constants/constants';
import { Auto } from 'types/parkingTypes';
import { RootState } from 'Redux/store';
import { checkExpiry } from 'api/auth';
import { createNewParkingForSale } from 'api/parking';
import { logout } from 'Redux/reducers/login/loginReducer';
import { paths } from 'config';

import {
  GoogleAutoCompleteStreet,
  GoogleCityAutoCompleteCreate
} from 'Components/GoogleAutoComplete/GoogleAutoCompleteSearch';
import { ICoordinates } from 'Pages/CreateParking/CreateParking';
import { MainHeader } from 'Components/Header/MainHeader';
import { Notification } from 'Components/UI/Notification/Notification';
import { FileUploader } from 'Components/FileUploader/FileUploader';
import { ImageUploader } from 'Components/ImageUploader/ImageUploader';
import { GoogleMap } from 'Components/GoogleMap/GoogleMap';
import { Button } from 'Components/UI/Button/Button';

import { Background } from 'Pages/Parking/style';
import { MapWrapper } from 'Components/UI/Map/style';
import { MainContainer } from 'Pages/AddParking/style';
import { StyledInput } from 'Components/UI/Input/style';
import { RequiredLabel } from 'Components/UI/StyledLabel/style';
import { UpperPlaceholder, DownPlaceholder } from 'Pages/EditParking/style';
import { Tooltip } from 'Components/UI/Tooltip/style';
import {
  StyledTitle,
  InfoContainer,
  ContentWrapper,
  Select,
  TextArea,
  SmallContainer,
  TextP
} from 'Pages/CreateParking/style.js';

type ChosenCityType = {
  cityTitle: string;
  coordinates: { lat: number; lng: number };
  formattedCityAddress: string;
  isSelected: boolean;
  placeId: string;
  serverCityId: number | undefined;
};

type FormValues = {
  street: string;
  streetLat: number;
  streetLng: number;
  house: string;
  placeNumber: string;
  parkingLevel: string;
  city: string;
  placeWidth: number;
  placeLengt: number;
  heightOfParkingEntry: number;
  price: number;
  carClass: string;
  security: boolean;
  parkingType: string;
  electricCharge: boolean;
  elevator: boolean;
  sector: string;
  owner: boolean;
  parkingName: string;
  description: string;
  familyPlace: boolean;
  lat: number;
  lng: number;
};

export const SellParkingPlace = () => {
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.accessToken);

  const [autocompleteCityErrors, setAutocompleteCityErrors] = useState(false);
  const [autocompleteStreetErrors, setAutocompleteStreetErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chosenCity, setChosenCity] = useState<ChosenCityType | null>(null);
  const [street, setStreet] = useState<string>('');
  const [coordinates, setCoordinates] = useState<ICoordinates | null>(null);
  const [newCoordinates, setNewCoordinates] = useState<ICoordinates | null>(null);
  const [filesSelected, setFilesSelected] = useState<File[]>([]);
  const [imagesSelected, setImageSelected] = useState<File[]>([]);
  const [mainIndex, setMainIndex] = useState(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [customStyle, setCustomStyle] = useState<{} | null>(null);

  const { mutateAsync } = useMutation(createNewParkingForSale, {
    onError: (error: AxiosError) => {
      setLoading(false);
      let message: string = '';
      if (error.response?.status === 500) {
        message = t('notifications.created_error');
      } else if (error.response?.status === 401) {
        message = t('notifications.need_auth');
      } else if (error.response?.status === 409 || error.response?.status === 404) {
        message = t('notifications.parking_already_exists');
      } else {
        message = t('notifications.something_wrong');
      }

      toast.error(message, NOTIFICATION_OPTIONS);

      setIsDisabled(false);
    },
    onSuccess: (response: AxiosResponse<CreateParkingResponse>) => {
      if (response.status === 201) {
        setIsDisabled(false);
        setLoading(false);

        toast.success(t('notifications.sent_to_modarate'), NOTIFICATION_OPTIONS);

        setTimeout(() => {
          history.push({
            pathname: `${paths.partnerprofile}`
          });
        }, 500);
      }
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(
      yup.object().shape({
        house: yup.string().required().matches(regexHouseNumber, t('validation.houseNumber')),
        placeNumber: yup.string().required().matches(regexPlaceNumber, t('validation.placeNumber')),
        parkingLevel: yup.string().required().matches(regexLevel, t('validation.placeNumber')),
        placeWidth: yup
          .string()
          .required()
          .matches(regexDimensionsValue, t('validation.dimensionsNumber')),
        placeLength: yup
          .string()
          .required()
          .matches(regexDimensionsValue, t('validation.dimensionsNumber')),
        heightOfParkingEntry: yup
          .string()
          .required()
          .matches(regexDimensionsValue, t('validation.dimensionsNumber')),
        price: yup.string().required().matches(regexOnlyNumber, t('validation.onlyNumber')),
        carClass: yup.string().required(),
        security: yup.boolean().nullable(),
        parkingType: yup.string().required(),
        electricCharge: yup.boolean().nullable(),
        elevator: yup.boolean().nullable(),
        sector: yup.string().nullable(),
        owner: yup.boolean().nullable(),
        parkingName: yup.string().nullable(),
        description: yup.string().nullable(),
        familyPlace: yup.boolean().nullable(),
        lat: yup.number(),
        lng: yup.number()
      })
    )
  });

  const watchHouseField = watch('house');

  const handleCoordinates = useCallback((data: ICoordinates) => {
    setCoordinates(data);
  }, []);

  const onSubmit = useCallback(
    async (data: FormValues) => {
      if (!chosenCity || !street) {
        if (!chosenCity) setAutocompleteCityErrors(true);
        if (!street) setAutocompleteStreetErrors(true);
        window.scrollTo(0, 0);
        return;
      }

      setIsDisabled(true);
      let formData = new FormData();

      if (filesSelected) {
        Array.from(filesSelected).forEach((file) => {
          formData.append('document', file);
        });
      }

      if (imagesSelected.length > 0) {
        formData.append('mainImage', imagesSelected[mainIndex]);
        Array.from(imagesSelected).forEach((file, index) => {
          if (index !== mainIndex) formData.set('image', file);
        });
      }

      Object.keys(data).forEach((el: string) => {
        if (
          el === 'placeWidth' ||
          el === 'placeLength' ||
          el === 'price' ||
          el === 'heightOfParkingEntry'
        ) {
          formData.append(el, Number(data[el as keyof FormValues]));
        } else {
          data[el as keyof FormValues] !== '' && formData.append(el, data[el as keyof FormValues]);
        }
      });

      formData.append('city', chosenCity?.cityTitle);
      if (newCoordinates) {
        formData.append('lat', newCoordinates.lat);
        formData.append('lng', newCoordinates.lng);
      } else {
        formData.append('lat', coordinates.lat);
        formData.append('lng', coordinates.lng);
      }

      formData.set('street', street);
      // @ts-ignore
      formData.append('streetLat', Number(coordinates?.lat));
      // @ts-ignore
      formData.append('streetLng', Number(coordinates?.lng));

      setLoading(true);

      mutateAsync({
        token: token,
        formData
      });
    },
    [
      filesSelected,
      imagesSelected,
      newCoordinates,
      street,
      coordinates?.lat,
      coordinates?.lng,
      mutateAsync,
      token,
      mainIndex,
      chosenCity
    ]
  );

  const renderOptions = (arr: Auto[]) => {
    const options = arr.map((item) => {
      return (
        <option key={item.value} value={item.value}>
          {t(`parking.${item.value}`)}
        </option>
      );
    });
    return options;
  };

  const classAuto: Auto[] = [
    { value: 'FIRST', label: t('parking.FIRST') },
    { value: 'SECOND', label: t('parking.SECOND') },
    { value: 'THIRD', label: t('parking.THIRD') }
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedAuto = useMemo(() => renderOptions(classAuto), []);

  async function getNewCoordinates(address: string) {
    const result = await geocodeByAddress(address);
    const latLng = await getLatLng(result[0]);
    if (latLng) {
      handleCoordinates(latLng);
    }
  }

  useEffect(() => {
    setNewCoordinates(coordinates);
  }, [chosenCity, coordinates]);

  useEffect(() => {
    if (street) {
      setAutocompleteStreetErrors(false);
    }

    if (chosenCity) {
      setAutocompleteCityErrors(false);
    }

    if (Object.keys(errors).length !== 0) {
      if (!street) {
        setAutocompleteStreetErrors(true);
      }
      if (!chosenCity) {
        setAutocompleteCityErrors(true);
      }
    }
  }, [street, chosenCity, errors]);

  // Set new google map coordinates when house number input
  useEffect(() => {
    if (street && watchHouseField && chosenCity?.formattedCityAddress) {
      getNewCoordinates(`${street}, ${watchHouseField}, ${chosenCity?.formattedCityAddress}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchHouseField, street, chosenCity?.formattedCityAddress]);

  useEffect(() => {
    (async () => {
      const token = await checkExpiry(localStorage.getItem('accessToken'));
      if (!token) {
        dispatch(logout());
        history.push('/');
      }
    })();
  }, [dispatch, history]);

  const mapWrapperStyle = useCallback(() => {
    if (!customStyle) {
      setCustomStyle({ position: 'fixed', left: 0, top: 0 });
    } else {
      setCustomStyle(null);
    }
  }, [customStyle]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', mapWrapperStyle);
    return () => {
      document.removeEventListener('fullscreenchange', mapWrapperStyle);
    };
  }, [mapWrapperStyle]);

  return (
    <Background height="auto">
      <MainHeader />

      <MainContainer>
        <InfoContainer autocomplete="off">
          <StyledTitle>{t('titles.general_info')}</StyledTitle>
          <ContentWrapper height={isMobile ? '380' : ' '}>
            <RequiredLabel htmlFor="city" required={true} marginBottom={isMobile ? '10' : '0'}>
              <UpperPlaceholder>{t('placeholders.city')}</UpperPlaceholder>
              <GoogleCityAutoCompleteCreate
                setChosenCity={setChosenCity}
                error={autocompleteCityErrors}
              />
              <Notification isAccent={true}>
                {autocompleteCityErrors && t('login.required')}
              </Notification>
            </RequiredLabel>

            <RequiredLabel htmlFor="street" required={true} marginBottom={isMobile ? '50' : '20'}>
              <UpperPlaceholder>{t('placeholders.street')}</UpperPlaceholder>
              <GoogleAutoCompleteStreet
                setStreet={setStreet}
                city={chosenCity?.formattedCityAddress}
                handleCoordinates={handleCoordinates}
                error={autocompleteStreetErrors}
              />
              <Notification isAccent={true}>
                {autocompleteStreetErrors && t('login.required')}
              </Notification>
              <DownPlaceholder>{t('notifications.wrong_street')}</DownPlaceholder>
            </RequiredLabel>

            <RequiredLabel htmlFor="house" required={true} marginBottom={isMobile ? '10' : '0'}>
              <UpperPlaceholder>{t('placeholders.house')}</UpperPlaceholder>
              <StyledInput
                {...register('house')}
                // value={inputValueHouseNumber}
                // onChange={onChanceHouseNumber}
                islight={true}
                width={isMobile ? '300px' : '350px'}
                error={errors.house}
              />
              <Notification isAccent={true}>
                {errors?.house?.type === 'required' && t('login.required')}
                {errors?.house?.type === 'matches' && errors.house.message}
              </Notification>
            </RequiredLabel>

            <RequiredLabel
              htmlFor="placeNumber"
              required={true}
              marginBottom={isMobile ? '10' : '0'}>
              <UpperPlaceholder>{t('placeholders.place_number')}</UpperPlaceholder>
              <StyledInput
                {...register('placeNumber')}
                //   value={inputValuePlaceNumber}
                //   onChange={onChancePlaceNumber}
                islight={true}
                width={isMobile ? '300px' : '350px'}
                refs={register}
                error={errors.placeNumber}
              />
              <Notification isAccent={true}>
                {errors?.placeNumber?.type === 'required' && t('login.required')}
                {errors?.placeNumber?.type === 'matches' && errors.placeNumber.message}
              </Notification>
            </RequiredLabel>

            <RequiredLabel
              htmlFor="parkingLevel"
              required={true}
              marginBottom={isMobile ? '10' : '0'}>
              <UpperPlaceholder>{t('placeholders.parking_level')}</UpperPlaceholder>
              <StyledInput
                {...register('parkingLevel')}
                //   value={inputChanceLevel}
                //   onChange={onChanceLevel}
                islight={true}
                width={isMobile ? '300px' : '350px'}
                refs={register}
                error={errors.parkingLevel}
              />
              <Notification isAccent={true}>
                {errors?.parkingLevel?.type === 'required' && t('login.required')}
                {errors?.parkingLevel?.type === 'matches' && errors.parkingLevel.message}
              </Notification>
            </RequiredLabel>
          </ContentWrapper>
        </InfoContainer>

        <InfoContainer>
          <StyledTitle>{t('titles.size')}</StyledTitle>
          <ContentWrapper height={isMobile ? '210' : ' '}>
            <RequiredLabel
              htmlFor="placeWidth"
              required={true}
              marginBottom={isMobile ? '10' : '0'}>
              <UpperPlaceholder>{t('placeholders.place_width')}</UpperPlaceholder>
              <StyledInput
                {...register('placeWidth')}
                // value={inputWidthValue}
                // onChange={onChanceWidth}
                islight={true}
                placeholder={`${t('placeholders.example')} 4.20 м.`}
                width={isMobile ? '300px' : '350px'}
                error={errors.placeWidth}
              />
              <Notification isAccent={true}>
                {errors?.placeWidth?.type === 'required' && t('login.required')}
                {errors?.placeWidth?.type === 'matches' && errors.placeWidth.message}
              </Notification>
            </RequiredLabel>
            <RequiredLabel
              htmlFor="placeLength"
              required={true}
              marginBottom={isMobile ? '10' : '0'}>
              <UpperPlaceholder>{t('placeholders.place_length')}</UpperPlaceholder>
              <StyledInput
                {...register('placeLength')}
                // value={inputLengthValue}
                // onChange={onChanceLength}
                islight={true}
                placeholder={`${t('placeholders.example')} 6.10 м.`}
                width={isMobile ? '300px' : '350px'}
                error={errors.placeLength}
              />
              <Notification isAccent={true}>
                {errors?.placeLength?.type === 'matches' && errors.placeLength.message}
              </Notification>
            </RequiredLabel>
            <RequiredLabel
              htmlFor="heightOfParkingEntry"
              required={true}
              marginBottom={isMobile ? '10' : '0'}>
              <UpperPlaceholder>{t('placeholders.entrance_height')}</UpperPlaceholder>
              <StyledInput
                {...register('heightOfParkingEntry')}
                // value={inputHeightValue}
                // onChange={onChanceHeight}
                islight={true}
                placeholder={`${t('placeholders.example')} 7.50 м.`}
                width={isMobile ? '300px' : '350px'}
                error={errors.heightOfParkingEntry}
              />
              <Notification isAccent={true}>
                {errors?.heightOfParkingEntry?.type === 'required' && t('login.required')}
                {errors?.heightOfParkingEntry?.type === 'matches' &&
                  errors.heightOfParkingEntry.message}
              </Notification>
            </RequiredLabel>
          </ContentWrapper>
        </InfoContainer>

        <InfoContainer>
          <StyledTitle>{t('titles.price')}</StyledTitle>
          <ContentWrapper align={!isMobile && 'start'} height={isMobile ? '120' : ' '}>
            <RequiredLabel htmlFor="price" required={true} marginRight={isMobile ? '0' : '70'}>
              <UpperPlaceholder>{t('placeholders.sell_parking_price')}</UpperPlaceholder>
              <StyledInput
                {...register('price')}
                // value={pricePerDayValue}
                // onChange={onChancePricePerDay}
                islight={true}
                placeholder={t('placeholders.sell_parking_price')}
                width={isMobile ? '300px' : '350px'}
                marginRight={isMobile ? '0' : '70px'}
                error={errors.price}
              />
              <Notification isAccent={true}>
                {errors?.price?.type === 'required' && t('login.required')}
                {errors?.price?.type === 'matches' && errors.pricePerDay.message}
              </Notification>
            </RequiredLabel>
          </ContentWrapper>
        </InfoContainer>

        <InfoContainer>
          <StyledTitle>{t('titles.additional_info')}</StyledTitle>
          <ContentWrapper margin_b="20" height={isMobile ? '440' : ' '}>
            <Tooltip />

            <RequiredLabel
              htmlFor="carClass"
              required={true}
              marginBottom={isMobile ? '10' : '20'}
              data-tip={t('parking.car_class_desc')}>
              <UpperPlaceholder>{t('parking.car_class')}</UpperPlaceholder>
              <Select required {...register('carClass')} error={errors.carClass}>
                <option value="" hidden>
                  {t('parking.car_class')}
                </option>
                {memoizedAuto}
              </Select>
            </RequiredLabel>

            <RequiredLabel htmlFor="security" marginBottom={isMobile ? '10' : '0'}>
              <UpperPlaceholder>{t('placeholders.security')}</UpperPlaceholder>
              <Select defaultValue="0" required {...register('security')}>
                <option value="0">
                  {t('placeholders.security')} {t('placeholders.not_exists')}
                </option>
                <option value="1">
                  {t('placeholders.security')} {t('placeholders.exists')}
                </option>
              </Select>
            </RequiredLabel>

            <RequiredLabel required htmlFor="parkingType" marginBottom={isMobile ? '10' : '0'}>
              <UpperPlaceholder>{t('placeholders.parking_types')}</UpperPlaceholder>
              <Select
                error={errors.parkingType}
                required
                {...register('parkingType')}
                refs={register}>
                <option value="" hidden>
                  {t('placeholders.parking_types')}
                </option>
                <option value="UNDEGROUND">{t('placeholders.underground')}</option>
                <option value="OVERGROUND">{t('placeholders.ground')}</option>
              </Select>
            </RequiredLabel>

            <RequiredLabel htmlFor="electricCharge" marginBottom={isMobile ? '10' : '20'}>
              <UpperPlaceholder>{t('placeholders.electric_charger')}</UpperPlaceholder>
              <Select required {...register('electricCharge')}>
                <option value="0">
                  {t('placeholders.electric_charger')} {t('placeholders.not_exists')}
                </option>
                <option value="1">
                  {t('placeholders.electric_charger')} {t('placeholders.exists')}
                </option>
              </Select>
            </RequiredLabel>

            <RequiredLabel htmlFor="elevator" marginBottom={isMobile ? '10' : '0'}>
              <UpperPlaceholder>{t('placeholders.elevator')}</UpperPlaceholder>
              <Select required defaultValue="0" {...register('elevator')}>
                <option value="1">
                  {t('placeholders.elevator')} {t('placeholders.exists')}
                </option>
                <option value="0">
                  {t('placeholders.elevator')} {t('placeholders.not_exists')}
                </option>
              </Select>
            </RequiredLabel>

            <RequiredLabel htmlFor="sector" marginBottom={isMobile ? '10' : '0'}>
              <UpperPlaceholder>{t('placeholders.section')}</UpperPlaceholder>
              <StyledInput
                type="text"
                islight={true}
                placeholder={t('placeholders.section')}
                width={isMobile ? '300px' : '350px'}
                {...register('sector')}
              />
            </RequiredLabel>

            <RequiredLabel htmlFor="parkingName">
              <UpperPlaceholder>{t('placeholders.park_name')}</UpperPlaceholder>
              <StyledInput
                type="text"
                islight={true}
                placeholder={t('placeholders.park_name')}
                width={isMobile ? '300px' : '350px'}
                {...register('parkingName')}
              />
            </RequiredLabel>
          </ContentWrapper>

          <ContentWrapper>
            <SmallContainer>
              <StyledTitle>{t('placeholders.description')}</StyledTitle>
              <RequiredLabel maxHeight="220" htmlFor="description">
                <TextArea
                  placeholder={t('placeholders.description')}
                  rows="12"
                  {...register('description')}
                />
              </RequiredLabel>
            </SmallContainer>

            <SmallContainer>
              <StyledTitle>{t('titles.parking_docs')}</StyledTitle>
              <TextP margin_b="15">{`${filesSelected.length} ${t('titles.file_length')}`}</TextP>
              <FileUploader filesSelected={filesSelected} setFilesSelected={setFilesSelected} />
            </SmallContainer>

            <SmallContainer>
              <StyledTitle>{t('titles.additional_info')}</StyledTitle>
              <RequiredLabel htmlFor="familyPlace">
                <UpperPlaceholder>{t('placeholders.family_place')}</UpperPlaceholder>
                <Select required defaultValue="0" {...register('familyPlace')}>
                  <option value="1">
                    {t('placeholders.family_place')} {t('placeholders.yes')}
                  </option>
                  <option value="0">
                    {t('placeholders.family_place')} {t('placeholders.no')}
                  </option>
                </Select>
              </RequiredLabel>
            </SmallContainer>
          </ContentWrapper>

          <ContentWrapper>
            <SmallContainer>
              <ImageUploader
                imagesSelected={imagesSelected}
                setImageSelected={setImageSelected}
                mainIndex={mainIndex}
                setMainIndex={setMainIndex}
                setIsDisabled={setIsDisabled || loading}
              />
            </SmallContainer>
          </ContentWrapper>

          <StyledTitle>{t('titles.map')}</StyledTitle>
          {newCoordinates && (
            <>
              <TextP>{t('titles.choose_location')}</TextP>
              <MapWrapper style={customStyle && customStyle}>
                <GoogleMap
                  lat={newCoordinates.lat}
                  lng={newCoordinates.lng}
                  setNewCoordinates={setNewCoordinates}
                />
              </MapWrapper>
            </>
          )}

          <Button
            dataTestid="submit-sell-parking"
            primary={true}
            width="226"
            height="50"
            onClick={handleSubmit(onSubmit)}
            disabled={isDisabled}
            margin_right={isMobile ? 'auto' : '0'}
            margin_left={isMobile ? 'auto' : '0'}>
            {t('buttons.send_check')}
          </Button>
        </InfoContainer>
      </MainContainer>
    </Background>
  );
};
