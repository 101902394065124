import styled from "styled-components";

import { color, device } from 'theme';

export const FooterContainer = styled.footer`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  margin-top: auto;

  @media (min-width: 768px) {
    padding: 8px 0;
  }
`;

export const Nav = styled.ul`
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
  justify-content: space-between;
  max-width: 1000px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 10px;
    align-items: center;
  }
`;

export const ListItem = styled.li`
  @media (max-width: 768px) {
    margin-top: 5px;
  }
  &:first-of-type {
    margin-left: 0;
  }
  & a {
    color: #000;
  }
`;

export const CopyRight = styled.span`
  font-size: 12px;
`;

export const ShortenFooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  margin-top: auto;
  padding: 10px 5px;
`;

export const ShortenNav = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 10px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ShortenListItem = styled.li`
  & a {
    color: ${color.darkGrey};
    opacity: 0.8;
  }
`;

export const ContactsIconsContainer = styled.div`
  display: flex;
  gap: 5px;

  @media ${device.tablet} {
    gap: 10px;
  }

  & > a,
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  & img {
    width: 30px;
    height: 30px;
  }
`;
