import styled from "styled-components";

import { device, color } from 'theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  background: #ffffff;

  @media (min-width: 375px) {
    padding: 10px;
  }

  @media ${device.tablet} {
    padding: 12px 20px;
  }
`;

export const CalendarWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0 10px 0;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  padding: 0 10px 10px 10px;
`;

export const StyledPhoneLink = styled.a`
  color: ${color.blue};
`;
