import styled from "styled-components";

import { font, color, fontSize, device } from "theme";

export const StyledH2 = styled.h2`
  font-family: ${font.primary};
  font-weight: 400;
  font-style: normal;
  font-size: ${fontSize.regular};
  line-height: 14px;
  color: ${color.blue};
  margin-bottom: 10px;
  text-align: center;

  @media ${device.tablet} {
    font-weight: 500;
    font-style: normal;
    font-size: ${fontSize.large};
    line-height: 21px;
    margin-bottom: 20px;
  }

  @media ${device.laptopL} {
    text-align: left;
  }
`;
