import styled from 'styled-components';

import { color, fontSize, device } from 'theme';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ isSmall }) => (isSmall ? '6px 0 0 20px' : '6px 0')};
  color: ${color.greayText};
  font-size: ${fontSize.small};

  @media ${device.tablet} {
    font-size: ${fontSize.regular};
    padding: ${({ isSmall }) => (isSmall ? '6px 0 0 20px' : '10px 0')};
  }
`;

export const StyledInput = styled.input`
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.5em;
  border: 0.1em solid ${color.blue};
  outline: none;
  cursor: pointer;
  background-color: ${({ checked }) => (checked ? `${color.blue}` : 'transparent')};
  position: relative;
  transition: all 0.3s ease-in-out;
`;
