import { NavLink } from "react-router-dom";
import {
  FooterContainer,
  Nav,
  ListItem,
  CopyRight,
  ContactsIconsContainer,
  BottomContainer
} from './styles';
import { useTranslation } from 'react-i18next';

import { smallVersionIcons, generateContactsIcons } from 'Pages/Contacts/ContactsModule';

const Footer = () => {
  const { t } = useTranslation(['common']);

  return (
    <FooterContainer>
      <Nav>
        <ListItem>
          <NavLink to="/about-us">{t('footer.about_us')}</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/partner">{t('footer.partners')}</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/privacy-policy">{t('footer.privacy_policy')}</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/conditions">{t('footer.conditions')}</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/contacts">{t('footer.contacts')}</NavLink>
        </ListItem>
      </Nav>
      <BottomContainer>
        <CopyRight>Parkovki&copy;</CopyRight>{' '}
        <ContactsIconsContainer>{generateContactsIcons(smallVersionIcons)}</ContactsIconsContainer>
      </BottomContainer>
    </FooterContainer>
  );
};

export default Footer;