import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'Components/UI/Button/Button';
import { Wrapper, ContentWrapper, StyledPhoneLink } from './style';
import { StyledPrice, StyledNote } from 'Components/ParkingCard/style';

type BookParkingForSaleProps = {
  id?: number;
  currency?: string;
  price: string;
  telephone: string;
};

export const BookParkingForSale = ({
  telephone,
  price,
  currency = ''
}: BookParkingForSaleProps) => {
  const { t } = useTranslation(['common']);
  const [isPhoneVisible, setIsPhoneVisible] = useState<boolean>(false);

  const handleShowPhone = () => {
    setIsPhoneVisible((prevState) => !prevState);
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <div>
          <StyledNote> {t('parking.total')} </StyledNote>
          <StyledPrice data-testid="sell-price">
            {price} {currency}
          </StyledPrice>
        </div>
        <div>
          <StyledNote> {t('parking.phone_number')}:</StyledNote>
          {isPhoneVisible ? (
            <StyledPhoneLink href={`tel:${telephone}`}>{telephone}</StyledPhoneLink>
          ) : (
            <StyledPhoneLink>XX(XXX)XXX-XX-XX</StyledPhoneLink>
          )}
        </div>

        <Button dataTestid="show-phone-number" primary={true} onClick={handleShowPhone}>
          {isPhoneVisible ? t('buttons.hide_phone') : t('buttons.show_phone')}
        </Button>
      </ContentWrapper>
    </Wrapper>
  );
};
