import React from 'react';

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 58 58"
      fill="#2F80ED"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M44.7661 41.5683L31.9759 28.778L44.7661 15.9878L41.5686 12.7902L28.7783 25.5805L15.9881 12.7902L12.7905 15.9878L25.5808 28.778L12.7905 41.5683L15.9881 44.7658L28.7783 31.9756L41.5686 44.7658L44.7661 41.5683Z" />
    </svg>
  );
};
