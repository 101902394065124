import React, { useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import Modal from "react-modal";
import { color } from "theme";
import {
  Container,
  Column,
  Row,
  Text,
  Content,
  Footer,
  CalendarColumn,
  Buttons,
  Button,
  Tip,
  Email,
  EmailText,
  ChangeEmailBtn,
  EmailStatus,
  EmailButtons,
  EmailButton,
  EmailInput,
  EmailTitle,
} from "./style";
import { CalendarGrid, Info } from "./CalendarGrid";
import { useMutation } from "react-query";
import { addBookingDays, AddBookingDaysResponse, getDays, getMyParkings, ParkingPlace, Status, updateEmail } from "api/parking";
import { AxiosResponse } from "axios";
import { AddBookingPopup } from './AddBookingPopup';
import { Day } from './type';
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import styles from './style.module.css';
import { checkExpiry, getProfile } from "api/auth";
import { logout, setUserInfo } from "Redux/reducers/login/loginReducer";
import { useDispatch, useSelector } from "react-redux";
import { getAllParkings } from 'Redux/reducers/parking/actions';
import { useHistory } from 'react-router-dom';
import { RootState } from 'Redux/store';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { emailSchema } from 'config';
import { yupResolver } from '@hookform/resolvers/yup';
import { EMAIL_MUST_BE_VALID, EMAIL_REQUIRED_FIELD } from 'Constants/constants';
import { Notification } from 'Components/UI/Notification/Notification';

const customStyles = {
  overlay: {
    background: 'rgba(51, 51, 51, 0.5)',
    overflow: 'auto'
  }
};

Modal.setAppElement('#root');

type FormValues = { email: string };

const createItem = (date: Date, next: boolean) => {
  next && date.setMonth(date.getMonth() + 1);
  return {
    date,
    status: 4,
    minDays: 1,
    type: 'UNAVAILABLE'
  };
};

const daysInMonth = (y: number, m: number) => new Date(y, m, 0).getDate();
function createArray() {
  const date = new Date();
  const day = moment.tz(new Date(), 'Europe/Kiev').date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const arrOne = Array.from({ length: daysInMonth(year, month + 1) - day + 1 }, (_, k) =>
    createItem(new Date(year, month, k + day), false)
  );
  const end = moment().add(1, 'M').endOf('M').date();
  const max = (a: number, b: number): number => (a < b ? a : b);
  const arrTwo = Array.from({ length: max(day, end) }, (_, k) =>
    createItem(new Date(year, month, k + 1), true)
  );
  return [...arrOne, ...arrTwo];
}

const defaultArray = createArray();

const limit = 25;

export const Calendar = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [parkingPlaceId, setParkingPlaceId] = useState<number>(-1);
  const [index, setIndex] = useState(0);
  const { t } = useTranslation('common');
  const [info, setInfo] = useState<Info | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(emailSchema)
  });

  function openModal(index: number, info: Info) {
    setIndex(index);
    setParkingPlaceId(parkings[index].id);
    setIsOpen(true);
    setInfo(info);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const { mutateAsync } = useMutation(addBookingDays, {
    onSuccess: (response: AxiosResponse<AddBookingDaysResponse>) => {
      (async function () {
        try {
          const token = localStorage.getItem('accessToken') || '';
          const response = await getDays({ parkingPlaceId }, token);
          const copy = [...responses];
          copy[index] = response;
          setResponses(copy);
          closeModal();
        } catch (error) {
          console.log(error);
        }
      })();
    }
  });

  const [parkings, setParkings] = useState<ParkingPlace[]>([]);
  const [responses, setResponses] = useState<AxiosResponse[]>([]);

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    (async function () {
      try {
        const token = await checkExpiry(localStorage.getItem('accessToken'));
        if (token) {
          const { data } = await getMyParkings(
            {
              limit,
              page,
              sortingType: 'DATE_DESCENDING',
              statuses: [Status.ApprovedVerified, Status.ApprovedUnverified]
            },
            token
          );
          const parkings = data.results;
          setParkings(parkings);
          dispatch(getAllParkings(data));
          setPages(Math.ceil(data.count / limit));
          const promises = parkings.map(
            async (parking: any) => await getDays({ parkingPlaceId: parking.id }, token)
          );
          const responses = await Promise.all<AxiosResponse>(promises);
          setResponses(responses);
          closeModal();
        } else {
          dispatch(logout());
          history.push('/');
        }
      } catch (error) {
        // @ts-ignore
        if (error?.response?.status === 401) dispatch(logout());
      }
    })();
  }, [page, dispatch, history]);

  const handlePageClick = (page: any) => {
    setPage(page.selected + 1);
  };

  const next = Array.from({ length: 6 }, (_, k) => k + 1);

  const [arr, setArr] = useState<Day[]>(defaultArray);

  const changeMonth = (defaultMonth: boolean = false, date: Date) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    setCurrentMonth(month);
    const days = daysInMonth(year, month + 1);
    if (defaultMonth) {
      setArr(defaultArray);
      return;
    }
    setArr(
      Array.from({ length: days as number }, (_, k) =>
        createItem(new Date(year, month, k + 1), false)
      )
    );
  };

  const handleMultiple = (start: Date, end: Date) => {
    const today = new Date();
    if (start.getTime() < today.getTime()) {
      setStartDate(today);
    } else {
      setStartDate(start);
    }

    if (end.getTime() < today.getTime()) {
      setEndDate(today);
    } else {
      setEndDate(end);
    }
  };

  const bookingHandleSubmit = async (data: any) => {
    const token = localStorage.getItem('accessToken') || '';
    mutateAsync({
      token,
      parkingPlaceId,
      ...data
    });
  };

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [all, setAll] = useState<any>();

  const getStatus = (item: any) => {
    if ((item.status !== 'BOOKED' && Number(item.openRequests) > 0) || item.status === 'LOCKED')
      return 5;

    if (item.status === 'FREE' && Number(item.openRequests) === 0) {
      switch (item.bookingType) {
        case 'AUTO_APPROVE':
          return 1;
        case 'MANUAL_APPROVE':
          return 3;
        case 'UNAVAILABLE':
          return 4;
        default:
          return 1;
      }
    }
    if (item.status === 'BOOKED') return 2;
    return 1;
  };

  useEffect(() => {
    (async () => {
      const { data } = await getProfile(localStorage.getItem('accessToken') || '');
      setStatus(data.emailConfirmed || false);
    })();
  }, []);

  useEffect(() => {
    const temp = responses?.map(({ data }) => {
      const array: Array<any> = data.results;
      const a = arr.map((item) => ({
        ...item,
        type: data.default,
        status: data.default === 'AUTO_APPROVE' ? 1 : 4
      }));
      array.forEach((item) => {
        const date = new Date(item.date);
        const momentDate = moment.tz(new Date(item.date), 'Europe/Kiev');
        const day = momentDate.date();
        const month = momentDate.month();
        const year = momentDate.year();

        const index = a.findIndex(
          (item) =>
            item.date.getDate() === day &&
            item.date.getMonth() === month &&
            item.date.getFullYear() === year
        );

        if (index > -1) {
          a[index] = {
            status: getStatus(item),
            minDays: item.minDays,
            date,
            price: item.price,
            id: item.id,
            type: item.bookingType
          };
        }
      });
      return a;
    });
    setAll(temp);
  }, [responses, currentMonth, arr]);

  const [emailModal, setShowChangeEmailModal] = useState(false);
  const [status, setStatus] = useState(false);
  const [emailError, setEmailError] = useState(false);

  async function handleEmailChange(values: FormValues) {
    try {
      setEmailError(false);
      const update = await updateEmail(values, localStorage.getItem('accessToken') || '');
      if (update.data.isEmailUpdated) {
        const { data } = await getProfile(localStorage.getItem('accessToken') || '');
        localStorage.setItem('userInfo', JSON.stringify(data));
        dispatch(setUserInfo(data));
        setStatus(false);
        setShowChangeEmailModal(false);
        return toast.success(t('status.email_changed'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined
        });
      }

      setShowChangeEmailModal(false);
      return toast.error(t('status.email_not_changed'), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined
      });
    } catch (error) {
      // @ts-ignore
      if (error?.response.status === 409) {
        setEmailError(true);
      }
    }
  }

  const email = useSelector((state: RootState) => state.auth.email);

  function getEmailStatus() {
    if (status) return t('status.verified');
    return t('status.unverified');
  }

  return (
    <Container>
      <Modal
        isOpen={!!info && modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        className={styles.Modal}>
        {!!info && (
          <AddBookingPopup
            start={startDate}
            end={endDate}
            onSubmit={bookingHandleSubmit}
            onClose={closeModal}
            info={info}
          />
        )}
      </Modal>
      <Modal
        isOpen={emailModal}
        onRequestClose={() => setShowChangeEmailModal(false)}
        contentLabel="Example Modal"
        className={styles.EmailModal}
        style={customStyles}>
        <form onSubmit={handleSubmit(handleEmailChange)}>
          <EmailTitle>{t('profile.calendar_add_email')}</EmailTitle>
          <EmailInput
            data-testid="email"
            {...register('email', { required: true })}
            placeholder="Email"
          />
          <Notification isAccent={true}>
            {errors?.email?.message === EMAIL_REQUIRED_FIELD && t('login.required')}&nbsp;
            {errors?.email?.message === EMAIL_MUST_BE_VALID && t('login.validEmail')}&nbsp;
          </Notification>
          {emailError && <span style={{ color: 'red' }}>{t('profile.calendar_email_exists')}</span>}
          <EmailButtons>
            <EmailButton>Submit</EmailButton>
            <EmailButton onClick={() => setShowChangeEmailModal(false)} type="button" cancel>
              Cancel
            </EmailButton>
          </EmailButtons>
        </form>
      </Modal>
      <Email>
        <EmailText>
          {t('profile.calendar_email_confirmation')} {email}{' '}
          <EmailStatus status={status}>{getEmailStatus()}</EmailStatus>
        </EmailText>
        <ChangeEmailBtn onClick={() => setShowChangeEmailModal(true)}>
          {t('profile.calendar_email_edit')}
        </ChangeEmailBtn>
      </Email>
      <Buttons>
        <Button
          current={currentMonth === new Date().getMonth()}
          onClick={() => changeMonth(true, new Date())}>
          {t(`months.${new Date().getMonth() + 1}`)}
        </Button>
        {next.map((item, index) => {
          const date = new Date();
          date.setMonth(date.getMonth() + item);
          return (
            <Button
              current={currentMonth === date.getMonth()}
              key={index}
              onClick={() => changeMonth(false, date)}>
              {t(`months.${date.getMonth() + 1}`)}
            </Button>
          );
        })}
      </Buttons>
      <Content>
        <Column>
          <Row>
            <Text color={color.blue}>{t('profile.calendar_parking_place')}</Text>
          </Row>
          {parkings?.map((item, index) => (
            <Row key={item.id}>
              <Text>{item.parkingName ? item.parkingName : `${item.street}, ${item.house}`}</Text>
              {/* <EditIcon onClick={() => openModal(index, { price: item.pricePerDay })} /> */}
            </Row>
          ))}
        </Column>
        <CalendarColumn data-testid="calendar">
          <CalendarGrid
            header={arr}
            arr={all}
            first={moment(arr[0].date).startOf('M').day() - 1}
            openModal={openModal}
            handleMultiple={handleMultiple}
            prices={useMemo(() => parkings.map((parking) => parking.pricePerDay), [parkings])}
            currency={(!!parkings.length && parkings[0]?.currency) || ''}
          />
        </CalendarColumn>
      </Content>
      {pages > 1 && (
        <ReactPaginate
          previousLabel={t('pagination.previous')}
          nextLabel={t('pagination.next')}
          breakLabel={'...'}
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={styles.pagination}
          activeClassName={styles.active}
          pageClassName={styles.page}
          previousClassName={styles.nextPrev}
          nextClassName={styles.nextPrev}
          disabledClassName={styles.disabled}
          breakClassName={styles.break}
          initialPage={page - 1}
        />
      )}
      <Footer>
        <Tip color={color.backgroundGrey}>
          <Text nowrap>{t('profile.calendar_footer_date_on_book')}</Text>
        </Tip>
        <Tip color={color.red}>
          <Text nowrap>{t('profile.calendar_footer_date_close_book')}</Text>
        </Tip>
        <Tip color={color.lightGreen}>
          <Text nowrap>{t('profile.calendar_footer_book_system')}</Text>
        </Tip>
        <Tip color={color.yellow}>
          <Text nowrap>{t('profile.calendar_footer_available_date')}</Text>
        </Tip>
        <Tip color={color.blue}>
          <Text nowrap>{t('profile.calendar_footer_date_booking')}</Text>
        </Tip>
      </Footer>
    </Container>
  );
};
