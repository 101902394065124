import axios, { AxiosResponse } from "axios";
import {
  RESET_PASSWORD_URL,
  GET_MY_PROFILE_URL,
  REFRESH_TOKEN_URL,
  SET_NEW_PASSWORD_URL,
  CHECK_RESTORE_PASSWORD_TOKEN_URL,
  UPDATE_LANGUAGE_SELECTION_URL
} from 'config';
import { Profile } from 'Redux/reducers/login/loginReducer';
import jwt_decode from 'jwt-decode';

type RegisterProps = {
  name: string;
  surname: string;
  email: string;
  password: string;
  phone: string;
  url: string;
  language: string;
};

type LoginProps = {
  email: string;
  password: string;
  url: string;
};

type PhoneVerivicationProps = {
  url: string;
  code: string;
  token: string;
};

type ResetPasswordProps = {
  email: string;
};

type RestorePassProps = {
  token: string | string[];
  password: string;
};

export type RegisterResponse = {
  accessToken: string;
};

export interface IToken {
  token: string;
  expiresAt: string;
}

export type LoginResponse = {
  accessToken: string;
  refreshToken: IToken;
};

export const registerUser = async ({
  name,
  surname,
  email,
  password,
  phone,
  country,
  url,
  language
}: RegisterProps): Promise<AxiosResponse> => {
  return axios.post(url, { email, password, name, surname, phone, country, language });
};

export const loginUser = async ({ email, password, url }: LoginProps) => {
  return axios.post(url, { email, password });
};

export const phoneVerification = async ({
  url,
  code,
  token
}: PhoneVerivicationProps): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return axios.post(url, { code }, { headers: headers });
};

export const resetPassword = async ({ email }: ResetPasswordProps): Promise<AxiosResponse> => {
  return axios.get(RESET_PASSWORD_URL, { params: { email: email } });
};

export const restorePassword = async ({
  token,
  password
}: RestorePassProps): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json'
  };
  const data = {
    token: token,
    newPassword: password
  };

  const response = await axios.put(SET_NEW_PASSWORD_URL, data, {
    headers: headers
  });

  return response;
};

export const getProfile = async (
  token: string | undefined | null
): Promise<AxiosResponse<Profile>> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  const response = await axios.get(GET_MY_PROFILE_URL, {
    headers: headers
  });

  return response;
};

export const refreshToken = async (refreshToken: string): Promise<AxiosResponse<LoginResponse>> => {
  const headers = {
    'Content-Type': 'application/json'
  };
  // throw "Error2";
  return await axios.post(
    REFRESH_TOKEN_URL,
    {
      refreshToken
    },
    { headers: headers }
  );
};

type Decoded = {
  exp: number;
};

export const checkExpiry = async (token: string | null) => {
  if (!token) return null;
  try {
    const decoded: Decoded = jwt_decode(token);
    if (decoded.exp * 1000 < new Date().getTime()) {
      const data = localStorage.getItem('refreshToken') as string;
      const parsedData = JSON.parse(data);
      const response = await refreshToken(parsedData.token);
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', JSON.stringify(response.data.refreshToken));
      return response.data.accessToken;
    }
    return token;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const validateRestorePasswordToken = (token: string): Promise<AxiosResponse> => {
  return axios.get(`${CHECK_RESTORE_PASSWORD_TOKEN_URL}?token=${token}`);
};

export const setNewLanguageSelection = ({ token, language }): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  axios.put(UPDATE_LANGUAGE_SELECTION_URL, { language }, { headers: headers });
};
