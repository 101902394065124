import styled from 'styled-components';
import { color } from 'theme';

export const ResponseMessage = styled.p`
  color: ${props => props.success ? color.green : color.red};
`;

export const TabsButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const ContentWrapper = styled.div`
  padding: 20px 10px 20px 0px;
`;