import React from "react";
import { useTranslation } from "react-i18next";

// import { ReactComponent as Checked } from "assets/svg/checked-icon.svg";
import { UserProps } from "Pages/AddParking/AddParking";

import { UserCardCont, UserName, VerificationNote, Title } from "./style";

export const UserCard = ({ name, src, isVerified }: UserProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <UserCardCont>
      {/* <AvaWrapper>
        <AvaImage src={src} />
        {isVerified && (
          <Checked style={{ position: "absolute", bottom: "0", right: "0" }} />
        )}
      </AvaWrapper> */}
      <Title>{t("texts.user_card_owner")}</Title>
      <div>
        <UserName>{name}</UserName>
        {isVerified && (
          <VerificationNote>{t("status.verified_user")}</VerificationNote>
        )}
      </div>
    </UserCardCont>
  );
};
