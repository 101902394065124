import React from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

import { Button } from "Components/UI/Button/Button";
import { ReactComponent as SuccessIcon } from "assets/svg/success-icon.svg";
import {
  Header,
  StyledModal,
  Content,
  StyledP,
  IconWrapper,
  Backdrop,
  Wrapper,
} from "./style";

type Props = {
  backToLogin: React.MouseEventHandler<HTMLButtonElement>;
  isRegister: boolean;
  isShown?: boolean;
  hide?: () => void;
};

export const SuccessModal = ({ isShown, backToLogin, isRegister }: Props) => {
  const { t } = useTranslation(["common"]);

  if (isRegister)
    return (
      <StyledModal data-testid="success-registration-containter">
        <Header></Header>
        <Content>
          <IconWrapper>
            <SuccessIcon />
          </IconWrapper>
          <StyledP>{t("login.success_registration")}</StyledP>
          <Button primary={true} onClick={backToLogin}>
            {t("buttons.login")}
          </Button>
        </Content>
      </StyledModal>
    );

  const modal = (
    <React.Fragment>
      <Backdrop />
      <Wrapper aria-modal tabIndex={-1} role="dialog">
        <StyledModal>
          <Header></Header>
          <Content>
            <IconWrapper>
              <SuccessIcon />
            </IconWrapper>
            <StyledP>{t("notifications.feedback_success")}</StyledP>
            <Button primary={true} onClick={backToLogin}>
              {t("titles.back_home")}
            </Button>
          </Content>
        </StyledModal>
      </Wrapper>
    </React.Fragment>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
