import React from 'react';

export const CarDefaultParkingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <g>
        <path d="M133.8 314.91l11.1-16.71v-71.63a95.72 95.72 0 01-27.6 0v89.07h15.16a1.59 1.59 0 001.34-.73zM117.3 214.71v.17a85.58 85.58 0 0027.6 0v-.17a85.55 85.55 0 10-27.6 0zm13.8-145a60.62 60.62 0 11-60.62 60.61 60.62 60.62 0 0160.62-60.66z"></path>
        <path d="M123.39 136.77v-30h11a11 11 0 110 21.93v16.19a27.16 27.16 0 100-54.31H107.2V170h16.19zM382.06 319.91l-39.22-58.21a29 29 0 00-24-12.78H207.13a29 29 0 00-24 12.78l-5.68 8.43-32.53 49-1.44 2.17a13.1 13.1 0 01-11 5.93H107.74a29 29 0 00-27 18.47l-20.1 51.72a16.88 16.88 0 0015.73 23h50.33a46.88 46.88 0 0093.76 0h85a46.88 46.88 0 0093.76 0h50.31a16.87 16.87 0 0015.72-23l-20.05-51.71a29 29 0 00-27-18.47h-22.37a16.58 16.58 0 01-13.77-7.33zM173.58 441.57a21.17 21.17 0 1121.16-21.16 21.16 21.16 0 01-21.16 21.16zm76.49-122.41a7.49 7.49 0 01-7.49 7.49h-53a9.09 9.09 0 01-7.76-13.82l19.79-32.52a17.21 17.21 0 0114.69-8.25h26.31a7.49 7.49 0 017.49 7.49zm33.31 7.49a7.49 7.49 0 01-7.49-7.49v-39.61a7.49 7.49 0 017.49-7.49h26.31a17.19 17.19 0 0114.68 8.25l19.8 32.52a9.09 9.09 0 01-7.77 13.82zm69 114.92a21.17 21.17 0 1121.16-21.16 21.16 21.16 0 01-21.16 21.16z"></path>
      </g>
    </svg>
  );
};
