import React from "react";
import ReactDOM from "react-dom";
import './index.css';

import { Provider } from "react-redux";
import { store } from "Redux/store";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { config as i18nextConfig } from "./Localization/index";
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const tagManagerArgs = {
  gtmId: 'GTM-W6FZNCC'
}

TagManager.initialize(tagManagerArgs)

// const advancedMatching: AdvancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};
ReactPixel.init('639731647409674', undefined, options);
ReactPixel.pageView();
i18next.init(i18nextConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <Router>
          <App />
        </Router>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
