import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'Redux/store';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { GET_MY_PARKINGS_URL, paths } from 'config';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';
// import useIntersectionObserver from "hooks/useIntersectionObserver";

import Modal from 'react-modal';
import { ParkingItem } from './ParkingItem';
import { Loading } from 'Components/Loader/Loader';
import { StyledNotification } from 'Components/Profile/OnDemand/style';
import { Button } from 'Components/UI/Button/Button';
import { isMobile } from 'react-device-detect';

import styles from './style.module.css';
import { ButtonsWrapperModal, TabsButtonsContainer } from './style';
import { removeParking, removeParkingForSale, getMyParkingPlacesForSale } from 'api/parking';
import { logout } from 'Redux/reducers/login/loginReducer';
import { getImageUrl } from 'Pages/Parking/components/ParkingsList.tsx';

export interface IParkingPlace {
  street: string;
  house: string;
  auto: string;
  price: string;
  status: string;
  id: number;
  src?: string;
  createdDate: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  message: string;
  parkingName: string;
  handleRemove: (id: number) => void;
  currency: string;
}

enum PlaceTypes {
  rent = 'rent',
  sell = 'sell'
}

const customStyles = {
  overlay: {
    background: 'rgba(51, 51, 51, 0.5)'
  }
};

Modal.setAppElement('#root');

export const ParkingPlaces = () => {
  const token = useSelector((state: RootState) => state?.auth?.accessToken);
  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation(['common']);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(-1);
  const [placeType, setPlaceType] = useState<PlaceTypes>('rent');

  const {
    status: sellParkingStatus,
    data: sellParkingData,
    refetch: refetchParkingsForSale
  } = useQuery('getMyParkingsForSale', () => getMyParkingPlacesForSale(1, token), {
    enabled: placeType === 'sell',
    onError: (error: AxiosError) => {
      toast.error(t('notifications.my_sell_parking_error'), NOTIFICATION_OPTIONS);
    }
  });

  const { status, data, isFetching, fetchNextPage, refetch } = useInfiniteQuery(
    'getMyParkingPlaces',
    async ({ pageParam = 1 }) => {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
      const res = await axios.get(`${GET_MY_PARKINGS_URL}${pageParam}`, {
        headers: headers
      });
      return { data: res.data, nextPage: pageParam + 1 };
    },
    {
      refetchOnWindowFocus: false,
      // keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      onError: (error: AxiosError) => {
        if (error.response?.status === 403) {
          toast.error(t('notifications.forbidden'), NOTIFICATION_OPTIONS);
        }
        if (error.response?.status === 401) {
          dispatch(logout());
          history.push('/');
        }
      }
    }
  );

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    history.push({
      pathname:
        placeType === 'rent'
          ? `${paths.editParking}`
          : `${paths.editParkingForSale}?parkingId=${e.currentTarget.value}`,
      state: { parkingId: Number(e.currentTarget.value) }
    });
    window.location.reload();
  };

  function handleRemove(id: number) {
    setId(id);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  async function onSubmitRemove() {
    try {
      await removeParking(id, token);
      refetch();
      setId(-1);
      setIsOpen(false);
    } catch (error: AxiosError) {
      let errorText: string = '';
      if (error.response.status === 401) {
        errorText = t('notifications.auth_problem');
      } else if (error.response.status === 403) {
        errorText = t('notifications.delete_booked_parking');
      } else if (error.response.status === 404) {
        errorText = t('notifications.not_found');
      } else {
        errorText = t('notifications.parking_delete_error');
      }

      toast.error(errorText, NOTIFICATION_OPTIONS);
    }
  }

  const handleRemoveParkingForSale = async () => {
    try {
      await removeParkingForSale(id, token);
      refetchParkingsForSale();
      setId(-1);
      setIsOpen(false);
    } catch (error: AxiosError) {
      let errorText: string = '';
      if (error.response.status === 401) {
        errorText = t('notifications.auth_problem');
      } else if (error.response.status === 403) {
        errorText = t('notifications.delete_booked_parking');
      } else if (error.response.status === 404) {
        errorText = t('notifications.not_found');
      } else {
        errorText = t('notifications.parking_delete_error');
      }

      toast.error(errorText, NOTIFICATION_OPTIONS);
    }
  };

  const handleChangeTabs = useCallback(() => {
    setPlaceType((prevState) => (prevState === 'sell' ? 'rent' : 'sell'));
  }, []);

  if (status === 'loading' || sellParkingStatus === 'loading') return <Loading />;

  if (status === 'error' || sellParkingStatus === 'error') {
    return <StyledNotification>{t('notifications.connection_failed')}</StyledNotification>;
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        className={styles.Modal}>
        <p>{t('notifications.deleting_confirmation')}</p>
        <ButtonsWrapperModal>
          <Button
            dataTestid="confirm-delete"
            remove
            width="126"
            height="50"
            onClick={placeType === 'rent' ? onSubmitRemove : handleRemoveParkingForSale}>
            {t('buttons.delete')}
          </Button>
          <Button
            dataTestid="confirm-cancel"
            primary={true}
            width="126"
            height="50"
            onClick={closeModal}>
            {t('buttons.cancel')}
          </Button>
        </ButtonsWrapperModal>
      </Modal>
      <TabsButtonsContainer>
        <Button
          dataTestid="rent-tab"
          primary={placeType === 'rent'}
          height="50"
          onClick={handleChangeTabs}
          disabled={placeType === 'rent'}>
          {t('parking.rent')}
        </Button>
        <Button
          dataTestid="sell-tab"
          primary={placeType === 'sell'}
          height="50"
          onClick={handleChangeTabs}
          disabled={placeType === 'sell'}>
          {t('parking.sell')}
        </Button>
      </TabsButtonsContainer>
      {data?.pages[0].data.count === 0 && (
        <StyledNotification>{t('notifications.no_own_parkings')}</StyledNotification>
      )}

      {placeType === 'sell' && sellParkingData?.data?.count === 0 && (
        <StyledNotification>{t('notifications.no_own_parkings')}</StyledNotification>
      )}

      {placeType === 'rent' &&
        data?.pages.map((page) => (
          <React.Fragment key={page.nextPage}>
            {page?.data?.results.map((item: any) => (
              <ParkingItem
                key={item.id}
                onClick={handleEdit}
                street={item.street}
                house={item.house}
                auto={item.carClass}
                id={item.id}
                price={item.pricePerDay}
                status={item.status}
                createdDate={item.createdDate}
                src={getImageUrl(item.files)}
                message={item.message}
                parkingName={item.parkingName}
                handleRemove={handleRemove}
                currency={item.currency}
              />
            ))}
          </React.Fragment>
        ))}

      {isFetching && <Loading />}
      {/* {data?.pages[0]?.data.count > 10 && <div ref={loadMoreRef}></div>} */}
      {placeType === 'rent' && data?.pages[0]?.data.count > 10 && (
        <>
          {/* <PaginationBlock ref={loadMoreRef}></PaginationBlock> */}
          {Number(data?.pages.length) * 10 <= Number(data?.pages[0].data.count) && (
            <Button
              primary={true}
              onClick={() => fetchNextPage()}
              height={isMobile ? '40' : '50'}
              padding="0">
              {t('placeholders.load_more')}
            </Button>
          )}
        </>
      )}

      {placeType === 'sell' &&
        sellParkingStatus === 'success' &&
        sellParkingData?.data?.results.map((item: any) => (
          <ParkingItem
            key={item.id}
            onClick={handleEdit}
            street={item.street}
            house={item.house}
            auto={item.carClass}
            id={item.id}
            price={item.price}
            status={item.status}
            createdDate={item.createdDate}
            src={getImageUrl(item.files)}
            message={item?.message}
            parkingName={item.parkingName}
            handleRemove={handleRemove}
            currency={item.currency}
          />
        ))}
    </>
  );
};
