import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ADMIN_TOKEN_KEY, ADMIN_TOKEN_EXPIRATION_KEY } from 'Components/Admin/constants.ts';

import { Link } from 'Components/UI/Link/Link';
import { Button } from 'Components/UI/Button/Button';

import { List, ListItem } from '../style.js';

type NavigationPanelProps = {
  onClick: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NavigationPanel = ({ onClick }: NavigationPanelProps) => {
  const { t } = useTranslation(['admin']);
  const { pathname } = useLocation();
  const { url } = useRouteMatch();

  const onLogout = () => {
    localStorage.removeItem(ADMIN_TOKEN_KEY);
    localStorage.removeItem(ADMIN_TOKEN_EXPIRATION_KEY);
    onClick(false);
  };
  return (
    <List>
      <ListItem isActive={pathname === `${url}`}>
        <Link to={`${url}`}>{t('linkItems.ads')}</Link>
      </ListItem>
      <ListItem isActive={pathname === `${url}/sell`}>
        <Link to={`${url}/sell`}>{t('linkItems.ads_sell')}</Link>
      </ListItem>
      <ListItem isActive={pathname === `${url}/users`}>
        <Link to={`${url}/users`}>{t('linkItems.users')}</Link>
      </ListItem>
      <ListItem isActive={pathname === `${url}/bank`}>
        <Link to={`${url}/bank`}>{t('linkItems.requisites')}</Link>
      </ListItem>
      <ListItem>
        <Button dataTestid="edit" primary={true} width="120" height="50" onClick={onLogout}>
          {t('linkItems.logout')}
        </Button>
      </ListItem>
    </List>
  );
};
