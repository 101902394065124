import React, { useContext, Dispatch, SetStateAction, useCallback } from 'react';
import { SellParkingFilterContext } from 'context/sellParkingContext';
import { SellParkingFilterContextType } from 'context/types';
import { iconMap } from 'Constants/iconMap';

import { IconButton } from 'Components/UI/Button/Button';
import { CloseIcon } from 'assets/svg';

import { color } from 'theme';
import {
  SelectedFiltersBarContainer,
  CloseBtnContainer,
  SelectedFiltersContainer,
  SelectedFiltersIcons
} from '../style.js';

export const SellParkingSelectedFiltersBar = () => {
  const {
    eCharger,
    setECharger,
    parkingType,
    setParkingType,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    sortType,
    setSortType
  } = useContext(SellParkingFilterContext) as SellParkingFilterContextType;

  const renderSelectedFilterBtn = (name: string, onClose: Dispatch<SetStateAction<any>>) => {
    return (
      <SelectedFiltersContainer>
        <CloseBtnContainer top="-5" right="-5" isRound>
          <CloseIcon onClick={onClose} />
        </CloseBtnContainer>
        <IconButton onClick={() => {}}>
          <SelectedFiltersIcons>
            {React.createElement(iconMap[name], {
              style: { fill: `${color.blue}` }
            })}
          </SelectedFiltersIcons>
        </IconButton>
      </SelectedFiltersContainer>
    );
  };

  const handleResetPrice = useCallback(() => {
    setMinPrice('');
    setMaxPrice('');
  }, [setMaxPrice, setMinPrice]);

  return (
    <SelectedFiltersBarContainer>
      {eCharger &&
        renderSelectedFilterBtn('eCharger', () => {
          setECharger(false);
        })}

      {parkingType &&
        renderSelectedFilterBtn(parkingType, () => {
          setParkingType(null);
        })}

      {minPrice && maxPrice && (
        <div style={{ position: 'relative' }}>
          <CloseBtnContainer top="-5" right="-5" isRound>
            <CloseIcon onClick={handleResetPrice} />
          </CloseBtnContainer>
          <IconButton onClick={() => {}} name={`${minPrice} - ${maxPrice}`}>
            {'₴'}
          </IconButton>
        </div>
      )}

      {sortType !== 'CHEEP' &&
        renderSelectedFilterBtn(sortType, () => {
          setSortType('CHEEP');
        })}
    </SelectedFiltersBarContainer>
  );
};
