import * as yup from "yup";

export const regex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/;
export const emailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const paths = {
  parking: 'parkings-for-rent',
  oneparking: 'parking-places',
  createparking: 'createparking',
  partnerprofile: 'partnerprofile',
  admin: 'admin',
  forgotPassword: 'forgotpassword',
  resetPassword: 'resetpassword',
  feedback: 'feedback',
  emailConfirmed: 'emailconfirmed',
  emailConfirmationDenied: 'validation-link-is-invalid',
  editParking: 'editparking',
  bookingConfirmation: 'booking/confirmation',
  aboutUs: 'about-us',
  contacts: 'contacts',
  conditions: 'conditions',
  privacyPolicy: 'privacy-policy',
  partners: 'partner',
  sellParkingPlace: 'sell-parking-place',
  parkingsSale: 'parkings-for-sale',
  selectedParkingForSale: 'selected-parking-for-sale',
  editParkingForSale: 'edit-parking-for-sale'
};

export const GOOGLE_API_KEY = 'AIzaSyBGUkiJRuqIN3F61CuPhZmKVhTbgzZHYK4';

export const BASE_URL = `${
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_DEV_URL
    : process.env.REACT_APP_API_URL
}`;

export const LOGIN_API_URL = `${BASE_URL}/api/auth/login`;
export const LOGIN_ADMIN_API_URL = `${BASE_URL}/api/auth/admin/login`;
export const REGISTER_API_URL = `${BASE_URL}/api/v2/auth/register`;
export const REGISTER_API_URL_TEST = `${BASE_URL}/api/v2/auth/register-test`;
export const PHONE_VERIFICATION_API_URL = `${BASE_URL}/api/phone-verification/verify`;
export const PHONE_VERIFICATION_API_URL_TEST = `${BASE_URL}/api/phone-verification/verify-test`;
export const PARKING_PLACES_API_URL = `${BASE_URL}/api/parking-places`;
export const PARKING_PLACES_BY_LOCATION_API_URL = `${BASE_URL}/api/parking-places`;
export const NEW_PARKING_PLACE_API_URL = `${BASE_URL}/api/parking-places/create-parking-place`;
export const GET_ONE_PARKING_PLACE_URL = `${BASE_URL}/api/parking-places/byId?id=`;
export const BOOK_PARKING_PLACE_URL = `${BASE_URL}/api/parking-booking/book`;
export const POST_CITY_URL = `${BASE_URL}/api/places/create-city`;
export const GET_CITY_BY_TITLE_URL = `${BASE_URL}/api/places/cityByTitle`;
export const GET_CITY_BY_ID_URL = `${BASE_URL}/api/places/cityById`;
export const GET_PARKINGS_ONDEMAND_URL = `${BASE_URL}/api/parking-booking/get-requests?limit=${10}&page=${1}`;
export const APPROVE_PARKING_URL = `${BASE_URL}/api/parking-booking/request-approve`;
export const GET_MY_PARKINGS_URL = `${BASE_URL}/api/parking-places/my-parking-places?limit=${10}&page=`;
export const ADD_BOOKING_DAYS = `${BASE_URL}/api/parking-booking/add-booking-days`;
export const GET_DAYS = `${BASE_URL}/api/parking-booking/get-days`;
export const GET_MY_PARKINGS = `${BASE_URL}/api/parking-places/my-parking-places`;
export const ADD_DISCOUNT = `${BASE_URL}/api/discount/add`;
export const GET_DISCOUNTS = `${BASE_URL}/api/discount/get`;
export const GET_COMMISSIONS_REQUESTS = `${BASE_URL}/api/parking-booking/commission-requests`;
export const GET_REQUESTS = `${BASE_URL}/api/parking-booking/get-requests`;
export const GET_RATINGS = `${BASE_URL}/api/v2/review`;
export const GET_USERS_URL = `${BASE_URL}/api/users/admin/list`;
export const CHANGE_STATUS_URL = `${BASE_URL}/api/parking-places/change-status-parking-place`;
export const BLOCK_USER_URL = `${BASE_URL}/api/users/admin/blockUserById`;
export const GET_REVIEWS_BY_PARKING_ID = `${BASE_URL}/api/parking-review/get-reviews-by-parking-place?limit=10&page=`;
export const POST_REVIEW_URL = `${BASE_URL}/api/parking-review/add`;
export const RESET_PASSWORD_URL = `${BASE_URL}/api/users/restore-password`;
export const UPDATE_PARKING_PLACE_URL = `${BASE_URL}/api/parking-places/update-parking-place`;
export const DELETE_FILE_URL = `${BASE_URL}/api/parking-place-files/remove-file`;
export const ADD_PARKING_FILES_URL = `${BASE_URL}/api/aws-files/aws-upload-files`;
export const GET_MY_PROFILE_URL = `${BASE_URL}/api/users/me`;
export const REFRESH_TOKEN_URL = `${BASE_URL}/api/auth/refreshTokens`;
export const GET_PARKING_REVIEWS_URL = `${BASE_URL}/api/parking-review/get-reviews-by-parking-place`;
export const SET_NEW_PASSWORD_URL = `${BASE_URL}/api/users/restore-new-password`;
export const DELETE_DISCOUNT_URL = `${BASE_URL}/api/discount/delete`;
export const NOT_ARRIVED_URL = `${BASE_URL}/api/parking-booking/not-arrived`;
export const DELETE_PARKING_URL = `${BASE_URL}/api/v2/parking-places/remove-parking-place`;
export const CHECK_AVAILABILITY_URL = `${BASE_URL}/api/parking-places/check-availability`;
export const MAIN_IMAGE_URL = `${BASE_URL}/api/parking-place-files/make-main-image`;
export const UPDATE_EMAIL_URL = `${BASE_URL}/api/users/update-mail`;
export const CHANGE_BANK_INFO_URL = `${BASE_URL}/api/transactions/change-bank-info`;
export const GET_BANK_INFO_URL = `${BASE_URL}/api/transactions/get-bank-info`;
export const BALANCE_POPUP_URL = `${BASE_URL}/api/transactions/popup`;
export const GET_USER_URL = `${BASE_URL}/api/users/user-by-id`;
export const BOOKING_CONFIRMATION_URL = `${BASE_URL}/api/parking-booking/arrived`;
export const PART_OF_PATH_TO_CUT = `${process.env.REACT_APP_PART_OF_PATH_TO_CUT}`;
export const USER_APPROVED_URL = `${BASE_URL}/api/parking-booking/user-approved`;
export const TEST_PHONE_NUMBER = `${process.env.REACT_APP_TEST_PHONE_NUMBER}`;
export const TEST_PHONE_NUMBER_2 = `${process.env.REACT_APP_TEST_PHONE_NUMBER_2}`;
export const TEST_SMS = `${process.env.REACT_APP_TEST_SMS}`;
export const DELETE_PARKING_BY_ID_URL = `${BASE_URL}/api/parking-places/total-remove-parking-place`;
export const DELETE_PARKINGS_BY_USER_ID_URL = `${BASE_URL}/api/parking-places/remove-user-places`;
export const DELETE_USER_BY_ID_URL = `${BASE_URL}/api/users/remove-user-after-test`;
export const TOTAL_DELETE_USER_BY_ID_URL = `${BASE_URL}/api/users/total-remove-user`;
export const VALIDATE_FEEDBACK_TOKEN = `${BASE_URL}/api/parking-review/check-token?token=`;
export const GET_COUNTRIES_LIST = `${BASE_URL}/api/countries`;
export const GET_ALL_CUSTOMERS_BOOKINGS_URL = `${BASE_URL}/api/parking-booking/requests`;
export const CHECK_RESTORE_PASSWORD_TOKEN_URL = `${BASE_URL}/api/v2/password-restore/check-token`;
export const UPDATE_LANGUAGE_SELECTION_URL = `${BASE_URL}/api/users`;
export const PARKING_PLACES_FOR_SALE_API_URL = `${BASE_URL}/api/sales`;
export const NEW_PARKING_PLACE_FOR_SALE_API_URL = `${BASE_URL}/api/sales`;
export const MY_PARKING_PLACE_FOR_SALE_API_URL = `${BASE_URL}/api/sales/my`;
export const DELETE_PARKING_PLACE_FOR_SALE_API_URL = `${BASE_URL}/api/sales`;
export const USER_PARKING_PLACE_FOR_SALE_API_URL = `${BASE_URL}/api/user`;
export const GET_USER_BILLS_API_URL = `${BASE_URL}/api/finance/bills`;
export const GET_PARTNER_BILLS_API_URL = `${BASE_URL}/api/users`;
export const GET_AVAILABLE_MONTHS_YEARS = `${BASE_URL}/api/parking-booking/commission-requests/months`;
export const GET_ALL_CUSTOMERS_AVAILABLE_MONTHS_YEARS = `${BASE_URL}/api/parking-booking/requests/months`;

export const ADMIN_EMAIL = `${process.env.REACT_APP_ADMIN_EMAIL}`;
export const ADMIN_PASSWORD = `${process.env.REACT_APP_ADMIN_PASSWORD}`;

export const LANDLORD_NAME = `${process.env.REACT_APP_LANDLORD_NAME}`;
export const LANDLORD_SURNAME = `${process.env.REACT_APP_LANDLORD_SURNAME}`;
export const LANDLORD_PHONE = `${process.env.REACT_APP_LANDLORD_PHONE}`;
export const LANDLORD_EMAIL = `${process.env.REACT_APP_LANDLORD_EMAIL}`;
export const LANDLORD_PASSWORD = `${process.env.REACT_APP_LANDLORD_PASSWORD}`;

export const loginSchema = yup.object().shape({
  email: yup.lazy((value) => {
    if (value.length <= 11 && !isNaN(value)) {
      return yup.string().email('Invalid number format').required();
    }
    if (value.includes('+')) {
      // eslint-disable-next-line no-useless-escape
      return yup.string('[+]d{2}[(]d{2}[)]d{4}[-]d{4}').required();
    }
    return yup.string().email().required();
  }),
  password: yup.string().required()
});

export const emailSchema = yup.object().shape({
  email: yup.lazy((value) => {
    return yup.string().email().required();
  })
});

export const phoneVerificationSchema = yup.object().shape({
  code: yup.string().required()
});

export const discountVerificationSchema = yup.object().shape({
  range: yup.number().required(),
  discount: yup.number().required()
});

export const feedbackSchema = yup.object().shape({
  feedback: yup.string().required()
});

export const IS_SELL_PARKING_AVAILABLE = true;