import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { SellParkingFilterContext } from 'context/sellParkingContext';
import { SellParkingFilterContextType } from 'context/types';
import { ParkingsListPropsType } from '../types';
import { SingleParkingType } from 'types/parkingTypes';
import { paths } from 'config';
import { RootState } from 'Redux/store';
import { useRefs } from 'hooks/useRefs';
import { getImageUrl } from './ParkingsList';

import { ParkingCard } from 'Components/ParkingCard/ParkingCard';
import { Button } from 'Components/UI/Button/Button';

import { StyledNotification } from 'Components/Profile/OnDemand/style';
import { InvisibleLink, LoadMoreContainer } from '../style.js';

export const SellParkingsList = ({
  data,
  fetchNextPage,
  isFetching,
  hasNextPage,
  searchRangeRadius
}: ParkingsListPropsType) => {
  const { t } = useTranslation(['common']);
  const { coordinates, setHoveredCard, hoveredPin } = useContext(
    SellParkingFilterContext
  ) as SellParkingFilterContextType;
  const chosenLabel = useSelector(
    (state: RootState) => state.citySearchValue.searchValue.chosenLabel
  );
  const { refs, setRefFromKey } = useRefs();

  const handleHover = useCallback(
    (id) => {
      setHoveredCard(id);
    },
    [setHoveredCard]
  );

  const handleHoverOut = useCallback(() => {
    setHoveredCard(null);
  }, [setHoveredCard]);

  useEffect(() => {
    if (hoveredPin !== -1) {
      //@ts-expect-error
      refs[hoveredPin].scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [hoveredPin, refs]);

  if (!Boolean(data[0].data.results.length))
    return <StyledNotification>{t('notifications.no_searching_results_sell')}</StyledNotification>;

  return (
    <>
      {data.map((page, i) => {
        return (
          <React.Fragment key={page.nextPage + i}>
            {page.data.results.map((item: SingleParkingType) => {
              const imageUrl = getImageUrl(item?.files);
              const link = `/${paths.selectedParkingForSale}?parkingId=${item.id}&searchLat=${coordinates.lat}&searchLng=${coordinates.lng}&chosenLabel=${chosenLabel}&searchRangeRadius=${searchRangeRadius}`;

              return (
                <InvisibleLink
                  key={item.id}
                  target="_blank"
                  rel="noopener noreferrer"
                  to={link}
                  ref={setRefFromKey(item.id)}>
                  <ParkingCard
                    onMouseOver={handleHover}
                    onMouseOut={handleHoverOut}
                    onClick={() => {}}
                    id={item.id}
                    street={item.street}
                    house={item.house}
                    pricePerDay={item.pricePerDay}
                    price={item.price}
                    carClass={item.carClass}
                    status={item.bookingType}
                    images={imageUrl}
                    key={item.id}
                    rate={item.rate}
                    parkingName={item.parkingName}
                    handleBookParking={() => {}}
                    isAccent={hoveredPin === item.id}
                    security={item.security}
                    electricCharge={item.electricCharge}
                    parkingStatus={item.status}
                    parkingType={item.parkingType}
                    currency={item.currency}
                  />
                </InvisibleLink>
              );
            })}
          </React.Fragment>
        );
      })}

      {hasNextPage && (
        <LoadMoreContainer>
          <Button
            primary={true}
            onClick={() => fetchNextPage()}
            width="200"
            height={isMobile ? '40' : '50'}
            padding="0"
            margin_right="auto"
            margin_left="auto">
            {t('placeholders.load_more')}
            {isFetching && (
              <FontAwesomeIcon
                icon={faSync}
                className="fa-spin"
                style={{ marginLeft: '20px', color: '#ffffff' }}
              />
            )}
          </Button>
        </LoadMoreContainer>
      )}
    </>
  );
};
