import React, { FC, useState, useContext, useEffect, Dispatch } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  geocodeByLatLng,
  getLatLng
} from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { SearchIcon, GeoPositionDetectIcon } from 'assets/svg';
import {
  SearchCont,
  IconWrapper,
  EmptySearchCont,
  SearchLocationCont,
  IconLocationWrapper,
  DetectPositionButton,
  IconWrapperCreate
} from './style';
import { MapContext } from 'App';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchValue } from 'Redux/reducers/citySearch/citySearchReducer';
import { getCityByLocationId } from 'api/cities';
import { toast } from 'react-toastify';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';
import { color } from 'theme';
import { CoordinatesType } from 'types/parkingTypes';
import { RootState } from 'Redux/store';
import { getRangeRadius } from 'helpers/getRangeRadius.js';

type GoogleLocationAutoCompleteProps = {
  isDetectPosition?: boolean;
  setNewCoordinates?: Dispatch<SetStateAction<CoordinatesType>>;
  setRadius?: Dispatch<SetStateAction<number>>;
  setLocation?: Dispatch<SetStateAction<string>>;
  newLocation?: string;
};

const DEFAULT_RANGE_DISTRICT_SIZE = 3565.8049691378988;

export const GoogleLocationAutoComplete: FC<GoogleLocationAutoCompleteProps> = ({
  isDetectPosition = false,
  setNewCoordinates,
  setRadius,
  setLocation,
  newLocation
}) => {
  const { t } = useTranslation(['common']);
  const selectLocation = t('placeholders.select_location');
  const [value, setValue] = useState(null);
  const [label, setLabel] = useState<string>(newLocation || selectLocation);
  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false);
  const dispatch = useDispatch();
  const searchValue = useSelector((state: RootState) => state.citySearchValue.searchValue);
  const chosenCoordinates = useSelector(
    (state: RootState) => state.citySearchValue.searchValue.coordinates
  );
  const searchRangeRadius = useSelector(
    (state: RootState) => state.citySearchValue.searchValue.range
  );

  useEffect(() => {
    if (searchValue && searchValue?.value !== null) {
      //@ts-expect-error
      setLabel(searchValue?.value?.label);
    } else {
      setLabel(newLocation || selectLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchValue?.value]);

  useEffect(() => {
    if (
      searchRangeRadius &&
      chosenCoordinates &&
      Boolean(setRadius) &&
      Boolean(setNewCoordinates)
    ) {
      setRadius(searchRangeRadius);
      setNewCoordinates(chosenCoordinates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRangeRadius, chosenCoordinates]);

  const getAddressLabelByCoordinates = ({ lat, lng }: CoordinatesType) => {
    geocodeByLatLng({ lat, lng })
      .then((results) => {
        if (!!results.length) {
          const addressLabel = results[0].formatted_address;
          const searchRadius =
            results[0].geometry.viewport &&
            getRangeRadius(
              results[0].geometry.viewport.getNorthEast(),
              results[0].geometry.viewport.getSouthWest()
            );
          setLabel(addressLabel);
          dispatch(
            setSearchValue({
              coordinates: {
                lat: lat,
                lng: lng
              },
              chosenLabel: addressLabel,
              value: { label: addressLabel },
              range: searchRadius || DEFAULT_RANGE_DISTRICT_SIZE
            })
          );
        }
      })
      .catch((error) => {
        toast.error(`ERROR(${error.code}): ${error.message}`, NOTIFICATION_OPTIONS);
      });
  };

  const handleSearch = async (value: any) => {
    setValue(value);
    const results = await geocodeByPlaceId(value.value.place_id);
    const range =
      !!results.length &&
      results[0] &&
      results[0].geometry.viewport &&
      getRangeRadius(
        results[0].geometry.viewport.getNorthEast(),
        results[0].geometry.viewport.getSouthWest()
      );
    const chosenLabel = results[0].formatted_address;
    const coordinates = await getLatLng(results[0]);
    dispatch(setSearchValue({ value, coordinates, chosenLabel, range }));
    setLabel(chosenLabel);

    if (setLocation) {
      setLocation(chosenLabel);
    }
  };

  const getMyPosition = () => {
    if (!navigator.geolocation) {
      toast.error(t('notifications.not_support_position'), NOTIFICATION_OPTIONS);
    } else {
      setIsButtonDisable(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getAddressLabelByCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
          setIsButtonDisable(false);
        },
        (error) => {
          toast.error(`ERROR(${error.code}): ${error.message}`, NOTIFICATION_OPTIONS);
          setIsButtonDisable(false);
        }
      );
    }
  };

  const loaded = useContext(MapContext);

  if (!loaded) return null;

  return (
    <SearchLocationCont>
      <IconLocationWrapper>
        <SearchIcon />
      </IconLocationWrapper>
      <GooglePlacesAutocomplete
        selectProps={{
          placeholder: label,
          value,
          onInputChange: (value: any) => {
            setValue(value);
          },
          onChange: handleSearch,

          styles: {
            container: (provided) => ({
              ...provided,
              width: '100%'
            }),
            input: (provided) => ({
              ...provided,
              border: 'none',
              outline: 'none',
              height: '40px',
              marginLeft: '40px',
              lineHeight: '40px',
              background: 'none',
              color: `${color.blue}`
            }),
            control: (provided) => ({
              ...provided,
              border: 'none',
              outline: 'none',
              borderRadius: '6px',
              height: '50px',
              background: 'none',
              borderColor: 'transparent',
              boxShadow: 'none'
            }),
            placeholder: (provided) => ({
              ...provided,
              marginLeft: '40px',
              color: `${color.blue}`
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: 'none'
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              display: 'none'
            }),
            singleValue: (provided) => ({
              ...provided,
              marginLeft: '40px'
            })
          }
        }}
      />
      {isDetectPosition && (
        <DetectPositionButton disabled={isButtonDisable} onClick={getMyPosition}>
          <GeoPositionDetectIcon />
        </DetectPositionButton>
      )}
    </SearchLocationCont>
  );
};

export const GoogleCityAutoCompleteCreate = ({
  setChosenCity,
  error
}: {
  setChosenCity: React.Dispatch<React.SetStateAction<{ [key: string]: any } | null>>;
  error: boolean;
}) => {
  const { t } = useTranslation(['common']);
  const [cityInputValue, setCityInputValue] = useState<any>(null);

  const handleSearch = async (value: any) => {
    setCityInputValue(value);
    const results = await geocodeByPlaceId(value.value.place_id);
    const formattedCityAddress = results[0].formatted_address;
    const placeId = value.value.place_id;
    const cityTitle = results[0].address_components[0].long_name;
    const serverCity = await getCityByLocationId(results[0].place_id);
    let serverCityId;
    const isSelected = !!results;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    serverCity ? (serverCityId = serverCity.id) : 0;
    const coordinates = await getLatLng(results[0]);
    setChosenCity({
      coordinates,
      placeId,
      cityTitle,
      serverCityId,
      isSelected,
      formattedCityAddress
    });
  };

  const loaded = useContext(MapContext);

  if (!loaded) return null;
  return (
    <SearchCont
      style={{
        width: isMobile ? '300px' : '350px',
        border: `${error ? `1px solid ${color.red}` : 'none'}`,
        borderRadius: '8px'
      }}>
      <IconWrapperCreate>
        <SearchIcon />
      </IconWrapperCreate>

      <GooglePlacesAutocomplete
        autocompletionRequest={{
          types: ['(cities)']
        }}
        selectProps={{
          value: cityInputValue,
          placeholder: t('placeholders.city'),
          onInputChange: (value: any) => {
            if (typeof value === 'string' && value?.length > 0) {
              setChosenCity(null);
              setCityInputValue(value);
            }
          },
          onChange: handleSearch,

          styles: {
            input: (provided) => ({
              ...provided,
              border: 'none',
              height: '40px',
              marginLeft: '40px',
              lineHeight: '40px'
            }),
            control: (provided) => ({
              ...provided,
              border: 'none',
              borderRadius: '8px',
              height: '48px',
              boxSizing: 'border-box',
              outline: 'none'
            }),
            placeholder: (provided) => ({
              ...provided,
              marginLeft: '40px'
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: 'none'
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              display: 'none'
            }),
            singleValue: (provided) => ({
              ...provided,
              marginLeft: '40px'
            })
          }
        }}
      />
    </SearchCont>
  );
};

export const GoogleAutoCompleteStreet = ({
  setStreet,
  city,
  handleCoordinates,
  defaultValue,
  error
}: {
  setStreet: React.Dispatch<React.SetStateAction<string>>;
  city?: string;
  handleCoordinates: (coordinates: any) => void;
  defaultValue?: string;
  error: boolean;
}) => {
  const { t } = useTranslation(['common']);
  const [streetInputValue, setStreetInputValue] = useState<any>(null);
  const [cityLatLng, setCityLatLng] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    if (!city && !defaultValue) {
      setStreet('');
      setStreetInputValue('');
      handleCoordinates(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, defaultValue]);

  const handleSearch = async (value: any) => {
    setStreetInputValue(value);
    if (typeof value === 'object') {
      const selectedCity = city?.split(',').map((word) => word.trim())[0];
      const selectedCityLabel = value?.label?.split(',').map((word) => word.trim());
      const isMatch = selectedCityLabel.some((substring) => substring === selectedCity);
      const isMatchWidely = selectedCityLabel.some((substring) => substring.includes(selectedCity));

      if (isMatch || isMatchWidely) {
        setStreet(value.value.structured_formatting.main_text.split(',')[0]);
        const results = await geocodeByAddress(value?.label);

        const latLng = await getLatLng(results[0]);
        handleCoordinates(latLng);
      } else {
        handleCoordinates(null);
        setStreetInputValue('');
        toast.error(t('notifications.street_in_city'), NOTIFICATION_OPTIONS);
      }
    }
  };

  const loaded = useContext(MapContext);

  useEffect(() => {
    if (loaded && city) {
      (async function () {
        const results = await geocodeByAddress(city);
        const latLng = await getLatLng(results[0]);
        setCityLatLng(latLng);
        const sw = results[0].geometry.bounds?.getSouthWest();
        const ne = results[0].geometry.bounds?.getNorthEast();
        setBounds([
          { lat: sw?.lat(), lng: sw?.lng() },
          { lat: ne?.lat(), lng: ne?.lng() }
        ]);
      })();
    }
  }, [city, loaded, streetInputValue]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bounds, setBounds] = useState<any>();

  // TODO: Do we need it now?
  // useEffect(() => {
  //   if (streetInputValue && !city) {
  //     toast.error(t('notifications.city_and_street_in_city'), NOTIFICATION_OPTIONS);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [streetInputValue, city]);

  // TODO: Find out what exactly is checking here? Do we need it now?
  // useEffect(() => {
  //   const chosenCity = city?.split(',');
  //   if (streetInputValue && chosenCity) {
  //     if (streetInputValue?.value?.terms[1]?.value !== chosenCity[0]) {
  //       toast.error(t('notifications.street_in_city'), NOTIFICATION_OPTIONS);
  //       setStreetInputValue(null);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [city, streetInputValue, streetInputValue?.value?.terms]);

  return (
    <SearchCont style={{ width: isMobile ? '300px' : '350px' }}>
      {!!city ? (
        <>
          <IconWrapper>
            <SearchIcon />
          </IconWrapper>
          <GooglePlacesAutocomplete
            autocompletionRequest={{
              types: ['address'],
              location: cityLatLng,
              radius: 20
            }}
            selectProps={{
              value: streetInputValue,
              defaultInputValue: defaultValue,
              placeholder: t('placeholders.enter_street'),
              onChange: handleSearch,
              styles: {
                input: (provided) => ({
                  ...provided,
                  border: 'none',
                  height: '40px',
                  marginLeft: '40px',
                  lineHeight: '40px'
                }),
                control: (provided) => ({
                  ...provided,
                  border: 'none',
                  borderRadius: '6px',
                  height: '50px',
                  outline: 'none'
                }),
                placeholder: (provided) => ({
                  ...provided,
                  marginLeft: '40px'
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: 'none'
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  display: 'none'
                }),
                singleValue: (provided) => ({
                  ...provided,
                  marginLeft: '40px'
                })
              }
            }}
          />
        </>
      ) : (
        <EmptySearchCont
          style={{
            width: isMobile ? '300px' : '350px',
            border: `${error ? '1px solid red' : 'none'}`
          }}>
          {t('placeholders.choose_city_first')}
        </EmptySearchCont>
      )}
    </SearchCont>
  );
};
