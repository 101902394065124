import styled from "styled-components";
import { StylesConfig } from 'react-select';

import { color, fontSize, device } from 'theme';

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
  display: ${(props) => (props.show ? 'none' : 'block')};
`;

export const Backdrop = styled.div`
  display: ${(props) => (props.show ? 'none' : 'block')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 500;
`;

export const StyledModal = styled.div`
  width: 300px;
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  border-radius: 10px;
  padding: 10px;

  @media ${device.tablet} {
    width: 390px;
    padding: 20px;
  }
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: ${(props) => (props.centered ? 'center' : 'space-between')};
  padding: 0.3rem;
  margin-bottom: 20px;
`;

export const HeaderText = styled.p`
  color: ${color.blue};
  font-size: ${fontSize.medium};
  line-height: 21px;
  font-weight: 500;

  @media ${device.tablet} {
    font-size: ${fontSize.large};
  }
`;

export const StyledText = styled(HeaderText)`
  margin-top: 20px;
  cursor: pointer;
`;

export const CloseButton = styled.button`
  font-size: 0.8rem;
  border: none;
  border-radius: 3px;
  margin-left: 0.5rem;
  background: none;
  :hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: ${(props) => `${props.height}px`};
`;

export const LinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const ErrorsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const LinkContainerLeft = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 20px;
  align-items: center;
`;

export const AgreeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const AgreeNote = styled.span`
  font-size: ${fontSize.regular};
  color: ${color.greayText};
  line-height: 16px;
  padding-left: 10px;
`;

export const StyledP = styled.p`
  text-align: center;
  font-size: ${fontSize.medium};
  line-height: 18px;
  color: ${color.greayText};
  margin-bottom: 20px;

  @media ${device.tablet} {
    font-size: ${fontSize.large};
    line-height: 21px;
  }
`;

export const IconWrapper = styled.div`
  width: 164px;
  height: 164px;
  margin: 0 auto;
  margin-bottom: 40px;
`;

export const TimerContainer = styled.div`
  color: ${color.greayText};
  margin-left: 15px;
  margin-top: 10px;
  line-height: 16px;
  font-weight: 400;
  font-size: ${fontSize.regular};
`;

export const StyledI = styled.i`
  position: absolute;
  top: 26px;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
`;

export const BackHomeCont = styled.div`
  margin-bottom: 15px;
`;

export const ArrowContainer = styled.div`
  width: 40px;
  height: 20px;
  margin-bottom: 15px;
  cursor: pointer;
`;

export const colorStyles: StylesConfig<ColourOption> = {
  control: (styles) => ({
    ...styles,
    backgroundColor: `${color.backgroundGrey}`,
    color: `${color.greayText}`,
    outline: 'none',
    borderRadius: '10px',
    border: 'none',
    height: '50px',
    boxShadow: 'none',
    fontSize: `${fontSize.regular}`
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? `${color.backgroundGrey}`
        : isFocused
        ? `rgba(51, 51, 51, 0.3)`
        : `${color.backgroundGrey}`,
      color: isDisabled ? '#ccc' : `${color.greayText}`,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? `${color.backgroundGrey}`
            : `${color.backgroundGrey}`
          : `${color.backgroundGrey}`
      }
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles })
};
