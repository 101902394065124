import React from 'react';

export const FlagIconPoland = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 512 512"
      {...props}>
      <g fillRule="nonzero">
        <path
          fill="#999"
          d="M256 0c70.68 0 134.69 28.66 181.01 74.99C483.34 121.31 512 185.32 512 256c0 70.68-28.66 134.69-74.99 181.01C390.69 483.34 326.68 512 256 512c-70.68 0-134.69-28.66-181.01-74.99C28.66 390.69 0 326.68 0 256c0-70.68 28.66-134.69 74.99-181.01C121.31 28.66 185.32 0 256 0z"></path>
        <path
          fill="#fff"
          d="M256 19.48c65.3 0 124.46 26.48 167.25 69.27l1.09 1.18c42.14 42.71 68.18 101.37 68.18 166.06 0 65.31-26.5 124.46-69.29 167.25l-1.18 1.09c-42.73 42.16-101.4 68.19-166.05 68.19-65.23 0-124.37-26.51-167.18-69.33-42.84-42.74-69.33-101.89-69.33-167.2 0-65.31 26.48-124.45 69.27-167.24C131.55 45.96 190.7 19.48 256 19.48z"></path>
        <path
          fill="#fff"
          d="M243.37 472.03h25.27c113.64-6.54 203.77-100.76 203.77-216.04 0-114.41-88.83-208.09-201.27-215.86h-30.28C128.43 47.9 39.6 141.58 39.6 255.99c0 115.28 90.13 209.5 203.77 216.04z"></path>
        <path
          fill="#dc143c"
          d="M243.37 472.03h25.27c113.61-6.54 203.72-100.72 203.77-215.94H39.6c.05 115.22 90.16 209.4 203.77 215.94z"></path>
      </g>
    </svg>
  );
};
