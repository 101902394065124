import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { color, fontSize, device } from 'theme';

export const Background = styled.div`
  background: ${color.backgroundGrey};
`;

export const BarContainer = styled.div`
  max-width: ${({ isWide }) => (isWide ? '100%' : '1200px')};
  margin: 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  border-radius: 0;
  position: sticky;
  top: 0;
  z-index: 5;
  border-bottom: 1px solid ${color.greayText};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  @media ${device.tablet} {
    padding: 10px;
    flex-direction: row;
    border-radius: 10px;
    position: unset;
    border-bottom: none;
    box-shadow: none;
  }

  @media ${device.laptopL} {
    padding: 20px 25px;
    height: 90px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media ${device.tablet} {
    /* width: 100%; */
    flex-direction: row;
    justify-content: start;
  }
`;

export const RightSide = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  padding-left: 4px;

  @media ${device.laptop} {
    width: 40%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  /* @media screen and (min-width: 320px) and (max-width: 767px) {
    align-items: flex-end;
  } */
`;

export const MainContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 100px;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;

  @media ${device.laptopL} {
    justify-content: space-between;
  }
`;

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
`;

export const PaginationBlock = styled.div`
  height: 100px;
`;

export const FiltersCont = styled.div`
  margin-bottom: 30px;
`;

export const SelectWrapper = styled.div`
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Select = styled.select`
  background-color: ${color.backgroundGrey};
  border-radius: 10px;
  padding: 10px;
  font-size: ${fontSize.regular};
  border: none;
  outline: none;
  width: ${(props) => (props.width ? `${props.width}` : '280px')};
  height: 50px;
  color: ${color.greayText};

  @media ${device.tablet} {
    width: ${(props) => (props.width ? `${props.width}` : '350px')};
  }
`;

export const StyledLabel = styled.label`
  font-size: ${fontSize.small};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledH1 = styled.h1`
  font-size: 24px;
  color: ${color.greayText};
  text-align: center;
`;

export const HiddenText = styled.h1`
  font-size: 0;
`;

export const WideContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 10px 10px 0 10px;
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 10px;

  @media ${device.laptop} {
    margin: 0 10px 0 0;
    width: 18px;
    height: 18px;
  }
`;

export const RightSideBar = styled.div`
  display: flex;
  gap: 20px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  z-index: 100;
  top: 58px;
  left: -84px;
  width: 320px;
  height: fit-content;

  padding: 20px 20px 10px 20px;
  background: ${color.white};
  border-radius: 4px;
  border: 2px solid ${color.backgroundGrey};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  @media ${device.tablet} {
    flex-direction: row;
    width: ${({ isWide }) => (isWide ? '560px' : '320px')};
    height: 320px;
    left: auto;
    right: 0;
  }
`;

export const CarClassFiltersContainer = styled(FiltersContainer)`
  left: -10px;
  padding: 20px 5px 10px 5px;

  @media ${device.tablet} {
    left: auto;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin-bottom: 10px;

  @media ${device.tablet} {
    border-left: 1px solid ${color.greayText};
    padding-left: 10px;
    height: fit-content;
    margin-bottom: 0;
  }
`;

export const CloseBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isRound }) => (isRound ? '20px' : '16px')};
  height: ${({ isRound }) => (isRound ? '20px' : '16px')};
  position: absolute;
  top: ${({ top }) => (top ? `${top}px` : '8px')};
  right: ${({ right }) => (right ? `${right}px` : '8px')};
  border-radius: ${({ isRound }) => (isRound ? '50%' : '0')};
  background: ${({ isRound }) => (isRound ? `${color.blue}` : 'transparent')};

  & > svg {
    width: 16px;
    height: 16px;
    cursor: pointer;

    & > path {
      fill: ${({ isRound }) => (isRound ? '#fff' : `${color.blue}`)};
    }
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 20px;
`;

export const ParkingSearchContainer = styled(WideContainer)`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 2px;
  margin-bottom: 15px;

  @media ${device.tablet} {
    padding: 0 10px;
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 2fr;
    gap: 15px;
    height: calc(100vh - 200px);
    margin-bottom: 0;
  }
`;

export const ParkingSearchResultsWrapper = styled.div`
  overflow-y: scroll;
  z-index: 1;
  background: ${color.backgroundGrey};
  display: flex;
  flex-direction: column;

  height: calc(100vh - 250px);

  @media ${device.tablet} {
    height: unset;
  }
`;

export const MapWithParkingsWrapper = styled.div`
  display: none;

  @media ${device.laptop} {
    display: block;
  }
`;

export const InvisibleLink = styled(Link)`
  all: unset;

  &:focus {
    outline: 0;
  }

  & > * {
    cursor: pointer;
  }
`;

export const FiltersCountLabel = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${color.blue};
  color: #fff;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClearFiltersBtn = styled.p`
  padding: 15px 0 0 10px;
  color: ${color.blue};
  font-size: ${fontSize.small};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  @media ${device.tablet} {
    font-size: ${fontSize.regular};
  }
`;

export const SelectedFiltersBarContainer = styled.div`
  display: none;

  @media ${device.laptopL} {
    display: flex;
    gap: 8px;
  }
`;

export const MapButtonWrapper = styled.div`
  @media ${device.laptop} {
    display: none;
  }
`;

export const MobileMapContainer = styled.div`
  position: sticky;
  top: 187px;
  z-index: 0;
  width: 370px;
  height: ${({ isOpen }) => (isOpen ? '450px' : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  margin: 0 auto;
  margin-bottom: 10px;
  transition: height 0.3s ease-in-out;

  @media ${device.laptop} {
    display: none;
  }
`;

export const SelectedFiltersContainer = styled.div`
  position: relative;
`;

export const SelectedFiltersIcons = styled.div`
  width: 32px;
  height: 32px;
  & > svg {
    width: 32px;
    height: 32px;
    fill: ${color.blue};

    & > path,
    & > g {
      fill: ${color.blue};
    }
  }
`;

export const AutoCompleteContainer = styled.div`
  width: 300px;
  margin-bottom: 5px;

  @media ${device.laptop} {
    margin-right: 10px;
    margin-bottom: 0;
  }
`;

export const DatePickerContainer = styled.div`
  width: 300px;
  margin-bottom: 5px;

  @media ${device.laptop} {
    margin-bottom: 0;
  }
`;


export const LoadMoreContainer = styled.div`
  padding: 10px 0 20px 0;
`;