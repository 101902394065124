import React from 'react';
import { useTranslation } from 'react-i18next';

import { PARKOVKI_HOSTNAMES } from 'Constants/constants';

import { ContactsWrapper, ContactsIconsContainer } from './styles.js';
import { StyledH1 } from 'Components/UI/H1/style.js';
import viber from 'assets/svg/viber.svg';
import twitter from 'assets/svg/twitter.svg';
import instagram from 'assets/svg/instagram.svg';
import facebook from 'assets/svg/facebook.svg';
import telegram from 'assets/svg/telegram.svg';
import whatsup from 'assets/svg/whatsup.svg';
import email from 'assets/svg/email.svg';

type ContactsType = {
  name: string;
  icon: React.ReactNode;
  link: string;
};

const hostname = window.location.hostname;
const isParkovkiMd = hostname === PARKOVKI_HOSTNAMES.MD;

const fullVersionIcons: ContactsType[] = [
  {
    name: 'whatsup',
    icon: <img src={whatsup} alt="viber" />,
    link: isParkovkiMd ? 'https://wa.me/+37360605638' : 'https://wa.me/+380982208925'
  },
  {
    name: 'viber',
    icon: <img src={viber} alt="viber" />,
    link: isParkovkiMd ? 'viber://chat?number=+37360605638' : 'viber://chat?number=+380631001128'
  },
  {
    name: 'telegram',
    icon: <img src={telegram} alt="telegram" />,
    link: isParkovkiMd ? 'https://t.me/parkovki_md' : 'https://t.me/Parkovki_ua'
  },
  {
    name: 'email',
    icon: <img src={email} alt="email" />,
    link: 'mailto:Parkovki.service@gmail.com'
  },
  {
    name: 'instagram',
    icon: <img src={instagram} alt="instagram" />,
    link: isParkovkiMd
      ? 'https://www.instagram.com/parkovki.md'
      : 'https://www.instagram.com/parkovki.ua'
  },
  {
    name: 'twitter',
    icon: <img src={twitter} alt="twitter" />,
    link: 'https://twitter.com/ParkovkiUA'
  },
  {
    name: 'facebook',
    icon: <img src={facebook} alt="facebook" />,
    link: isParkovkiMd
      ? 'https://www.facebook.com/parkovki.md'
      : 'https://www.facebook.com/parkovki.ua'
  }
];

export const smallVersionIcons: ContactsType[] = [
  {
    name: 'twitter',
    icon: <img src={twitter} alt="twitter" />,
    link: 'https://twitter.com/ParkovkiUA'
  },
  {
    name: 'instagram',
    icon: <img src={instagram} alt="instagram" />,
    link: isParkovkiMd
      ? 'https://www.instagram.com/parkovki.md'
      : 'https://www.instagram.com/parkovki.ua'
  },
  {
    name: 'viber',
    icon: <img src={viber} alt="viber" />,
    link: isParkovkiMd ? 'viber://chat?number=+37360605638' : 'viber://chat?number=+380631001128'
  },
  {
    name: 'facebook',
    icon: <img src={facebook} alt="facebook" />,
    link: isParkovkiMd
      ? 'https://www.facebook.com/parkovki.md'
      : 'https://www.facebook.com/parkovki.ua'
  },
  {
    name: 'telegram',
    icon: <img src={telegram} alt="telegram" />,
    link: isParkovkiMd ? 'https://t.me/parkovki_md' : 'https://t.me/Parkovki_ua'
  }
];

type ContactsModulePropsType = {
  isFullContacts: boolean;
};

export const generateContactsIcons = (iconsArray: ContactsType) => (
  <>
    {iconsArray.map(({ name, icon, link }: ContactsType) => (
      <a href={link} key={name} target="_blank" rel="noreferrer">
        {icon}
      </a>
    ))}
  </>
);

export const ContactsModule = ({ isFullContacts = true }: ContactsModulePropsType) => {
  const { t } = useTranslation(['common']);

  return (
    <ContactsWrapper>
      <StyledH1>{t('titles.contact_page')}</StyledH1>
      <ContactsIconsContainer>{generateContactsIcons(fullVersionIcons)}</ContactsIconsContainer>
    </ContactsWrapper>
  );
};
