import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'react-dates';
import { paths, IS_SELL_PARKING_AVAILABLE } from 'config';
import 'react-dates/initialize';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dates/lib/css/_datepicker.css';
import './style.css';
import {
  ParkingSearchWrapper,
  ParkingSearchContainer,
  DateCont,
  IconContainer,
  SecondCalendarWrapper,
  TabButton,
  TabButtonContainer
} from './style';
import { Button } from 'Components/UI/Button/Button';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { GoogleLocationAutoComplete } from 'Components/GoogleAutoComplete/GoogleAutoCompleteSearch';
import { ChevronRightIcon, CalendarIcon } from 'assets/svg';
import { RootState } from 'Redux/store';
import { StyledH2 } from 'Components/UI/H2/style.js';

type ParkingTypeSearch = 'rent' | 'sell';

export const ParkingSearch = () => {
  const { t, i18n } = useTranslation(['common']);
  const history = useHistory();
  const chosenCoordinates = useSelector(
    (state: RootState) => state.citySearchValue.searchValue.coordinates
  );
  const searchRangeRadius = useSelector(
    (state: RootState) => state.citySearchValue.searchValue.range
  );

  const chosenLocation = useSelector(
    (state: RootState) => state.citySearchValue?.searchValue?.value?.label
  );

  const [startDate, setStartDate] = useState(moment().locale(i18n.language));
  const [endDate, setEndDate] = useState(moment().add(1, 'd').locale(i18n.language));
  const [focusedInput, setFocusedInput] = useState(null);
  const [parkingSearchType, setParkingSearchType] = useState<ParkingTypeSearch>('rent');

  const sendRequest = () => {
    if (parkingSearchType === 'rent' && (!startDate || !endDate)) return;

    if (parkingSearchType === 'rent') {
      const dateFrom = startDate.format('MM/DD/yyyy');
      const dateTo = endDate.format('MM/DD/yyyy');

      history.push(
        `${paths.parking}?lat=${chosenCoordinates.lat}&lng=${chosenCoordinates.lng}&searchRadius=${searchRangeRadius}&searchingLocation=${chosenLocation}&from=${dateFrom}&to=${dateTo}`
      );
    } else {
      history.push(
        `${paths.parkingsSale}?lat=${chosenCoordinates.lat}&lng=${chosenCoordinates.lng}&searchRadius=${searchRangeRadius}&searchingLocation=${chosenLocation}`
      );
    }
  };

  const handleChangeSearch = useCallback((event: React.MouseEvent<HTMLElement>) => {
    const value = event.target.value;
    setParkingSearchType(value);
  }, []);

  return (
    <ParkingSearchWrapper>
      {IS_SELL_PARKING_AVAILABLE ? (
        <TabButtonContainer>
          <TabButton
            onClick={handleChangeSearch}
            value="rent"
            isDisabled={parkingSearchType === 'rent'}>
            {t('parking.rent')}
          </TabButton>
          <TabButton
            onClick={handleChangeSearch}
            value="sell"
            isDisabled={parkingSearchType === 'sell'}>
            {t('parking.sell')}
          </TabButton>
        </TabButtonContainer>
      ) : (
        <StyledH2>{t('titles.park_auto_quickly')}</StyledH2>
      )}

      <ParkingSearchContainer data-testid="search-container">
        <GoogleLocationAutoComplete isDetectPosition />
        {parkingSearchType === 'rent' && (
          <DateCont data-testid="seach-dates">
            <DateRangePicker
              startDatePlaceholderText={t('placeholders.begin')}
              endDatePlaceholderText={t('placeholders.end')}
              // renderMonthElement={({ month }) => moment(month).locale('ru').format('MMMM YYYY')}
              startDate={startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }: any) => {
                setStartDate(startDate);
                setEndDate(endDate || moment(startDate).add(1, 'd').locale(i18n.language));
              }} // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
              withFullScreenPortal={isMobile}
              orientation={isMobile ? 'vertical' : 'horizontal'}
              hideKeyboardShortcutsPanel={true}
              readOnly={true}
              customInputIcon={
                <IconContainer>
                  <CalendarIcon />
                </IconContainer>
              }
            />
            <SecondCalendarWrapper>
              <IconContainer>
                <CalendarIcon />
              </IconContainer>
            </SecondCalendarWrapper>
          </DateCont>
        )}

        <Button dataTestid="search-button" primary={true} height="50" onClick={sendRequest}>
          <p style={{ textTransform: 'uppercase', display: 'block' }}>
            {t('buttons.find_parking_places')}
          </p>
          <IconContainer style={{ top: '-4px', marginLeft: '15px' }}>
            <ChevronRightIcon />
          </IconContainer>
        </Button>
      </ParkingSearchContainer>
    </ParkingSearchWrapper>
  );
};
