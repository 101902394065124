import styled from "styled-components";

import { color } from "theme";

export const PrimaryImg = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: ${color.blue};
  padding: 2px 5px;
`;

export const MakePrimary = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: ${color.green};
  color: ${color.white};
  padding: 2px 5px;
  display: none;
  cursor: pointer;
`;


export const ImageWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : "160px")};
  height: 120px;
  margin-bottom: 10px;
  &:hover ${MakePrimary} {
    display: block;
  };
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledButtton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid ${color.red};
  outline: none;
  position: absolute;
  background: ${color.red};
  color: #ffffff;
  top: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
`;
