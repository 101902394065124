import React, { createContext, useState } from 'react';
import {
  SellParkingFilterContextType,
  SellParkingSortingTypes,
  ParkingTypes,
  CarClassTypes
} from './types';
import { CoordinatesType } from 'types/parkingTypes';

export const SellParkingFilterContext = createContext<SellParkingFilterContextType | null>(null);

export const SellParkingContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [coordinates, setCoordinates] = useState<CoordinatesType>({
    lat: 0,
    lng: 0
  });

  const [eCharger, setECharger] = useState<boolean>(false);
  const [sortType, setSortType] = useState<SellParkingSortingTypes>('CHEEP');
  const [parkingType, setParkingType] = useState<ParkingTypes | null>(null);
  const [carClass, setCarClass] = useState<CarClassTypes | null>(null);
  const [minPrice, setMinPrice] = useState<string>('');
  const [maxPrice, setMaxPrice] = useState<string>('');
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);
  const [positionDetected, setPositionDetected] = useState<boolean>(false);
  const [isMobileMapOpen, setIsMobileMapOpen] = useState<boolean>(false);
  const [hoveredPin, setHoveredPin] = useState<number>(-1);

  const clearFilters = () => {
    setECharger(false);
    setSortType('CHEEP');
    setParkingType(null);
    setMinPrice('');
    setMaxPrice('');
  };

  return (
    <SellParkingFilterContext.Provider
      value={{
        coordinates,
        setCoordinates,
        eCharger,
        setECharger,
        sortType,
        setSortType,
        parkingType,
        setParkingType,
        carClass,
        setCarClass,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        clearFilters,
        hoveredCard,
        setHoveredCard,
        positionDetected,
        setPositionDetected,
        isMobileMapOpen,
        setIsMobileMapOpen,
        hoveredPin,
        setHoveredPin
      }}>
      {children}
    </SellParkingFilterContext.Provider>
  );
};
