import React from "react";
import { Route, Redirect } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: `/admin/login`, state: { from: props.location } }}
          />
        )
      }
    />
  );
};
