import styled from "styled-components";

import { color, fontSize, device } from "theme";

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${fontSize.small};
  height: ${(props) => (props.height ? `${props.height}px` : '50px')};
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  border-radius: 8px;
  color: ${(props) => (props.primary ? '#FFFFFF' : props.color || color.blue)};
  background: ${(props) => (props.primary ? props.color || color.blue : 'transparent')};
  border: 1px solid ${(props) => props.color || color.blue};
  cursor: pointer;
  padding: ${(props) => (props.padding ? `${props.padding}px ${props.padding}px` : `5px 10px`)};
  margin-right: ${(props) => props.margin_right};
  margin-bottom: ${(props) => (props.margin_b ? `${props.margin_b}px` : '0')};
  margin-left: ${(props) => props.margin_left};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  background-color: ${(props) => props.remove && color.red};
  border-color: ${(props) => props.remove && color.red};
  color: ${(props) => props.remove && color.white};
  text-wrap: nowrap;

  & > p {
    display: none;
    color: ${(props) => (props.primary ? '#FFFFFF' : props.color || color.blue)};

    @media ${device.tablet} {
      display: unset;
    }
  }

  @media ${device.laptop} {
    font-size: ${fontSize.regular};
    padding: ${(props) => (props.padding ? `${props.padding}px ${props.padding}px` : `10px 20px`)};
    font-size: ${fontSize.medium};
  }
`;

export const StyledControlButton = styled.button`
  height: ${(props) => (props.height ? `${props.height}px` : '30px')};
  width: ${(props) => (props.width ? `${props.width}px` : '100px')};

  @media ${device.tablet} {
    height: ${(props) => (props.height ? `${props.height}px` : '50px')};
    width: ${(props) => (props.width ? `${props.width}px` : '130px')};
  }

  text-wrap: nowrap;
  border-radius: 8px;
  border: none;
  outline: none;
  color: #ffffff;
  background: ${(props) => (props.isConfirm ? color.lightGreen : color.red)};
  cursor: pointer;
  padding: ${(props) => (props.padding ? `${props.padding}px ${props.padding}px` : `10px 20px`)};
  margin-bottom: ${(props) => (props.margin ? `${props.margin}px` : 'none')};
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding: 0;
  }
`;

export const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? `${width}px` : `auto`)};
  height: ${({ height }) => (height ? `${height}px` : `50px`)};
  margin: ${({ margin }) => (margin ? margin : '0')};
  padding: ${({ padding }) => (padding ? padding : '10px')};
  outline: none;
  background: ${({ isActive }) => (isActive ? color.blue : 'transparent')};
  border-radius: 8px;
  border: 1px solid ${color.blue};
  cursor: pointer;
  opacity: ${({ isDisabled }) => (isDisabled ? `0.5` : `1`)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? `none` : `auto`)};
  color: ${color.blue};
  text-wrap: nowrap;

  & > p {
    margin-left: 5px;
    color: ${({ isActive }) => (isActive ? '#fff' : `${color.blue}`)};
    font-size: ${fontSize.small};
    display: none;

    @media ${device.tablet} {
      display: block;
    }
  }

  & > svg,
  & > img {
    display: block;
    width: 32px;
    height: 32px;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 20px 0 10px 0;

  & > p {
    color: ${color.greayText};
    font-size: ${fontSize.small};

    @media ${device.tablet} {
      font-size: ${fontSize.regular};
    }
  }
`;

export const ToggleLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  min-width: 58px;
  height: 30px;
`;

export const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const ToggleBtn = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ toggled }) => (toggled ? `${color.blue}` : `${color.greayText}`)};
  transition: 0.3s;
  border-radius: 30px;

  &:before {
    position: absolute;
    content: '';
    height: 25px;
    width: 25px;
    left: 5px;
    bottom: 2.6px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
    transform: ${({ toggled }) => toggled && 'translateX(29px)'};

    @media ${device.tablet} {
      transform: ${({ toggled }) => toggled && 'translateX(24px)'};
    }
  }
`;
