import { PartnersParkingCard } from 'Components/ParkingCard/PartnersParkingCard';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Container,
  List,
  DiscountEdit,
  Row,
  Title,
  AddContainer,
  Text,
  Card,
  ButtonWrapper,
  StyledLabel
} from './style';
import { PlusIcon, MinusIcon } from 'assets/svg';
import { color } from 'theme';
import { useMutation } from 'react-query';
import { addDiscount, deleteDiscount, getDiscounts, getMyParkings, Status } from 'api/parking';
import { Button } from 'Components/UI/Button/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { discountVerificationSchema } from 'config';
import { StyledInput } from 'Components/UI/Input/style';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { checkExpiry } from 'api/auth';
import { useDispatch } from 'react-redux';
import { logout } from 'Redux/reducers/login/loginReducer';
import { regexDiscountValue } from 'Constants/constants';
import { Notification } from 'Components/UI/Notification/Notification';

interface FormValues {
  range: number;
  discount: number;
}

export const Discount = () => {
  const [parkingPlaceId, setParkingId] = useState<number>(0);
  const token = localStorage.getItem('accessToken') || '';
  const [allDiscounts, setAllDiscounts] = useState<any[]>([]);
  const [parkings, setParkings] = useState<any[]>([]);
  const isMobileDevice = useMediaQuery({
    query: '(max-width: 767px)'
  });
  const { t } = useTranslation('common');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [rangeValue, setRangeValue] = useState('');
  const [discountValue, setDiscountValue] = useState('');
  const [isNotification, setIsNotification] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    async function getData() {
      try {
        const token = await checkExpiry(localStorage.getItem('accessToken'));

        if (token) {
          const { data } = await getMyParkings(
            {
              page,
              sortingType: 'DATE_DESCENDING',
              statuses: [Status.ApprovedVerified, Status.ApprovedUnverified]
            },
            token
          );
          setParkings([...parkings, ...data.results]);
          setPages(Math.ceil(data.count / 10));
          const promises = data.results.map(async (parking: any) => {
            const res = await getDiscounts({ parkingPlaceId: parking.id }, token);
            return res.data.results;
          });
          const newDiscounts = await Promise.all<any>(promises);
          const discounts = [...allDiscounts, ...newDiscounts];
          setAllDiscounts(discounts);
          setDiscounts(discounts?.[0]);
          setParkingId(data.results?.[0].id);
        } else {
          dispatch(logout());
        }
      } catch (error: any) {
        console.log(error);
        if (error?.response?.status === 401) dispatch(logout());
      }
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  const { mutateAsync } = useMutation(addDiscount, {
    onSuccess: async (data, variables, context) => {
      setForm({ isShown: false, type: false });
      const res = await getDiscounts({ parkingPlaceId, page: 1 }, token);
      setDiscounts(res.data.results);
      const newAllDiscounts = allDiscounts?.map((item, index) => {
        if (index === currentIndex) {
          return res.data.results;
        }
        return item;
      });
      setAllDiscounts(newAllDiscounts);
    }
  });

  const {
    register,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
    reset
  } = useForm<FormValues>({
    resolver: yupResolver(discountVerificationSchema)
  });

  const handleAdd = (form: FormValues) => {
    reset();
    const token = localStorage.getItem('accessToken') || '';
    mutateAsync({
      body: {
        range: form.range,
        discount: form.discount / 100,
        parkingPlaceId
      },
      token
    });
  };
  const [formView, setForm] = useState({ isShown: false, type: true });

  const handleClick = (index: number, id: number) => {
    const discounts = allDiscounts?.[index];
    setDiscounts(discounts);
    setCurrentIndex(index);
    setParkingId(id);
  };
  const onChanceRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexDiscountValue)) {
      setRangeValue(e.target.value);
    }
  };

  const onChanceDiscountValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexDiscountValue)) {
      setDiscountValue(e.target.value);
      setIsNotification(false);
    }
    if (e.target.value.length !== 0 && +e.target.value > 100) {
      setIsNotification(true);
      setDiscountValue('');
    }
  };

  useEffect(() => {}, [discountValue]);

  const renderDiscount = () => (
    <DiscountEdit>
      <Title>{t('profile.discount_title')}</Title>
      {discounts?.map((discount: any) => (
        <Row key={discount.id}>
          <Card>{`${discount.range} ${t('profile.discount_card_day')}`}</Card>
          <Card>{`${(discount.discount * 100).toFixed(0)} %`}</Card>
          {/* <EditIcon onClick={() => setForm({isShown: true, type: false})} style={{ margin: 'auto 0 auto 10px'}} /> */}
          <MinusIcon
            onClick={() => handleDelete(discount.id)}
            style={{ margin: 'auto 0 auto 10px' }}
          />
        </Row>
      ))}
      <AddContainer>
        <PlusIcon onClick={() => setForm({ isShown: true, type: true })} />
        <Text color={color.greayText}>{t('profile.discount_add')}</Text>
      </AddContainer>
      <form
        style={{ display: formView.isShown ? 'block' : 'none' }}
        onSubmit={handleSubmit(handleAdd)}>
        <Row>
          <StyledLabel>
            <StyledInput
              {...register('range')}
              value={rangeValue}
              onChange={onChanceRange}
              islight
              placeholder={t('placeholders.number_of_days')}
              marginRight="20px"
            />
          </StyledLabel>

          <StyledLabel>
            <StyledInput
              {...register('discount')}
              value={discountValue}
              onChange={onChanceDiscountValue}
              islight
              placeholder={t('placeholders.discount')}
            />
            {isNotification && (
              <Notification isAccent={true}>{t('profile.discount_max_value')}</Notification>
            )}
          </StyledLabel>

          <ButtonWrapper>
            <Button>
              {formView.type ? t('profile.discount_btn_add') : t('profile.discount_btn_edit')}
            </Button>
          </ButtonWrapper>
        </Row>
      </form>
    </DiscountEdit>
  );
  const handleDelete = async (id: number) => {
    try {
      const token = localStorage.getItem('accessToken') || '';
      await deleteDiscount(id, token);
      const res = await getDiscounts({ parkingPlaceId, page: 1 }, token);
      setDiscounts(res.data.results);
      const newAllDiscounts = allDiscounts?.map((item, index) => {
        if (index === currentIndex) {
          return res.data.results;
        }
        return item;
      });
      setAllDiscounts(newAllDiscounts);

      toast.success(t('profile.discount_removed'), {
        position: 'top-right',
        autoClose: 60000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined
      });
    } catch (err) {
      toast.error(t('notifications.something_wrong'), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined
      });
    }
  };

  const [discounts, setDiscounts] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <Container>
      <List>
        {parkings?.map((item: any, index: number) => (
          <div key={item.id}>
            <PartnersParkingCard
              current={index === currentIndex}
              onClick={() => handleClick(index, item.id)}
              id={item.id}
              street={item.street}
              house={item.house}
              pricePerDay={item.pricePerDay}
              carClass={item.carClass}
              status={item.status}
              images={
                item?.files.length > 0
                  ? item.files.find((el: any) => el.type === 'MAIN_IMAGE') &&
                    item.files.find((el: any) => el.type === 'MAIN_IMAGE').path
                  : ''
              }
              parkingName={item.parkingName}
              profile
              handleBookParking={() => {}}
              onMouseOver={() => {}}
              onMouseOut={() => {}}
              security={item.security}
              electricCharge={item.electricCharge}
              owner={item.owner}
              parkingStatus={item.status}
              parkingType={item.parkingType}
              currency={item.currency}
            />
            {isMobileDevice && index === currentIndex && renderDiscount()}
          </div>
        ))}
        {pages > 0 && page < pages && <Button onClick={() => setPage(page + 1)}>Еще</Button>}
      </List>
      {!isMobileDevice && parkings.length > 0 && renderDiscount()}
    </Container>
  );
};
