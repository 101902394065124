import styled from "styled-components";

import { color, device } from "theme";

export const StyledStars = styled.div`
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  margin-left: 10px;

  &::before {
    content: "★★★★★";
    letter-spacing: 3px;
    background: linear-gradient(
      90deg,
      #f2c94c ${(props) => props.rating}%,
      ${color.backgroundGrey} ${(props) => props.rating}%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 20px;
  }
`;
