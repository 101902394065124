import { types } from "./types";

export const getAllParkings = (data) => {
  return {
    type: types.GET_ALL_PARKINGS_SUCCESS,
    payload: {
      results: data.results,
      count: data.count,
    },
  };
};

export const getAllParkingsError = (data) => {
  return {
    type: types.GET_ALL_PARKINGS_ERROR,
    payload: {
      error: data,
    },
  };
};
