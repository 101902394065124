import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';
import { setSearchValue } from 'Redux/reducers/citySearch/citySearchReducer';
import { TLocation } from '../types';
import { FilterContext } from 'context/filterContext';
import { FilterContextType } from 'context/types';
import moment, { Moment } from 'moment';

import { Button } from 'Components/UI/Button/Button';
import { GoogleLocationAutoComplete } from 'Components/GoogleAutoComplete/GoogleAutoCompleteSearch';
import { NotificationTooltip } from 'Components/NotificationTooltip/NotificationTooltip';
import { FilterDropdown } from './FilterDropdown';
import { CarClassFilter } from './CarClassFilter';
import { SelectedFiltersBar } from './SelectedFiltersBar';
import { DirectionIcon, MapMarkedIcon } from 'assets/svg';
import { CoordinatesType } from 'types/parkingTypes.ts';

import { color } from 'theme';
import { DateCont } from 'Components/ParkingSearch/style';
import { DesktopVisibleText } from 'Components/UI/Text/style.js';
import {
  BarContainer,
  LeftSide,
  IconWrapper,
  RightSideBar,
  MapButtonWrapper,
  AutoCompleteContainer,
  DatePickerContainer
} from '../style.js';

export const FiltersPanel = () => {
  const location = useLocation<TLocation>();
  const history = useHistory();

  const { lat, lng, searchRadius, searchingLocation, from, to } = queryString.parse(
    location.search
  );
  const { t, i18n } = useTranslation(['common']);
  const dispatch = useDispatch();

  const { setDates, setCoordinates, setPositionDetected, setIsMobileMapOpen, isMobileMapOpen } =
    useContext(FilterContext) as FilterContextType;

  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false);
  const [isDateMissing, setIsDateMissing] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Moment>(moment(from));
  const [endDate, setEndDate] = useState<Moment>(moment(to));
  const [focusedInput, setFocusedInput] = useState(null);
  const [radius, setRadius] = useState<number | undefined>(searchRadius);
  const [newCoordinates, setNewCoordinates] = useState<CoordinatesType>({ lat, lng });
  const [newLocation, setLocation] = useState<string>(searchingLocation);

  const getMyPosition = () => {
    if (!navigator.geolocation) {
      toast.error(t('notifications.not_support_position'), NOTIFICATION_OPTIONS);
    } else {
      setIsButtonDisable(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
          dispatch(
            setSearchValue({
              coordinates: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              },
              chosenLabel: t('placeholders.your_location'),
              value: { label: t('placeholders.your_location') }
            })
          );

          setPositionDetected(true);
          setIsButtonDisable(false);
        },
        (error) => {
          toast.error(`ERROR(${error.code}): ${error.message}`, NOTIFICATION_OPTIONS);
          setIsButtonDisable(false);
        }
      );
    }
  };

  useEffect(() => {
    const isFromDateToday = moment(from).isSame(moment(), 'day');
    const tomorrowDate = moment().clone().add(1, 'days');
    const isToDateTomorrow = moment(to).isSame(tomorrowDate, 'days');
    if (!isFromDateToday && !isToDateTomorrow) {
      setDates({ from: moment(from).locale(i18n.language), to: moment(to).locale(i18n.language) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      setDates({ from: startDate, to: endDate });
      setIsDateMissing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    const mewUrl = `?lat=${newCoordinates.lat}&lng=${
      newCoordinates.lng
    }&searchRadius=${radius}&searchingLocation=${newLocation}&from=${
      startDate ? startDate.format('MM/DD/yyyy') : from
    }&to=${endDate ? endDate.format('MM/DD/yyyy') : to}`;

    history.replace(mewUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, newCoordinates, newLocation]);

  return (
    <BarContainer isWide>
      <LeftSide>
        <AutoCompleteContainer>
          <GoogleLocationAutoComplete
            newLocation={newLocation}
            setRadius={setRadius}
            setNewCoordinates={setNewCoordinates}
            setLocation={setLocation}
          />
        </AutoCompleteContainer>

        <DatePickerContainer>
          <DateCont>
            <DateRangePicker
              startDatePlaceholderText={t('placeholders.begin')}
              endDatePlaceholderText={t('placeholders.end')}
              startDate={startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id"
              endDate={endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id"
              onDatesChange={({ startDate, endDate }: any) => {
                setStartDate(startDate);
                setEndDate(endDate);
                if (!endDate) {
                  setIsDateMissing(true);
                }
              }}
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
              withFullScreenPortal={isMobile}
              orientation={isMobile ? 'vertical' : 'horizontal'}
              readOnly={true}
            />
            {isDateMissing && (
              <NotificationTooltip isError={true} text={t('notifications.enter_end_date')} />
            )}
          </DateCont>
        </DatePickerContainer>
      </LeftSide>

      <RightSideBar>
        <SelectedFiltersBar />
        <CarClassFilter />
        <FilterDropdown />

        <Button
          primary={false}
          width="auto"
          height="50"
          onClick={getMyPosition}
          disabled={isButtonDisable}>
          <IconWrapper>
            <DirectionIcon fill={color.blue} />
          </IconWrapper>
          <DesktopVisibleText>{t('buttons.get_position')}</DesktopVisibleText>
        </Button>

        <MapButtonWrapper>
          <Button
            primary={isMobileMapOpen ? true : false}
            width="auto"
            height="50"
            onClick={() => {
              setIsMobileMapOpen((prevState) => !prevState);
            }}>
            <IconWrapper>
              <MapMarkedIcon fill={isMobileMapOpen ? '#fff' : `${color.blue}`} />
            </IconWrapper>
          </Button>
        </MapButtonWrapper>
      </RightSideBar>
    </BarContainer>
  );
};
