import styled from "styled-components";

import { font, color, fontSize } from "theme";

export const StyledH4 = styled.h4`
  font-family: ${font.primary};
  font-weight: 500;
  font-style: normal;
  font-size: ${fontSize.x_large};
  line-height: 18px;
  color: ${color.blue};
  text-align: ${({ align }) => (align ? align : 'left')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : 0)};
`;
