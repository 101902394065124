import React, { useRef, useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SellParkingFilterContext } from 'context/sellParkingContext';
import { SellParkingFilterContextType } from 'context/types';
import { CAR_CLASS_TYPE, CAR_TYPES_DESCRIPTION } from 'Constants/constants';
import { iconMap } from 'Constants/iconMap';
import { SortButtonType } from 'types/filterTypes';

import { IconButton } from 'Components/UI/Button/Button';
import { IconCheckbox } from 'Components/UI/Checkbox/Checkbox';
import { Text } from 'Components/UI/Text/style';
import { CarPlusIcon, CloseIcon } from 'assets/svg';

import { color } from 'theme';
import { CarClassFiltersContainer, CloseBtnContainer } from '../style.js';

export const SellCarClassFilter = () => {
  const { t, i18n } = useTranslation(['common']);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const { carClass, setCarClass } = useContext(
    SellParkingFilterContext
  ) as SellParkingFilterContextType;

  const handleToggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        isModalOpen &&
        modalRef.current &&
        !modalRef.current?.contains(e.target as Node) &&
        buttonRef.current &&
        !buttonRef.current?.contains(e.target as Node)
      ) {
        setIsModalOpen(false);
      }
    },
    [isModalOpen]
  );

  const handleCarChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if ((e.target.value as CarClassTypes) === carClass) {
        setCarClass(null);
        return;
      }
      setCarClass(e.target.value as CarClassTypes);
    },
    [setCarClass, carClass]
  );

  const handleResetCarTypeFilter = useCallback(() => {
    setCarClass(null);
  }, [setCarClass]);

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const selectedCarType = CAR_CLASS_TYPE.find((type) => type.value === carClass);

  return (
    <div style={{ position: 'relative' }} ref={buttonRef}>
      {carClass && (
        <CloseBtnContainer top="3" right="4">
          <CloseIcon onClick={handleResetCarTypeFilter} />
        </CloseBtnContainer>
      )}
      <IconButton
        isActive={isModalOpen}
        onClick={handleToggleModal}
        name={carClass && selectedCarType ? t(selectedCarType.title) : t('parking.car_class')}>
        {carClass && selectedCarType ? (
          React.createElement(iconMap[selectedCarType.value], {
            style: { fill: isModalOpen ? '#fff' : `${color.blue}` }
          })
        ) : (
          <CarPlusIcon fill={isModalOpen ? '#fff' : `${color.blue}`} />
        )}
      </IconButton>

      {isModalOpen && (
        <CarClassFiltersContainer ref={modalRef}>
          <CloseBtnContainer>
            <CloseIcon onClick={handleCloseModal} />
          </CloseBtnContainer>

          <Text mb="5" mt="12">
            {t('parking.car_class')}
          </Text>

          {CAR_CLASS_TYPE.map((carType: SortButtonType, i: number) => {
            return (
              <IconCheckbox
                key={carType.value}
                id={carType.value}
                label={t(carType.title)}
                value={carType.value}
                name={carType.value}
                onChange={handleCarChange}
                checked={carClass === carType.value}
                icon={React.createElement(iconMap[carType.value])}
                text={CAR_TYPES_DESCRIPTION[`${i18n.language}`][i]}
              />
            );
          })}
        </CarClassFiltersContainer>
      )}
    </div>
  );
};
