import styled from "styled-components";

import { color, fontSize, device } from "theme";

export const StyledTitle = styled.h4`
  font-size: ${fontSize.m_large};
  line-height: 23px;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;

  @media ${device.laptop} {
    text-align: left;
  }
`;

export const InfoContainer = styled.form`
  width: 100%;
  padding: 20px 40px;

  @media ${device.tablet} {
    padding: 20px 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.align ? `flex-${props.align}` : `space-between`)};
  margin-bottom: ${(props) => (props.margin_b ? `${props.margin_b}px` : '0px')};
  min-height: ${(props) => (props.height ? `${props.height}px` : 'unset')};

  @media ${device.laptop} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const Select = styled.select`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  font-size: ${fontSize.regular};
  border: ${(props) => (props.error ? `1px solid ${color.red}` : "none")};
  outline: none;
  width: 300px;
  height: 50px;
  color: ${color.black};
  :invalid {
    color: ${color.greayText};
  }

  @media ${device.tablet} {
    width: 350px;
  }
`;

export const TextArea = styled.textarea`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  font-size: ${fontSize.regular};
  border: none;
  outline: none;
  width: 300px;
  resize: none;
  color: ${color.greayText};

  @media ${device.tablet} {
    width: 350px;
  }
`;

export const StyledPlaceholder = styled.span`
  color: ${color.greayText};
  font-size: ${fontSize.regular};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
`;

export const UploadFile = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 50px;
  width: 100px;
  z-index: 0;
  cursor: pointer;
  width: 100%;
`;

export const UploadLabel = styled.label`
  display: inline-block;
  line-height: 27px;
  position: relative;
  height: 50px;
  margin-bottom: 20px;
  background-color: transparent;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: ${fontSize.regular};
  color: ${color.blue};
  border: 1px solid ${color.blue};
  cursor: pointer;
`;

export const SmallContainer = styled.div`
  width: 300px;
  margin-bottom: 20px;

  @media ${device.tablet} {
    width: 350px;
  }

  @media ${device.laptop} {
    margin-bottom: 100px;
  }
`;

export const TextP = styled.p`
  font-size: ${fontSize.regular};
  color: ${color.greayText};
  line-height: 16px;
  margin-bottom: ${(props) => (props.margin_b ? `${props.margin_b}px` : "0px")};
`;

export const PreviewImgContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
`;

export const FilesCont = styled.div`
  margin-right: 10px;
`;
