import styled from "styled-components";

import { color, fontSize } from "theme";
import { CustomScrollBar } from "../Calendar/style";

export const Container = styled.div`
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Selector = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  height: 50px;
  background: ${color.white};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  font-size: ${fontSize.regular};
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 10px;
  margin-top: ${props => props.mt && '10px'};
  width: 250px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
  position: relative;
`;

export const SelectorContainer = styled.div`
  position: relative;
`;

export const List = styled.div`
  margin-top: 10px;
`;

export const Button = styled.button`
  border-radius: 10px;
  background: ${color.white};
  padding: 15px;
  border: none;
  height: 50px;
  margin-left: 10px;

  font-family: Roboto;
  font-size: ${fontSize.medium};
  line-height: 19px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-top: 10px;
  }
`;

export const FilterList = styled(CustomScrollBar)`
  border-radius: 10px;
  max-height: 300px;
  width: 250px;
  position: absolute;
  left: 0;
  top: 101%;
  display: ${props => props.show ? 'block': 'none'};
  background: ${color.white};
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
`;

