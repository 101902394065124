import styled from 'styled-components';
import { color } from 'theme';

export const PartnerInfoBlock = styled.div`
  display: flex;
  gap: 25px;
  padding: 20px;
`;

export const EmptyPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OptionsSelectorContainer = styled.div`
  display: flex;
  gap: 40px;
`;

export const Label = styled.label`
  color: ${color.blue};
`;
