import styled from "styled-components";

import { color, fontSize } from 'theme';

export const Container = styled.div`
  padding: 20px 12px;
  background: ${color.backgroundGrey};
`;

export const ParkingCard = styled.div`
  border-radius: 10px;
  padding: 15px 20px;
  background: ${(props) => props.color || color.white};
  margin: 5px 10px 5px 0;
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  cursor: pointer;
`;

export const Title = styled.h2`
  width: 25%;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  cursor: pointer;
  color: ${(props) => props.active && color.blue};
  margin-left: 10px;
  text-align: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Status = styled.div`
  display: flex;
`;

export const ParkingsList = styled.div`
  margin-bottom: 50px;
`;

export const ParkingCardSection = styled.div`
  span {
    color: ${color.darkGrey};
    font-size: ${fontSize.regular};
  }
`;