/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useDebouncedCallback } from 'use-debounce';
import {
  Container,
  Row,
  HeaderTitle,
  Column,
  Filter,
  Booking,
  Header,
  SelectItem,
  MoreButton,
} from './style';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-down.svg';
import { Button } from 'Components/UI/Button/Button';
import {
  getMyParkings,
  getRequests,
  notArrivedRequest,
  ParkingPlace,
  RequestsParams,
  Status,
} from 'api/parking';
import { Selector, FilterList, SelectorContainer } from '../Ratings/style';
import { useMediaQuery } from 'react-responsive';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkExpiry } from 'api/auth';
import { logout } from 'Redux/reducers/login/loginReducer';
import { color } from 'theme';
import { useTranslation } from 'react-i18next';
import { APPROVED_BY_CRON, DD_MM_YYYY, REJECTED_NOT_ARRIVED } from 'Constants/constants';

export interface RequestInterface {
  name: string;
  id: number;
  from: string;
  to: string;
  price: number;
  car: string;
  color: string;
  parkingPlace: ParkingPlace;
  email: string;
  phoneNumber: string;
  status: string;
  commission: number;
  autoApproved: boolean;
}

export const Bookings = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [list, setList] = useState<RequestInterface[]>();
  const [startSelected, setStartSelected] = useState<boolean>(false);
  const [move, setMove] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [clickedBookingId, setClickedBookingId] = useState<number>(0);

  const { t } = useTranslation(['common']);

  const header = (
    <Header>
      <HeaderTitle>{t('headerTitle.id')}</HeaderTitle>
      <HeaderTitle>{t('headerTitle.name')}</HeaderTitle>
      <HeaderTitle>{t('headerTitle.date_in')}</HeaderTitle>
      <HeaderTitle>{t('headerTitle.date_out')}</HeaderTitle>
      <HeaderTitle>{t('headerTitle.mark_auto')}</HeaderTitle>
      <HeaderTitle>{t('headerTitle.color')}</HeaderTitle>
      <HeaderTitle>{t('headerTitle.class_auto')}</HeaderTitle>
      <HeaderTitle>{t('headerTitle.price')}</HeaderTitle>
      <HeaderTitle>{t('headerTitle.commission')}</HeaderTitle>
      <HeaderTitle>{t('headerTitle.contacts')}</HeaderTitle>
      <HeaderTitle />
    </Header>
  );

  const isMobileDevice = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const [params, setParams] = useState<RequestsParams>({});

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  useEffect(() => {
    (async function () {
      try {
        const token = await checkExpiry(localStorage.getItem('accessToken'));
        if (token) {
          const res = await getRequests(params, token);
          setList(res.data.results);
        } else {
          dispatch(logout());
          history.push('/');
        }
      } catch (error: any) {
        if (error.response.status === 401) dispatch(logout());
      }
    })();
  }, [dispatch, history, params]);

  useEffect(() => {
    (async function () {
      try {
        const token = await checkExpiry(localStorage.getItem('accessToken'));
        if (token) {
          const { data } = await getMyParkings(
            {
              page,
              limit: 25,
              sortingType: 'DATE_DESCENDING',
              statuses: [Status.ApprovedVerified, Status.ApprovedUnverified]
            },
            token
          );
          setParkings([...parkings, ...data.results]);
          setPages(Math.ceil(data.count / 25));
        } else {
          dispatch(logout());
          history.push('/');
        }
      } catch (error: any) {
        if (error.response.status === 401) dispatch(logout());
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function onParkingClick(current: any, parkingPlaceId?: number) {
    setParams({ parkingPlaceId });
    setCurrent(current);
    setMore(false);
  }

  async function handleClick(requestId: number) {
    try {
      const token = localStorage.getItem('accessToken') || '';
      setIsClicked(true);
      setClickedBookingId(requestId);
      await notArrivedRequest(requestId, token);
      const res = await getRequests(params, token);
      setList(res.data.results);
    } catch (error: any) {
      if (error.response.status === 401) dispatch(logout());
    }
  }


  const debouncedCallback = useDebouncedCallback((requestId: number) => {
    handleClick(requestId);
  }, 500)

  function isSoon(time: Date): boolean {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date.getTime() > time.getTime();
  }

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setStartSelected(true);
  };

  const [more, setMore] = useState(false);
  const [parkings, setParkings] = useState<ParkingPlace[]>([]);
  const [current, setCurrent] = useState(t('profile.all'));

  return (
    <Container>
      <Filter move={move}>
        <SelectorContainer>
          <Selector onClick={() => setMore((more) => !more)} ml>
            {current}
            <ArrowIcon />
          </Selector>
          <FilterList show={more}>
            <SelectItem onClick={() => onParkingClick(t('profile.all'))}>
              {t('profile.all')}
            </SelectItem>
            {parkings?.map((parking) => (
              <SelectItem
                key={parking.id}
                onClick={() =>
                  onParkingClick(
                    `${parking.street} ${parking.house}`,
                    parking.id
                  )
                }
              >{`${parking.id}: ${parking.street} ${parking.house}`}</SelectItem>
            ))}
            {page < pages && (
              <MoreButton onClick={() => setPage(page + 1)}>
                {t('profile.page_download_more')}
              </MoreButton>
            )}
          </FilterList>
        </SelectorContainer>
      </Filter>
      {!isMobileDevice && header}
      {list?.map((booking) => (
        <Booking key={booking.id}>
          {isMobileDevice && header}
          <Row>
            <Column>{`${booking.id}, ${
              booking.parkingPlace?.parkingName || ''
            }`}</Column>
            <Column>{booking.name}</Column>
            <Column>
              {moment
                .tz(new Date(booking.from), 'Europe/Kiev')
                .format(DD_MM_YYYY)}
            </Column>
            <Column>
              {moment
                .tz(new Date(booking.to), 'Europe/Kiev')
                .format(DD_MM_YYYY)}
            </Column>
            <Column>{booking.car}</Column>
            <Column>{booking.color}</Column>
            <Column>{t(`parking.${booking.parkingPlace.carClass}`)}</Column>
            <Column>{`${booking.price} грн`}</Column>
            <Column>{`${
              booking.status === REJECTED_NOT_ARRIVED ? 0 : booking.commission
            } грн`}</Column>
            <Column>{`${
              booking.email !== 'Not selected' ? booking.email : ''
            } ${booking.phoneNumber}`}</Column>
            <Column>
              
              {
               booking.status === APPROVED_BY_CRON ? (
                  !booking.autoApproved && ( <p> </p>) 
                ) : (
                  isSoon(new Date(booking.from)) &&
                  ((booking.status !== REJECTED_NOT_ARRIVED ) ? (
                    
                      <Button
                        disabled={isClicked && clickedBookingId === booking.id}
                        onClick={() => debouncedCallback(booking.id)}
                        height={'40'}
                        primary
                    >
                      {t('profile.booking_not_checkin')}
                    </Button>
                  ) : (
                    <p style={{ textAlign: 'center', color: color.red }}>
                      {t('profile.booking_not_checkin')}
                    </p>
                  ))
                )
              }
            </Column>
          </Row>
        </Booking>
      ))}
    </Container>
  );
};
