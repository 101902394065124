import resources from "./resources";

import { PARKOVKI_HOSTNAMES, BROWSER_LANGUAGES } from 'Constants/constants';

const hostname = window.location.hostname;
const isParkovkiMd = hostname === PARKOVKI_HOSTNAMES.MD;
const savedLanguageMd = localStorage.getItem('language') || 'ro';

const browserLanguage = window.navigator.userLanguage || window.navigator.language;
let detectedLanguage = undefined;

Object.entries(BROWSER_LANGUAGES).forEach(([key, value]) => {
  if (value.includes(browserLanguage?.toLowerCase())) {
    detectedLanguage = key;
  }
  return detectedLanguage;
});

const defaultLanguage = isParkovkiMd
  ? savedLanguageMd
  : localStorage.getItem('language') || detectedLanguage || 'uk';

export const config = {
  interpolation: { escapeValue: false }, // React already does escaping
  lng: defaultLanguage,
  resources
};

export { resources };
