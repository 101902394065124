import React from "react";
import { useTranslation } from "react-i18next";
// import { format } from "date-fns";
import { dateDiffInDays } from "helpers/getDays";
import { IOnDemandParkings } from "./OnDemand";
import { ControlButton } from "Components/UI/Button/Button";
import image from "assets/img/no-parking-photo.png";

import {
  CardWrapper,
  ImgWrapper,
  ContentWrapper,
  ParkingImage,
  StreetTitle,
  StyledText,
  StyledPrice,
  StyledPriceNote,
  LeftSideCont,
  ParkingInfoCont,
  StyledColumns,
  Label,
  RightSideCont,
} from "./style";
import { formatDate } from "utils";

export const OnDemandItem = ({
  to,
  from,
  paid,
  phoneNumber,
  car,
  name,
  price,
  status,
  id,
  commission,
  src,
  onClick,
  onReject,
  parkingPlace,
  currency
}: IOnDemandParkings) => {
  const { t } = useTranslation(['common']);

  let daysAmount = dateDiffInDays(new Date(to), new Date(from));

  if (daysAmount === 0) {
    daysAmount = 1;
  }

  return (
    <CardWrapper>
      <LeftSideCont>
        <ImgWrapper>
          <ParkingImage src={src ? `${src}` : image} />
        </ImgWrapper>
        <ContentWrapper>
          <StreetTitle>
            {parkingPlace.parkingName || `${parkingPlace.street} ${parkingPlace.house}`}
          </StreetTitle>
          <ParkingInfoCont>
            <StyledColumns>
              <StyledText>
                {formatDate(from)}-{formatDate(to)}
              </StyledText>
              <StyledPrice>
                {Number(price).toFixed(2)}
                <StyledPriceNote>
                  <p>{currency}</p> {t('parking.total_price')}
                </StyledPriceNote>
              </StyledPrice>
            </StyledColumns>
            <StyledColumns>
              <StyledText>ID {id}</StyledText>
              <StyledText>{car}</StyledText>
              <StyledText>{`${t('titles.commission')}: ${commission}`}</StyledText>
            </StyledColumns>
          </ParkingInfoCont>
          <Label status={status}>
            {status === 'APPROVED' ? t('status.approved') : t('status.ondemand')}
          </Label>
        </ContentWrapper>
      </LeftSideCont>
      <RightSideCont>
        <ControlButton value={id} isConfirm={true} margin="10" onClick={onClick}>
          {t('buttons.confirm')}
        </ControlButton>
        <ControlButton value={id} isConfirm={false} onClick={onReject}>
          {t('buttons.reject')}
        </ControlButton>
      </RightSideCont>
    </CardWrapper>
  );
};
