import React from 'react';

export const CarPlusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 32 32"
      version="1.1"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      {...props}>
      <path d="M24 16c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3 9h-2v2c0 .6-.4 1-1 1s-1-.4-1-1v-2h-2c-.6 0-1-.4-1-1s.4-1 1-1h2v-2c0-.6.4-1 1-1s1 .4 1 1v2h2c.6 0 1 .4 1 1s-.4 1-1 1z"></path>
      <path d="M8.4 22l1.2-2.3c.5-1 1.5-1.7 2.7-1.7H16c1.8-2.4 4.7-4 8-4 1.2 0 2.3.2 3.4.6-.4-.6-.9-1.2-1.4-1.6h1c.6 0 1-.4 1-1s-.4-1-1-1h-2.8L23 8c-.8-1.8-2.6-3-4.6-3H9.6C7.6 5 5.8 6.2 5 8l-1.3 3H1c-.6 0-1 .4-1 1s.4 1 1 1h1c-1.2.9-2 2.4-2 4v4c0 .9.4 1.7 1 2.2V25c0 1.7 1.3 3 3 3h2c1.7 0 3-1.3 3-3v-1h5c0-.7.1-1.4.2-2H8.4zM7 19H4c-.6 0-1-.4-1-1s.4-1 1-1h3c.6 0 1 .4 1 1s-.4 1-1 1zm-1.5-7l1.4-3.2C7.4 7.7 8.4 7 9.6 7h8.7c1.2 0 2.3.7 2.8 1.8l1.4 3.2h-17z"></path>
    </svg>
  );
};
