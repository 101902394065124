import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { TOptions } from '../../constants.ts';

import { Input, SelectWrapper } from '../style';

type SearchBarProps = {
  selected: TOptions;
  handleSelect: () => TOptions;
  handleChange: () => React.ChangeEvent<HTMLInputElement>;
  query: string;
  options: Array<TOptions>;
};

export const SearchBar = ({
  handleSelect,
  selected,
  handleChange,
  query,
  options
}: SearchBarProps) => {
  const { t } = useTranslation(['admin']);

  return (
    <SelectWrapper>
      <span>{t('admin.users_search')}</span>
      <Select
        styles={{
          container: (provided, state) => ({
            ...provided,
            width: 150
          })
        }}
        value={selected}
        onChange={handleSelect}
        options={options}
      />
      <Input
        type="text"
        value={query}
        onChange={handleChange}
        placeholder={t('admin.users_search_input_placeholder')}
      />
    </SelectWrapper>
  );
};
