import styled from "styled-components";

import { color, fontSize, device } from "theme";

export const UserCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;

  @media ${device.tablet} {
    min-width: 165px;
  }
`;

export const StyledText = styled.div`
  text-transform: uppercase;
  color: ${color.greayText};
  font-size: ${fontSize.small};
  font-weight: 400;
  line-height: 12px;

  @media ${device.tablet} {
    font-size: ${fontSize.medium};
    line-height: 19px;
  }
`;
