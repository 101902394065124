import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ImagePreview } from 'Components/ImagePreview/ImagePreview';

import { color } from 'theme';
import { UploadFile, UploadLabel, TextP, PreviewImgContainer } from 'Pages/CreateParking/style.js';

type ImageUploaderProps = {
  imagesSelected: File[] | [];
  setImageSelected: React.Dispatch<React.SetStateAction<File[]>>;
  mainIndex: number;
  setMainIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ImageUploader = ({
  imagesSelected,
  setImageSelected,
  mainIndex,
  setMainIndex,
  setIsDisabled
}: ImageUploaderProps) => {
  const { t } = useTranslation(['common']);
  const [imgErr, setImgErr] = useState(false);
  const [countError, setCountError] = useState(false);
  const [imgUrls, setImgUrls] = useState<string[]>([]);

  const handleImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const imageList = e.target.files;
      if (!imageList) return;

      const imagesUrls: string[] = [];

      const arr = Array.from(imageList);
      let err = arr.some((element) => {
        // if (element.size / 1024 < 300 || element.size / 1024 / 1024 > 10) {
        //   return true;
        // }
        const img = new Image();
        let blob = URL.createObjectURL(element);
        img.src = blob;
        // img.onload = function () {
        //   const width = img.width;
        //   const height = img.height;
        //   if (width < 400 && height < 400) {
        //     return setImgErr(true);
        //   }
        // };
        imagesUrls.push(img.src);
        return false;
      });

      // setImgErr(err);
      // if (err) return;
      const images = [...imagesSelected, ...arr];
      setImageSelected(images);

      if (images.length > 5) {
        setCountError(true);
        setIsDisabled(true);
      }
      setImgUrls([...imgUrls, ...imagesUrls]);
    },
    [imgUrls, imagesSelected, setImageSelected, setIsDisabled]
  );

  const deleteImage = useCallback(
    (id: number) => {
      if (id < mainIndex) setMainIndex(mainIndex - 1);
      if (id === mainIndex) setMainIndex(0);

      setImgErr(false);
      const deletePhotoArr = imgUrls.filter((item, index) => index !== id);

      setImgUrls(deletePhotoArr);

      const newImagesList = imagesSelected.filter((item, index) => index !== id);

      if (newImagesList.length <= 5) {
        setCountError(false);
        setIsDisabled(false);
      }

      setImageSelected(newImagesList);
    },
    [imagesSelected, mainIndex, imgUrls, setImageSelected, setMainIndex, setIsDisabled]
  );
  return (
    <>
      <UploadLabel htmlFor="photos">
        {t('buttons.add_photo')}
        <UploadFile
          name="photos"
          id="uploadPhoto"
          type="file"
          accept="image/*"
          multiple={true}
          onChange={handleImageChange}
        />
      </UploadLabel>
      <TextP>{t('texts.photo_text')}</TextP>
      {imgErr && <TextP style={{ color: color.red }}>{t('notifications.img_size_big')}</TextP>}
      {countError && <p style={{ color: color.red }}>{t('notifications.img_cnt_high')}</p>}
      {!!imgUrls.length && (
        <PreviewImgContainer>
          {imgUrls?.map((item, index) => (
            <ImagePreview
              primary={index === mainIndex}
              src={item}
              key={item}
              onClick={() => deleteImage(index)}
              onPrime={() => setMainIndex(index)}
            />
          ))}
        </PreviewImgContainer>
      )}
    </>
  );
};
