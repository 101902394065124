import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import { getParkingsOnDemand, approveParking, ParkingPlace } from "api/parking";
import { StyledNotification } from "./style";
import { OnDemandItem } from "./OnDemandItem";
import { Loading } from 'Components/Loader/Loader';
import { useEffect } from 'react';
import { checkExpiry } from 'api/auth';
import { useHistory } from 'react-router-dom';
import { logout } from 'Redux/reducers/login/loginReducer';
import { getImageLink } from 'helpers/getImageLink.ts';

export interface IOnDemandParkings {
  car: string;
  from: string;
  id: number;
  name: string;
  paid: boolean;
  phoneNumber: string;
  price: number;
  status: string;
  commission: number;
  to: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onReject?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  src: string | null;
  parkingPlace: ParkingPlace;
  currency: string;
}

export interface OnDemandResponse {
  count: number;
  results: IOnDemandParkings[];
}

export const OnDemand = () => {
  const { t } = useTranslation(['common']);
  const [token, setToken] = useState<string | null>('');

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    (async function () {
      try {
        const token = await checkExpiry(localStorage.getItem('accessToken'));
        if (!token) {
          dispatch(logout());
          history.push('/');
        }
        setToken(token);
      } catch (error: any) {
        if (error.response.status === 401) dispatch(logout());
      }
    })();
  }, [dispatch, history]);

  const { status, data, refetch } = useQuery('getParkingPlaces', () => getParkingsOnDemand(token), {
    enabled: !!token,
    onSuccess: (response: AxiosResponse<OnDemandResponse | undefined>) => {},
    onError: (error: AxiosError) => {
      if (error.response?.status === 403) {
        toast.error(t('notifications.need_auth'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined
        });
        dispatch(logout());
        history.push('/');
      }
    }
  });

  const approveSingleParking = useMutation(approveParking, {
    onError: (error: AxiosError) => {
      if (error.response?.status === 404) {
        toast.error(t('notifications.not_found'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined
        });
      }
    },
    onSuccess: (response: AxiosResponse) => {
      if (response.status === 201) {
        const message =
          response.data === 'Rejected'
            ? t('notifications.parking_rejected')
            : t('notifications.parking_approved');
        toast.success(message, {
          position: 'top-right',
          autoClose: 60000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined
        });
      }

      refetch();
    }
  });

  const handleReject = (e: React.MouseEvent<HTMLButtonElement>) => {
    approveSingleParking.mutateAsync({
      body: {
        parkingPlaceRequestId: Number(e.currentTarget.value),
        approved: false
      },
      token
    });
  };

  const handleAccept = (e: React.MouseEvent<HTMLButtonElement>) => {
    approveSingleParking.mutateAsync({
      body: {
        parkingPlaceRequestId: Number(e.currentTarget.value),
        approved: true
      },
      token
    });
  };

  return (
    <>
      {status === 'loading' && <Loading />}
      {status === 'error' && <div>{t('notifications.server_error')}</div>}

      {status === 'success' && !data?.data?.results.length && (
        <StyledNotification>{t('notifications.no_booked_parking')}</StyledNotification>
      )}

      {data?.data?.results.map((item) => (
        <OnDemandItem
          key={item.id}
          // street={item.street}
          name={item.name}
          price={item.price}
          from={item.from}
          to={item.to}
          status={item.status}
          id={item.id}
          phoneNumber={item.phoneNumber}
          car={item.car}
          paid={item.paid}
          src={getImageLink(item.parkingPlace.files)}
          commission={item.commission}
          onClick={handleAccept}
          onReject={handleReject}
          parkingPlace={item.parkingPlace}
          currency={item.currency}
        />
      ))}
    </>
  );
};
