import React from 'react';

import { CheckboxWrapper, StyledInput } from './style';

type RadiobuttonPropsType = {
  id: string;
  label: string;
  checked: boolean;
  value?: string;
  name?: string;
  isSmall?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Radiobutton = ({
  id,
  label,
  checked,
  onChange,
  value,
  name,
  isSmall = false
}: RadiobuttonPropsType) => {
  return (
    <CheckboxWrapper isSmall={isSmall}>
      <StyledInput
        value={value}
        name={name}
        checked={checked}
        id={id}
        type="radio"
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </CheckboxWrapper>
  );
};
