import React from 'react';

export const ChevronRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}>
      <g fill="#fff">
        <path d="M23.662 15.286l-6.9-6.999a.994.994 0 00-1.414 0 1.016 1.016 0 000 1.428L21.544 16l-6.196 6.285a1.016 1.016 0 000 1.428.994.994 0 001.414 0l6.899-6.999a1.043 1.043 0 00.001-1.428z"></path>
        <path d="M16.662 15.286L9.763 8.287a.994.994 0 00-1.414 0 1.016 1.016 0 000 1.428L14.544 16l-6.196 6.285a1.016 1.016 0 000 1.428.994.994 0 001.414 0l6.899-6.999a1.043 1.043 0 00.001-1.428z"></path>
      </g>
    </svg>
  );
};
