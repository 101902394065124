import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { citySearchSlice } from "Redux/reducers/citySearch/citySearchReducer";
import { citiesSlice } from "Redux/reducers/cities/citiesReducer";
import { authSlice } from "Redux/reducers/login/loginReducer";
import { parkingReducer } from "Redux/reducers/parking/parkingReducer";
import { routerSlice } from "Redux/reducers/router/routerReducer";
import { singleParkingReducer } from "Redux/reducers/singleParking/singleParkingReducer";

const reducer = combineReducers({
  auth: authSlice.reducer,
  parkings: parkingReducer,
  singleParking: singleParkingReducer,
  router: routerSlice.reducer,
  cities: citiesSlice.reducer,
  citySearchValue: citySearchSlice.reducer,
});

export const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof reducer>;
