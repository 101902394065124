import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { deleteFile as deleteFileMutation } from 'api/parking';
import {
  updateParkingPlaceInfo,
  getOneParkingPlace,
  ParkingPlace,
  CreateParkingResponse,
  updateParkingFiles,
  mainImage
} from 'api/parking';
import { getAllParkingsError } from 'Redux/reducers/parking/actions';
import { FormValues } from 'Pages/CreateParking/CreateParking';
import { RootState } from 'Redux/store';
import { Auto, ICoordinates } from 'Pages/CreateParking/CreateParking';
import { MainHeader } from 'Components/Header/MainHeader';
import { GoogleMap } from 'Components/GoogleMap/GoogleMap';
import { ImagePreview } from 'Components/ImagePreview/ImagePreview';
import { Loading } from 'Components/Loader/Loader';
import { Background } from 'Pages/Parking/style';
import { MainContainer } from 'Pages/AddParking/style';
import { StyledInput } from 'Components/UI/Input/style';
import { RequiredLabel } from 'Components/UI/StyledLabel/style';
import { MapWrapper } from 'Components/UI/Map/style';
import { Button } from 'Components/UI/Button/Button';
import { Tooltip } from 'Components/UI/Tooltip/style';
import {
  StyledTitle,
  InfoContainer,
  ContentWrapper,
  Select,
  TextArea,
  UploadFile,
  UploadLabel,
  SmallContainer,
  TextP,
  PreviewImgContainer,
  FilesCont
} from 'Pages/CreateParking/style';
import { UpperPlaceholder } from './style';
import pdfIcon from 'assets/img/pdf.png';
import { shortenFileName } from 'helpers/shortenFileName';
import { color } from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'Components/UI/Notification/Notification';
import * as yup from 'yup';
import {
  regexDimensionsValue,
  regexHouseNumber,
  regexLevel,
  regexNumberValue,
  regexOnlyNumber,
  regexPlaceNumber,
  NOTIFICATION_OPTIONS
} from 'Constants/constants';
import { getCityById } from 'api/cities';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { paths } from 'config';

type StateProps = {
  state: {
    parkingId: string | number;
    parkingInfo: number;
  };
};

type FileProps = {
  id: number;
  path: string;
  type: string;
};

export const EditParking = () => {
  const history = useHistory();

  const { state }: StateProps = useLocation();
  const token = useSelector((state: RootState) => state.auth.accessToken);
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(false);
  const [filesSelected, setFilesSelected] = useState<File[]>([]);
  const [documentUrls, setDocumentUrls] = useState<string[]>([]);
  const [imagesSelected, setImageSelected] = useState<File[]>([]);
  const [imgUrls, setImgUrls] = useState<string[]>([]);
  const [images, setImages] = useState<FileProps[] | undefined>();
  const [documents, setDocuments] = useState<FileProps[]>([]);
  const [parkingInfo, setParkingInfo] = useState<ParkingPlace>();
  const [parkingPlaceId, setParkingPlaceId] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [success, setSuccess] = useState(false);
  const [cityId, setCityId] = useState<number>(0);
  const [inputValueHouseNumber, setInputValueHouseNumber] = useState<string | number | undefined>(
    parkingInfo?.house
  );
  const [filesToDelete, setToFilesToDelete] = useState<any[]>([]);
  const [formattedCityAddress, setFormattedCityAddress] = useState<string>('');
  const [inputValuePlaceNumber, setInputValuePlaceNumber] = useState<string | number | undefined>(
    parkingInfo?.placeNumber
  );
  const [inputChanceLevel, setInputChanceLevel] = useState<string | number | undefined>(
    parkingInfo?.parkingLevel
  );
  const [inputWidthValue, setInputWidthValue] = useState<string | number | undefined>(
    parkingInfo?.placeWidth
  );
  const [inputLengthValue, setInputLengthValue] = useState<string | number | undefined>(
    parkingInfo?.placeLength
  );
  const [inputHeightValue, setInputHeightValue] = useState<string | number | undefined>(
    parkingInfo?.heightOfParkingEntry
  );
  const [pricePerDayValue, setPricePerDayValue] = useState<string | number | undefined>(
    parkingInfo?.price
  );
  const [street, setStreet] = useState<string>('');
  // eslint-disable-next-line
  const [coordinates, setCoordinates] = useState<ICoordinates>();
  // eslint-disable-next-line
  const [streetIsSelected, setStreetIsSelected] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [docsError, setDocsError] = useState(false);
  const [countError, setCountError] = useState(false);
  const [newCoordinates, setNewCoordinates] = useState<ICoordinates | null>({
    //@ts-expect-error
    lat: parkingInfo?.positionOfEntry.coordinates[0],
    //@ts-expect-error
    lng: parkingInfo?.positionOfEntry.coordinates[1]
  });
  const [mainIndex, setMain] = useState(-1);
  const inputPhotosRef = useRef<any>(null);

  useMemo(async () => {
    if (cityId !== 0) {
      const city = await getCityById(cityId);
      //@ts-ignore
      const results = await geocodeByPlaceId(city.title);
      results.length && setFormattedCityAddress(results[0].formatted_address);
    }
  }, [cityId]);

  const { status } = useQuery(
    'getOneParkingPlace',
    () => getOneParkingPlace({ id: state.parkingId }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (response: AxiosResponse<ParkingPlace>) => {
        const parkingInfo = response.data;
        setParkingInfo(parkingInfo);
        setCityId(parkingInfo.city.id);
        setParkingPlaceId(parkingInfo.id);
        const { id, positionOfEntry, city, files, status, ...data } = parkingInfo;

        delete (data as any).message;
        delete (data as any).defaultDayType;
        delete (data as any).createdDate;
        delete (data as any).updatedDate;
        delete (data as any).rate;
        reset({
          ...data,
          ...(parkingInfo?.security !== null && {
            security: +(parkingInfo?.security as Boolean)
          }),
          ...(parkingInfo?.electricCharge !== null && {
            electricCharge: +(parkingInfo?.electricCharge as Boolean)
          }),
          ...(parkingInfo?.elevator !== null && {
            elevator: +(parkingInfo?.elevator as Boolean)
          }),
          ...(parkingInfo?.familyPlace !== null && {
            familyPlace: parkingInfo?.familyPlace ? 1 : 0
          })
        });

        setStreet(parkingInfo.street.split(', ')[1]);

        const images = parkingInfo?.files.filter(
          (item) => item.type === 'IMAGE' || item.type === 'MAIN_IMAGE'
        );
        setImages(images);

        setDocuments(parkingInfo?.files.filter((item) => item.type === 'DOCUMENT'));

        setNewCoordinates({
          lat: parkingInfo?.positionOfEntry.coordinates[0],
          //@ts-expect-error
          lng: parkingInfo?.positionOfEntry.coordinates[1]
        });
        setMain(images.findIndex((image) => image.type === 'MAIN_IMAGE'));
      },
      onError: (error: AxiosError) => {
        dispatch(getAllParkingsError(t('notifications.connection_failed')));
      }
    }
  );

  const renderOptions = (arr: Auto[]) => {
    const options = arr.map((item) => {
      return (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      );
    });
    return options;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const classAuto: Auto[] = [
    { value: 'FIRST', label: t('parking.FIRST') },
    { value: 'SECOND', label: t('parking.SECOND') },
    { value: 'THIRD', label: t('parking.THIRD') }
  ];
  const memoizedAuto = useMemo(() => renderOptions(classAuto), [classAuto]);

  const defaultValues = {};

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm<FormValues>({
    resolver: yupResolver(
      yup.object().shape({
        house: yup.string().required().matches(regexHouseNumber, t('validation.houselAndNumber')),
        placeNumber: yup.string().required().matches(regexPlaceNumber, t('validation.placeNumber')),
        parkingLevel: yup.string().required().matches(regexLevel, t('validation.placeNumber')),
        placeWidth: yup
          .string()
          .required()
          .matches(regexDimensionsValue, t('validation.dimensionsNumber')),
        placeLength: yup
          .string()
          .required()
          .matches(regexDimensionsValue, t('validation.dimensionsNumber')),
        heightOfParkingEntry: yup
          .string()
          .required()
          .matches(regexDimensionsValue, t('validation.dimensionsNumber')),
        pricePerDay: yup.string().required().matches(regexOnlyNumber, t('validation.onlyNumber')),
        carClass: yup.string().required(),
        security: yup.boolean().nullable(),
        parkingType: yup.string().nullable(),
        electricCharge: yup.boolean().nullable(),
        elevator: yup.boolean().nullable(),
        sector: yup.string().nullable(),
        owner: yup.boolean().nullable(),
        parkingName: yup.string().nullable(),
        description: yup.string().nullable(),
        familyPlace: yup.boolean().nullable(),
        lat: yup.number(),
        lng: yup.number()
      })
    ),
    defaultValues
  });

  const onChanceHouseNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexHouseNumber)) {
      setIsDisabled(false);
      setInputValueHouseNumber(e.target.value);
    }
  };
  const onChancePlaceNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexPlaceNumber)) {
      setIsDisabled(false);
      setInputValuePlaceNumber(e.target.value);
    }
  };
  const onChanceLevel = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexLevel)) {
      setIsDisabled(false);
      setInputChanceLevel(e.target.value);
    }
  };
  const onChanceWidth = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexDimensionsValue)) {
      setIsDisabled(false);
      setInputWidthValue(e.target.value);
    }
  };
  const onChanceLength = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexDimensionsValue)) {
      setIsDisabled(false);
      setInputLengthValue(e.target.value);
    }
  };
  const onChanceHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexDimensionsValue)) {
      setIsDisabled(false);
      setInputHeightValue(e.target.value);
    }
  };
  const onChancePricePerDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexNumberValue)) {
      setIsDisabled(false);
      setPricePerDayValue(e.target.value);
    }
  };

  const parkingFilesForm = useMutation(updateParkingFiles, {
    onError: (error: AxiosError) => {
      let message;
      if (error.response?.status === 401) {
        message = t('notifications.auth_problem');
      } else if (error.response?.status === 400) {
        message = t('notifications.img_format_wrong');
      } else if (error.response?.status === 404) {
        message = t('notifications.upload_error');
      } else if (error.response?.status === 403) {
        message = t('notifications.maximum_photos');
      } else if (error.response?.status === 500) {
        message = t('notifications.server_error');
      }

      toast.error(message, NOTIFICATION_OPTIONS);
      setProgress(false);
    },
    onSuccess: () => {
      setSuccess(true);
      setProgress(false);
      history.push({
        pathname: `${paths.partnerprofile}`,
        state: { cityId: cityId, show: true }
      });
    }
  });

  const parkingInfoForm = useMutation(updateParkingPlaceInfo, {
    onError: (error: AxiosError) => {
      let message: string = '';
      if (error.response?.status === 400) {
        message = error.response?.data.message[0];
      }

      if (error.response?.status === 500 || error.response?.status === 403) {
        message = t('notifications.created_error');
      }

      if (error.response?.status === 409) {
        message = t('notifications.address_exists');
      }
      if (error.response?.status === 401) {
        message = t('notifications.need_auth');
      }

      toast.error(message, NOTIFICATION_OPTIONS);
      setProgress(false);
    },
    onSuccess: (response: AxiosResponse<CreateParkingResponse>) => {
      if (imagesSelected.length === 0 && filesSelected.length === 0) {
        setSuccess(true);
        setProgress(false);
        history.push({
          pathname: `${paths.partnerprofile}`,
          state: { cityId: cityId, show: true }
        });
      }
    }
  });

  const { mutateAsync: deleteFileMutate } = useMutation(deleteFileMutation, {
    onError: (error: AxiosError) => {
      let message: string = '';
      if (error.response?.status === 401 || error.response?.status === 403) {
        message = t('notifications.auth_problem');
      } else if (error.response?.status === 400 || error.response?.status === 404) {
        message = t('notifications.file_not_found');
      }

      toast.error(message, NOTIFICATION_OPTIONS);
      setProgress(false);
    },
    onSuccess: (response: AxiosResponse) => {
      if (response.status === 200) {
        toast.success(t('notifications.file_deleted'), NOTIFICATION_OPTIONS);
      }
    }
  });

  const handleDelete = (value: number, id: number) => {
    if (id < mainIndex) setMain(mainIndex - 1);
    if (id === mainIndex) setMain(0);

    setToFilesToDelete([...filesToDelete, value]);

    const newList = images?.filter((image, index) => id !== index);
    setImages(newList);
    streetIsSelected && setIsDisabled(false);
  };

  const handleFileDelete = (fileId: number, id: number) => {
    setToFilesToDelete([...filesToDelete, fileId]);

    const newList = documents?.filter((file, index) => id !== index);

    setDocuments(newList);
    streetIsSelected && setIsDisabled(false);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageList = e.target.files;
    if (!imageList) return;

    const imagesUrls: string[] = [];

    const arr = Array.from(imageList);

    arr.forEach((element) => {
      const img = new Image();
      let blob = URL.createObjectURL(element);
      img.src = blob;

      imagesUrls.push(img.src);
    });

    const images = [...imagesSelected, ...arr];

    setImageSelected(images);

    setImgUrls([...imgUrls, ...imagesUrls]);
    setIsDisabled(false);
  };

  const deleteImage = (id: number) => {
    if (id + (images ? images.length : 0) < mainIndex) setMain(mainIndex - 1);
    if (id + (images ? images.length : 0) === mainIndex) setMain(0);

    const deletePhotoArr = imgUrls.filter((item, index) => index !== id);

    setImgUrls(deletePhotoArr);

    const newImagesList = Array.from(imagesSelected).filter((item, index) => index !== id);

    if (newImagesList.length + (images?.length || 0) <= 5) {
      setCountError(false);
      streetIsSelected && setIsDisabled(false);
    }

    if (newImagesList.length === 0) {
      inputPhotosRef.current.value = '';
    }

    setImageSelected(newImagesList);
    streetIsSelected && setIsDisabled(false);
  };

  const handleDocsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;

    const arr = Array.from(fileList);

    const err = arr.some((element) => {
      if (element.size / 1024 ** 2 > 10) {
        return true;
      }
      return false;
    });

    setDocsError(err);
    if (err) return;

    const newFilesList = [...filesSelected, ...arr].slice(0, 5);
    setFilesSelected(newFilesList);

    streetIsSelected && setIsDisabled(false);
  };

  const deleteFile = (id: number) => {
    const deletePhotoArr = documentUrls.filter((item, index) => index !== id);

    setDocumentUrls(deletePhotoArr);

    const newFilesList = filesSelected.filter((item, index) => index !== id);

    setFilesSelected(newFilesList);
  };

  const onSubmit = async (data: FormValues) => {
    try {
      setProgress(true);
      const formData = new FormData();
      formData.append('parkingPlaceId', parkingPlaceId.toString());
      if (imagesSelected) {
        Array.from(imagesSelected).forEach((file, index) => {
          if (index !== mainIndex - (images?.length || 0)) formData.append('image', file);
        });
      }

      if (filesSelected) {
        Array.from(filesSelected).forEach((file) => {
          formData.append('document', file);
        });
      }

      if (images!.length > 0 && mainIndex < images!.length && mainIndex !== -1) {
        await mainImage(
          { fileId: images?.[mainIndex].id },
          localStorage.getItem('accessToken') || ''
        );
      } else if (imagesSelected.length > 0 && mainIndex !== -1) {
        formData.append('mainImage', imagesSelected[mainIndex - (images?.length || 0)]);
      }

      const { electricCharge, security, elevator, parkingType, familyPlace } = data;

      delete data.security;
      delete data.electricCharge;
      delete data.elevator;
      delete data.familyPlace;
      delete data.parkingType;
      delete data.currency;

      filesToDelete.forEach((fileId) => {
        deleteFileMutate({
          fileId,
          token
        });
      });

      parkingInfoForm.mutateAsync({
        parkingPlaceId: state.parkingId,
        ...data,
        ...(security !== null && { security }),
        ...(electricCharge !== null && { electricCharge }),
        ...(elevator !== null && { elevator }),
        ...(familyPlace !== null && { familyPlace }),
        ...(parkingType !== null && { parkingType }),
        token: token,
        owner: true,
        cityId,
        lat: newCoordinates?.lat,
        lng: newCoordinates?.lng,
        street: formattedCityAddress.split(',')[0] + ', ' + street,
        ...(coordinates !== undefined && { streetLng: coordinates.lng, streetLat: coordinates.lat })
      });

      if (imagesSelected.length > 0 || filesSelected.length > 0) {
        parkingFilesForm.mutateAsync({
          token: token,
          formData
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    !!isDirty && setIsDisabled(!isDirty);
  }, [isDirty]);

  if (status === 'loading') return <Loading />;

  if (status === 'error') return <div>{t('notifications.connection_failed')}</div>;

  return (
    <Background height="auto">
      <MainHeader />
      <MainContainer>
        {progress && <Loading />}
        <InfoContainer>
          <StyledTitle>{t('titles.general_info')}</StyledTitle>
          <ContentWrapper height={isMobile ? '310' : ' '}>
            <RequiredLabel htmlFor="city" marginBottom={isMobile ? '50' : '20'}>
              <UpperPlaceholder>{t('placeholders.city')}</UpperPlaceholder>
              <StyledInput
                value={formattedCityAddress}
                islight={true}
                width={isMobile ? '300px' : '350px'}
                disabled
              />
            </RequiredLabel>
            <RequiredLabel htmlFor="street" marginBottom={isMobile ? '50' : '20'}>
              <UpperPlaceholder>{t('placeholders.street')}</UpperPlaceholder>
              <StyledInput
                value={street}
                islight={true}
                width={isMobile ? '300px' : '350px'}
                disabled
              />
            </RequiredLabel>

            <RequiredLabel htmlFor="house" required={true} marginBottom={isMobile ? '10' : '20'}>
              <UpperPlaceholder>{t('placeholders.house')}</UpperPlaceholder>
              <StyledInput
                {...register('house')}
                value={inputValueHouseNumber}
                onChange={onChanceHouseNumber}
                islight={true}
                width={isMobile ? '300px' : '350px'}
                error={errors.house}
              />
              <Notification isAccent={true}>
                {errors?.house?.type === 'matches' && errors.house.message}
              </Notification>
            </RequiredLabel>

            <RequiredLabel
              htmlFor="placeNumber"
              required={true}
              marginBottom={isMobile ? '10' : '20'}>
              <UpperPlaceholder>{t('placeholders.place_number')}</UpperPlaceholder>
              <StyledInput
                {...register('placeNumber')}
                value={inputValuePlaceNumber}
                onChange={onChancePlaceNumber}
                islight={true}
                width={isMobile ? '300px' : '350px'}
                refs={register}
                error={errors.placeNumber}
              />
              <Notification isAccent={true}>
                {errors?.placeNumber?.type === 'required' && t('login.required')}
                {errors?.placeNumber?.type === 'matches' && errors.placeNumber.message}
              </Notification>
            </RequiredLabel>

            <RequiredLabel htmlFor="parkingLevel" required={true} marginBottom="20">
              <UpperPlaceholder>{t('placeholders.parking_level')}</UpperPlaceholder>
              <StyledInput
                {...register('parkingLevel')}
                value={inputChanceLevel}
                onChange={onChanceLevel}
                islight={true}
                width={isMobile ? '300px' : '350px'}
              />
              <Notification isAccent={true}>
                {errors?.parkingLevel?.type === 'required' && t('login.required')}
                {errors?.parkingLevel?.type === 'matches' && errors.parkingLevel.message}
              </Notification>
            </RequiredLabel>
          </ContentWrapper>
        </InfoContainer>

        <InfoContainer>
          <StyledTitle>{t('titles.size')}</StyledTitle>
          <ContentWrapper height={isMobile ? '210' : ' '}>
            <RequiredLabel
              htmlFor="placeWidth"
              required={true}
              marginBottom={isMobile ? '10' : ' '}>
              <UpperPlaceholder>{t('placeholders.place_width')}</UpperPlaceholder>
              <StyledInput
                islight={true}
                width={isMobile ? '300px' : '350px'}
                {...register('placeWidth')}
                value={inputWidthValue}
                onChange={onChanceWidth}
                error={errors.placeWidth}
              />
              <Notification isAccent={true}>
                {errors?.placeWidth?.type === 'matches' && errors.placeWidth.message}
              </Notification>
            </RequiredLabel>
            <RequiredLabel
              htmlFor="placeLength"
              required={true}
              marginBottom={isMobile ? '10' : ' '}>
              <UpperPlaceholder>{t('placeholders.place_length')}</UpperPlaceholder>
              <StyledInput
                islight={true}
                width={isMobile ? '300px' : '350px'}
                {...register('placeLength')}
                value={inputLengthValue}
                onChange={onChanceLength}
                error={errors.placeLength}
              />
              <Notification isAccent={true}>
                {errors?.placeLength?.type === 'matches' && errors.placeLength.message}
              </Notification>
            </RequiredLabel>
            <RequiredLabel htmlFor="heightOfParkingEntry" required={true}>
              <UpperPlaceholder>{t('placeholders.entrance_height')}</UpperPlaceholder>
              <StyledInput
                islight={true}
                width={isMobile ? '300px' : '350px'}
                {...register('heightOfParkingEntry')}
                value={inputHeightValue}
                onChange={onChanceHeight}
                error={errors.heightOfParkingEntry}
              />
              <Notification isAccent={true}>
                {errors?.heightOfParkingEntry?.type === 'matches' &&
                  errors.heightOfParkingEntry.message}
              </Notification>
            </RequiredLabel>
          </ContentWrapper>
        </InfoContainer>

        <InfoContainer>
          <StyledTitle>{t('titles.price')}</StyledTitle>
          <ContentWrapper align={!isMobile && 'start'} height={isMobile ? '120' : ' '}>
            <RequiredLabel
              htmlFor="pricePerDay"
              required={true}
              marginRight={isMobile ? '0' : '70'}>
              <UpperPlaceholder>{t('placeholders.price_day')}</UpperPlaceholder>
              <StyledInput
                islight={true}
                width={isMobile ? '300px' : '350px'}
                marginRight={isMobile ? '0' : '70'}
                {...register('pricePerDay')}
                value={pricePerDayValue}
                onChange={onChancePricePerDay}
                error={errors.pricePerDay}
              />
              <Notification isAccent={true}>
                {errors?.pricePerDay?.type === 'matches' && errors.pricePerDay.message}
              </Notification>
            </RequiredLabel>
          </ContentWrapper>
        </InfoContainer>

        <InfoContainer>
          <StyledTitle>{t('titles.additional_info')}</StyledTitle>
          <ContentWrapper margin_b="20" height={isMobile ? '440' : ' '}>
            <Tooltip />
            <RequiredLabel
              htmlFor="carClass"
              required={true}
              marginBottom={isMobile ? '10' : '20'}
              data-tip={t('parking.car_class_desc')}>
              <UpperPlaceholder>{t('parking.car_class')}</UpperPlaceholder>
              <Select {...register('carClass')}>{memoizedAuto}</Select>
            </RequiredLabel>
            <RequiredLabel htmlFor="security" marginBottom={isMobile ? '10' : ' '}>
              <UpperPlaceholder>{t('placeholders.security')}</UpperPlaceholder>
              <Select defaultValue={parkingInfo?.security ? 1 : 0} {...register('security')}>
                <option value="1">
                  {t('placeholders.security')} {t('placeholders.exists')}
                </option>
                <option value="0">
                  {t('placeholders.security')} {t('placeholders.not_exists')}
                </option>
              </Select>
            </RequiredLabel>
            <RequiredLabel htmlFor="parkingType" marginBottom={isMobile ? '10' : ' '}>
              <UpperPlaceholder>{t('placeholders.parking_types')}</UpperPlaceholder>
              <Select {...register('parkingType')}>
                <option value="UNDEGROUND">{t('placeholders.underground')}</option>
                <option value="OVERGROUND">{t('placeholders.ground')}</option>
              </Select>
            </RequiredLabel>
            <RequiredLabel htmlFor="electricCharge" marginBottom={isMobile ? '10' : '20'}>
              <UpperPlaceholder>{t('placeholders.electric_charger')}</UpperPlaceholder>
              <Select
                defaultValue={parkingInfo?.electricCharge ? 1 : 0}
                {...register('electricCharge')}>
                <option value="1">
                  {t('placeholders.electric_charger')} {t('placeholders.exists')}
                </option>
                <option value="0">
                  {t('placeholders.electric_charger')} {t('placeholders.not_exists')}
                </option>
              </Select>
            </RequiredLabel>
            <RequiredLabel htmlFor="elevator" marginBottom={isMobile ? '10' : ' '}>
              <UpperPlaceholder>{t('placeholders.elevator')}</UpperPlaceholder>
              <Select defaultValue={parkingInfo?.elevator ? 1 : 0} {...register('elevator')}>
                <option value="1">
                  {t('placeholders.elevator')} {t('placeholders.exists')}
                </option>
                <option value="0">
                  {t('placeholders.elevator')} {t('placeholders.not_exists')}
                </option>
              </Select>
            </RequiredLabel>
            <RequiredLabel htmlFor="sector" marginBottom={isMobile ? '10' : ' '}>
              <UpperPlaceholder>{t('placeholders.section')}</UpperPlaceholder>
              <StyledInput
                type="text"
                islight={true}
                width={isMobile ? '300px' : '350px'}
                {...register('sector')}
              />
            </RequiredLabel>
            <RequiredLabel htmlFor="parkingName">
              <UpperPlaceholder>{t('placeholders.park_name')}</UpperPlaceholder>
              <StyledInput
                type="text"
                islight={true}
                defaultValue={parkingInfo?.parkingName}
                width={isMobile ? '300px' : '350px'}
                {...register('parkingName')}
              />
            </RequiredLabel>
          </ContentWrapper>
          <ContentWrapper>
            <SmallContainer>
              <StyledTitle>{t('placeholders.description')}</StyledTitle>
              <RequiredLabel maxHeight="220" htmlFor="description">
                <TextArea
                  placeholder={t('placeholders.description')}
                  rows="12"
                  {...register('description')}
                />
              </RequiredLabel>
            </SmallContainer>
            <SmallContainer>
              <StyledTitle>{t('titles.parking_docs')}</StyledTitle>
              <TextP number margin_b="15">{`${documents?.length + filesSelected?.length} ${t(
                'titles.file_length'
              )}`}</TextP>
              <UploadLabel htmlFor="documents">
                {t('buttons.upload')}
                <UploadFile
                  name="documents"
                  id="uploadDocs"
                  type="file"
                  accept=".jpeg, .jpg, .png, .pdf"
                  multiple
                  onChange={handleDocsChange}
                />
              </UploadLabel>
              {documents?.map((item, index) => (
                <span key={item.id}>
                  <ImagePreview
                    document={true}
                    width="100"
                    src={pdfIcon}
                    onClick={() => handleFileDelete(item.id, index)}
                  />
                  <TextP>{shortenFileName(item.path.split('/').pop())}</TextP>
                </span>
              ))}
              {filesSelected?.map((item, index) => (
                <FilesCont key={item.name}>
                  <ImagePreview
                    key={item.name}
                    document={true}
                    width="100"
                    src={pdfIcon}
                    onClick={() => deleteFile(index)}
                  />
                  <TextP>{shortenFileName(item.name)}</TextP>
                </FilesCont>
              ))}
              {docsError && <p style={{ color: color.red }}>{t('notifications.file_size_big')}</p>}
              <TextP>{t('texts.docs_text')}</TextP>
            </SmallContainer>

            <SmallContainer>
              <StyledTitle>{t('titles.additional_info')}</StyledTitle>
              <RequiredLabel htmlFor="familyPlace">
                <UpperPlaceholder>{t('placeholders.family_place')}</UpperPlaceholder>
                <Select {...register('familyPlace')}>
                  <option value="1">
                    {t('placeholders.family_place')} {t('placeholders.yes')}
                  </option>
                  <option value="0">
                    {t('placeholders.family_place')} {t('placeholders.no')}
                  </option>
                </Select>
              </RequiredLabel>
            </SmallContainer>
          </ContentWrapper>
          <ContentWrapper>
            <SmallContainer>
              <UploadLabel htmlFor="photos">
                {parkingInfo?.files.length
                  ? t('buttons.add_horizontal_photo')
                  : t('buttons.upload_photo')}

                <UploadFile
                  name="photos"
                  id="uploadPhoto"
                  type="file"
                  accept="image/*"
                  multiple={true}
                  onChange={handleImageChange}
                  ref={inputPhotosRef}
                />
              </UploadLabel>
              <TextP>{t('texts.photo_text')}</TextP>
              {countError && <p style={{ color: color.red }}>{t('notifications.img_cnt_high')}</p>}
              <PreviewImgContainer>
                {images?.map((item, index) => (
                  <ImagePreview
                    primary={index === mainIndex}
                    src={`${item.path}`}
                    key={item.id}
                    onPrime={() => {
                      setMain(index);
                      setIsDisabled(false);
                    }}
                    onClick={() => handleDelete(item.id, index)}
                  />
                ))}
                {imgUrls?.map((item, index) => (
                  <ImagePreview
                    primary={(images ? images.length : 0) + index === mainIndex}
                    src={item}
                    key={item}
                    onClick={() => deleteImage(index)}
                    onPrime={() => {
                      setMain((images ? images.length : 0) + index);
                      setIsDisabled(false);
                    }}
                  />
                ))}
              </PreviewImgContainer>
            </SmallContainer>
          </ContentWrapper>
          {!!newCoordinates && (
            <>
              <StyledTitle>{t('titles.map')}</StyledTitle>
              <TextP>{t('titles.choose_location')}</TextP>
              <MapWrapper>
                <GoogleMap
                  lat={newCoordinates.lat}
                  lng={newCoordinates.lng}
                  // @ts-expect-error
                  setNewCoordinates={({ lat, lng }) => {
                    setNewCoordinates({ lat, lng });
                    streetIsSelected && setIsDisabled(false);
                  }}
                />
              </MapWrapper>
            </>
          )}
          <Button
            disabled={isDisabled}
            primary={true}
            width="226px"
            height="50px"
            onClick={handleSubmit(onSubmit)}>
            {t('buttons.sent_to_moderation')}
          </Button>
        </InfoContainer>
      </MainContainer>
    </Background>
  );
};
