import styled from "styled-components/macro";

import { color, fontSize, lineHeight } from "theme";

export const Container = styled.div`
  display: flex;
`;

export const List = styled.div`
  width: 45%;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`;

export const DiscountEdit = styled.div`
  padding: 0 30px 0 50px;
  width: 55%;
  position: sticky;
  position: -webkit-sticky;
  top: 10px;
  height: fit-content;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-family: Roboto;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 20px;
`;

export const AddContainer = styled.div`
  display: flex;
   align-items: center;
  margin: 23px 0;
`;

export const Text = styled.p`
  margin-left: 6px;
  font-size: ${fontSize.regular};
  line-height: ${lineHeight.regular};
  color: ${props => props.color};
`;

export const Card = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  border-radius: 10px;
  background: ${color.white};
  height: 50px;

  /* Gray 1 */

  color: #333333;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  :first-child {
    margin-left: 0;
  }
  width: 290px;
  box-sizing: border-box;
`;

export const ButtonWrapper = styled.div`
  margin-left: 10px;
`;

export const StyledLabel = styled.label`
margin-right: 10px;
`;

