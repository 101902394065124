import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PARKOVKI_HOSTNAMES } from 'Constants/constants';

import bannerUa from 'assets/banners/mainBannerUa.gif';
import bannerRu from 'assets/banners/mainBannerRu.gif';
import bannerMd from 'assets/banners/mainBannerMd.gif';

const BannerWrapper = styled.div`
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: 10px;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const hostname = window.location.hostname;
const isParkovkiMd = hostname === PARKOVKI_HOSTNAMES.MD;

const BANNERS = {
  uk: bannerUa,
  ru: isParkovkiMd ? bannerRu : bannerUa,
  ro: bannerMd,
  pl: bannerUa,
  en: bannerUa
};

export const Banner = () => {
  const { i18n } = useTranslation(['common']);

  return (
    <BannerWrapper>
      <img src={BANNERS[i18n.language]} alt="banner on main page" />
    </BannerWrapper>
  );
};
