import styled from "styled-components";

import { color } from "theme";

export const Container = styled.div`
  background: ${color.backgroundGrey};
  height: 100%;
  padding: 10px 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 50%;
  border-radius: 10px;
  padding: 10px 0;
`;

export const Input = styled.input`
  margin: 10px 0;
`;
export const Label = styled.label`
  margin-top: 10px;
`;