import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getParkingPlacesForSale, ParkingPlace } from 'api/parking';
import { Status as StatusEnums } from 'api/parking';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../UI/Pagination/Pagination';

import { SmallLoader } from 'Components/Loader/Loader';
import { ParkingCard } from './components/ParkingCard';
import { HeaderWithFilters } from './components/HeaderWithFilters';
import { StyledH4 } from 'Components/UI/H4/style.js';
import { Container, ParkingsList } from './style';
import { getActiveStatus } from './getActiveStatus.ts';

import { ADMIN_TOKEN_KEY } from '../constants.ts';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';

export const SellParkings = () => {
  const history = useHistory();
  const { t } = useTranslation(['admin']);
  const { url } = useRouteMatch();

  const [parkings, setParkings] = useState<ParkingPlace[]>([]);
  const params = new URLSearchParams(history.location.search);
  const token = localStorage.getItem(ADMIN_TOKEN_KEY) || '';

  const [page, setPage] = useState(+(params.get('page') ?? 1));
  const [pages, setPages] = useState<number>(1);
  const [status, setStatus] = useState<StatusEnums>(
    (params.get('status') as StatusEnums) ?? StatusEnums.Pending
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [active, setActive] = useState(getActiveStatus(params.get('status')));

  const handleClick = (id?: number) => {
    history.push({ pathname: `${url}/${id}`, state: 'sell' });
  };

  const handlePageClick = (page: any) => {
    setPage(page.selected + 1);
    history.push({
      pathname: '',
      search: `?page=${page.selected + 1}&status=${status}`
    });
  };

  const handleChangeStatus = (index: number, status: StatusEnums) => {
    setStatus(status);
    setActive(index);
    setPage(1);
    history.push({
      pathname: '',
      search: `?page=${1}&status=${status}`
    });
  };

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        const { data } = await getParkingPlacesForSale({
          params: { page, statuses: status, sortingType: 'DATE_DESCENDING' },
          token
        });
        setPages(Math.ceil(data.count / 10));
        setParkings(data.results);
      } catch (error: AxiosError) {
        const errorMessage = error?.response?.data?.message?.join(', ');
        toast.error(`${t('notifications.connection_error')} ${errorMessage}`, NOTIFICATION_OPTIONS);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, status, token]);

  return (
    <Container>
      <StyledH4 align="center" mb={20}>
        {t('linkItems.ads_sell')}
      </StyledH4>

      <HeaderWithFilters active={active} onClick={handleChangeStatus} />

      {isLoading && <SmallLoader />}

      {!isLoading && parkings.length > 0 && (
        <>
          <ParkingsList data-testid="parking-list">
            {parkings.map((parking) => (
              <ParkingCard parking={parking} onClick={handleClick} />
            ))}
          </ParkingsList>
          {!isLoading && parkings.length > 0 && (
            <Pagination page={page} pages={pages} handlePageClick={handlePageClick} />
          )}
        </>
      )}
    </Container>
  );
};
