import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IParkings } from 'Pages/Parking/Parking';

import { Label } from 'Components/UI/Label/Label';
import { Tooltip } from 'Components/UI/Tooltip/style';
import { Button } from 'Components/UI/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

import { color } from 'theme';
import {
  CardWrapper,
  ImgWrapper,
  ContentWrapper,
  ParkingImage,
  StyledText,
  StyledPrice,
  StyledNote,
  RatingContainer,
  RatingText,
  StyledTextRatingWrapper,
  ButtonsWrapper,
  ParkingsAttributesWrapper,
  ParkingAttribute
} from './style';

import image from 'assets/img/no-parking-photo.png';
import electricChargeImg from 'assets/img/electricCharge.jpg';
import overgroundParkingImg from 'assets/img/overgroundParking.jpg';
import undergroundParkingImg from 'assets/img/undergroundParking.jpg';
import ownerImg from 'assets/img/owner.webp';
import { SecurityIcon } from 'assets/svg';

export const ParkingCard = ({
  parkingName,
  street,
  house,
  carClass,
  price,
  status,
  images,
  onClick,
  onMouseOver,
  onMouseOut,
  current,
  rate,
  profile,
  hasBookButton = false,
  handleBookParking,
  id,
  isAccent,
  security = false,
  electricCharge = false,
  parkingStatus,
  parkingType,
  currency = ''
}: IParkings) => {
  const { t } = useTranslation(['common']);

  const bookParking = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      handleBookParking(e.currentTarget.value);
    },
    [handleBookParking]
  );

  const tooltipText: string = `parking.car_class_${carClass}`;

  return (
    <CardWrapper
      className="parking-card"
      data-testid="parking-card"
      current={current}
      onClick={hasBookButton ? () => {} : onClick}
      //@ts-expect-error
      onMouseOver={() => onMouseOver(Number(id))}
      onMouseOut={onMouseOut}
      id={id}
      isAccent={isAccent}>
      <ImgWrapper>
        <ParkingImage src={images ? `${images}` : image} />
      </ImgWrapper>
      <ContentWrapper>
        <StyledTextRatingWrapper>
          <StyledText>{`${street}, ${house}`}</StyledText>
          {!!rate && (
            <RatingContainer>
              <RatingText>{!!rate && rate > 0 && rate.toFixed(1)}</RatingText>
            </RatingContainer>
          )}
        </StyledTextRatingWrapper>

        <StyledText data-tip={t(`${tooltipText}`)}>
          <Tooltip />
          {t('parking.car_class')}: {t(`parking.${carClass}`)}
        </StyledText>
        {'    '}
        {price && (
          <>
            <StyledPrice>
              {price} {currency}
            </StyledPrice>
            {hasBookButton && <StyledNote>/ {t('parking.for_period')} </StyledNote>}
          </>
        )}
        <ButtonsWrapper>
          {!profile && hasBookButton ? (
            <Label height="25" status={status === 'MANUAL_APPROVE'}>
              {status === 'MANUAL_APPROVE' ? t('status.ondemand') : t('status.online')}
            </Label>
          ) : (
            <div></div>
          )}
          {hasBookButton ? (
            <Button
              primary={true}
              onClick={bookParking}
              height="35"
              width="auto"
              padding="0 10"
              value={id}
              color={color.gold}>
              {t('buttons.park_now')}{' '}
              <FontAwesomeIcon icon={faArrowCircleRight} style={{ marginLeft: '5px' }} />
            </Button>
          ) : (
            <Button
              primary={true}
              onClick={() => {}}
              height="35"
              width="auto"
              padding="0 10"
              value={id}
              color={color.gold}>
              {t('buttons.buy')}{' '}
              <FontAwesomeIcon icon={faArrowCircleRight} style={{ marginLeft: '5px' }} />
            </Button>
          )}
        </ButtonsWrapper>
        <ParkingsAttributesWrapper>
          {security && (
            <>
              <Tooltip />
              <ParkingAttribute data-tip={t('placeholders.security')}>
                <SecurityIcon />
              </ParkingAttribute>
            </>
          )}

          {electricCharge && (
            <>
              <Tooltip />
              <ParkingAttribute data-tip={t('placeholders.electric_charger')}>
                <img src={electricChargeImg} alt="parking lot with electric charger" />
              </ParkingAttribute>
            </>
          )}

          {parkingStatus !== 'APPROVED_UNVERIFIED' && (
            <>
              <Tooltip />
              <ParkingAttribute data-tip={t('status.verified_user')}>
                <img
                  src={ownerImg}
                  alt="verified owner parking lot"
                  style={{ height: '75%', marginTop: '6px' }}
                />
              </ParkingAttribute>
            </>
          )}

          {parkingType && (
            <>
              <Tooltip />
              <ParkingAttribute
                data-tip={
                  parkingType === 'OVERGROUND'
                    ? t('buttons.overground_parking')
                    : t('buttons.underground_parking')
                }>
                <img
                  src={parkingType === 'OVERGROUND' ? overgroundParkingImg : undergroundParkingImg}
                  alt={`${parkingType} parking lot`}
                />
              </ParkingAttribute>
            </>
          )}
        </ParkingsAttributesWrapper>
      </ContentWrapper>
    </CardWrapper>
  );
};
