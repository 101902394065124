import React from 'react';

export const MinusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="12" cy="12" r="12" fill="#2F80ED" />
      <rect width="2" height="12" rx="1" transform="matrix(0 -1 -1 0 18 13)" fill="white" />
    </svg>
  );
};
