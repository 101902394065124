import { types } from "./types";
import { ParkingPlace } from "api/parking";

interface SingleParkingState {
  data?: ParkingPlace | null;
  error?: string | null;
}

interface ParkingAction {
  type: string;
  payload?: SingleParkingState;
}

const initState: SingleParkingState = {
  data: null,
  error: null,
};

export const singleParkingReducer = (
  state = initState,
  action: ParkingAction
): SingleParkingState => {
  switch (action.type) {
    case types.GET_SINGLE_PARKING_SUCCESS:
      return {
        ...action.payload,
        error: null,
      };
    default:
      return state;
  }
};
