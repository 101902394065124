import React from 'react';
import { useTranslation } from 'react-i18next';
import { ParkingPlace } from 'api/parking';

import { ParkingCard as ParkingCardContainer, ParkingCardSection } from '../style.js';

type ParkingCardProps = {
  parking: ParkingPlace;
  onClick: () => number;
};

export const ParkingCard = ({ parking, onClick }: ParkingCardProps) => {
  const { t } = useTranslation(['admin']);

  const { id, street, house, user, files } = parking;

  return (
    <ParkingCardContainer data-testid="parking" key={id} onClick={() => onClick(id)}>
      <ParkingCardSection>
        <p data-testid="parkings_card_id">
          {t('admin.parkings_card_id')}
          <span>{id}</span>
        </p>
        <p data-testid="parkings_card_address">
          {t('admin.parkings_card_address')}
          <span>
            {street} {house}
          </span>
        </p>
        <p data-testid="parkings_card_docs">
          {t('admin.parkings_card_docs')}{' '}
          <span>
            {files.some((file) => file.type === 'DOCUMENT')
              ? t('admin.parkings_card_docs_present')
              : t('admin.parkings_card_docs_missing')}
          </span>
        </p>
      </ParkingCardSection>
      <ParkingCardSection>
        <p data-testid="parkings_card_user_name">
          {t('admin.parkings_card_user_name')}{' '}
          <span>
            {user?.name} {user?.surname}
          </span>
        </p>
        <p data-testid="parkings_card_id_partner">
          {t('admin.parkings_card_id_partner')} <span>{user?.id}</span>
        </p>
        <p data-testid="parkings_card_counts">
          {t('admin.parkings_card_counts')} <span>{user.countOfParkingPlaces}</span>
        </p>
      </ParkingCardSection>
      <ParkingCardSection>
        <p data-testid="parkings_card_user_country">
          {t('admin.country')}: <span>{user?.country}</span>
        </p>
      </ParkingCardSection>
    </ParkingCardContainer>
  );
};
