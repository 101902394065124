import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { smallVersionIcons, generateContactsIcons } from 'Pages/Contacts/ContactsModule';

import {
  ShortenFooterContainer,
  ShortenNav,
  ShortenListItem,
  ContactsIconsContainer,
  CopyRight
} from './styles.js';

export const ShortenFooter = () => {
  const { t } = useTranslation(['common']);

  return (
    <ShortenFooterContainer>
      <ShortenNav>
        <ShortenListItem>
          <NavLink to="/about-us">{t('footer.about_us')}</NavLink>
        </ShortenListItem>
        <ShortenListItem>
          <NavLink to="/partner">{t('footer.partners')}</NavLink>
        </ShortenListItem>
        <ShortenListItem>
          <NavLink to="/privacy-policy">{t('footer.privacy_policy')}</NavLink>
        </ShortenListItem>
        <ShortenListItem>
          <NavLink to="/conditions">{t('footer.conditions')}</NavLink>
        </ShortenListItem>
        <ShortenListItem>
          <NavLink to="/contacts">{t('footer.contacts')}</NavLink>
        </ShortenListItem>
      </ShortenNav>
      <CopyRight>Parkovki&copy;</CopyRight>
      <ContactsIconsContainer>{generateContactsIcons(smallVersionIcons)}</ContactsIconsContainer>
    </ShortenFooterContainer>
  );
};
