import { useState } from "react";

export const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const toggle = () => setIsShown(!isShown);
  return {
    isShown,
    toggle,
  };
};

export const useModalRegister = () => {
  const [isShownRegister, setIsShownRegister] = useState<boolean>(false);

  const toggleRegister = () => {
    setIsShownRegister(!isShownRegister);
  };
  return {
    isShownRegister,
    toggleRegister,
  };
};
