export function shortenFileName(name) {
  let file_name = name;
  let arr_filename = file_name.split(".");
  let file_ex = arr_filename[arr_filename.length - 1];
  if (file_name.length > 12) {
    file_name = file_name.substr(0, 12) + "..." + file_ex;
  }

  return file_name;
}
