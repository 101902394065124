import axios, { AxiosResponse } from "axios";
import { UserType } from "Components/Admin/Users/Users";
import {
  BLOCK_USER_URL,
  CHANGE_STATUS_URL,
  GET_USERS_URL,
  PARKING_PLACES_FOR_SALE_API_URL
} from 'config';

export interface userParams {
  userId?: string;
  name?: string;
  surname?: string;
  page: number;
}

export interface UsersResponse {
  results: UserType[];
  count: number;
}

export const getUsers = async (
  token: string | undefined | null,
  params?: userParams
): Promise<AxiosResponse<UsersResponse>> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  const response = await axios.get(GET_USERS_URL, {
    headers: headers,
    params
  });

  return response;
};

export interface StatusChangeBody {
  parkingPlaceId: string;
  status: string;
  message: string;
}

interface VarsType {
  body: StatusChangeBody;
  token: string;
}

export const setParkingPlaceStatus = async ({ body, token }: VarsType): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return axios.put(CHANGE_STATUS_URL, body, { headers: headers });
};

type StatusChangeSellParking = {
  status: string;
  message: string;
};
type setParkingSellPlaceStatusRequest = {
  body: StatusChangeSellParking;
  id: string;
  token: string;
};

export const setParkingSellPlaceStatus = async ({
  body,
  token,
  id
}: setParkingSellPlaceStatusRequest): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return axios.patch(`${PARKING_PLACES_FOR_SALE_API_URL}/${id}/update-status`, body, {
    headers: headers
  });
};

export interface BlockUserBody {
  userId: number;
  blocked: boolean;
}

export interface BlockUser {
  body: BlockUserBody;
  token: string;
}

export const blockUser = async ({ body, token }: BlockUser): Promise<AxiosResponse> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return axios.put(BLOCK_USER_URL, body, { headers: headers });
};