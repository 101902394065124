import styled from "styled-components";
import { color } from 'theme';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Menu = styled.div`
  width: 300px;
  padding: 50px 20px;
`;

export const Content = styled.div`
  width: 100%;
`;

export const List = styled.ul`
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  padding: 10px;
  pointer-events: ${({ isActive }) => (isActive ? 'none' : 'auto')};
  & > a {
    color: ${({ isActive }) => (isActive ? `${color.red}` : `${color.blue}`)};
  }
`;