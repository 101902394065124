import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GET_ALL_CUSTOMERS_BOOKINGS_URL } from 'config';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';
import Select from 'react-select';
import { getCustomersAvailableMonthsYears, MonthsYearsResponse } from 'api/finance';
import ReactPaginate from 'react-paginate';

import { Loading } from 'Components/Loader/Loader';
import { UserBookingContent } from './components/UserBookingContent';
import { PartnerInfoBlock, OptionsSelectorContainer, Label } from './style.js';
import { StyledSelect } from '../BillsAndPayments/style.js';
import { MAX_PAGE_NUMBER_RESULTS, ADMIN_TOKEN_KEY, TOptions } from '../constants.ts';
import { Params, UserBookingInfoType, GetAllBookingsType } from '../types.ts';

export type userBookingsResponse = {
  results: UserBookingInfoType[];
  count: number;
};

export const UserBookings = () => {
  const { id } = useParams<Params>();
  const token = localStorage.getItem(ADMIN_TOKEN_KEY) || '';
  const { state } = useLocation();
  const { t } = useTranslation(['admin']);
  const [page, setPage] = useState<number>(1);
  const [year, setYear] = useState<number | string>('all');
  const [month, setMonth] = useState<number | string>('all');
  const defaultOption = { label: t('payments.all_months'), value: 'all' };

  const [selectedMonth, setSelectedMonth] = useState<TOptions>(defaultOption);
  const [selectedYear, setSelectedYear] = useState<TOptions>(defaultOption);
  const [yearsOptions, setYearsOptions] = useState<OptionsType[]>([defaultOption]);
  const [monthsOptions, setMonthsOptions] = useState<OptionsType[]>([defaultOption]);

  const { isLoading: isDatesLoading } = useQuery(
    ['months', selectedYear],
    () =>
      getCustomersAvailableMonthsYears({
        token,
        userId: id,
        year: selectedYear.value !== 'all' && selectedYear.value
      }),
    {
      enabled: !!token,
      onError: (error) => {
        toast.error(t('notifications.connection_failed'), NOTIFICATION_OPTIONS);
      },
      onSuccess: ({ data: dates }: MonthsYearsResponse) => {
        if (dates && dates?.years?.length) {
          const years = dates?.years.reduce((acc, item) => {
            acc.push(item.year);
            return acc;
          }, []);

          const yearsOptions = [...new Set(years)].map((item) => {
            return { label: item, value: item };
          });
          setYearsOptions([defaultOption, ...yearsOptions]);

          const sortedMonth = dates?.years.sort((a, b) => a.month - b.month);
          const monthsOptions = sortedMonth.map((item) => {
            return { label: `${t(`months.${item.month}`)}`, value: item.month };
          });

          setMonthsOptions([defaultOption, ...monthsOptions]);
        }
      }
    }
  );

  const getAllCustomerBookings = async ({
    token,
    pageNumber = page,
    id
  }: GetAllBookingsType): Promise<AxiosResponse<userBookingsResponse>> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };

    const res = await axios.get(
      `${GET_ALL_CUSTOMERS_BOOKINGS_URL}?limit=${MAX_PAGE_NUMBER_RESULTS}&page=${pageNumber}&userId=${id}${
        month !== 'all' ? `&month=${month}` : ''
      }${year !== 'all' ? `&year=${year}` : ''}`,
      {
        headers: headers
      }
    );

    return res.data;
  };

  const { status, data, isLoading } = useQuery(
    [id, token, year, month],
    () => getAllCustomerBookings({ id, token }),
    {
      onError: (response: AxiosError<ErrorResponse>) => {
        if (response.response?.status === 500) {
          toast.error(t('notifications.server_error'), NOTIFICATION_OPTIONS);
        }
      }
    }
  );

  const handleYearChange = (value) => {
    setSelectedYear(value);
    setYear(value.value);
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
    setMonth(value.value);
  };

  const { userId, userName, userSurname, userEmail } = state;

  return (
    <>
      <PartnerInfoBlock>
        {(userName || userSurname) && (
          <div>
            <p>{t('partner_info.partner')}: </p>{' '}
            <p>
              {userName} {userSurname}
            </p>
          </div>
        )}
        {userEmail && (
          <div>
            <p>{t('partner_info.email')}: </p>
            <p>{userEmail}</p>
          </div>
        )}
        {userId && (
          <div>
            <p>{t('partner_info.partner_id')}: </p>
            <p>{userId}</p>
          </div>
        )}
      </PartnerInfoBlock>

      <OptionsSelectorContainer>
        <Label>
          {t('labels.choose_year')}:
          <Select
            options={yearsOptions}
            isSearchable={false}
            isMulti={false}
            value={selectedYear}
            styles={StyledSelect}
            onChange={handleYearChange}
            isDisabled={isDatesLoading}
          />
        </Label>

        <Label>
          {t('labels.choose_month')}:
          <Select
            options={monthsOptions}
            isSearchable={false}
            isMulti={false}
            value={selectedMonth}
            styles={StyledSelect}
            onChange={handleMonthChange}
            isDisabled={isDatesLoading}
          />
        </Label>
      </OptionsSelectorContainer>

      {isLoading && <Loading />}

      {!isLoading && status === 'success' && (
        <UserBookingContent data={data.results || []} setYear={setYear} setMonth={setMonth} />
      )}

      {data?.count > MAX_PAGE_NUMBER_RESULTS && (
        <div style={{ marginTop: '40px' }}>
          <ReactPaginate
            previousLabel={t('pagination.previous')}
            nextLabel={t('pagination.next')}
            breakLabel={'...'}
            pageCount={Math.ceil(count / MAX_PAGE_NUMBER_RESULTS)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(event) => setPage(event.selected + 1)}
            containerClassName={styles.pagination}
            activeClassName={styles.active}
            pageClassName={styles.page}
            previousClassName={styles.nextPrev}
            nextClassName={styles.nextPrev}
            disabledClassName={styles.disabled}
            breakClassName={styles.break}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  );
};
