import { MainHeader } from 'Components/Header/MainHeader';
import { Container } from './styles';
import { BackgroundWrapper } from 'Pages/HomePage/style.js';

import { ContactsModule } from './ContactsModule';

export const Contacts = () => {
  return (
    <>
      <BackgroundWrapper isSecondary />
      <MainHeader />
      <Container>
        <ContactsModule />
      </Container>
    </>
  );
};
