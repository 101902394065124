export const color = {
  backgroundGrey: '#F2F2F2',
  header: '#E5E9F2',
  darkGreen: '#09852C',
  green: '#20CF6B',
  lightGreen: '#1ADD50',
  blue: '#2F80ED',
  orange: '#F2994A',
  yellow: '#F2C94C',
  red: '#EB5757',
  black: '#000000',
  darkGrey: '#333333',
  greayText: '#808080',
  white: '#FFF',
  disabled: '#e6e9ed',
  modalBackground: 'rgba(51, 51, 51, 0.5)',
  gold: '#FFA500',
  lightGrey: '#cccccc',
  darkGreyBackground: 'rgba(50, 70, 88, 0.9)'
};

export const font = {
  primary: "Roboto, sans-serif",
};

export const fontSize = {
  small: "12px",
  regular: "14px",
  medium: "16px",
  large: "18px",
  m_large: "20px",
  x_large: "24px",
};

export const lineHeight = {
  x_small: '12px',
  medium: '18px',
  regular: '20px',
  x_large: '28px'
};

export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1366px",
  desktop: "1920px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
};
