import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { paths } from "config";
import { AddButtonIcon } from 'assets/svg';

import { Wrapper, StyledA, IconWrapper } from "./style";
import { getProfile } from "api/auth";
import { logout } from "Redux/reducers/login/loginReducer";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Modal from "react-modal";
import { Button } from "Components/UI/Button/Button";
import styles from "Components/Profile/Calendar/style.module.css";
import { useModal } from "hooks/useModal";
import { ModalAuth } from "Components/Modal/ModalAuth";

const customStyles = {
  overlay: {
    background: "rgba(51, 51, 51, 0.5)",
  },
};

type AddParkingLinkProps = {
  rent?: boolean;
};

export const AddParkingLink = ({ rent = true }: AddParkingLinkProps) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isShown, toggle } = useModal();
  async function handleClick() {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return toggle();
    }
    try {
      const { data } = await getProfile(localStorage.getItem('accessToken'));
      if (data.emailConfirmed) {
        rent ? history.push('/' + paths.createparking) : history.push('/' + paths.sellParkingPlace);
      } else {
        setStatus(true);
      }
    } catch (error) {
      dispatch(logout());
    }
  }

  const [status, setStatus] = useState(false);

  return (
    <>
      <ModalAuth isShown={isShown} hide={toggle} primary={true} />
      <Modal
        isOpen={status}
        onRequestClose={() => setStatus(false)}
        style={customStyles}
        contentLabel="Example Modal"
        className={styles.EmailModal}>
        <p style={{ textAlign: 'center' }}>{t('notifications.email_verification')}</p>
        <Button onClick={() => setStatus(false)}>{t('notifications.close')}</Button>
      </Modal>
      <Wrapper onClick={handleClick}>
        <IconWrapper>
          <AddButtonIcon />
        </IconWrapper>
        <StyledA data-testid="add-parking">
          {rent ? t('links.rent_parking') : t('links.sell_parking')}
        </StyledA>
      </Wrapper>
    </>
  );
};
