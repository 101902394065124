import styled from "styled-components";

import { color, fontSize, device } from "theme";

export const UserCardCont = styled.div`
  width: 100%;
  height: 110px;
  border-radius: 10px;
  background: #ffffff;
  padding: 10px;
  margin-bottom: 10px;

  @media ${device.tablet} {
    padding: 20px;
  }
`;

export const AvaWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;

  @media ${device.tablet} {
    width: 80px;
    height: 80px;
  }
`;

export const AvaImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const UserName = styled.p`
  font-size: ${fontSize.medium};
  font-weight: 400;
  line-height: 16px;

  @media ${device.tablet} {
    font-size: ${fontSize.large};
    font-weight: 500;
    line-height: 21px;
  }
`;

export const VerificationNote = styled.p`
  color: ${color.blue};
  font-size: ${fontSize.small};
  font-weight: 400;
  line-height: 12px;
  margin-top: 2px;

  @media ${device.tablet} {
    font-size: ${fontSize.regular};
    line-height: 16px;
  }
`;

export const Title = styled.h3`
  margin-bottom: 10px;
  font-weight: 600;
`;