import moment from 'moment';
import { DD_MM_YYYY } from 'Constants/constants';

type UserBillsType = {
  amount: number;
  createdDate: string;
  currency: string;
  id: number;
  paidAmount: number;
  paidDate: string | null;
  paymentStatus: string;
  type: string | null;
};

export const mapUserBills = (bills: UserBillsType[]) => {
  const billsTableContent = bills.map((bill) => {
    return {
      id: bill.id,
      month: moment(bill.createdDate).subtract(1, 'M').format('MMMM'),
      amount: bill.amount,
      currency: bill.currency,
      status: bill.paymentStatus,
      paymentDate: Boolean(bill.paidDate) ? moment(bill.paidDate).format(DD_MM_YYYY) : '-',
      paymentMethod: Boolean(bill.type) ? bill.type : '-',
      createdDate: bill.createdDate
    };
  });

  return billsTableContent;
};
