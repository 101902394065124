import styled from 'styled-components';

import { color, fontSize, lineHeight, device } from 'theme';

export const Container = styled.div``;

export const Row = styled.div`
  border-radius: 10px;
  background: ${color.white};
  display: grid;
  grid-template-columns: 1fr 1fr repeat(7, 1fr) 1.8fr 1.1fr;
  grid-template-rows: 1fr;
  min-height: 50px;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  width: 100%;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    grid-template-rows: repeat(10, 1fr);
    grid-template-columns: 1fr;
    height: auto;
  }
`;

export const Header = styled(Row)`
  @media ${device.tablet} {
    margin-top: 10px;
  }
`;

export const HeaderTitle = styled.div``;

export const Column = styled.div`
  font-size: ${fontSize.regular};
  line-height: ${lineHeight.regular};
  color: rgba(0, 0, 0, 0.5);
  word-break: break-word;
`;

export const Filter = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  width: 50%;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    grid-template-columns: 1fr;
    ${'' /* grid-template-rows: repeat(3, 1fr); */}
    grid-row-gap: 5px;
    width: 100%;
  }

  position: relative;
  bottom: 0;
  bottom: ${(props) => props.move && '-300px'};
  transition: bottom 1s ease 0s;
`;

export const Wrapper = styled.div``;

export const Booking = styled.div`
  display: flex;
  background: ${color.white};
  border-radius: 10px;
  justify-content: space-around;
  margin-top: 10px;
  padding: 10px 0;
`;

export const Dates = styled.div`
  display: flex;
`;

export const SelectItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background: ${color.blue};
    color: ${color.white};
  }
`;

export const MoreButton = styled.div`
  background: none;
  border: none;
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  :hover {
    color: ${color.blue};
  }
`;
