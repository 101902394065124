import { ParkingPlace } from "api/parking";
import { types } from "./types";

export const getSingleParking = (data: ParkingPlace) => {
  return {
    type: types.GET_SINGLE_PARKING_SUCCESS,
    payload: {
      data,
    },
  };
};
