import styled, { css } from 'styled-components';

import { color, fontSize, device, lineHeight } from 'theme';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ isSmall }) => (isSmall ? '6px 0 0 20px' : '10px 0')};
  color: ${color.greayText};
  font-size: ${fontSize.small};

  @media ${device.tablet} {
    font-size: ${fontSize.regular};
  }
`;

export const StyledInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 1.3em;
  height: 1.3em;
  border-radius: 0.1em;
  margin-right: 0.5em;
  border: 0.1em solid ${color.blue};
  outline: none;
  cursor: pointer;
  background-color: ${({ checked }) => (checked ? `${color.blue}` : 'transparent')};
  position: relative;
  transition: all 0.3s ease-in-out;

  &::before {
    ${({ checked }) =>
      checked
        ? css`
            content: '✔';
            font-size: 1em;
            color: #fff;
            position: absolute;
            right: 2px;
            top: 0px;
          `
        : css``};
  }
`;

export const IconCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  padding: 0 5px 0 5px;
  border: 1px solid ${({ checked }) => (checked ? color.blue : color.backgroundGrey)};
  border-radius: 8px;

  & label {
    display: flex;
  }

  & label,
  & p {
    color: ${({ checked }) => (checked ? color.blue : color.greayText)};
    cursor: pointer;
  }

  & label > svg,
  & label > img {
    display: block;
    width: 50px;
    height: 50px;
    margin-right: 5px;

    & > path {
      fill: ${({ checked }) => (checked ? color.blue : color.greayText)};
    }
  }
`;

export const StyledRoundCheckbox = styled(StyledInput)`
  width: 1.8em;
  height: 1.8em;
  border: none;
  border-radius: 50%;
  background-color: ${({ checked }) => (checked ? color.blue : color.backgroundGrey)};

  &::before {
    content: '✔';
    font-size: 1em;
    position: absolute;
    right: 6px;
    top: 5px;
    ${({ checked }) =>
      checked
        ? css`
            color: #fff;
          `
        : css`
            color: ${color.darkGrey};
            opacity: 0.5;
          `};
  }
`;

export const DescriptionText = styled.p`
  width: 205px;
  color: ${color.greayText};
  opacity: 0.6;
  font-size: ${fontSize.small};
  line-height: ${lineHeight.x_small};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
