import React from 'react';

export const FlagIconRussian = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 512 512"
      {...props}>
      <g fillRule="nonzero">
        <path
          fill="#4D4D4D"
          d="M256 0c70.684 0 134.689 28.664 181.012 74.987C483.336 121.311 512 185.316 512 256c0 70.684-28.664 134.689-74.988 181.013C390.689 483.336 326.684 512 256 512c-70.677 0-134.689-28.664-181.013-74.987C28.664 390.689 0 326.676 0 256c0-70.684 28.664-134.689 74.987-181.013C121.311 28.664 185.316 0 256 0z"></path>
        <path
          fill="#fff"
          d="M256.001 19.597c65.278 0 124.382 26.466 167.162 69.242 42.776 42.78 69.242 101.884 69.242 167.162S465.939 380.384 423.16 423.16c-42.777 42.78-101.881 69.246-167.159 69.246-65.278 0-124.382-26.466-167.162-69.243-42.777-42.779-69.243-101.884-69.243-167.162S46.062 131.619 88.839 88.839c42.78-42.776 101.884-69.242 167.162-69.242z"></path>
        <path
          fill="#fff"
          d="M472.405 256.232H39.597l-.003-.231c0-119.52 96.886-216.407 216.407-216.407 119.517 0 216.407 96.887 216.407 216.407l-.003.231z"></path>
        <path
          fill="#D52B1E"
          d="M39.597 256.232h432.808c-.124 119.411-96.963 216.176-216.404 216.176-119.444 0-216.28-96.765-216.404-216.176z"></path>
        <path
          fill="#0039A6"
          d="M52.038 183.55h407.926c8.048 22.652 12.444 47.036 12.444 72.451 0 25.586-4.452 50.131-12.606 72.915H52.2c-8.154-22.784-12.606-47.329-12.606-72.915 0-25.415 4.396-49.799 12.444-72.451z"></path>
      </g>
    </svg>
  );
};
