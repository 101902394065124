import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/store';
import { useTranslation } from 'react-i18next';
import { useModal } from 'hooks/useModal';

import { UserLoggedInfo } from 'Components/UserLoggedInfo/UserLoggedInfo';
import { ModalAuth } from 'Components/Modal/ModalAuth';
import { Link } from 'Components/UI/Link/Link';
import { Button } from 'Components/UI/Button/Button';
import { LanguageSelector } from './LanguageSelector';
import Logo from 'assets/img/logo.jpeg';
import src from 'assets/img/ava.png';
import { LoginIcon } from 'assets/svg';

import { color } from 'theme';
import {
  HeaderContainer,
  LeftSideWrapper,
  RightSideWrapper,
  Container,
  LogoImgCont
} from './style';

type MainHeaderProps = {
  isDark?: boolean;
};

export const MainHeader: FC<MainHeaderProps> = ({ isDark = false }) => {
  const { t } = useTranslation(['common']);
  const auth = useSelector((state: RootState) => state.auth);
  const { isShown, toggle } = useModal();

  const mainHeaderText = t('titles.main_header');

  return (
    <HeaderContainer isDark={isDark}>
      <ModalAuth isShown={isShown} hide={toggle} primary={true} />

      <Container>
        <LeftSideWrapper>
          <Link to="/">
            <LogoImgCont>
              <img
                src={Logo}
                alt={mainHeaderText}
                title={mainHeaderText}
                width="100%"
                height="100%"
              />
            </LogoImgCont>
          </Link>
          <LanguageSelector />
        </LeftSideWrapper>
        <RightSideWrapper>
          {auth.accessToken ? (
            <UserLoggedInfo src={src} />
          ) : (
            <Button
              dataTestid="auth-button"
              primary={true}
              onClick={toggle}
              height="40"
              color={color.gold}>
              <LoginIcon style={{ marginRight: '5px', fill: '#fff' }} />
              {t('buttons.offer_place')}
            </Button>
          )}
        </RightSideWrapper>
      </Container>
    </HeaderContainer>
  );
};
