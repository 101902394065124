import React, { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import Modal from 'react-modal';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { getUerBills } from 'api/finance';
import { OptionsType } from 'types/filterTypes';
import { RootState } from 'Redux/store';
import { mapUserBills } from './mapUserBills.ts';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';

import { Button } from 'Components/UI/Button/Button';
import { SmallLoader } from 'Components/Loader/Loader';
import { color } from 'theme';
import {
  StyledTable,
  StyledTBody,
  HeaderRaw,
  TableRaw
} from 'Components/Admin/UserBookings/components/style.js';
import { StatusCell } from 'Components/Admin/BillsAndPayments/style.js';
import { CellWithButton, inlineStyles, StyledSelect, UpperPanel } from './style.js';
import { StyledH3 } from 'Components/UI/H3/style.js';
import { StyledH4 } from 'Components/UI/H4/style.js';
import styles from 'Components/Admin/Users/style.module.css';

const NUMBER_OF_PAYMENTS_TO_SHOW = 25;

const TABLE_HEADERS = {
  ru: ['Месяц', 'Сумма', 'Статус оплаты', 'Дата оплаты', 'Метод оплаты'],
  uk: ['Місяць', 'Сума', 'Статус оплати', 'Дата оплати', 'Метод оплати'],
  en: ['Month', 'Amount', 'Payment status', 'Payment date', 'Payment method'],
  pl: ['Miesiąc', 'Kwota', 'Status płatności', 'Data płatności', 'Metoda płatności'],
  ro: ['Lună', 'Suma', 'Starea plății', 'Data plății', 'Metoda de plată']
};

const TABLE_CONTENT_SCHEME = ['month', 'amount', 'status', 'paymentDate', 'paymentMethod'];

type Props = {
  prepaid: string;
};

export const PartnerPayment = ({ prepaid }: Props) => {
  const { t, i18n } = useTranslation(['common']);
  const token = useSelector((state: RootState) => state?.auth?.accessToken);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<OptionsType>({
    value: 'all',
    label: `${t('parking.for_period')}`
  });
  const [page, setPage] = useState<number>(1);
  const [totalBills, setTotalBills] = useState<number>(0);
  const [bills, setBills] = useState([]);

  const {
    status: userBillsStatus,
    data: userBillsData,
    isLoading: userBillsIsLoading
  } = useQuery(['bills', page], () => getUerBills({ token, page }), {
    enabled: !!token,
    onError: (error: AxiosError) => {
      toast.error(t('notifications.connection_failed'), NOTIFICATION_OPTIONS);
    },
    onSuccess: (userBillsData) => {
      if (userBillsData?.data?.count > 0) {
        setTotalBills(userBillsData?.data?.count);
      }

      if (userBillsData?.data?.results?.length > 0) {
        const bills = mapUserBills(userBillsData?.data?.results);
        setBills(bills);
      }
    }
  });

  const tableHeader = useMemo(() => {
    return (
      <HeaderRaw>
        {TABLE_HEADERS[i18n.language].map((item, index) => (
          <th key={item} colSpan={index === 2 && '2'}>
            {item}
          </th>
        ))}
      </HeaderRaw>
    );
  }, [i18n]);

  const yearsOptions = useMemo(() => {
    const years = new Set(bills.map((item) => moment(item.createdDate).format('YYYY')));

    const yearsOptions = [...years].map((item) => ({
      label: item,
      value: item
    }));
    return [{ value: 'all', label: `${t('parking.for_period')}` }, ...yearsOptions];
  }, [t, bills]);

  const handleCheckPaymentDetail = useCallback((value) => {
    // setModalIsOpen(true);
  }, []);

  const handleYearChange = (value) => {
    setSelectedYear(value);
    let allData = bills;

    if (value.value === 'all') {
      setBills(allData);
    } else {
      const filteredTable = data.filter(
        (item) => moment(item.createdDate).format('YYYY') === value.value
      );
      setBills(filteredTable);
    }
  };

  const generateTableCellContent = (property, item) => {
    switch (property) {
      case 'status':
        return (
          <StatusCell key={property} isPayed={item[property] === 'COMPLETED'}>
            {item[property] === 'COMPLETED' ? t('titles.paid') : t('titles.not_paid')}
          </StatusCell>
        );

      case 'amount':
        return (
          <td key={property}>
            {item[property]} {item.currency}
          </td>
        );

      case 'paymentDate':
        return <td key={property}>{item[property]}</td>;

      default:
        return <td key={property}>{item[property]}</td>;
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={inlineStyles.modal}
        contentLabel="Payment editing Modal"
        // className={styles.Modal}
      >
        <div>Payment info</div>
      </Modal>

      <UpperPanel>
        <Select
          options={yearsOptions}
          isSearchable={false}
          isMulti={false}
          value={selectedYear}
          styles={StyledSelect}
          onChange={handleYearChange}
          isDisabled={bills.length === 0}
        />
        <StyledH4>
          {t('titles.prepaid')}: {prepaid}
        </StyledH4>
      </UpperPanel>

      {userBillsIsLoading && <SmallLoader />}

      {userBillsStatus === 'success' && bills.length === 0 && (
        <StyledH3>{t('notifications.no_payment_info')}</StyledH3>
      )}

      {userBillsStatus === 'success' && bills.length > 0 && (
        <>
          <StyledTable style={{ marginTop: '20px' }}>
            <thead>{tableHeader}</thead>
            <StyledTBody>
              {bills.map((item, index) => {
                return (
                  <TableRaw key={`${item.month}-${index}`} isEven={index % 2 === 0}>
                    {TABLE_CONTENT_SCHEME.map((table, index) => (
                      <>
                        {generateTableCellContent(table, item)}
                        {index === 2 && (
                          <CellWithButton color={item.status === 'PENDING' && color.green}>
                            {item.status === 'PENDING' && (
                              <Button
                                color={color.green}
                                dataTestid="pay-commission-button"
                                primary
                                onClick={handleCheckPaymentDetail}
                                disabled>
                                {t('buttons.pay')}
                              </Button>
                            )}
                          </CellWithButton>
                        )}
                      </>
                    ))}
                  </TableRaw>
                );
              })}
            </StyledTBody>
          </StyledTable>

          {totalBills > NUMBER_OF_PAYMENTS_TO_SHOW && (
            <ReactPaginate
              previousLabel={t('pagination.previous')}
              nextLabel={t('pagination.next')}
              breakLabel={'...'}
              pageCount={Math.ceil(count / NUMBER_OF_PAYMENTS_TO_SHOW)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(event) => setPage(event.selected + 1)}
              containerClassName={styles.pagination}
              activeClassName={styles.active}
              pageClassName={styles.page}
              previousClassName={styles.nextPrev}
              nextClassName={styles.nextPrev}
              disabledClassName={styles.disabled}
              breakClassName={styles.break}
              renderOnZeroPageCount={null}
            />
          )}
        </>
      )}
    </div>
  );
};
