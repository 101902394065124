import moment from 'moment';
import { PAYMENT_METHOD_OPTIONS } from '../constants';
import { UserType } from '../Users/Users.tsx';
import { DD_MM_YYYY } from 'Constants/constants';

type PartnerBillsType = {
  adminUserName: string;
  amount: number;
  createdDate: string;
  currency: string;
  id: number;
  paidAmount: number;
  paidDate: string | null;
  paymentStatus: string;
  type: string | null;
};

type PartnerPrepaidType = {
  id: number;
  reason: string;
  amount: number;
  createdDate: string;
  adminUserName: string;
};

const getPaymentMethod = (method: string) => {
  return PAYMENT_METHOD_OPTIONS.find((item) => item.value === method)?.label || '';
};

export const mapPartnersBills = (bills: PartnerBillsType[]) => {
  const billsTableContent = bills.map((bill) => {
    return {
      admin: bill.adminUserName,
      id: bill.id,
      month: moment(bill.createdDate).subtract(1, 'M').format('MMMM'),
      amount: bill.amount,
      currency: bill.currency,
      status: bill.paymentStatus,
      paymentDate: Boolean(bill.paidDate) ? moment(bill.paidDate).format(DD_MM_YYYY) : '-',
      paymentMethod: Boolean(bill.type) ? getPaymentMethod(bill.type) : '-',
      createdDate: bill.createdDate,
      paidAmount: Boolean(bill.paidAmount) ? bill.paidAmount : '-'
    };
  });

  return billsTableContent;
};

export const mapPartnersPrepaidTable = (prepaid: PartnerPrepaidType[]) => {
  const prepaidContent = prepaid.map((item) => {
    return {
      date: moment(item.date).format(DD_MM_YYYY),
      admin: item.adminUserName,
      reason: item.reason,
      paymentAmount: item.amount,
      currency: item?.currency || '',
      id: item.id
    };
  });

  return prepaidContent;
};

export const mapPartnerInfo = (partnerInfo: UserType) => {
  return [
    {
      partnerId: partnerInfo.id,
      name: `${partnerInfo.name} ${partnerInfo.surname}`,
      email: partnerInfo.email,
      phone: partnerInfo.phone,
      currency: partnerInfo?.currency || '',
      debt: partnerInfo.dept,
      advance: partnerInfo.prepaid
    }
  ];
};
