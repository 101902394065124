import React, { FC, useState, useEffect } from "react";
import GoogleMapReact from 'google-map-react';
import { GOOGLE_API_KEY } from 'config';
import MarkerImg from "assets/img/map-marker.png";
import { ICoordinates } from 'Pages/CreateParking/CreateParking';
import { PointOnMapWrapp, MarkerWrapper, MarkerIcon } from "./style";
import { useTranslation } from "react-i18next";

interface ICoordinatesProps {
  lat: number;
  lng: number;
  setNewCoordinates?: (value: null | ICoordinates) => void;
  notChange?: boolean;
}

const Marker: FC<ICoordinatesProps> = ({ lat, lng }) => {
  return (
    <PointOnMapWrapp>
      <MarkerWrapper lat={lat} lng={lng}>
        <MarkerIcon src={MarkerImg} alt="custom marker icon" />
      </MarkerWrapper>
    </PointOnMapWrapp>
  );
};

export const GoogleMap: FC<ICoordinatesProps> = ({
  lat,
  lng,
  setNewCoordinates,
  notChange,
}) => {
  let [coordinates, setCoordinates] = useState<ICoordinatesProps>({ lat, lng });
  const { i18n } = useTranslation(['common']);

  function onChangeCoordinates({ lat, lng }: ICoordinatesProps) {
    if (notChange) return;
    setCoordinates({ lat, lng });
    if (setNewCoordinates) {
      setNewCoordinates({ lat, lng });
    }
  }

  useEffect(() => {
    setCoordinates({ lat, lng });
  }, [lat, lng]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_API_KEY, language: i18n.language, libraries: ['places'] }}
      center={{ lat, lng }}
      defaultZoom={12}
      onClick={(value) => onChangeCoordinates({ lat: value.lat, lng: value.lng })}>
      <Marker lat={coordinates.lat} lng={coordinates.lng} />
    </GoogleMapReact>
  );
};
