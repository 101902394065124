import { BankInfo, changeBankInfo, getBankInfo } from "api/parking";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ResponseMessage } from "../UI/style";
import { useTranslation } from "react-i18next";
import { Button } from 'Components/UI/Button/Button';
import { StyledH2 } from 'Components/UI/H2/style.js';
import { Container, Form, Input, Label } from './style';

import { ADMIN_TOKEN_KEY } from '../constants.ts';

interface ResponseMessageType {
  success: boolean;
  message: string;
}

function Bank() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid }
  } = useForm<BankInfo>();
  const { t } = useTranslation(['admin']);

  const token = localStorage.getItem(ADMIN_TOKEN_KEY) || '';

  useEffect(() => {
    (async function () {
      try {
        const { data } = await getBankInfo(token);
        reset({
          reciver: data.reciver,
          recieverCode: data.recieverCode,
          recieverBank: data.recieverBank,
          iban: data.iban
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [reset, token]);

  const [responseMessage, setResponseMessage] = useState<ResponseMessageType>(
    {} as ResponseMessageType
  );

  async function onSubmit(data: BankInfo) {
    try {
      await changeBankInfo(data, token);
      setResponseMessage({
        message: t('notifications.change_bank_info_success'),
        success: true
      });
    } catch (error) {
      setResponseMessage({
        message: t('notifications.change_bank_info_failed'),
        success: false
      });
    }
  }

  return (
    <Container>
      <StyledH2>{t('admin.bank_title')}</StyledH2>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label>{t('admin.receiver')}</Label>
        <Input {...register('reciver')} />
        <Label>{t('admin.receiver_code')}</Label>
        <Input {...register('recieverCode')} />
        <Label>{t('admin.receiver_bank')}</Label>
        <Input {...register('recieverBank')} />
        <Label>{t('admin.receiver_iban')}</Label>
        <Input {...register('iban')} />
        <Button disabled={!isDirty || !isValid} primary>
          {t('buttons.change')}{' '}
        </Button>
        {responseMessage && (
          <ResponseMessage success={responseMessage.success}>
            {responseMessage.message}
          </ResponseMessage>
        )}
      </Form>
    </Container>
  );
}

export default Bank;