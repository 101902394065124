import React from 'react';
import { useTranslation } from 'react-i18next';
import { SingleParkingType } from 'types/parkingTypes';
import { SellAdditionalInfo } from './SellAdditionalInfo';
import { Advantages } from './Advantages';

import { Wrapper, Container, StyledTitle, StyledP, Row } from './style';
import { StyledText } from 'Components/ParkingCard/style';

type Props = {
  width: string;
  showUserInfo?: boolean;
  parkingInfo: SingleParkingType;
};

export const SellParkingDescription = ({ width, parkingInfo }: Props) => {
  const { t } = useTranslation(['common']);

  const {
    parkingType,
    parkingLevel,
    carClass,
    placeLength,
    placeWidth,
    heightOfParkingEntry,
    electricCharge,
    elevator,
    security,
    familyPlace,
    owner,
    description,
    street,
    house,
    city
  } = parkingInfo;

  return (
    <Wrapper width={width}>
      <Row>
        <StyledText>
          {street} {house}, {city}
        </StyledText>
      </Row>
      <SellAdditionalInfo
        parkingType={parkingType}
        parkingLevel={parkingLevel}
        carClass={carClass}
        placeLength={placeLength}
        placeWidth={placeWidth}
        heightOfParkingEntry={heightOfParkingEntry}
      />
      <Advantages
        electricCharge={electricCharge}
        elevator={elevator}
        security={security}
        familyPlace={familyPlace}
        owner={owner}
      />
      {description && (
        <Container>
          <StyledTitle>{t('placeholders.description')}</StyledTitle>
          <StyledP>{description}</StyledP>
        </Container>
      )}
    </Wrapper>
  );
};
