import React from 'react';
import { useTranslation } from 'react-i18next';
import { PAYMENT_STATUS } from 'Components/Admin/constants.ts';

import { Button } from 'Components/UI/Button/Button';

import {
  StyledTable,
  StyledTBody,
  HeaderRaw,
  TableRaw
} from '../../UserBookings/components/style.js';
import { CellWithButton, StatusCell } from '../style.js';
import { color } from 'theme';

const PARTNER_PAYMENT_TABLE_HEADERS = {
  month: 'Месяц',
  paymentDate: 'Дата оплаты',
  admin: 'Админ',
  paymentMethod: 'Метод оплаты',
  amount: 'Долг',
  paidAmount: 'Сумма внесения',
  status: 'Статус оплаты'
};

export const PaymentsTable = ({ payment, onClick }) => {
  const { t } = useTranslation(['admin']);

  const paymentTableHeader = (
    <HeaderRaw>
      {Object.values(PARTNER_PAYMENT_TABLE_HEADERS).map((item, index) => (
        <th
          key={item}
          {...(index === Object.values(PARTNER_PAYMENT_TABLE_HEADERS).length - 1 && {
            colSpan: 2
          })}>
          {item}
        </th>
      ))}
    </HeaderRaw>
  );
  return (
    <StyledTable>
      <thead>{paymentTableHeader}</thead>
      <StyledTBody>
        {payment.map((item, index) => (
          <TableRaw key={`${item.id}-${index}`} isEven={index % 2 === 0}>
            <>
              {Object.keys(PARTNER_PAYMENT_TABLE_HEADERS).map((key, index) => {
                return key === 'status' ? (
                  <StatusCell
                    key={`${item.id}-${index}`}
                    isPayed={item[key] === PAYMENT_STATUS.COMPLETED}>
                    {item[key] === PAYMENT_STATUS.COMPLETED
                      ? t('payments.paid')
                      : t('payments.not_paid')}
                  </StatusCell>
                ) : (
                  <td key={`${item.id}-${index}`} style={{ textAlign: 'center' }}>
                    {item[key]} {(key === 'amount' || key === 'paidAmount') && item.currency}
                  </td>
                );
              })}
              <CellWithButton>
                <Button
                  color={color.green}
                  dataTestid="edit-payment-button"
                  primary
                  onClick={() => onClick(item)}
                  disabled={item?.admin.toLowerCase() === 'generated by system'}>
                  {t('buttons.edit')}
                </Button>
              </CellWithButton>
            </>
          </TableRaw>
        ))}
      </StyledTBody>
    </StyledTable>
  );
};
