import React from 'react';

export const PriceDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 512 512"
      {...props}>
      <g>
        <path
          d="M420 402c-10 0-20-4-28-12l-53-53c-8-7-12-17-12-27 0-11 4-21 12-28 7-7 17-11 27-11 5 0 11 1 15 3V149c0-22 18-39 39-39 22 0 39 17 39 39v125c4-2 9-3 14-3 11 0 20 4 28 11 7 8 11 17 11 28 0 10-4 20-11 27l-54 54c-7 7-17 11-27 11zM146 402C65 402 0 336 0 256s65-146 146-146c80 0 146 66 146 146s-66 146-146 146zm0-27c-66 0-119-54-119-119s53-119 119-119c65 0 118 54 118 119s-53 119-118 119zm0-224c-58 0-105 47-105 105s47 105 105 105c57 0 104-47 104-105s-47-105-104-105zm-7 181c-16-2-29-14-34-29-1-4 1-8 5-9 3-1 7 1 8 5 4 11 15 20 27 20h1c15-1 27-13 27-28s-13-28-27-28c-23 0-41-19-41-42 0-20 14-37 34-41v-18c0-4 3-7 7-7s7 3 7 7v18c16 2 29 14 34 29 1 4-1 8-5 9s-8-1-9-5c-3-11-14-20-26-20h-1c-15 1-27 13-27 28s12 28 27 28c22 0 41 19 41 42 0 20-15 37-34 41v18c0 4-3 7-7 7s-7-3-7-7v-18z"
          className="fil0"></path>
      </g>
    </svg>
  );
};
