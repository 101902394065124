import React from 'react';

export const CarUndergroundIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      version="1.1"
      viewBox="0 0 11 11"
      {...props}>
      <path d="M7.25 7.44a2.35 2.35 0 01-1.53.44H4.45V10H3.19V4H5.8a2.13 2.13 0 011.44.46c.385.372.583.897.54 1.43a1.84 1.84 0 01-.53 1.55zm-1-2.16a1 1 0 00-.68-.2H4.45v1.76H5.6a1 1 0 00.68-.22.87.87 0 00.24-.68.82.82 0 00-.24-.66h-.03zm4.16-2a.5.5 0 00-.19-.68L5.72.1a.5.5 0 00-.49 0L.73 2.6a.5.5 0 00.49.87l4.28-2.4 4.26 2.37a.5.5 0 00.679-.198l.001-.002-.03.04z"></path>
    </svg>
  );
};
