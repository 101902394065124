import styled from "styled-components";

import { color, fontSize } from 'theme';

export const Container = styled.div`
  padding: 50px;
  background: ${color.backgroundGrey};
`;

export const User = styled.div`
  padding: 20px;
  background: ${color.white};
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;

export const Input = styled.input`
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  padding: 10px 20px;
  width: 50%;
  background: ${color.white};
  outline: none;
`;

export const Button = styled.button`
  height: 50px;
  border: none;
  background: ${(props) => props.color};
  border-radius: 10px;
  width: 150px;
  color: ${color.white};
  font-family: Roboto;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  span {
    color: ${color.darkGrey};
    font-size: ${fontSize.regular};
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ContextWrapper = styled.div`
margin: auto 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 110px;
`;
export const ButtonsWrapperModal = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`;

export const inlineStyles = {
  modal: {
    overlay: {
      background: color.modalBackground,
    },
  }

};