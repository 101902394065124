import React, { useRef, useState, useCallback, useMemo, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import axios, { AxiosResponse, AxiosError } from "axios";
import { useQuery, useInfiniteQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import StarRatingComponent from "react-star-rating-component";
import { Carousel } from 'react-responsive-carousel';
import * as QueryString from 'querystring';
import { isMobile } from 'react-device-detect';
import { feedbackSchema, GET_REVIEWS_BY_PARKING_ID } from 'config';
import { getOneParkingPlace, ParkingPlace, postReview, validateToken } from 'api/parking';
import { getAllParkingsError } from 'Redux/reducers/parking/actions';
import { getSingleParking } from 'Redux/reducers/singleParking/actions';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useModal } from 'hooks/useModal';
import { dd_MM_yyyy } from 'Constants/constants';
import { MainHeader } from 'Components/Header/MainHeader';
import { ParkingDescription } from 'Components/ParkingDescription/ParkingDescription';
import { GoogleMap } from 'Components/GoogleMap/GoogleMap';
import { RatingCard } from 'Components/RatingCard/RatingCard';
import { SuccessModal } from 'Components/Modal/SuccessModal';
import { Loading } from 'Components/Loader/Loader';
import { Background } from 'Pages/Parking/style';
import { Button } from 'Components/UI/Button/Button';
import { RequiredLabel } from 'Components/UI/StyledLabel/style';
import { Notification } from 'Components/UI/Notification/Notification';
import { MapWrapper } from 'Components/UI/Map/style';
import { PaginationBlock } from 'Pages/Parking/style';
import { StyledNotification } from 'Components/Profile/OnDemand/style';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';
import { MainContainer, BannerWrapper, StyledImage, ImgWrapper } from 'Pages/AddParking/style';
import {
  FeedbackForm,
  Title,
  BookingInfoCont,
  BookingDetails,
  TextArea,
  BottomCont,
  RatingCont,
  StarsWrapper,
  RatingTitle,
  ParkingInfoContainer
} from './style';

type FeedbackValues = {
  feedback: string;
};

type RatingValues = {
  comfort: number;
  size: number;
  talk: number;
};

type ErrorReviewsResponse = {
  statusCode: number;
  message: string;
  error: string;
};

export const Feedback = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { isShown, toggle } = useModal();
  const parsedToken = QueryString.parse(location.search);
  const [imagesLength, setImagesLength] = useState<number>(0);

  const {
    data: tokenValidationData,
    isLoading: tokenValidationLoading,
    status: tokenValidationStatus
  } = useQuery('validateToken', () => validateToken(parsedToken['?token'].toString()));

  const {
    data: data_parking,
    status: data_status,
    isSuccess: data_isSuccess
  } = useQuery({
    queryKey: [tokenValidationData],
    queryFn: () =>
      getOneParkingPlace({ id: tokenValidationData && tokenValidationData.data.parkingPlaceId }),

    onSuccess: (response: AxiosResponse<ParkingPlace>) => {
      dispatch(getSingleParking(response.data));
    },
    onError: (error: AxiosError) => {
      dispatch(getAllParkingsError('Упс, щось пішло не так... Спробуйте пізніше.'));
    }
  });

  useEffect(() => {
    const imgArr = data_parking?.data?.files.filter(
      (file) => file.type === 'IMAGE' || file.type === 'MAIN_IMAGE'
    );
    if (imgArr?.length) {
      setImagesLength(imgArr?.length);
    }
  }, [data_parking]);

  const images = useMemo(
    () =>
      data_parking?.data?.files
        .filter((file) => file.type === 'IMAGE' || file.type === 'MAIN_IMAGE')
        .map((image) => (
          <ImgWrapper key={image.id}>
            <StyledImage src={`${image.path}`} />
          </ImgWrapper>
        )),
    [data_parking]
  );

  const getParkingPlaceReviews = async ({ pageParam = 1 }) => {
    let url = `${GET_REVIEWS_BY_PARKING_ID}${pageParam}&parkingPlaceId=${tokenValidationData.data.parkingPlaceId}`;
    const res = await axios.get(url);
    return { review_data: res.data, nextPage: pageParam + 1 };
  };

  const {
    status: review_status,
    data: review_data,
    isSuccess: review_isSuccess,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery('getParkingPlaceReviews', getParkingPlaceReviews, {
    getNextPageParam: (lastPage) => lastPage.nextPage,
    onError: (response: AxiosError<ErrorReviewsResponse>) => {
      if (response.response?.status === 500) {
        toast.error(t('notifications.server_error'), NOTIFICATION_OPTIONS);
      }
    }
  });

  const loadMoreRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  //@ts-expect-error
  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage
  });

  const { mutateAsync } = useMutation(postReview, {
    onError: (error: AxiosError) => {
      if (
        error.response?.status === 500 ||
        error.response?.status === 400 ||
        error.response?.status === 404
      ) {
        let message =
          error.response?.status === 500
            ? t('notifications.server_error')
            : t('notifications.feedback_error');

        toast.error(message, NOTIFICATION_OPTIONS);
      }
    },
    onSuccess: (response: AxiosResponse) => {
      if (response.status === 201) {
        reset();
        toggle();
      }
    }
  });

  const streetLat = data_parking?.data?.streetLat;
  const streetLng = data_parking?.data?.streetLng;

  const [rating, setRating] = useState<RatingValues>({
    comfort: 5,
    size: 5,
    talk: 5
  });

  const onStarClick = (nextValue: any, prevValue: any, name: string) => {
    if (name === 'comfort') {
      setRating({ ...rating, comfort: nextValue });
    } else if (name === 'size') {
      setRating({ ...rating, size: nextValue });
    } else if (name === 'talk') {
      setRating({ ...rating, talk: nextValue });
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FeedbackValues>({
    resolver: yupResolver(feedbackSchema)
  });

  const onSubmit = useCallback(
    (data: FeedbackValues) => {
      const { feedback } = data;
      const { comfort, talk, size } = rating;
      mutateAsync({
        description: feedback,
        size: size,
        comfort: comfort,
        talk: talk,
        token: parsedToken['?token'] as string
      });
    },
    [rating, mutateAsync, parsedToken]
  );

  const backToLogin = () => {
    history.push('/');
  };

  return (
    <>
      <SuccessModal backToLogin={backToLogin} isRegister={false} isShown={isShown} hide={toggle} />
      <Background height="unset">
        <MainHeader />
        {(data_status === 'loading' || tokenValidationLoading) && <Loading />}
        <MainContainer>
          {tokenValidationStatus === 'error' && <Title>{t('notifications.token_expired')}</Title>}
          {tokenValidationStatus === 'success' && data_status === 'success' && (
            <>
              <Title>{t('titles.leave_feedback')}</Title>
              <FeedbackForm>
                <BookingInfoCont>
                  <BookingDetails>
                    {t('parking.rent')} {t('parking.of')}{' '}
                    {tokenValidationData &&
                      format(new Date(tokenValidationData.data.from), dd_MM_yyyy)}{' '}
                    {t('placeholders.to')}{' '}
                    {tokenValidationData &&
                      format(new Date(tokenValidationData.data.to), dd_MM_yyyy)}
                  </BookingDetails>
                  <BookingDetails>
                    {t('titles.price')}: {tokenValidationData && tokenValidationData.data.price}{' '}
                    {t('parking.uah')}
                  </BookingDetails>
                </BookingInfoCont>

                <RequiredLabel
                  text
                  htmlFor="feedback"
                  required={true}
                  maxHeight="150px"
                  marginBottom={isMobile ? '10' : '25'}>
                  <TextArea
                    placeholder={t('placeholders.comment')}
                    rows="8"
                    {...register('feedback')}
                  />
                  <Notification isAccent={true}>
                    {errors?.feedback?.message && t('login.required')}
                  </Notification>
                </RequiredLabel>

                <BottomCont>
                  <RatingCont>
                    <StarsWrapper>
                      <RatingTitle>{t('titles.comfort')}</RatingTitle>
                      <StarRatingComponent
                        name="comfort"
                        starCount={5}
                        value={rating.comfort}
                        onStarClick={onStarClick}
                        emptyStarColor={'#C2CFE0'}
                      />
                    </StarsWrapper>

                    <StarsWrapper>
                      <RatingTitle>{t('titles.place_sizes')}</RatingTitle>
                      <StarRatingComponent
                        name="size"
                        starCount={5}
                        value={rating.size}
                        onStarClick={onStarClick}
                        emptyStarColor={'#C2CFE0'}
                      />
                    </StarsWrapper>

                    <StarsWrapper>
                      <RatingTitle>{t('titles.contacting')}</RatingTitle>
                      <StarRatingComponent
                        name="talk"
                        starCount={5}
                        value={rating.talk}
                        onStarClick={onStarClick}
                        emptyStarColor={'#C2CFE0'}
                      />
                    </StarsWrapper>
                  </RatingCont>

                  <Button
                    primary={true}
                    width={isMobile ? '125' : '225'}
                    height={isMobile ? '30' : '50'}
                    onClick={handleSubmit(onSubmit)}>
                    {t('buttons.done')}
                  </Button>
                </BottomCont>
              </FeedbackForm>
            </>
          )}

          {data_isSuccess && (
            <>
              {!!imagesLength && (
                <BannerWrapper>
                  <Carousel showThumbs={false} showStatus={false}>
                    {images}
                  </Carousel>
                </BannerWrapper>
              )}

              <ParkingInfoContainer>
                <ParkingDescription width={isMobile ? '100%' : '70%'} showUserInfo={true} />
              </ParkingInfoContainer>

              <Title>{t('titles.map')}</Title>
              <MapWrapper>
                <GoogleMap
                  //@ts-expect-error
                  lat={streetLat}
                  //@ts-expect-error
                  lng={streetLng}
                />
              </MapWrapper>

              <Title>{t('titles.client_feedbacks')}</Title>

              {review_status === 'loading' && <div>loading</div>}

              {review_data?.pages[0]?.review_data.count === 0 && (
                <StyledNotification>{t('notifications.no_feedbacks')}</StyledNotification>
              )}

              {review_isSuccess &&
                review_data?.pages.map((page) => (
                  <React.Fragment key={page.nextPage}>
                    {page?.review_data?.results.map((item: any) => {
                      const carName =
                        page?.review_data.carNamesArr[page?.review_data?.results.indexOf(item)];
                      return <RatingCard key={item.id} rating={item} carName={carName} />;
                    })}
                  </React.Fragment>
                ))}

              {review_data?.pages[0]?.review_data.count > 10 && (
                <PaginationBlock ref={loadMoreRef}></PaginationBlock>
              )}
            </>
          )}
        </MainContainer>
      </Background>
    </>
  );
};
