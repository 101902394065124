import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shortenFileName } from 'helpers/shortenFileName';

import { ImagePreview } from 'Components/ImagePreview/ImagePreview';

import pdfIcon from 'assets/img/pdf.png';
import {
  UploadFile,
  UploadLabel,
  TextP,
  PreviewImgContainer,
  FilesCont
} from 'Pages/CreateParking/style.js';

type FileUploaderProps = {
  filesSelected: File[] | [];
  setFilesSelected: React.Dispatch<React.SetStateAction<File[]>>;
};

export const FileUploader = ({ filesSelected, setFilesSelected }: FileUploaderProps) => {
  const { t } = useTranslation(['common']);
  const [docsError, setDocsError] = useState(false);

  const handleDocsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;
      if (!fileList) return;
      const arr = Array.from(fileList);

      const err = arr.some((element) => {
        if (element.size / 1024 ** 2 > 10) {
          return true;
        }
        return false;
      });

      if (err) {
        setDocsError(err);
        return;
      }

      const newFilesList = [...filesSelected, ...arr].slice(0, 5);

      setFilesSelected(newFilesList);
    },
    [filesSelected, setFilesSelected]
  );

  const deleteFile = useCallback(
    (id: number) => {
      const newFilesList = filesSelected.filter((item, index) => index !== id);

      setFilesSelected(newFilesList);
    },
    [filesSelected, setFilesSelected]
  );

  return (
    <>
      <UploadLabel htmlFor="documents">
        {t('buttons.upload')}
        <UploadFile
          name="documents"
          id="uploadDocs"
          type="file"
          accept=".jpeg, .jpg, .png, .pdf"
          multiple
          onChange={handleDocsChange}
        />
      </UploadLabel>
      {!!filesSelected.length && (
        <PreviewImgContainer>
          {filesSelected.map((item, index) => (
            <FilesCont key={item.name}>
              <ImagePreview
                document={true}
                width="100"
                src={pdfIcon}
                onClick={() => deleteFile(index)}
              />
              <TextP>{shortenFileName(item.name)}</TextP>
            </FilesCont>
          ))}
        </PreviewImgContainer>
      )}
      {docsError && <p style={{ color: color.red }}>{t('notifications.file_size_big')}</p>}
      <TextP>{t('texts.docs_text')}</TextP>
    </>
  );
};
