import React from "react";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Redux/store';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { paths } from 'config';

import { Button } from 'Components/UI/Button/Button';

import { ReactComponent as Notification } from 'assets/svg/notification-icon.svg';
import { UserCont, StyledText } from './style';
import { logout } from 'Redux/reducers/login/loginReducer';

type UserLogged = {
  src?: string;
};

export const UserLoggedInfo = ({ src }: UserLogged) => {
  const { t } = useTranslation(['common']);
  const auth = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogOut = () => {
    dispatch(logout());
    history.push('/');
  };

  return (
    <>
      <Button
        dataTestid="logout"
        primary={true}
        height={isMobile ? '30' : '50'}
        width={isMobile ? '60' : '100'}
        margin={isMobile ? '20' : '50'}
        margin_right="10px"
        onClick={handleLogOut}>
        {t('buttons.log_out')}
      </Button>

      <Link to={`/${paths.partnerprofile}`}>
        <UserCont>
          <Notification />
          <StyledText style={{ marginLeft: '10px' }}>ID {`${auth?.id}`}</StyledText>

          <StyledText
            data-testid="fullname"
            style={{ marginLeft: '10px' }}>{`${auth?.name} ${auth?.surname}`}</StyledText>
        </UserCont>
      </Link>
    </>
  );
};
