export const getRangeRadius = (p1: google.maps.LatLng, p2: google.maps.LatLng) => {
  const R = 6371.071; // Radius of the Earth in kilometers

  const rlat1 = p1.lat() * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = p2.lat() * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (p2.lng() - p1.lng()) * (Math.PI / 180); // Radian difference (longitudes)

  const result =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
      )
    );
  return (result / 2) * 1000;
};

export const getBoundsRadius = ({ bounds, center }) => {
  // r = radius of the earth in km
  const r = 6378.8;
  // degrees to radians (divide by 57.2958)
  const ne_lat = bounds.ne.lat / 57.2958;
  var ne_lng = bounds.ne.lng / 57.2958;
  var c_lat = center.lat / 57.2958;
  var c_lng = center.lng / 57.2958;
  // distance = circle radius from center to Northeast corner of bounds
  var r_km =
    r *
    Math.acos(
      Math.sin(c_lat) * Math.sin(ne_lat) +
        Math.cos(c_lat) * Math.cos(ne_lat) * Math.cos(ne_lng - c_lng)
    );
  return r_km * 1000; // radius in meters
};
