import React, { useState, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import Select from 'react-select';
import { setNewLanguageSelection } from 'api/auth';
import {
  FlagIconRussian,
  FlagIconUkraine,
  FlagIconRomania,
  FlagIconPoland,
  FlagIconEngland
} from 'assets/svg';
import { PARKOVKI_HOSTNAMES } from 'Constants/constants';

import { StyledSelect, OptionItem } from './style';

interface ILanguages {
  value: string;
  label: string;
  icon?: ReactElement;
  isDisabled?: boolean;
}

export const languageOptionsExtended: ILanguages[] = [
  { value: 'uk', label: 'Українська', icon: <FlagIconUkraine />, isDisabled: false },
  { value: 'en', label: 'English', icon: <FlagIconEngland />, isDisabled: false },
  { value: 'ro', label: 'Română', icon: <FlagIconRomania />, isDisabled: false },
  { value: 'pl', label: 'Polski', icon: <FlagIconPoland />, isDisabled: false },
  { value: 'ru', label: 'Русский', icon: <FlagIconRussian />, isDisabled: false }
];

export const languageOptionsRo: ILanguages[] = [
  { value: 'ro', label: 'Română', icon: <FlagIconRomania />, isDisabled: false },
  { value: 'ru', label: 'Русский', icon: <FlagIconRussian />, isDisabled: false }
];

export const LanguageSelector = () => {
  const { i18n } = useTranslation(['common']);
  const hostname = window.location.hostname;
  const isParkovkiMd = hostname === PARKOVKI_HOSTNAMES.MD;
  const fallbackLanguageOption = isParkovkiMd ? languageOptionsRo[0] : languageOptionsExtended[0];

  const filteredLanguageOptions = isParkovkiMd ? languageOptionsRo : languageOptionsExtended;

  const defaultOption =
    filteredLanguageOptions.find((option) => option.value === i18n.language) ||
    fallbackLanguageOption;

  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const { mutateAsync } = useMutation(setNewLanguageSelection);

  const handleLanguageChange = (e: ILanguages) => {
    localStorage.setItem('language', e.value);
    setSelectedOption(e);

    const token = localStorage.getItem('accessToken') || '';

    if (token) {
      mutateAsync({ token, language: e.value.toUpperCase() });
    }

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <Select
      options={filteredLanguageOptions}
      isSearchable={false}
      isMulti={false}
      value={{ value: selectedOption.value, icon: selectedOption.icon }}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      styles={StyledSelect}
      getOptionLabel={(e) => (
        <OptionItem isDisabled={e.isDisabled}>
          <div style={{ width: '30px', height: '30px' }}>{e.icon}</div>
          <p style={{ marginLeft: 5 }}>{e.label}</p>
        </OptionItem>
      )}
      onChange={handleLanguageChange}
    />
  );
};
