import axios, { AxiosResponse } from "axios";

import { BOOKING_CONFIRMATION_URL, BOOK_PARKING_PLACE_URL } from "config";

export interface IBookParkingProps {
  name: string;
  car: string;
  color: string;
  phoneNumber: string;
  from: string;
  to: string;
  parkingPlaceId: number | undefined;
}

export const bookNewParkingPlace = (body: IBookParkingProps): Promise<AxiosResponse> => {
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.post(BOOK_PARKING_PLACE_URL, body, { headers: headers });
};

export const confirmBooking = (token: string, authToken: string): Promise<AxiosResponse> => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };

  return axios.post(BOOKING_CONFIRMATION_URL, {}, { headers: headers, params: { token } });
};