import React, { useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import Modal from 'react-modal';
import { getPartnerPrepaidDataAdmin } from 'api/finance';
import { mapPartnersPrepaidTable } from '../mapPartnersBills.ts';

import { Button } from 'Components/UI/Button/Button';
import { EditAdvancePayment } from './EditAdvancePayment';
import { SmallLoader } from 'Components/Loader/Loader';
import { StyledH3 } from 'Components/UI/H3/style.js';
import { Pagination } from '../../UI/Pagination/Pagination';

import { color } from 'theme';
import { inlineStyles } from '../style.js';
import {
  StyledTable,
  StyledTBody,
  HeaderRaw,
  TableRaw
} from '../../UserBookings/components/style.js';
import { CellWithButton } from '../style.js';
import { MAX_PAGE_NUMBER_RESULTS, ADMIN_TOKEN_KEY } from '../../constants.ts';

const ADVANCE_PAYMENT_TABLE_HEADERS = {
  date: 'Дата',
  admin: 'Имя админа',
  reason: 'Причина',
  paymentAmount: 'Сумма'
};

export const AdvanceAmount = () => {
  const { t } = useTranslation(['admin']);
  const { id } = useParams<Params>();
  const token = localStorage.getItem(ADMIN_TOKEN_KEY) || '';

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPrepaid, setTotalPrepaid] = useState<number>(0);
  const [prepaid, setPrepaid] = useState([]);
  const [prepaidId, setPrepaidId] = useState(null);

  const {
    status: partnerPrepaidStatus,
    data: partnerPrepaidData,
    isLoading: partnerPrepaidIsLoading
  } = useQuery(['prepaid', page], () => getPartnerPrepaidDataAdmin({ token, page, userId: id }), {
    enabled: !!token && !!id,
    onError: (error: AxiosError) => {
      // toast.error(t('notifications.connection_failed'), NOTIFICATION_OPTIONS);
    },
    onSuccess: (partnerPrepaidData) => {
      if (partnerPrepaidData?.data?.count > 0) {
        setTotalPrepaid(partnerPrepaidData?.data?.count);
      }

      if (partnerPrepaidData?.data?.results?.length > 0) {
        const prepaidArray = mapPartnersPrepaidTable(partnerPrepaidData?.data?.results);
        setPrepaid(prepaidArray);
      }
    }
  });

  const handleEditAdvancePayment = useCallback((e) => {
    const partnerPrepaidId = e.target.value;
    if (partnerPrepaidId) {
      setPrepaidId(partnerPrepaidId);
    }
    setModalIsOpen(true);
  }, []);

  const handlePageClick = useCallback((page: any) => {
    setPage(page.selected + 1);
  }, []);

  const tableHeader = useMemo(() => {
    return (
      <HeaderRaw>
        <>
          {Object.values(ADVANCE_PAYMENT_TABLE_HEADERS).map((item, index) => (
            <th key={item}>{item}</th>
          ))}
          <th></th>
        </>
      </HeaderRaw>
    );
  }, []);

  const totalAmount = useMemo(() => {
    return prepaid.reduce((acc, item) => {
      return acc + Number(item?.paymentAmount);
    }, 0);
  }, [prepaid]);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={inlineStyles.modal}
        contentLabel="Advance payment editing Modal"
        // className={styles.Modal}
      >
        <EditAdvancePayment onClose={setModalIsOpen} userId={id} prepaidId={prepaidId} />
      </Modal>
      <Button
        color={color.green}
        dataTestid="advance-payment-button"
        primary
        onClick={handleEditAdvancePayment}
        width={200}>
        {t('buttons.advance_payment')}
      </Button>

      <>
        {partnerPrepaidIsLoading && <SmallLoader />}

        {partnerPrepaidStatus === 'success' && partnerPrepaidData?.data?.results?.length === 0 && (
          <StyledH3 style={{ textAlign: 'center' }}>{t('notifications.no_prepaid_data')}</StyledH3>
        )}

        {partnerPrepaidStatus === 'success' && prepaid.length > 0 && (
          <StyledTable style={{ marginTop: '20px' }}>
            <thead>{tableHeader}</thead>
            <StyledTBody>
              <>
                {prepaid.map((item, index) => (
                  <TableRaw key={item.id} isEven={index % 2 === 0}>
                    <>
                      {Object.keys(ADVANCE_PAYMENT_TABLE_HEADERS).map((key, index) => {
                        return (
                          <td key={`${item[key]}-${index}`}>
                            {item[key]} {key === 'paymentAmount' && item.currency}
                          </td>
                        );
                      })}
                      <CellWithButton>
                        <Button
                          color={color.green}
                          dataTestid="edit-advance-payment-button"
                          primary
                          onClick={handleEditAdvancePayment}
                          value={item.id}>
                          {t('buttons.edit')}
                        </Button>
                      </CellWithButton>
                    </>
                  </TableRaw>
                ))}
                <TableRaw>
                  <td colSpan={3} style={{ textAlign: 'right' }}>
                    {t('payments.amount_sum')}:
                  </td>
                  <td>
                    {totalAmount} {prepaid[0]?.currency}
                  </td>
                  <td></td>
                </TableRaw>
              </>
            </StyledTBody>
          </StyledTable>
        )}

        {totalPrepaid > MAX_PAGE_NUMBER_RESULTS && (
          <Pagination page={page + 1} pages={pages} handlePageClick={handlePageClick} />
        )}
      </>
    </div>
  );
};
