import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MainHeader } from 'Components/Header/MainHeader';
import { ParkingSearch } from 'Components/ParkingSearch/ParkingSearch';
import { Helmet } from 'react-helmet';
import { setSearchValue } from 'Redux/reducers/citySearch/citySearchReducer';
import { Banner } from 'Components/Banner';

import { HiddenText } from 'Pages/Parking/style.js';
import { BackgroundWrapper, SearchContainer, Home } from './style';

export const HomePage = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchValue({ value: null, coordinates: { lat: 0, lng: 0 }, chosenLabel: '' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('titles.helmet_rent')}</title>
        <meta name="description" content={t('contents.home_meta_desc')} data-react-helmet="true" />
        <meta property="og:type" content={t('pagesParking.title_first_half')} />
        <meta property="og:title" content={t('titles.main_title')} />
        <meta property="og:description" content={t('titles.helmet_rent')} />
      </Helmet>
      <BackgroundWrapper />
      <MainHeader isDark />
      <Home>
        <SearchContainer>
          <HiddenText>{t('titles.main_title')}</HiddenText>
          <Banner />
          <ParkingSearch />
        </SearchContainer>
      </Home>
    </>
  );
};
