import React, { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

import {emailRegex} from "config";
import { resetPassword } from "api/auth";

import { MainHeader } from 'Components/Header/MainHeader';
import { StyledInput } from "Components/UI/Input/style";
import { StyledLabel } from "Components/UI/StyledLabel/style";
import { Notification } from "Components/UI/Notification/Notification";
import { Button } from "Components/UI/Button/Button";
import { StyledH3 } from "Components/UI/H3/style";
import { Link } from "Components/UI/Link/Link";

import { MainImg, FormWrapper, StyledForm, LinkWrapper } from "./style";
import { isMobile } from "react-device-detect";
import * as yup from "yup";
import { NOTIFICATION_OPTIONS } from 'Constants/constants';

type FormData = {
  email: string;
};

type AxiosResponse = {
  error: string;
  message: string;
  statusCode: number;
};

export const ForgotPassword: FC = () => {
  const { t } = useTranslation(['common']);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().required().email().matches(emailRegex, t('validation.email'))
      })
    )
  });

  const { mutateAsync } = useMutation(resetPassword, {
    onError: (error: AxiosError<AxiosResponse>) => {
      if (error?.response?.status === 404) {
        toast.error(t('notifications.no_user'), NOTIFICATION_OPTIONS);
      }
    },
    onSuccess: () => {
      toast.success(t('notifications.restore_pass_link_sent'), NOTIFICATION_OPTIONS);

      reset();
    }
  });

  const onSubmit = useCallback(
    async (data: FormData) => {
      mutateAsync({
        email: data.email
      });
    },
    [mutateAsync]
  );

  return (
    <>
      <MainHeader />
      <FormWrapper>
        {!isMobile && <MainImg />}
        <LinkWrapper>
          <Link to="/">{t('titles.back_home')}</Link>
        </LinkWrapper>

        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledH3>{t('titles.enter_email')}</StyledH3>
          <StyledLabel htmlFor="email">
            <StyledInput
              type="text"
              placeholder="Email"
              maxLength="50"
              width={!isMobile && '400px'}
              {...register('email', { required: true })}
              islight
            />
            <Notification isAccent={true}>
              {errors?.email?.type === 'required' && t('login.required')}
              {errors?.email?.message === 'email must be a valid email' && t('login.validEmail')}
            </Notification>
          </StyledLabel>

          <Button dataTestid="submit" primary={true} width={!isMobile ? '190' : undefined}>
            {t('login.restore_password')}
          </Button>
        </StyledForm>
      </FormWrapper>
    </>
  );
};
