import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ModalBooking } from 'Components/Modal/ModalBooking';
import { isMobile } from 'react-device-detect';
import { useModal } from 'hooks/useModal';
import { Button } from 'Components/UI/Button/Button';
import 'react-datepicker/dist/react-datepicker.css';
import { Wrapper, CalendarWrapper, ContentWrapper } from './style';
import { StyledPrice, StyledNote } from 'Components/ParkingCard/style';
import { DateCont } from 'Components/ParkingSearch/style';
import { checkAvailability } from 'api/parking';
import { toast } from 'react-toastify';
import { RootState } from 'Redux/store';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { Label } from 'Components/UI/Label/Label';
import { Notification } from 'Components/UI/Notification/Notification';
import { paths } from 'config';
import { useHistory } from 'react-router-dom';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';

type BookingProps = {
  from: Date;
  to: Date;
  id: number;
  cityId: number;
  currency?: string;
  searchRangeRadius: string | number;
  searchLat?: string;
  searchLng?: string;
  chosenLabel?: string;
};

export const BookParking = ({
  from,
  to,
  id,
  cityId,
  currency = '',
  searchRangeRadius,
  searchLat,
  searchLng,
  chosenLabel
}: BookingProps) => {
  const { t } = useTranslation(['common']);
  const { isShown, toggle } = useModal();
  const [focusedInput, setFocusedInput] = useState(null);
  const [startDate, setStartDate] = useState<any>(moment(from));
  const [endDate, setEndDate] = useState<any>(moment(to));
  const [newDates, setNewDates] = useState<any>({ from: moment(from), to: moment(to) });
  const [price, setPrice] = useState<Number>(0);
  const [bookingType, setBookingType] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const history = useHistory();

  const { params } = useSelector((state: RootState) => state.router);

  const urlParams = new URLSearchParams(params);
  urlParams.delete('from');
  urlParams.delete('to');
  useEffect(() => {
    if (startDate && endDate) setNewDates({ from: startDate, to: endDate });
  }, [startDate, endDate]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await checkAvailability({
          id,
          from: newDates.from.format('MM/DD/yyyy'),
          to: newDates.to.format('MM/DD/yyyy')
        });
        setIsDisabled(false);
        setPrice(data.price);
        setBookingType(data.bookingType);
      } catch (error: any) {
        if (error.response.status === 404 || error.response.status === 403) {
          toast.error(t('notifications.parking_is_booked'), NOTIFICATION_OPTIONS);

          setIsDisabled(true);
          urlParams.append('from', newDates.from.format('MM/DD/yyyy'));
          urlParams.append('to', newDates.to.format('MM/DD/yyyy'));

          setTimeout(() => {
            history.replace(
              `${
                paths.parking
              }?lat=${searchLat}&lng=${searchLng}&searchRadius=${searchRangeRadius}&searchingLocation=${chosenLabel}&from=${urlParams.get(
                'from'
              )}&to=${urlParams.get('to')}`
            );
          }, 1000);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, newDates.to, t]);

  const handleBook = () => {
    !isDisabled && toggle();
  };

  return (
    <>
      <ModalBooking isShown={isShown} hide={handleBook} startDate={startDate} endDate={endDate} />
      <Wrapper>
        <CalendarWrapper>
          <DateCont>
            <DateRangePicker
              startDatePlaceholderText={t('placeholders.begin')}
              endDatePlaceholderText={t('placeholders.end')}
              // renderMonthElement={({ month }) => moment(month).locale('ru').format('MMMM YYYY')}
              startDate={startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }: any) => {
                setStartDate(startDate);
                if (endDate) {
                  setEndDate(endDate);
                }
              }} // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
              withFullScreenPortal={isMobile}
              orientation={isMobile ? 'vertical' : 'horizontal'}
              readOnly={true}
            />
          </DateCont>
        </CalendarWrapper>
        <ContentWrapper>
          {isDisabled && (
            <Notification isAccent={true}>{t('notifications.parking_is_booked')}</Notification>
          )}
          <Label dataTestid="status" height="25" status={bookingType === 'MANUAL_APPROVE'}>
            {bookingType === 'MANUAL_APPROVE' ? t('status.ondemand') : t('status.online')}
          </Label>
          <div>
            <StyledNote> {t('parking.total')} </StyledNote>
            <StyledPrice data-testid="price">
              {price} {currency}
            </StyledPrice>
          </div>
          <Button dataTestid="book" primary={true} onClick={handleBook}>
            {t('buttons.book')}
          </Button>
        </ContentWrapper>
      </Wrapper>
    </>
  );
};
