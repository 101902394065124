import React from 'react';

export const CarDistanceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="595.28"
      height="841.89"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 595.28 841.89"
      xmlSpace="preserve"
      {...props}>
      <g fill="#1B1B47">
        <path d="M496.067 123.305c-54.705 0-99.213 44.508-99.213 99.213 0 47.453 70.305 143.494 86.777 165.353-.005 7.061 0 13.994-.171 20.357-.181 6.842 5.22 12.547 12.074 12.729h.339c6.685 0 12.208-5.341 12.389-12.074.171-6.529.166-13.657.171-20.919 16.363-21.709 86.846-117.938 86.846-165.446.001-54.705-44.507-99.213-99.212-99.213zm0 239.677c-27.262-37.507-74.41-108.89-74.41-140.464 0-41.032 33.378-74.41 74.41-74.41s74.41 33.378 74.41 74.41c0 31.574-47.148 102.955-74.41 140.464z"></path>
        <path d="M496.067 197.715c-13.673 0-24.803 11.13-24.803 24.803s11.13 24.803 24.803 24.803 24.803-11.129 24.803-24.803c0-13.673-11.13-24.803-24.803-24.803zM148.82 396.142c-41.032 0-74.41 33.378-74.41 74.41s33.378 74.41 74.41 74.41 74.41-33.378 74.41-74.41-33.378-74.41-74.41-74.41zm0 124.016c-27.358 0-49.607-22.247-49.607-49.607s22.249-49.606 49.607-49.606 49.607 22.247 49.607 49.607-22.249 49.606-49.607 49.606zM336.674 570.274c-6.419-2.495-13.565.691-16.047 7.085-5.559 14.363-12.51 31.113-21.885 46.457-3.573 5.849-1.72 13.48 4.118 17.053a12.328 12.328 0 006.455 1.817c4.179 0 8.26-2.108 10.598-5.934 10.392-17.028 17.887-35.049 23.846-50.43 2.471-6.384-.702-13.565-7.085-16.048zM266.745 660.779c-12.426 9.846-26.547 17.258-43.188 22.672-6.516 2.12-10.077 9.12-7.957 15.635 1.708 5.232 6.564 8.563 11.796 8.563 1.272 0 2.567-.194 3.839-.606 19.22-6.249 36.345-15.271 50.915-26.814 5.366-4.263 6.274-12.063 2.023-17.428-4.288-5.352-12.075-6.309-17.428-2.022zM379.607 480.737c-20.467 11.615-31.839 31.779-38.622 45.925-2.967 6.177-.364 13.589 5.813 16.543a12.284 12.284 0 005.353 1.223c4.626 0 9.059-2.592 11.191-7.036 8.611-17.961 17.404-28.776 28.521-35.098 5.947-3.379 8.042-10.949 4.651-16.907-3.366-5.958-10.912-8.053-16.907-4.65zM491.464 450.036c-5.632-3.815-13.371-2.35-17.222 3.318-4.081 6.032-12.438 14.327-37.714 16.592-6.819.617-11.857 6.636-11.239 13.467.581 6.443 5.995 11.287 12.342 11.287.376 0 .751-.012 1.127-.049 26.85-2.41 45.174-11.372 56.026-27.396 3.839-5.666 2.348-13.38-3.32-17.219z"></path>
        <path d="M178.206 693.943c39.132-41.73 119.434-137.733 119.434-223.39 0-83.445-65.375-148.82-148.82-148.82S0 387.107 0 470.552C0 581.307 134.577 709.78 140.306 715.195a12.327 12.327 0 008.514 3.391c9.035 0 17.621-.315 25.76-.932 6.831-.521 11.953-6.468 11.432-13.298-.372-4.89-3.535-8.702-7.806-10.413zm-29.386-5.248c-29.297-29.89-124.017-133.124-124.017-218.143 0-69.542 54.476-124.017 124.017-124.017s124.017 54.475 124.017 124.017c0 85.019-94.72 188.253-124.017 218.143z"></path>
      </g>
    </svg>
  );
};
