export function getDays(milliseconds) {
  let minutes = Math.floor(milliseconds / 60000);
  let hours = Math.round(minutes / 60);
  let days = Math.round(hours / 24);
  return days;
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.abs(Math.floor((utc2 - utc1) / _MS_PER_DAY));
}
