type FileTypes = {
  id: number;
  path: string;
  type: string;
};

export const getImageLink = (files: Array<FileTypes> | []): string => {
  if (!Array.isArray(files) || !files.length) return '';

  const mainImageFile = files.find((file: any) => file.type === 'MAIN_IMAGE');

  return mainImageFile ? mainImageFile.path : '';
};
