import React, { createContext, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FilterContextType, DatesType, SortingTypes, ParkingTypes, CarClassTypes } from './types';
import { CoordinatesType } from 'types/parkingTypes';

export const FilterContext = createContext<FilterContextType | null>(null);

export const FilterContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { i18n } = useTranslation(['common']);

  const [dates, setDates] = useState<DatesType>({
    from: moment().locale(i18n.language),
    to: moment().add(1, 'days').locale(i18n.language)
  });

  const [coordinates, setCoordinates] = useState<CoordinatesType>({
    lat: 0,
    lng: 0
  });

  const [eCharger, setECharger] = useState<boolean>(false);
  const [sortType, setSortType] = useState<SortingTypes>('POPULARITY');
  const [parkingType, setParkingType] = useState<ParkingTypes | null>(null);
  const [carClass, setCarClass] = useState<CarClassTypes | null>(null);
  const [minPrice, setMinPrice] = useState<string>('');
  const [maxPrice, setMaxPrice] = useState<string>('');
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);
  const [positionDetected, setPositionDetected] = useState<boolean>(false);
  const [isMobileMapOpen, setIsMobileMapOpen] = useState<boolean>(false);
  const [hoveredPin, setHoveredPin] = useState<number>(-1);

  const clearFilters = () => {
    setECharger(false);
    setSortType('POPULARITY');
    setParkingType(null);
    setMinPrice('');
    setMaxPrice('');
  };

  return (
    <FilterContext.Provider
      value={{
        dates,
        setDates,
        coordinates,
        setCoordinates,
        eCharger,
        setECharger,
        sortType,
        setSortType,
        parkingType,
        setParkingType,
        carClass,
        setCarClass,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        clearFilters,
        hoveredCard,
        setHoveredCard,
        positionDetected,
        setPositionDetected,
        isMobileMapOpen,
        setIsMobileMapOpen,
        hoveredPin,
        setHoveredPin
      }}>
      {children}
    </FilterContext.Provider>
  );
};
