import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/store';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { FilterContextProvider } from 'context/filterContext';

import { MainHeader } from 'Components/Header/MainHeader';
import { FiltersPanel } from './components/FiltersPanel';
import { ParkingSearchResults } from './components/ParkingSearchResults';

import { Background, HiddenText } from './style';

export const ParkingPage = () => {
  const { t } = useTranslation(['common']);

  const { chosenLabel } = useSelector((state: RootState) => state.citySearchValue.searchValue);

  return (
    <FilterContextProvider>
      <Background>
        <Helmet>
          <title>{chosenLabel}</title>
          <meta name="description" content={t('admin.parking_desc_general')} />
        </Helmet>
        <MainHeader />
        {!!chosenLabel && (
          <HiddenText>
            {t('pagesParking.title_first_half')}
            {chosenLabel}
            {t('pagesParking.title_second_half')}
          </HiddenText>
        )}
        <FiltersPanel />
        <ParkingSearchResults />
      </Background>
    </FilterContextProvider>
  );
};
