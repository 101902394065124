import React from 'react';

export const PriceUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 512 512"
      {...props}>
      <g>
        <path
          d="M92 402c-21 0-39-17-39-39V238c-4 2-9 3-14 3-11 0-20-4-28-11-15-15-15-40 0-55l54-54c7-7 17-11 27-11s20 4 28 12l53 53c8 7 12 17 12 27 0 11-4 21-12 28-7 7-17 11-27 11-5 0-11-1-15-3v125c0 22-18 39-39 39zM366 402c-80 0-146-66-146-146s66-146 146-146c81 0 146 66 146 146s-65 146-146 146zm0-27c-65 0-118-54-118-119s53-119 118-119c66 0 119 54 119 119s-53 119-119 119zm0-224c-57 0-104 47-104 105s47 105 104 105c58 0 105-47 105-105s-47-105-105-105zm-7 181c-16-2-29-14-34-29-1-4 1-8 5-9s8 1 9 5c3 11 14 20 26 20h1c15-1 27-13 27-28s-12-28-27-28c-22 0-41-19-41-42 0-20 15-37 34-41v-18c0-4 3-7 7-7s7 3 7 7v18c16 2 29 14 34 29 1 4-1 8-5 9-3 1-7-1-8-5-4-11-15-20-27-20-15 0-28 13-28 28s13 28 27 28c23 0 41 19 41 42 0 20-14 37-34 41v18c0 4-3 7-7 7s-7-3-7-7v-18z"
          className="fil0"></path>
      </g>
    </svg>
  );
};
