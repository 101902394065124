import { SortButtonType } from 'types/filterTypes';

// export const regexHouseNumber = /^[0-9]{1,4}[/]{0,1}[0-9]{0,4}[A-Za-zА-Яа-я]{0,1}$/;
export const regexHouseNumber = /^[a-zA-ZА-Яа-я0-9.,/]{0,24}$/;
// export const regexPlaceNumber = /^[0-9]{1,4}[A-Za-zА-Яа-я]{0,1}$/;
export const regexPlaceNumber = /^[a-zA-ZА-Яа-я0-9.,/]{0,24}$/;
export const regexLevel = /^-?[a-zA-ZА-Яа-я0-9.,/]{0,24}$/;
export const regexDimensionsValue = /^[0-9]{1,4}[.]{0,1}[0-9]{0,2}$/;
export const regexNumberValue = /^[0-9]{1,6}$/;
export const regexDiscountValue = /^[0-9]{1,3}$/;
export const regexOnlyNumber = /^[0-9]{1,8}$/;
export const regexCarColor = /^[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ'-]*$/;
export const regexClientName = /^[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ']{1,1}[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ'\s]*$/;
export const regexCarBrand = /^[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ']{1,1}[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ'\s]*$/;
export const regexPhone = /^\+[0-9]+$/;

export const DD_MM_YYYY = 'DD/MM/YYYY';
export const dd_MM_yyyy = 'dd/MM/yyyy';
export const MM_dd_yyyy = 'MM/dd/yyyy';

export const EMAIL_MUST_BE_VALID = 'email must be a valid email';
export const INVALID_NUMBER_FORMAT = 'Invalid number format';
export const EMAIL_REQUIRED_FIELD = 'email is a required field';

export const PENDING = 'PENDING';
export const APPROVED = 'APPROVED';
export const REJECTED = 'REJECTED';
export const BLOCKED = 'BLOCKED';
export const REJECTED_NOT_ARRIVED = 'REJECTED_NOT_ARRIVED';
export const APPROVED_BY_CRON = 'APPROVED_BY_CRON';
export const DOCUMENT = 'DOCUMENT';

export const NOTIFICATION_OPTIONS: Object = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined
};

export const GOOGLE_PLACES_MARKERS: string[] = [
  'lodging',
  'neighborhood',
  'route',
  'tourist_attraction',
  'point_of_interest',
  'establishment',
  'shopping_mall',
  'town_square'
];

export const SORT_BUTTONS: SortButtonType[] = [
  { id: 0, title: 'sortButtons.popularity', value: 'POPULARITY' },
  { id: 1, title: 'sortButtons.rating', value: 'RATING' },
  { id: 2, title: 'sortButtons.cheap', value: 'CHEEP' },
  { id: 3, title: 'sortButtons.expensive', value: 'EXPENSIVE' },
  { id: 4, title: 'sortButtons.distance', value: 'DISTANCE' }
];

export const SELL_PARKING_SORT_BUTTONS: SortButtonType[] = [
  { id: 0, title: 'sortButtons.cheap', value: 'CHEEP' },
  { id: 1, title: 'sortButtons.expensive', value: 'EXPENSIVE' },
  { id: 2, title: 'sortButtons.distance', value: 'DISTANCE' }
];

export const PARKING_TYPE: SortButtonType[] = [
  { id: 0, title: 'placeholders.underground', value: 'UNDEGROUND' },
  { id: 1, title: 'placeholders.ground', value: 'OVERGROUND' },
  { id: 2, title: 'placeholders.choose_both', value: 'DEFAULT' }
];

export const CAR_CLASS_TYPE: SortButtonType[] = [
  { id: 0, title: 'parking.FIRST', value: 'FIRST' },
  { id: 1, title: 'parking.SECOND', value: 'SECOND' },
  { id: 1, title: 'parking.THIRD', value: 'THIRD' }
];

export const RESULTS_PER_PAGE: number = 10;

export const CAR_TYPES_DESCRIPTION: Record<string, string[]> = {
  ru: [
    'Маленький (до 4.4 м.): Volkswagen Golf, Ford Focus, Peugeot 308, Hyundai i30, Honda Civic, BMW 1, Ford Fusion, Nissan Qashkai.',
    'Средний (до 5 м.): Audi A4, BMW 3, Mercedes C, Volkswagen Passat, Peugeot 508, Hyundai Sonata, Toyota RAV 4, Huyndai Tucson, VW Tiguan, Mazda CX5, Audi Q5, KIA Sportage, Skoda Oktavia, Audi A6, BMW 5, Mercedes E, Honda Accord, Toyota Camry, Skoda Superb, Mazda 6, Porsche Cayenne, Toyota Highlander, Mitsubishi Pajero.',
    'Большой (более 5 м.): Audi A8, BMW 7, Mercedes S, Toyota Prado, BMW X5, Audi Q7, BMW X7, Mercedes GLS, Toyota Land Cruiser, Range Rover, Ford Explorer, Mercedes Vitо, Porsche Panamera.'
  ],
  uk: [
    'Малий (до 4.4 м.): Volkswagen Golf, Ford Focus, Peugeot 308, Hyundai i30, Honda Civic, BMW 1, Ford Fusion, Nissan Qashkai.',
    'Середній (до 5 м.): Audi A4, BMW 3, Mercedes C, Volkswagen Passat, Peugeot 508, Hyundai Sonata, Toyota RAV 4, Huyndai Tucson, VW Tiguan, Mazda CX5, Audi Q5, KIA Sportage, Skoda Oktavia, Audi A6, BMW 5, Mercedes E, Honda Accord, Toyota Camry, Skoda Superb, Mazda 6, Porsche Cayenne, Toyota Highlander, Mitsubishi Pajero.',
    'Великий (понад 5 м.): Audi A8, BMW 7, Mercedes S, Toyota Prado, BMW X5, Audi Q7, BMW X7, Mercedes GLS, Toyota Land Cruiser, Range Rover, Ford Explorer, Mercedes Vitо, Porsche Panamera.'
  ],
  ro: [
    'Mic (до 4.4 м.): Volkswagen Golf, Ford Focus, Peugeot 308, Hyundai i30, Honda Civic, BMW 1, Ford Fusion, Nissan Qashkai.',
    'Mediu (до 5 м.): Audi A4, BMW 3, Mercedes C, Volkswagen Passat, Peugeot 508, Hyundai Sonata, Toyota RAV 4, Huyndai Tucson, VW Tiguan, Mazda CX5, Audi Q5, KIA Sportage, Skoda Oktavia, Audi A6, BMW 5, Mercedes E, Honda Accord, Toyota Camry, Skoda Superb, Mazda 6, Porsche Cayenne, Toyota Highlander, Mitsubishi Pajero.',
    'Mare (понад 5 м.): Audi A8, BMW 7, Mercedes S, Toyota Prado, BMW X5, Audi Q7, BMW X7, Mercedes GLS, Toyota Land Cruiser, Range Rover, Ford Explorer, Mercedes Vitо, Porsche Panamera.'
  ],
  pl: [
    'Mały (до 4.4 м.): Volkswagen Golf, Ford Focus, Peugeot 308, Hyundai i30, Honda Civic, BMW 1, Ford Fusion, Nissan Qashkai.',
    'Średni (до 5 м.): Audi A4, BMW 3, Mercedes C, Volkswagen Passat, Peugeot 508, Hyundai Sonata, Toyota RAV 4, Huyndai Tucson, VW Tiguan, Mazda CX5, Audi Q5, KIA Sportage, Skoda Oktavia, Audi A6, BMW 5, Mercedes E, Honda Accord, Toyota Camry, Skoda Superb, Mazda 6, Porsche Cayenne, Toyota Highlander, Mitsubishi Pajero.',
    'Duży (понад 5 м.): Audi A8, BMW 7, Mercedes S, Toyota Prado, BMW X5, Audi Q7, BMW X7, Mercedes GLS, Toyota Land Cruiser, Range Rover, Ford Explorer, Mercedes Vitо, Porsche Panamera.'
  ],
  en: [
    'Small (up to 4.4 m.): Volkswagen Golf, Ford Focus, Peugeot 308, Hyundai i30, Honda Civic, BMW 1, Ford Fusion, Nissan Qashkai.',
    'Medium (up to 5 meters): Audi A4, BMW 3, Mercedes C, Volkswagen Passat, Peugeot 508, Hyundai Sonata, Toyota RAV 4, Huyndai Tucson, VW Tiguan, Mazda CX5, Audi Q5, KIA Sportage, Skoda Oktavia, Audi A6, BMW 5, Mercedes E, Honda Accord, Toyota Camry, Skoda Superb, Mazda 6, Porsche Cayenne, Toyota Highlander, Mitsubishi Pajero.',
    'Large (over 5 meters): Audi A8, BMW 7, Mercedes S, Toyota Prado, BMW X5, Audi Q7, BMW X7, Mercedes GLS, Toyota Land Cruiser, Range Rover, Ford Explorer, Mercedes Vitо, Porsche Panamera.'
  ]
};

export const PARKOVKI_HOSTNAMES = {
  UA: 'parkovki.ua',
  MD: 'parkovki.md'
};

export const BROWSER_LANGUAGES = {
  uk: ['uk-ua', 'uk'],
  ro: ['mo', 'ro-mo', 'ru-mo'],
  pl: ['pl'],
  ru: ['ru'],
  en: ['en-au', 'en', 'en-gb', 'en-ca', 'en-us']
};