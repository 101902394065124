import styled from "styled-components";

import { color, fontSize } from "theme";

export const StyledButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  line-height: 16px;
  font-weight: 400;
  font-size: ${fontSize.regular};
  cursor: pointer;
  color: ${(props) => (props.disabled ? `${color.greayText}` : color.blue)};
  margin-top: 10px;
`;
