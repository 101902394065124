import React from "react";
import { useTranslation } from "react-i18next";

import { IParkingPlace } from "./ParkingPlaces";

import { Button } from "Components/UI/Button/Button";

import {
  CardWrapper,
  ImgWrapper,
  ContentWrapper,
  ParkingImage,
  StyledText,
  LeftSideCont,
  ParkingInfoCont,
  StyledColumns,
  Label,
  LabelCont,
  ButtonsWrapper,
} from "./style";

import image from "assets/img/no-parking-photo.png";

export const ParkingItem = ({
  parkingName,
  street,
  house,
  auto,
  status,
  id,
  src,
  createdDate,
  onClick,
  message,
  handleRemove,
  currency
}: IParkingPlace) => {
  const { t } = useTranslation(['common']);

  let parkingStatus;

  if (status === 'APPROVED_VERIFIED' || status === 'APPROVED_UNVERIFIED') {
    parkingStatus = t('status.approved');
  } else if (status === 'PENDING') {
    parkingStatus = t('status.pending');
  } else {
    parkingStatus = t('status.declined');
  }

  const date = new Date(createdDate);

  return (
    <CardWrapper data-testId="parking-card">
      <LeftSideCont>
        <ImgWrapper>
          <ParkingImage src={src ? `${src}` : image} />
        </ImgWrapper>
        <ContentWrapper>
          <ParkingInfoCont>
            <StyledColumns>
              <StyledText data-testId="name">{parkingName}</StyledText>
              <StyledText data-testId="address">
                {street} {house}
              </StyledText>
              <StyledText data-testId="car-class">
                {t('parking.car_class')}: {t(`parking.${auto}`)}
              </StyledText>
            </StyledColumns>
            <StyledColumns>
              <StyledText data-testId="publish-date">
                {t('parking.date')} {date.toLocaleDateString()}
              </StyledText>
              <StyledText data-testId="id">ID {id}</StyledText>
            </StyledColumns>
          </ParkingInfoCont>
          <LabelCont>
            <Label data-testId="status" status={status}>
              {parkingStatus}
            </Label>{' '}
            {status === 'REJECTED' && <StyledText>{message}</StyledText>}
          </LabelCont>
        </ContentWrapper>
      </LeftSideCont>
      <ButtonsWrapper>
        <Button
          dataTestid="edit"
          value={id}
          primary={true}
          width="126"
          height="50"
          onClick={onClick}>
          {t('buttons.edit')}
        </Button>
        <Button
          dataTestid="delete"
          value={id}
          remove
          width="126"
          height="50"
          onClick={() => handleRemove(id)}>
          {t('buttons.delete')}
        </Button>
      </ButtonsWrapper>
    </CardWrapper>
  );
};
