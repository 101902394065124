import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { getPartnerBillsAdmin } from 'api/finance';
import { getUserInfo } from 'api/parking';
import { mapPartnersBills, mapPartnerInfo } from './mapPartnersBills.ts';
import { NOTIFICATION_OPTIONS } from 'Constants/constants';

import { Button } from 'Components/UI/Button/Button';
import { PaymentsTable } from './components/PaymentsTable';
import { PartnerInfoTable } from './components/PartnerInfoTable';
import { EditPaymentInfo } from './components/EditPaymentInfo';
import { AdvanceAmount } from './components/AdvanceAmount';
import { SmallLoader } from 'Components/Loader/Loader';
import { Pagination } from '../UI/Pagination/Pagination';

import { StyledH4 } from 'Components/UI/H4/style.js';
import { StyledH3 } from 'Components/UI/H3/style.js';
import { TabsButtonsContainer, ContentWrapper } from '../UI/style.js';
import { SmallTableWrapper, MainContent, inlineStyles } from './style.js';
import { MAX_PAGE_NUMBER_RESULTS, ADMIN_TOKEN_KEY } from '../constants.ts';
import { Params, TabTypes } from '../types.ts';

export const BillsAndPayments = () => {
  const { t } = useTranslation(['admin']);
  const { id } = useParams<Params>();

  const token = localStorage.getItem(ADMIN_TOKEN_KEY) || '';

  const [tab, setTab] = useState<TabTypes>('commission');
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [page, setPage] = useState<number>(1);
  const [totalBills, setTotalBills] = useState<number>(0);
  const [bills, setBills] = useState([]);

  const {
    status: partnerBillsStatus,
    data: partnerBillsData,
    isLoading: partnerBillsIsLoading
  } = useQuery(['bills', page], () => getPartnerBillsAdmin({ token, page, userId: id }), {
    enabled: !!token && !!id,
    onError: (error: AxiosError) => {
      toast.error(t('notifications.connection_error'), NOTIFICATION_OPTIONS);
    },
    onSuccess: (partnerBillsData) => {
      if (partnerBillsData?.data?.count > 0) {
        setTotalBills(partnerBillsData?.data?.count);
      }

      if (partnerBillsData?.data?.results?.length > 0) {
        const bills = mapPartnersBills(partnerBillsData?.data?.results);
        setBills(bills);
      }
    }
  });

  const { status: partnerInfoStatus, data: partnerInfoData } = useQuery(
    'partner',
    () => getUserInfo(+id, token),
    {
      enabled: !!token && !!id,
      onError: (error: AxiosError) => {
        toast.error(t('notifications.connection_error'), NOTIFICATION_OPTIONS);
      }
    }
  );

  const handleChangeTabs = useCallback(() => {
    setTab((prevState) => (prevState === 'commission' ? 'advance' : 'commission'));
  }, []);

  const handleEditPayment = useCallback(
    (value) => {
      setPaymentInfo({
        ...value,
        partnerId: id
      });
      setModalIsOpen(true);
    },
    [id]
  );

  const handlePageClick = useCallback((page: any) => {
    setPage(page.selected + 1);
  }, []);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={inlineStyles.modal}
        contentLabel="Payment editing Modal">
        <EditPaymentInfo paymentInfo={paymentInfo} onClose={setModalIsOpen} />
      </Modal>
      <ContentWrapper>
        <StyledH4 align="center" mb={20}>
          {t('titles.bills_and_payments')}
        </StyledH4>
        <TabsButtonsContainer>
          <Button
            dataTestid="commission-tab"
            primary={tab === 'commission'}
            height="50"
            onClick={handleChangeTabs}
            disabled={tab === 'commission'}>
            {t('buttons.commission_payment')}
          </Button>
          <Button
            dataTestid="advance-tab"
            primary={tab === 'advance'}
            height="50"
            onClick={handleChangeTabs}
            disabled={tab === 'advance'}>
            {t('buttons.advance_calculation')}
          </Button>
        </TabsButtonsContainer>

        <SmallTableWrapper>
          {partnerInfoStatus === 'error' && !partnerInfoData?.data && (
            <StyledH4>{t('notifications.partner_info_error')}</StyledH4>
          )}

          {partnerInfoStatus === 'success' && (
            <PartnerInfoTable partnerInfo={mapPartnerInfo(partnerInfoData?.data)} />
          )}
        </SmallTableWrapper>

        <MainContent>
          {partnerBillsIsLoading && <SmallLoader />}
          {tab === 'commission' ? (
            <>
              {partnerBillsStatus === 'success' &&
                partnerBillsData?.data?.results?.length === 0 && (
                  <StyledH3>{t('notifications.no_data_commission_payment')}</StyledH3>
                )}

              {partnerBillsStatus === 'success' && bills.length > 0 && (
                <PaymentsTable payment={bills} onClick={handleEditPayment} />
              )}

              {totalBills > MAX_PAGE_NUMBER_RESULTS && (
                <Pagination page={page + 1} pages={pages} handlePageClick={handlePageClick} />
              )}
            </>
          ) : (
            <AdvanceAmount />
          )}
        </MainContent>
      </ContentWrapper>
    </>
  );
};
