import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContextWrapper } from '../style';

type UserInfoProps = {
  id: number;
  email: string;
  name: string;
  surname: string;
  phone: string;
  parkings: number | string;
};

export const UserInfo = ({ id, email, name, surname, phone, parkings }: UserInfoProps) => {
  const { t } = useTranslation(['admin']);

  return (
    <ContextWrapper>
      <p>
        {t('partner_info.partner_id')}: <span>{id}</span>
      </p>
      <p>
        Email: <span>{email}</span>
      </p>
      <p>
        {t('admin.user_info_name')}{' '}
        <span>
          {name} {surname}
        </span>
      </p>
      <p>
        {t('admin.user_info_phone')} <span>{phone || t('admin.user_info_not_specified')}</span>
      </p>
      <p>
        {t('admin.user_info_count_parking')} <span>{parkings}</span>
      </p>
    </ContextWrapper>
  );
};
