import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterContext } from 'context/filterContext';
import { FilterContextType, SortingTypes, ParkingTypes } from 'context/types';
import { SORT_BUTTONS, PARKING_TYPE, regexNumberValue } from 'Constants/constants';
import { SortButtonType } from 'types/filterTypes';

import { Button, IconButton, ToggleButton } from 'Components/UI/Button/Button';
import { Radiobutton } from 'Components/UI/Radiobutton/Radiobutton';
import { Text } from 'Components/UI/Text/style';
import { StyledInput } from 'Components/UI/Input/style';
import { FiltersIcon, CloseIcon } from 'assets/svg';

import { color } from 'theme';
import {
  FiltersContainer,
  CloseBtnContainer,
  CheckboxContainer,
  PriceContainer,
  FiltersCountLabel,
  ClearFiltersBtn
} from '../style.js';

export const FilterDropdown = () => {
  const { t } = useTranslation(['common']);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [minPriceValue, setMinPriceValue] = useState<string>('');
  const [maxPriceValue, setMaxPriceValue] = useState<string>('');
  const modalRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const {
    eCharger,
    setECharger,
    sortType,
    setSortType,
    parkingType,
    setParkingType,
    setMinPrice,
    setMaxPrice,
    clearFilters
  } = useContext(FilterContext) as FilterContextType;

  const handleToggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        isModalOpen &&
        modalRef.current &&
        !modalRef.current?.contains(e.target as Node) &&
        buttonRef.current &&
        !buttonRef.current?.contains(e.target as Node)
      ) {
        setIsModalOpen(false);
      }
    },
    [isModalOpen]
  );

  const onChancePrice = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue: string = e.target.value;

    if (!inputValue) {
      e.target.name === 'minPrice' ? setMinPriceValue('') : setMaxPriceValue('');
    }

    if (inputValue.match(regexNumberValue)) {
      e.target.name === 'minPrice' ? setMinPriceValue(inputValue) : setMaxPriceValue(inputValue);
    }
  }, []);

  const handleSetPriceRange = useCallback(() => {
    if (minPriceValue && maxPriceValue) {
      setMinPrice(minPriceValue);
      setMaxPrice(maxPriceValue);
    }
  }, [maxPriceValue, minPriceValue, setMinPrice, setMaxPrice]);

  const handleResetAllFilters = useCallback(() => {
    setMinPriceValue('');
    setMaxPriceValue('');
    clearFilters();
  }, [clearFilters]);

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const numberOfFilters =
    +eCharger +
    +(sortType !== 'POPULARITY' ? true : false) +
    +Boolean(parkingType) +
    +(Boolean(minPriceValue) && Boolean(maxPriceValue));

  return (
    <div style={{ position: 'relative' }} ref={buttonRef}>
      {numberOfFilters > 0 && <FiltersCountLabel>{numberOfFilters}</FiltersCountLabel>}

      <IconButton isActive={isModalOpen} onClick={handleToggleModal}>
        <FiltersIcon fill={isModalOpen ? '#fff' : `${color.blue}`} />
        <p>{t('titles.filters')}</p>
      </IconButton>

      {isModalOpen && (
        <FiltersContainer ref={modalRef} isWide>
          <CloseBtnContainer>
            <CloseIcon onClick={handleCloseModal} />
          </CloseBtnContainer>

          <CheckboxContainer>
            {SORT_BUTTONS.map((sortBtn: SortButtonType) => {
              return (
                <Radiobutton
                  key={sortBtn.value}
                  id={sortBtn.value}
                  label={t(sortBtn.title)}
                  value={sortBtn.value}
                  name={sortBtn.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSortType(e.target.value as SortingTypes);
                  }}
                  checked={sortType === sortBtn.value}
                />
              );
            })}
          </CheckboxContainer>

          <CheckboxContainer>
            <Text mb="5">{t('placeholders.parking_types')}</Text>

            {PARKING_TYPE.map((parking: SortButtonType) => {
              return (
                <Radiobutton
                  key={parking.value}
                  id={parking.value}
                  label={t(parking.title)}
                  value={parking.value}
                  name={parking.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setParkingType(e.target.value as ParkingTypes);
                  }}
                  checked={parkingType === parking.value}
                  isSmall
                />
              );
            })}

            <ToggleButton
              label={t('placeholders.electric_charger')}
              onClick={() => {
                setECharger((prevState) => !prevState);
              }}
              toggled={eCharger}></ToggleButton>

            <Text mb="5" mt="18">
              {t('titles.price')}
            </Text>

            <PriceContainer>
              <StyledInput
                name="minPrice"
                value={minPriceValue}
                onChange={onChancePrice}
                placeholder={t('placeholders.from')}
                width="80px"
              />
              <StyledInput
                name="maxPrice"
                value={maxPriceValue}
                onChange={onChancePrice}
                placeholder={t('placeholders.to')}
                width="80px"
              />

              <Button
                primary={false}
                height="30"
                padding="0"
                onClick={handleSetPriceRange}
                disabled={!minPriceValue || !maxPriceValue}>
                Ok
              </Button>
            </PriceContainer>
            <ClearFiltersBtn onClick={handleResetAllFilters} disabled={numberOfFilters === 0}>
              {t('buttons.clear_filters')}
            </ClearFiltersBtn>
          </CheckboxContainer>
        </FiltersContainer>
      )}
    </div>
  );
};
