import { createSlice } from '@reduxjs/toolkit';

export const routerSlice = createSlice({
  name: 'router',
  initialState: { params: localStorage.getItem('params') ?? ''},
  reducers: {
    setRoute: (state, action) => {
      localStorage.setItem('params', action.payload.params)
      return {
        ...action.payload,
      }
    },
  }
})

export const { setRoute } = routerSlice.actions;