import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import { useModal } from 'hooks/useModal';
import { loginSchema, LOGIN_API_URL, paths } from 'config';
import { loginUser, LoginResponse, getProfile } from 'api/auth';
import { StyledLabel } from 'Components/UI/StyledLabel/style';
import { Notification } from 'Components/UI/Notification/Notification';
import { StyledInput } from 'Components/UI/Input/style';
import { Link } from 'Components/UI/Link/Link';
import { Button } from 'Components/UI/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { validateEmail } from 'helpers/validateEmail';

import {
  Header,
  StyledModal,
  HeaderText,
  Content,
  Form,
  StyledI,
  LinkContainer,
  ErrorsContainer
} from './style';
import { login, setUserInfo } from 'Redux/reducers/login/loginReducer';
import { EMAIL_REQUIRED_FIELD, INVALID_NUMBER_FORMAT } from 'Constants/constants';

type FormValues = { email: string; password: string };

const eye = <FontAwesomeIcon icon={faEye} />;
const slashedEye = <FontAwesomeIcon icon={faEyeSlash} />;

export const LoginModal = ({ openRegister }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['common']);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isShown, toggle } = useModal();

  const [responseError, setResponseError] = useState<number | null>(null);
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [responseErrorText, setResponseErrorText] = useState<string>('');

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(loginSchema)
  });

  const loginForm = useMutation(loginUser, {
    onError: (error: AxiosError) => {
      setResponseError(error.response?.data.statusCode);
      setResponseErrorText(error.response?.data.message);
    },
    onSuccess: async (response: AxiosResponse<LoginResponse>) => {
      try {
        const { data } = await getProfile(response.data.accessToken);
        dispatch(login(response.data));
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', JSON.stringify(response.data.refreshToken));
        localStorage.setItem('userInfo', JSON.stringify(data));
        dispatch(setUserInfo(data));
        toggle();
        history.push('/' + paths.partnerprofile);
      } catch (error) {
        // @ts-ignore
        setResponseError(error.response?.data.statusCode);
      }
    }
  });

  const onSubmit = useCallback(
    (data: FormValues) => {
      const { email, password } = data;
      loginForm.mutateAsync({
        email: email.toLowerCase(),
        password,
        url: LOGIN_API_URL
      });
    },
    [loginForm]
  );

  const onChangeLoginInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: 'email' | 'password'
  ) => {
    setValue(fieldName, e.target.value);
    !!responseError && setResponseError(null);
    !!responseErrorText && setResponseErrorText('');
  };

  const onChangePasswordInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: 'email' | 'password'
  ) => {
    setValue(fieldName, e.target.value);
    !!responseError && setResponseError(null);
    !!responseErrorText && setResponseErrorText('');
  };

  const { email } = getValues();

  return (
    <StyledModal data-testid="login-modal-container">
      <Header>
        <HeaderText>{t('titles.auth')}</HeaderText>
      </Header>
      <Content>
        <Form height="300" onSubmit={handleSubmit(onSubmit)}>
          <StyledLabel htmlFor="email">
            <StyledInput
              data-testid="email"
              placeholder={t('placeholders.email_or_phone_number')}
              {...register('email', { required: true })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeLoginInput(e, 'email')}
            />
            <Notification isAccent={true}>
              {errors?.email?.message === EMAIL_REQUIRED_FIELD && t('login.required')}&nbsp;
              {email && !email.startsWith('+') && !validateEmail(email) && t('login.validEmail')}
              &nbsp;
              {errors?.email?.message === INVALID_NUMBER_FORMAT && t('login.Invalid_number_format')}
              &nbsp;
              {((responseError === 403 && !errors?.email?.message) ||
                (responseError === 401 &&
                  responseErrorText === 'Unauthorized' &&
                  !errors?.email?.message)) &&
                t('login.incorrect')}
              &nbsp;
              {responseErrorText === 'User is blocked' && t('login.user_blocked')}
            </Notification>
          </StyledLabel>

          <StyledLabel htmlFor="password">
            <StyledInput
              data-testid="password"
              type={passwordShown ? 'text' : 'password'}
              placeholder={t('login.password')}
              {...register('password', { required: true })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangePasswordInput(e, 'password')
              }
            />
            {passwordShown ? (
              <StyledI onClick={togglePasswordVisibility}>{slashedEye}</StyledI>
            ) : (
              <StyledI onClick={togglePasswordVisibility}>{eye}</StyledI>
            )}
          </StyledLabel>
          <ErrorsContainer>
            <Notification isAccent={true}>
              {errors?.password?.message && t('login.required')}&nbsp;
            </Notification>
          </ErrorsContainer>
          <LinkContainer>
            <Link to={paths.forgotPassword}>{t('login.restore_password')}</Link>
          </LinkContainer>
          <Button dataTestid="login" type={'submit'} primary={true} margin_b="10">
            {t('buttons.login')}
          </Button>

          <Button primary={true} onClick={openRegister}>
            {t('buttons.register')}
          </Button>
        </Form>
      </Content>
    </StyledModal>
  );
};
