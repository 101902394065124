import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'Components/UI/Tooltip/style';

import { Container, StyledTitle, AddInfo, AddInfoColumns } from './style';
import { StyledText, StyledTextCustom } from 'Components/ParkingCard/style';

type SellAdditionalInfoProps = {
  parkingType: string;
  parkingLevel: string;
  carClass: string;
  placeLength: string;
  placeWidth: string;
  heightOfParkingEntry?: string;
};

export const SellAdditionalInfo = ({
  parkingType,
  parkingLevel,
  carClass,
  placeLength,
  placeWidth,
  heightOfParkingEntry
}: SellAdditionalInfoProps) => {
  const { t } = useTranslation(['common']);

  return (
    <Container>
      <StyledTitle>{t('titles.additional_info')}</StyledTitle>
      <AddInfo>
        <AddInfoColumns>
          <StyledText data-testid="sell-parking-type">
            {t('parking.type')}:{' '}
            {parkingType === 'UNDEGROUND'
              ? t('parking.underground_park')
              : t('parking.ground_park')}
          </StyledText>
          <StyledText data-testid="sell-parking-level">
            {t('placeholders.parking_level')}: {parkingLevel}
          </StyledText>
          <StyledTextCustom
            data-testid="sell-parking-car-class"
            data-tip={t('parking.car_class_desc')}>
            <Tooltip />
            {t('parking.car_class')}: {t(`parking.${carClass}`)}
          </StyledTextCustom>
        </AddInfoColumns>
        <AddInfoColumns>
          <StyledText data-testid="sell-parking-length">
            {t('parking.p_length')}: {placeLength} {t('parking.metres')}
          </StyledText>
          <StyledText data-testid="sell-parking-height">
            {t('parking.entrance_height')}: {heightOfParkingEntry} {t('parking.metres')}
          </StyledText>
        </AddInfoColumns>
        <AddInfoColumns>
          <StyledText data-testid="sell-parking-width">
            {t('parking.p_width')}: {placeWidth} {t('parking.metres')}
          </StyledText>
        </AddInfoColumns>
      </AddInfo>
    </Container>
  );
};
