import styled from "styled-components";
import { color, device } from 'theme';

export const ParkingSearchWrapper = styled.div`
  width: 100%;
  background: ${color.darkGreyBackground};
  border-radius: 20px;
  padding: 15px;

  @media ${device.tablet} {
    width: 515px;
  }

  @media ${device.laptopL} {
    padding: 25px 40px;
  }

  & > h2 {
    text-align: center;
    text-transform: uppercase;
  }
`;

export const ParkingSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: start;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
`;

export const DateCont = styled.label`
  position: relative;
  z-index: 2;
  width: 100%;
`;

export const IconCont = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  z-index: 1;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`;

export const ArrowCont = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  z-index: 1;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  position: relative;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const SecondCalendarWrapper = styled.div`
  position: absolute;
  top: 27%;
  right: 38%;

  @media ${device.tablet} {
    right: 42%;
  }
`;

export const TabButtonContainer = styled.div`
  margin-bottom: 15px;

  @media ${device.laptop} {
    margin-bottom: 20px;
  }
`;

export const TabButton = styled.button`
  width: 50%;
  height: 40px;
  border: none;
  background: ${(props) => (props.isDisabled ? 'transparent' : `${color.modalBackground}`)};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  text-transform: uppercase;
  color: ${color.white};
  cursor: pointer;
`;