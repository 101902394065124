import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/store';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { getCommissionRequests, ParkingPlace } from 'api/parking';
import { getAvailableMonthsYears, MonthsYearsResponse } from 'api/finance';
import { useTranslation } from 'react-i18next';
import { checkExpiry } from 'api/auth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from 'Redux/reducers/login/loginReducer';
import { formatDate } from 'utils';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';

import { Button } from 'Components/UI/Button/Button';
import { PartnerPayment } from './PartnerPayment';
import { SmallLoader } from 'Components/Loader/Loader';

import { color } from 'theme';
import { Container, Header, Row, Column, Table, Text, HeaderColumn, SelectorPanel } from './style';
import { StyledH3 } from 'Components/UI/H3/style.js';
import { TabsButtonsContainer } from '../ParkingPlaces/style.js';
import { StyledSelect } from 'Components/Admin/BillsAndPayments/style.js';
import { Label } from 'Components/Admin/UserBookings/components/style.js';
import styles from 'Components/Admin/Users/style.module.css';

const NUMBER_OF_COMMISSIONS = 25;

interface Request {
  name: string;
  id: number;
  from: string;
  to: string;
  price: number;
  parkingPlace: ParkingPlace;
  status: string;
  commission: number;
}

enum TabTypes {
  commission = 'commission',
  payment = 'payment'
}

type OptionsType = {
  value: string | number;
  label: string | number;
};

export const Commission = () => {
  const { t } = useTranslation(['common']);
  const [tab, setTab] = useState<TabTypes>(TabTypes.commission);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [list, setList] = useState<Request[]>();
  const [balance, setBalance] = useState(0);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [prepaid, setPrepaid] = useState<string>('');
  const { results } = useSelector((state: RootState) => state.parkings);
  const token = localStorage.getItem('accessToken');

  const dispatch = useDispatch();
  const history = useHistory();

  const defaultYearOption = { label: t('profile.all'), value: 'all' };

  const [yearsOptions, setYearsOptions] = useState<OptionsType[]>([defaultYearOption]);
  const [selectedYear, setSelectedYear] = useState<OptionsType>(defaultYearOption);
  const [monthsOptions, setMonthsOptions] = useState<OptionsType[]>([defaultYearOption]);
  const [selectedMonth, setSelectedMonth] = useState<OptionsType>(defaultYearOption);

  const { isLoading } = useQuery(
    ['months', selectedYear],
    () =>
      getAvailableMonthsYears({ token, year: selectedYear.value !== 'all' && selectedYear.value }),
    {
      enabled: !!token,
      onError: (error: AxiosError) => {
        // toast.error(t('notifications.connection_failed'), NOTIFICATION_OPTIONS);
      },
      onSuccess: ({ data: dates }: MonthsYearsResponse) => {
        if (dates && dates?.years?.length) {
          const years = dates?.years.reduce((acc, item) => {
            acc.push(item.year);
            return acc;
          }, []);

          const yearsOptions = [...new Set(years)].map((item) => {
            return { label: item, value: item };
          });
          setYearsOptions([defaultYearOption, ...yearsOptions]);

          const sortedMonth = dates?.years.sort((a, b) => a.month - b.month);
          const monthsOptions = sortedMonth.map((item) => {
            return { label: `${t(`months.${item.month}`)}`, value: item.month };
          });

          setMonthsOptions([defaultYearOption, ...monthsOptions]);
        }
      }
    }
  );

  const parkingCurrency = (results.length!! && results[0].currency) || '';

  const handleChangeTabs = useCallback(() => {
    setTab((prevState) =>
      prevState === TabTypes.commission ? TabTypes.payment : TabTypes.commission
    );
  }, []);

  const handleYearChange = useCallback((value: OptionsType) => {
    setSelectedYear(value);
  }, []);

  const handleMonthChange = useCallback((value: OptionsType) => {
    setSelectedMonth(value);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        setLoadingData(true);
        const token = await checkExpiry(localStorage.getItem('accessToken'));
        if (token) {
          const month = selectedMonth.value !== 'all' && selectedMonth.value;
          const year = selectedYear.value !== 'all' && selectedYear.value;

          const res = await getCommissionRequests({
            token,
            month: month,
            year: year,
            page: page,
            limit: NUMBER_OF_COMMISSIONS
          });
          setList(res.data.results);
          setPrepaid(res?.data?.prepaid);
          setCount(res.data?.count || 0);

          if (res?.data?.results.length) {
            const commissionSum = res?.data?.results.reduce(
              (acc, item) => acc + item.commission,
              0
            );
            setBalance(commissionSum);
          }
        } else {
          dispatch(logout());
          history.push('/');
        }
      } catch (error: any) {
        if (error.response.status === 401) dispatch(logout());
      } finally {
        setLoadingData(false);
      }
    })();
  }, [dispatch, history, selectedMonth, selectedYear, page]);

  return (
    <>
      <TabsButtonsContainer>
        <Button
          dataTestid="commission-tab"
          primary={tab === TabTypes.commission}
          height="50"
          onClick={handleChangeTabs}
          disabled={tab === TabTypes.commission}>
          {t('titles.commission')}
        </Button>
        <Button
          dataTestid="payment-tab"
          primary={tab === TabTypes.payment}
          height="50"
          onClick={handleChangeTabs}
          disabled={tab === TabTypes.payment}>
          {t('titles.payment')}
        </Button>
      </TabsButtonsContainer>

      {tab === TabTypes.commission ? (
        <Container>
          <SelectorPanel>
            <Label>
              {t('placeholders.choose_year')}:
              <Select
                options={yearsOptions}
                isSearchable={false}
                isMulti={false}
                value={selectedYear}
                styles={StyledSelect}
                onChange={handleYearChange}
                isDisabled={isLoading}
              />
            </Label>

            <Label>
              {t('placeholders.choose_month')}:
              <Select
                options={monthsOptions}
                isSearchable={false}
                isMulti={false}
                value={selectedMonth}
                styles={StyledSelect}
                onChange={handleMonthChange}
                isDisabled={isLoading}
              />
            </Label>
          </SelectorPanel>
          {loadingData && <SmallLoader />}

          {!loadingData && Boolean(list.length) && (
            <>
              {' '}
              <Header>
                <HeaderColumn />
                <HeaderColumn>
                  <Text>{t('titles.order_number')}</Text>
                </HeaderColumn>
                <HeaderColumn>
                  <Text>{t('titles.order_dates')}</Text>
                </HeaderColumn>
                <HeaderColumn>
                  <Text>{t('titles.order_price')}</Text>
                </HeaderColumn>
                <HeaderColumn>
                  <Text>{t('titles.order_commision')}</Text>
                </HeaderColumn>
              </Header>
              <Table>
                {list?.map((request: Request) => (
                  <Row key={request.id}>
                    <Column>
                      <Text>
                        {request.parkingPlace.parkingName ??
                          `${request.parkingPlace.street} ${request.parkingPlace.house}`}
                      </Text>
                    </Column>
                    <Column>
                      <Text style={{ textAlign: 'center' }}>{request.id}</Text>
                    </Column>
                    <Column>
                      <Text style={{ textAlign: 'center' }}>{`${formatDate(
                        request.from
                      )} - ${formatDate(request.to)}`}</Text>
                    </Column>
                    <Column>
                      <Text style={{ textAlign: 'center' }}>{`${Number(request.price).toFixed(
                        2
                      )} ${parkingCurrency}`}</Text>
                    </Column>
                    <Column>
                      <Text style={{ textAlign: 'center' }}>{`${
                        request.status === 'REJECTED_NOT_ARRIVED'
                          ? 0
                          : request.commission.toFixed(2)
                      } ${parkingCurrency}`}</Text>
                    </Column>
                  </Row>
                ))}

                <Row>
                  <Column />
                  <Column />
                  <Column />
                  <Column>
                    <Text color={color.blue} style={{ textAlign: 'center' }}>
                      {t('titles.commission_sum')}:
                    </Text>
                  </Column>
                  <Column>
                    <Text
                      color={color.blue}
                      style={{ textAlign: 'center' }}>{`${balance} ${parkingCurrency}`}</Text>
                  </Column>
                </Row>
              </Table>
            </>
          )}

          {!loadingData && !list.length && (
            <StyledH3 style={{ textAlign: 'center' }}>
              {t('notifications.no_commission_info')}
            </StyledH3>
          )}

          {count > NUMBER_OF_COMMISSIONS && (
            <ReactPaginate
              previousLabel={t('pagination.previous')}
              nextLabel={t('pagination.next')}
              breakLabel={'...'}
              pageCount={Math.ceil(count / NUMBER_OF_COMMISSIONS)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(event) => setPage(event.selected + 1)}
              containerClassName={styles.pagination}
              activeClassName={styles.active}
              pageClassName={styles.page}
              previousClassName={styles.nextPrev}
              nextClassName={styles.nextPrev}
              disabledClassName={styles.disabled}
              breakClassName={styles.break}
              renderOnZeroPageCount={null}
            />
          )}
        </Container>
      ) : (
        <PartnerPayment prepaid={prepaid} />
      )}
    </>
  );
};
