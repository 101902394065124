import styled from "styled-components";

import { color, fontSize, device } from "theme";

export const Container = styled.div`
  background: ${color.white};
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  :first-child {
    margin-top: 0;
  }
  justify-content: space-between;
  margin-top: 20px;

  @media ${device.laptop} {
    flex-direction: row;
    padding: 20px;
  }
`;

export const Info = styled.div``;
export const Rating = styled.div`
  display: flex;
  max-height: 80px;
  justify-content: space-around;

  @media ${device.laptop} {
    justify-content: unset;
  }
`;

export const ID = styled.span`
  font-size: ${fontSize.small};
  line-height: 14px;
  color: #c2cfe0;
`;

export const Title = styled.h3`
  font-size: ${fontSize.regular};
  line-height: 16px;
  color: #2f80ed;
  margin: 5px 0 10px 0;
`;

export const Text = styled.p`
  font-size: ${fontSize.small};
  line-height: 14px;
  color: ${color.greayText};
  margin-bottom: 10px;

  @media ${device.laptop} {
    margin-bottom: 0;
  }
`;

export const RatingTitles = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: start;

  @media ${device.laptop} {
    align-items: flex-end;
  }
`;

export const RatingTitle = styled.div`
  background: ${color.blue};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 2px 5px;
  height: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  font-size: ${fontSize.small};
  line-height: 15px;
  color: #ffffff;
  :last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    font-size: ${fontSize.regular};
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Score = styled.div`
  width: 32px;
  height: 30px;
  align-self: center;
  background: ${color.blue};
  border-radius: 5px;
  margin-left: 15px;

  @media ${device.tablet} {
    width: 42px;
    height: 40px;
  }
`;

export const ScoreNumbers = styled.span`
  color: #ffffff;
  font-size: ${fontSize.medium};
  line-height: 30px;
  text-align: center;
  display: block;

  @media ${device.tablet} {
    font-size: ${fontSize.x_large};
    line-height: 40px;
  }
`;
