import React, { useState, MouseEvent } from 'react';
import moment from "moment-timezone";
import { format } from 'date-fns';

import { color } from 'theme';
import {
  CalendarContaner,
  Header,
  Days,
  Cell,
  Info,
  InfoText,
  InfoWrapper,
  CellWrapper
} from './style';

import { Day } from './type';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface Info {
  price?: number;
  minDays?: number;
  bookingType?: string;
  status?: number;
  selectedDays: Day[] | [];
  allDays: Day[] | [];
  currency: string;
}

interface Props {
  header: Array<Day>;
  arr: Array<Day[]>;
  first: number;
  openModal: (id: number, info: Info) => void;
  handleMultiple: (start: Date, end: Date) => void;
  prices: number[];
  currency: string;
}

let targets: Array<HTMLElement> = [];

export const CalendarGrid = ({
  header,
  arr,
  first,
  openModal,
  handleMultiple,
  prices,
  currency
}: Props) => {
  const [down, setDown] = useState(false);
  const [flag, setFlag] = useState(true);
  const [currentRow, setCurrentRow] = useState(-1);
  const [startIndex, setStartIndex] = useState(0);
  const [last, setLast] = useState(0);
  const { t } = useTranslation(['common']);

  const [start, setStart] = useState<Date>();

  const handleDown = (e: MouseEvent, date: Date, row: number, index: number) => {
    if (arr[row][index].status === 5) {
      return;
    }

    if (e.target === e.currentTarget && flag) {
      setDown(true);
      setStart(date);
      setStartIndex(index);
      setCurrentRow(row);
    }
    const target = e.target as HTMLElement;
    target.classList.add('ch');
    targets.push(target);
  };

  const weekDays = [
    t('weeks.7'),
    t('weeks.1'),
    t('weeks.2'),
    t('weeks.3'),
    t('weeks.4'),
    t('weeks.5'),
    t('weeks.6')
  ];

  const handleMove = (e: MouseEvent, row: number, index: number) => {
    if (e.target === e.currentTarget && down && currentRow === row) {
      const target = e.target as HTMLElement;
      target.classList.add('ch');
      targets.push(target);
      if (index < last) {
        targets.forEach((item) => {
          if (Number(item.getAttribute('id')) > index) item.classList.remove('ch');
        });
      }
      setLast(index);
    }
  };

  const handleUp = (e: any, date: Date, row: number, index: number) => {
    if (arr[row][index].status === 5) {
      return;
    }

    let selectedDays: Day[] | [] = [];

    if (e.target === e.currentTarget && down) {
      if (targets.length > 1) {
        let selectedIndexes: number[] = targets.map((item) => Number(item.id));
        selectedDays = selectedIndexes.reduce((acc, selectedIndex) => {
          //@ts-expect-error
          acc.push(arr[row][selectedIndex]);
          return acc;
        }, []);
        selectedIndexes = [];
      }
      setDown(false);
      setFlag(false);
      const min = startIndex > index ? date : start;
      const max = startIndex < index ? date : start;
      handleMultiple(min as Date, max as Date);

      const info: Info = {
        price: arr[row][index].price || prices[row],
        minDays: arr[row][index].minDays,
        bookingType: arr[row][index].type,
        status: arr[row][index].status,
        selectedDays: selectedDays,
        allDays: arr[row],
        currency: currency
      };

      if (arr[row][index].status !== 2) {
        openModal(row, info);
      }
      setFlag(true);
      targets.forEach((target) => target.classList.remove('ch'));
      selectedDays = [];
      targets = [];
    }
  };

  const renderHeader = () =>
    header.map((item, index) => (
      <Cell borderHeader key={index} color={color.blue}>
        {weekDays[item.date.getDay()]}
      </Cell>
    ));

  const getColor = (status: number) => {
    switch (status) {
      case 2:
        return color.lightGreen;
      case 3:
        return color.yellow;
      case 4:
        return color.red;
      case 5:
        return color.blue;
    }
  };

  const getStatus = (type: number) => {
    switch (type) {
      case 1:
        return t('status.auto');
      case 3:
        return t('status.requesting');
      case 4:
        return t('status.blocked');
      case 2:
        return t('status.booked');
      case 5:
        return t('status.blocked');
    }
  };

  const [visible, setVisible] = useState({ row: -1, index: -1 });

  const renderDays = (array: Day[], row: number) => {
    return array.map(({ date, status, id, type, price, minDays }, index) => {
      return (
        <CellWrapper key={index} color={getColor(status)} border={row === arr.length - 1}>
          <Info visible={visible.row === row && visible.index === index}>
            <InfoWrapper>
              <InfoText color={color.blue}>ID {id}</InfoText>
            </InfoWrapper>
            <InfoWrapper>
              <InfoText>
                {id
                  ? moment.tz(date, 'Europe/Kiev').format('DD/M/yyyy')
                  : format(date, 'dd/M/yyyy')}
              </InfoText>
            </InfoWrapper>
            <InfoText center>
              {t('profile.calendar_grid_price')} {price ? price : prices[row]} {currency}
            </InfoText>
            <InfoText center>
              {t('profile.calendar_grid_availability')} {getStatus(status)}
            </InfoText>
            <InfoText center border>
              {t('profile.calendar_grid_min_stay')} {minDays ? minDays : 1}
            </InfoText>
          </Info>
          <Cell
            id={index}
            onMouseDown={(e: MouseEvent) => handleDown(e, date, row, index)}
            // onMouseMove={(e: MouseEvent) => handleMove(e, row, index)}
            onMouseUp={(e: MouseEvent) => handleUp(e, date, row, index)}
            onMouseOver={(e: MouseEvent) => {
              !isMobile && !down && setVisible({ row, index });
              handleMove(e, row, index);
            }}
            onMouseOut={
              () => !down && setVisible({ row: -1, index: -1 })
              // (e: MouseEvent) => handleMove(e, row)
            }>
            {id ? moment.tz(date, 'Europe/Kiev').date() : date.getDate()}
          </Cell>
        </CellWrapper>
      );
    });
  };

  return (
    <CalendarContaner days={header.length}>
      <Header days={header.length}>{renderHeader()}</Header>
      {arr?.map((item, index) => (
        <Days key={index} days={header.length}>
          {renderDays(item, index)}
        </Days>
      ))}
    </CalendarContaner>
  );
};