import React from "react";

import { StyledLabel } from "./style";

type Props = {
  dataTestid?: string,
  status?: boolean;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  children: string;
};

export const Label = ({
  dataTestid,
  children,
  width,
  height,
  status,
  margin,
  padding,
}: Props) => {
  return (
    <StyledLabel
      data-testid={dataTestid}
      width={width}
      height={height}
      status={status}
      margin={margin}
      padding={padding}
    >
      {children}
    </StyledLabel>
  );
};
