import React from 'react';

import {
  StyledTable,
  StyledTBody,
  HeaderRaw,
  TableRaw
} from '../../UserBookings/components/style.js';

const GENERAL_PARTNER_INFO_TABLE_HEADERS = {
  partnerId: 'ID партнера',
  name: 'Имя',
  email: 'Email',
  phone: 'Телефон',
  debt: 'Долг',
  advance: 'Аванс'
};

export const PartnerInfoTable = ({ partnerInfo }) => {
  const renderHeader = (): JSX.Element => {
    return (
      <HeaderRaw>
        {Object.values(GENERAL_PARTNER_INFO_TABLE_HEADERS).map((item) => (
          <th key={item} style={{ width: item === 'ID партнера' && '50px' }}>
            {item}
          </th>
        ))}
      </HeaderRaw>
    );
  };

  return (
    <StyledTable>
      <thead>{renderHeader()}</thead>
      <StyledTBody>
        {partnerInfo.map((item) => (
          <TableRaw key={item.partnerId}>
            {Object.keys(GENERAL_PARTNER_INFO_TABLE_HEADERS).map((key) => {
              return (
                <td key={item[key].toString()}>
                  {item[key]} {(key === 'debt' || key === 'advance') && item.currency}
                </td>
              );
            })}
          </TableRaw>
        ))}
      </StyledTBody>
    </StyledTable>
  );
};
