import styled from "styled-components";

import { device } from "theme";

export const SearchCont = styled.div`
  position: relative;
  width: 300px;
  height: 50px;

  @media ${device.laptop} {
    width: 475px;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 14px;
  left: 10px;
`;

export const IconWrapperCreate = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
`;

export const IconLocationWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 25px;
  left: 10px;
  transform: translateY(-50%);
`;

export const EmptySearchCont = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 50px;
  background-color: white;
  border-radius: 8px;
  color: gray;

  @media ${device.laptop} {
    width: 475px;
  }
`;

export const SearchLocationCont = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 50px;
  background: #f2f2f2;
  border-radius: 12px;

  @media ${device.laptop} {
    min-width: 300px;
  }
`;

export const DetectPositionButton = styled.button`
  width: 40px;
  height: 40px;
  outline: none;
  border: none;
  background: transparent;
  margin: auto 10px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  & > svg {
    width: 100%;
    height: 100%;
  }
`;


