import React, { useEffect, useRef, useState } from 'react';
import { compareDesc } from 'date-fns';
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { DateRangePicker } from 'react-dates';
import { ReactComponent as AddButton } from 'assets/svg/increase.svg';
import { MinusIcon, CloseIcon } from 'assets/svg';
import { color } from 'theme';
import { isMobile } from 'react-device-detect';

import {
  Text,
  Title,
  ModalRow,
  Card,
  DateContainer,
  CardTitle,
  Label,
  Input,
  Selector,
  Left,
  Right,
  InputWrapper,
  InputInt,
  InputIntContainer,
  PopupContainer,
  ButtonWrapper,
  Error
} from './style';
import { Button } from 'Components/UI/Button/Button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Info } from './CalendarGrid';
import { regexNumberValue } from 'Constants/constants';
import { momentDate } from 'helpers/momentHelpers';
import moment from 'moment';
import { Day } from './type';

interface Props {
  start: Date;
  end: Date;
  onSubmit: (data: any) => void;
  onClose: () => void;
  info: Info;
}

const schema = yup.object().shape({
  price: yup.number().min(1),
  minDays: yup.number().min(1)
});

interface FormValue {
  price: number;
  minDays: number;
}

export const AddBookingPopup = ({ start, end, onSubmit, onClose, info }: Props) => {
  const { t } = useTranslation('common');
  const defaultValues = {
    price: info?.price,
    minDays: info?.minDays
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm<FormValue>({ resolver: yupResolver(schema), defaultValues });
  const checkboxes = useRef<Array<HTMLInputElement | null>>([]);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [minDays, setMinDays] = useState<number>(info?.minDays || 1);
  const [maxDays, setMaxDays] = useState<number>(1);
  const [maxPriceOfDays, setMaxPriceOfDays] = useState<number>();
  const [minPriceOfDays, setMinPriceOfDays] = useState<number>();
  const [bookingType, setBookingType] = useState(info?.bookingType || 'AUTO_APPROVE');
  const [access, setAccess] = useState(info?.bookingType === 'UNAVAILABLE' ? false : true);
  const [pricePerDay, setPricePerDay] = useState<number | string>(info?.price || '');
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    checkboxes.current = checkboxes.current.slice(0, 7);
  }, []);

  const submit = ({ price, minDays }: FormValue) => {
    let daysOfWeak = 0;
    checkboxes.current.forEach((checkbox, index) => {
      if (checkbox?.checked) {
        daysOfWeak += Math.pow(2, 6 - index);
      }
    });
    if (startDate.toDateString() === endDate.toDateString()) daysOfWeak = 127;

    onSubmit({
      firstDate: momentDate(startDate, 'YYYY-MM-DD'),
      secondDate: momentDate(endDate, 'YYYY-MM-DD'),
      price,
      daysOfWeak,
      bookingType: access ? bookingType : 'UNAVAILABLE',
      minDays
    });
  };

  const handleClick = (add: number) => {
    const value = Number(minDays) + add > 0 ? Number(minDays) + add : minDays;
    setValue('minDays', value);
    setMinDays(value);
  };

  function renderError(type: string) {
    switch (type) {
      case 'min':
        return t('errors.min');
      case 'typeError':
        return t('errors.number');
    }
  }

  const onChancePricePerDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.match(regexNumberValue)) {
      setPricePerDay(e.target.value);
    }
  };

  const getMinPrice = (daysSelected: Array<Day | []>) => {
    if (!daysSelected.length) {
      return 0;
    }
    //@ts-expect-error
    const minPrice = Math.min(...daysSelected.map((item: Info) => item.price));
    return Number.isNaN(minPrice) ? Number(pricePerDay) : minPrice;
  };

  const getMaxPrice = (daysSelected: Array<Day | []>) => {
    if (!daysSelected.length) {
      return 0;
    }
    //@ts-expect-error
    const maxPrice = Math.max(...daysSelected.map((item: Info) => item.price));
    return maxPrice;
  };

  const getMinDay = (daysSelected: Array<Day | []>) => {
    if (!daysSelected.length) {
      return 1;
    }
    //@ts-expect-error
    const minDay = Math.max(...daysSelected.map((item: Info) => item.minDays));
    return minDay;
  };

  const getMaxDay = (daysSelected: Array<Day | []>) => {
    if (!daysSelected.length) {
      return 1;
    }
    //@ts-expect-error
    const maxDay = Math.max(...daysSelected.map((item: Info) => item.minDays));
    return maxDay;
  };

  useEffect(() => {
    if (info && !!info.selectedDays.length) {
      setMinPriceOfDays(getMinPrice(info.selectedDays));
      setMaxPriceOfDays(getMaxPrice(info.selectedDays));
      setMaxDays(getMaxDay(info.selectedDays));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (info && info.selectedDays.length) {
      // if (minPriceOfDays !== 0 && minPriceOfDays !== maxPriceOfDays) {
      //   setPricePerDay('');
      // }

      if (maxDays !== 1 && minDays !== maxDays) {
        setMinDays(Number(minDays));
      }
    }
  }, [info, minPriceOfDays, maxDays, minDays, maxPriceOfDays]);

  useEffect(() => {
    if (!moment(start).isSame(startDate) && !moment(end).isSame(endDate)) {
      const selectedDays = info.allDays.filter((day) =>
        moment(day.date).isBetween(moment(startDate), moment(endDate), 'days', '[]')
      );
      setMinPriceOfDays(getMinPrice(selectedDays));
      setMaxPriceOfDays(getMaxPrice(selectedDays));
      setMinDays(getMinDay(selectedDays));
      setMaxDays(getMaxDay(selectedDays));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, startDate, end, endDate, info.allDays]);

  return (
    <PopupContainer>
      <CloseIcon fill="#2F80ED" className="closeIcon" onClick={onClose} />
      <Title>{t('profile.add_booking_popup_title')}</Title>
      <DateContainer>
        <DateRangePicker
          startDatePlaceholderText={t('placeholders.begin')}
          endDatePlaceholderText={t('placeholders.end')}
          startDate={moment(startDate)} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id"
          endDate={moment(endDate)} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id"
          onDatesChange={({ startDate, endDate }: any) => {
            setStartDate(new Date(startDate));
            setEndDate(new Date(endDate));
          }}
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
          withFullScreenPortal={isMobile}
          orientation={isMobile ? 'vertical' : 'horizontal'}
          readOnly={true}
        />
      </DateContainer>

      {compareDesc(startDate, endDate) !== 0 && (
        <>
          <ModalRow margin="22px 0 0">
            <Text>{t('profile.checkbox_text')}</Text>
          </ModalRow>
          <ModalRow margin="10px 0 0">
            <input ref={(el) => (checkboxes.current[1] = el)} defaultChecked type="checkbox" />
            <Label>{t('weeks.1')}</Label>
            <input ref={(el) => (checkboxes.current[2] = el)} defaultChecked type="checkbox" />
            <Label>{t('weeks.2')}</Label>
            <input ref={(el) => (checkboxes.current[3] = el)} defaultChecked type="checkbox" />
            <Label>{t('weeks.3')}</Label>
            <input ref={(el) => (checkboxes.current[4] = el)} defaultChecked type="checkbox" />
            <Label>{t('weeks.4')}</Label>
            <input ref={(el) => (checkboxes.current[5] = el)} defaultChecked type="checkbox" />
            <Label>{t('weeks.5')}</Label>
            <input ref={(el) => (checkboxes.current[6] = el)} defaultChecked type="checkbox" />
            <Label>{t('weeks.6')}</Label>
            <input ref={(el) => (checkboxes.current[0] = el)} defaultChecked type="checkbox" />
            <Label>{t('weeks.7')}</Label>
          </ModalRow>
        </>
      )}
      <div>
        <Card>
          <InputWrapper>
            <CardTitle>{t('profile.card_title')}</CardTitle>
            <Selector data-testid="availability">
              <Left
                selected={access}
                onClick={() => {
                  setAccess(true);
                  setBookingType('AUTO_APPROVE');
                }}>
                <Text color={access ? color.white : color.blue}>
                  {t('profile.card_access_true')}
                </Text>
              </Left>
              <Right selected={!access} onClick={() => setAccess(false)}>
                <Text color={!access ? color.white : color.blue}>
                  {t('profile.card_access_false')}
                </Text>
              </Right>
            </Selector>
          </InputWrapper>
          <Text margin={'10px 0 0'}>{t('profile.card_availability')}</Text>
        </Card>
        {access && (
          <>
            <Card flex>
              <div>
                <CardTitle>
                  {t('profile.card_access_title')}
                  {info.currency}
                </CardTitle>
                <Text margin={'10px 0 0'}>{t('profile.card_access_price_change')}</Text>
                {info && !!info.selectedDays.length && minPriceOfDays !== maxPriceOfDays && (
                  <Text>
                    {t('parking.min_price_for_period')} -{' '}
                    {minPriceOfDays && minPriceOfDays !== 0 ? minPriceOfDays : ''} {info.currency}
                  </Text>
                )}
              </div>
              <div>
                <Input
                  {...register('price')}
                  value={pricePerDay}
                  onChange={onChancePricePerDay}
                  placeholder={t('titles.price')}
                />
                {errors.price && <Error>{renderError(errors.price.type)}</Error>}
              </div>
            </Card>
            <Card>
              <InputWrapper>
                <CardTitle>{t('profile.card_access_booking_type')}</CardTitle>
                <Selector data-testid="booking-type">
                  <Left
                    selected={bookingType === 'AUTO_APPROVE' || bookingType === 'UNAVAILABLE'}
                    onClick={() => setBookingType('AUTO_APPROVE')}>
                    <Text
                      color={
                        bookingType === 'AUTO_APPROVE' || bookingType === 'UNAVAILABLE'
                          ? color.white
                          : color.blue
                      }>
                      {t('profile.card_access_booking_type_auto')}
                    </Text>
                  </Left>
                  <Right
                    selected={bookingType === 'MANUAL_APPROVE'}
                    onClick={() => setBookingType('MANUAL_APPROVE')}>
                    <Text color={bookingType === 'MANUAL_APPROVE' ? color.white : color.blue}>
                      {t('profile.card_access_booking_type_request')}
                    </Text>
                  </Right>
                </Selector>
              </InputWrapper>
              <Text margin={'10px 0 0'}>{t('profile.card_access_booking_set_date')}</Text>
            </Card>
            <Card flex>
              <div>
                <CardTitle>{t('profile.card_access_booking_title_min_stay')} </CardTitle>
                <Text margin={'10px 0 0'}>{t('profile.card_access_booking_set_cnt_day')}</Text>
                {minDays !== maxDays && minDays < maxDays && (
                  <Text>
                    {t('parking.min_period_days_from')} {minDays} {t('parking.min_period_days_to')}{' '}
                    {maxDays}
                  </Text>
                )}
              </div>
              <div>
                <InputIntContainer>
                  <MinusIcon onClick={(e) => handleClick(-1)} />
                  <Controller
                    name="minDays"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <InputInt
                        data-testid="min-days"
                        value={minDays}
                        onChange={(e: any) => {
                          onChange(e.target.value);
                          setMinDays(Number(e.target.value));
                        }}
                      />
                    )}
                  />
                  <AddButton onClick={() => handleClick(1)} />
                </InputIntContainer>
                {errors.minDays && <Error>{renderError(errors.minDays.type)}</Error>}
              </div>
            </Card>
          </>
        )}
      </div>
      <ButtonWrapper>
        <Button dataTestid="submit" primary onClick={handleSubmit(submit)}>
          {t('profile.card_booking_accept')}
        </Button>
      </ButtonWrapper>
    </PopupContainer>
  );
};