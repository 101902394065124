import styled from "styled-components";
import { color } from 'theme';

export const PointOnMapWrapp = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -100%) ${(props) => (props.selected ? 'scale(1.5)' : 'scale(1)')};
  transition: transform 0.3s ease-in-out;

  & > svg {
    width: 100% !important;
    height: 100%;
    color: ${(props) => (props.selected || props.onHoverItem ? `${color.red}` : `${color.blue}`)};
    z-index: ${(props) => (props.selected ? 999 : 1)};
  }
`;

export const MarkerWrapper = styled.div`
  position: relative;
  transform: ${(props) => (props.selected ? 'scale(1.5)' : 'scale(1)')};
  transition: transform 0.3s ease-in-out;
`;

export const MarkerIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ClusterMarker = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  background: ${(props) => (props.selected ? 'red' : '#2F80ED')};
  border-radius: 50%;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 20px;
`;

export const StyledPrice = styled.p`
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -80%);
`;

export const RoundDot = styled.span`
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: ${color.white};
`;