import styled from "styled-components";
import { color, fontSize } from 'theme';

export const StyledInput = styled.input`
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: ${(props) => (props.marginRight ? `${props.marginRight}` : ``)};
  padding: 10px;
  width: ${(props) => props.width || '100%'};
  height: 50px;
  background: ${(props) => (props.islight ? '#ffffff' : `${color.backgroundGrey}`)};
  outline: none;
  border: ${(props) => (props.error ? `1px solid ${color.red}` : 'none')};
  font-size: ${fontSize.regular};
`;
