import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Login } from 'Components/Admin/Login/Login';
import { PrivateRoute } from 'utils/PrivateRoute';
import { UserParkings } from 'Components/Admin/UserParkings/UserParkings';
import Bank from 'Components/Admin/Bank';
import { Parkings } from 'Components/Admin/Parkings/Parkings';
import { SellParkings } from 'Components/Admin/Parkings/SellParkings';
import { Parking } from 'Components/Admin/Parking/Parking';
import { Users } from 'Components/Admin/Users/Users';
import { UserBookings } from 'Components/Admin/UserBookings/UserBookings';
import { BillsAndPayments } from 'Components/Admin/BillsAndPayments';
import { languageOptionsExtended } from 'Components/Header/LanguageSelector';
import { languageStyles } from 'Components/Header/style';
import { SmallLoader } from 'Components/Loader/Loader';
import { NavigationPanel } from './components/NavigationPanel';

import { ADMIN_TOKEN_KEY, ADMIN_TOKEN_EXPIRATION_KEY } from 'Components/Admin/constants.ts';
import { checkExpirationDate } from 'helpers/checkExpirationDate.js';
import { Container, Menu, Content } from './style';

export const Admin = () => {
  const { i18n } = useTranslation(['admin']);
  const history = useHistory();
  const { path } = useRouteMatch();
  const token = localStorage.getItem(ADMIN_TOKEN_KEY);
  const tokenExpirationDate = localStorage.getItem(ADMIN_TOKEN_EXPIRATION_KEY) || '';

  const [login, setLogin] = useState<boolean | null>(null);

  useEffect(() => {
    if (!tokenExpirationDate && checkExpirationDate(tokenExpirationDate)) {
      localStorage.removeItem(ADMIN_TOKEN_KEY);
      history.push('/admin');
    } else {
      setLogin(Boolean(token) ? true : false);
    }
  }, [token, tokenExpirationDate, history]);

  const handleLanguage = useCallback(
    (selected: any) => {
      i18n.changeLanguage(selected.value);
      localStorage.setItem('language', selected.value);
      window.location.reload();
    },
    [i18n]
  );

  const lng = useMemo(
    () => languageOptionsExtended.find((option) => option.value === i18n.language),
    [i18n]
  );

  return login !== null ? (
    <Container>
      {login && (
        <Menu>
          <Select
            defaultValue={lng}
            onChange={handleLanguage}
            options={languageOptionsExtended}
            isSearchable={false}
            styles={languageStyles}
            components={{ IndicatorSeparator: () => null }}
          />
          <NavigationPanel onClick={setLogin} />
        </Menu>
      )}
      <Content>
        <Switch>
          <Route path={`${path}/login`}>
            {!login ? <Login onLogin={() => setLogin(true)} /> : <Redirect to={path} />}
          </Route>
          <PrivateRoute isAuth={login} path={path} exact component={Parkings} />
          <PrivateRoute isAuth={login} path={`${path}/sell`} exact component={SellParkings} />
          <PrivateRoute isAuth={login} path={`${path}/sell/:id`} exact component={Parking} />
          <PrivateRoute isAuth={login} path={`${path}/users`} exact component={Users} />
          <PrivateRoute isAuth={login} path={`${path}/users/:id`} component={UserParkings} />
          <PrivateRoute isAuth={login} path={`${path}/bank`} exact component={Bank} />
          <PrivateRoute isAuth={login} path={`${path}/:id`} exact component={Parking} />
          <PrivateRoute
            exact
            isAuth={login}
            path={`${path}/user-all-bookings/:id`}
            component={UserBookings}
          />
          <PrivateRoute
            exact
            isAuth={login}
            path={`${path}/bills-and-payments/:id`}
            component={BillsAndPayments}
          />
        </Switch>
      </Content>
    </Container>
  ) : (
    <Container>
      <SmallLoader />
    </Container>
  );
};
