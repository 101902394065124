import styled from "styled-components";
import { Link } from "react-router-dom";

import { color } from "theme";

export const StyledLink = styled(Link)`
  color: ${(props) => (props.primary ? "#FFFFFF" : color.blue)};
  text-decoration: none;
  display: ${(props) => (props.display ? props.display : "unset")};
  align-items: center;
`;
