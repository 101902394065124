import styled from 'styled-components';
import { device } from 'theme';
import img from 'assets/img/background.jpg';
import img2 from 'assets/img/background_2.jpeg';

export const BackgroundWrapper = styled.div`
  background-image: url(${(props) => (props.isSecondary ? `${img2}` : `${img}`)});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #000000;
    position: absolute;
    opacity: 0.5;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Home = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 16px;
  margin-bottom: 10px;

  @media ${device.laptop} {
    margin-bottom: 100px;
  }
`;