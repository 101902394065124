import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { paths } from 'config';
import { Tooltip } from 'Components/Tooltip/Tooltip';
import { ICoordinatesProps } from './GoogleMapList';
import { getImageLink } from 'helpers/getImageLink.ts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';

import { PointOnMapWrapp, StyledPrice, RoundDot } from './style';

export const PointMarker: FC<ICoordinatesProps> = ({
  lat,
  lng,
  from,
  to,
  selected,
  onMouseOver,
  id,
  current,
  data,
  isForSale = false,
  chosenLabel,
  radius
}) => {
  const handleMouseOver = (id: number) => {
    onMouseOver!(data.id);
  };

  const handleMouseExit = () => {
    onMouseOver!(-1);
  };

  return (
    <Link
      to={
        isForSale
          ? `/${paths.selectedParkingForSale}?parkingId=${id}&searchLat=${lat}&searchLng=${lng}&chosenLabel=${chosenLabel}&searchRangeRadius=${radius}`
          : `/${paths.oneparking}?parkingId=${id}&from=${from}&to=${to}`
      }
      target="_blank"
      rel="noopener noreferrer">
      <PointOnMapWrapp
        selected={id === selected}
        onMouseEnter={() => handleMouseOver(id as number)}
        onMouseLeave={handleMouseExit}
        lat={lat}
        lng={lng}
        style={{ zIndex: (id === current || id === selected) && 1000000 }}
        onHoverItem={id === current}>
        <FontAwesomeIcon icon={faMapMarker} />
        {data?.price && (
          <StyledPrice>{isForSale ? <RoundDot /> : parseInt(String(data?.price), 10)}</StyledPrice>
        )}
        {id === current && (
          <Tooltip
            street={data?.street}
            house={data?.house}
            carClass={data?.carClass}
            price={data?.price}
            status={!isForSale && data?.bookingType}
            url={getImageLink(data?.files)}
            currency={data?.currency}
          />
        )}
      </PointOnMapWrapp>
    </Link>
  );
};
