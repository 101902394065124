import React, {
  useState,
  useCallback,
  useEffect,
  FunctionComponent,
} from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { RootState } from 'Redux/store';
import { ParkingPlace } from 'api/parking';
import { emailRegex } from 'config';
import { bookNewParkingPlace } from 'api/booking';

import {
  Wrapper,
  Header,
  StyledModal,
  HeaderText,
  CloseButton,
  Content,
  Backdrop,
  Form,
  StyledP,
  IconWrapper,
  AgreeContainer,
  AgreeNote
} from './style';

import { StyledLabel } from 'Components/UI/StyledLabel/style';
import { Notification } from 'Components/UI/Notification/Notification';
import { Button } from 'Components/UI/Button/Button';
import { StyledInput } from 'Components/UI/Input/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as SuccessIcon } from 'assets/svg/success-icon.svg';

import { useHistory } from 'react-router';
import * as yup from 'yup';
import {
  EMAIL_MUST_BE_VALID,
  EMAIL_REQUIRED_FIELD,
  regexCarBrand,
  regexCarColor,
  regexClientName,
  regexPhone
} from 'Constants/constants';

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  startDate: string;
  endDate: string;
  parkingId?: number;
}

type FormValues = {
  name: string;
  phoneNumber: string;
  car: string;
  color: string;
  email?: string;
  checkbox: boolean;
};

export const ModalBooking: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  endDate,
  startDate,
  parkingId
}) => {
  const parkingInfo: ParkingPlace | null | undefined = useSelector(
    (state: RootState) => state?.singleParking?.data
  );
  const { t, i18n } = useTranslation(['common']);
  const [responseError, setResponseError] = useState<number | null>(null);
  const [messageError, setMessageError] = useState<string | undefined>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isBookSuccess, setIsBookSuccess] = useState<boolean>(false);
  const [status, setStatus] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormValues>({
    resolver: yupResolver(
      yup.object().shape({
        name: yup
          .string()
          .required()
          .max(30, t('validation.'))
          .matches(regexClientName, t('validation.string')),
        car: yup
          .string()
          .required()
          .max(30, t('validation.name'))
          .matches(regexCarBrand, t('validation.string')),
        phoneNumber: yup.string().required().matches(regexPhone, t('login.Invalid_number_format')),
        email: yup.string().required().email().matches(emailRegex, t('validation.email')),
        color: yup.string().required().matches(regexCarColor, t('validation.string')),
        checkbox: yup.boolean().oneOf([true], t('validation.checkboxChecked'))
      })
    )
  });

  const history = useHistory();

  function redirect() {
    history.push('/');
  }

  const { mutateAsync } = useMutation(bookNewParkingPlace, {
    onError: (error: AxiosError) => {
      setMessageError(error.response?.data.message);
      setResponseError(error.response?.data.statusCode);

      let message;
      if (responseError === 400) {
        message = t('login.incorrect_phone');
      } else if (responseError === 403) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        message = t('notifications.error_date');
      }
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined
      });
      setIsDisabled(false);
    },
    onSuccess: (response: AxiosResponse) => {
      setResponseError(null);
      setIsDisabled(false);

      reset();
      // hide();
      setIsBookSuccess(true);
      setStatus(response.data.bookingStatus);
    }
  });

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 27 && isShown) {
      hide();
      reset();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown]);

  const onSubmit = useCallback(
    (data: FormValues) => {
      setIsDisabled(true);
      const { checkbox, ...body } = data;
      mutateAsync({
        ...body,
        from: startDate,
        to: endDate,
        parkingPlaceId: parkingInfo?.id || parkingId,
        language: i18n.language.toUpperCase()
      });
    },
    [mutateAsync, startDate, endDate, parkingInfo?.id, parkingId, i18n.language]
  );

  const closeModalSuccess = () => {
    setIsBookSuccess(false);
    hide();
  };

  const modal = (
    <React.Fragment>
      <Backdrop onClick={closeModalSuccess} />
      <Wrapper aria-modal tabIndex={-1} role="dialog">
        {isBookSuccess ? (
          <StyledModal>
            <Content>
              <IconWrapper>
                <SuccessIcon />
              </IconWrapper>
              <StyledP>
                {status === 'PENDING' ? t('status.booking_pending') : t('status.booking_confirmed')}
              </StyledP>
              <Button primary={true} onClick={redirect}>
                {t('buttons.close')}
              </Button>
            </Content>
          </StyledModal>
        ) : (
          <StyledModal>
            <Header>
              <HeaderText>{t('titles.booking')}</HeaderText>
              <CloseButton onClick={hide}>X</CloseButton>
            </Header>
            <Content>
              <Form height="450" onSubmit={handleSubmit(onSubmit)}>
                <StyledLabel htmlFor="name">
                  <StyledInput
                    placeholder={t('login.name')}
                    {...register('name', { required: true })}
                  />
                  <Notification isAccent={true}>
                    {errors?.name?.type === 'required' && t('login.required')}
                    {errors?.name?.type !== 'required' && errors?.name?.message}
                  </Notification>
                </StyledLabel>

                <StyledLabel htmlFor="car">
                  <StyledInput
                    placeholder={t('placeholders.car_model')}
                    {...register('car', { required: true })}
                  />
                  <Notification isAccent={true}>
                    {errors?.car?.type === 'required' && t('login.required')}
                    {errors?.car?.type !== 'required' && errors?.car?.message}
                  </Notification>
                </StyledLabel>

                <StyledLabel htmlFor="color">
                  <StyledInput
                    placeholder={t('placeholders.color')}
                    {...register('color', { required: true })}
                  />
                  <Notification isAccent={true}>
                    {errors?.color?.type === 'required' && t('login.required')}
                    {errors?.color?.type !== 'required' && errors?.color?.message}
                  </Notification>
                </StyledLabel>

                <StyledLabel htmlFor="email">
                  <StyledInput placeholder="Email" {...register('email', { required: true })} />
                  <Notification isAccent={true}>
                    {errors?.email?.message === EMAIL_REQUIRED_FIELD && t('login.required')}&nbsp;
                    {errors?.email?.message === EMAIL_MUST_BE_VALID && t('login.validEmail')}&nbsp;
                  </Notification>
                </StyledLabel>

                <StyledLabel htmlFor="phoneNumber">
                  <StyledInput
                    placeholder={t('login.phone')}
                    {...register('phoneNumber', { required: true })}
                  />
                  <Notification isAccent={true}>
                    {errors?.phoneNumber?.type === 'required' && t('login.required')}
                    {errors?.phoneNumber?.type !== 'required' && errors?.phoneNumber?.message}
                    {(messageError === 'Must be region!' && t('login.Invalid_number_format')) ||
                      (responseError === 400 && t('login.incorrect_phone'))}
                  </Notification>
                </StyledLabel>

                <AgreeContainer>
                  <label htmlFor="checkbox">
                    <input type="checkbox" {...register('checkbox', { required: true })} />

                    <AgreeNote>
                      {t('login.accept_note')}&nbsp;
                      <a href="/conditions" target="_blank" rel="noopener noreferrer">
                        {t('login.agreement')}
                      </a>
                    </AgreeNote>
                    <Notification isAccent={true}>
                      {errors?.checkbox?.message && t('login.agreement_warning')}
                    </Notification>
                  </label>
                </AgreeContainer>

                <Button dataTestid="submit" disabled={isDisabled} primary={true}>
                  {t('buttons.book')}
                  {isDisabled && (
                    <FontAwesomeIcon
                      icon={faSync}
                      className="fa-spin"
                      style={{ marginLeft: '20px', color: '#2F80ED' }}
                    />
                  )}
                </Button>
              </Form>
            </Content>
          </StyledModal>
        )}
      </Wrapper>
    </React.Fragment>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

