import styled from "styled-components";
import ImgSrc from 'assets/svg/car-background.svg';
import { color, fontSize, device } from 'theme';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${({ isDark }) => (isDark ? color.darkGreyBackground : '#ffffff')};
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
  background-image: ${({ isDark }) => isDark && `url(${ImgSrc})`};

  @media ${device.desktop} {
    height: 72px;
  }
`;

export const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  @media ${device.tablet} {
    padding: 0 20px;
  }

  @media ${device.desktop} {
    padding: 0;
  }
`;

export const LeftSideWrapper = styled.div`
  /* width: 80px; */
  height: 100%;
  display: flex;
  align-items: center;

  /* @media ${device.tablet} {
    width: 105px;
    height: 100%;
  } */
`;

export const LogoImgCont = styled.div`
  width: 40px;
  height: 40px;
  margin: 5px 10px 5px 0;

  @media ${device.tablet} {
    width: 60px;
    height: 60px;
    margin: 10px 20px 10px 0;
  }
`;

export const RightSideWrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;

  @media ${device.tablet} {
    margin-left: 0;
  }
`;

export const languageStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: `${color.backgroundGrey}`,
    borderRadius: '10px',
    border: 'none',
    width: '120px',
    height: '25px',
    color: '#33333380',
    fontSize: `${fontSize.small}`,
    lineHeight: '16px',
    fontWeight: '400'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: `${fontSize.small}`,
      lineHeight: '16px',
      fontWeight: '400'
    };
  }
};

export const StyledSelect = {
  control: (styles, { isFocused }) => ({
    ...styles,
    border: 'transparent',
    borderRadius: 0,
    width: '40px',
    height: '40px',
    outline: 'none !important',
    boxShadow: 'none',
    background: 'transparent',
    cursor: 'pointer'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      cursor: 'pointer',
      color: `${color.darkGrey}`,
      maxWidth: '200px',
      minWidth: '200px',
      width: '100%',
      backgroundColor: isFocused && `${color.blue}`,
      boxShadow:
        'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      display: 'flex',
      maxWidth: '200px',
      minWidth: '200px',
      width: '100%',
      zIndex: 10
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      overflow: 'visible',
    }
  }
};

export const OptionItem = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
`;