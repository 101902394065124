import React, { ReactNode } from 'react';

import {
  StyledButton,
  StyledControlButton,
  StyledIconButton,
  ToggleLabel,
  ToggleInput,
  ToggleBtn,
  ToggleWrapper
} from './style';

type Props = {
  dataTestid?: string;
  primary?: boolean;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  children: ReactNode | string;
  value?: string | number;
  type?: string;
  margin_b?: string;
  margin_left?: string;
  margin_right?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  remove?: boolean;
  color?: string;
};

type ControllProps = {
  dataTestid?: string;
  isConfirm?: boolean;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  children: ReactNode | string;
  value?: string | number | undefined;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const Button = ({
  dataTestid,
  children,
  width,
  height,
  primary,
  margin,
  padding,
  value,
  type,
  margin_b,
  margin_left,
  margin_right,
  onClick,
  disabled,
  remove,
  color
}: Props) => {
  return (
    <StyledButton
      data-testid={dataTestid}
      width={width}
      height={height}
      primary={primary}
      margin={margin}
      padding={padding}
      onClick={onClick}
      value={value}
      type={type}
      margin_b={margin_b}
      margin_left={margin_left}
      margin_right={margin_right}
      disabled={disabled}
      remove={remove}
      color={color}>
      {children}
    </StyledButton>
  );
};

export const ControlButton = ({
  dataTestid,
  children,
  width,
  height,
  isConfirm,
  margin,
  padding,
  value,
  onClick
}: ControllProps) => {
  return (
    <StyledControlButton
      data-testid={dataTestid}
      width={width}
      height={height}
      isConfirm={isConfirm}
      margin={margin}
      padding={padding}
      onClick={onClick}
      value={value}>
      {children}
    </StyledControlButton>
  );
};

type IconButtonProps = {
  dataTestid?: string;
  isDisabled?: boolean;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  children: ReactNode | string;
  value?: string | number;
  isActive?: boolean;
  name?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const IconButton = ({
  dataTestid,
  onClick,
  isDisabled = false,
  width,
  height,
  margin,
  padding,
  value,
  children,
  isActive = false,
  name
}: IconButtonProps) => {
  return (
    <StyledIconButton
      dataTestid={dataTestid}
      onClick={onClick}
      isDisabled={isDisabled}
      width={width}
      height={height}
      margin={margin}
      padding={padding}
      value={value}
      isActive={isActive}>
      {children}
      {name && <p>{name}</p>}
    </StyledIconButton>
  );
};

type ToggleButtonPropsType = {
  label: string;
  toggled: boolean;
  onClick: () => void;
};

export const ToggleButton = ({ label, toggled, onClick }: ToggleButtonPropsType) => {
  return (
    <ToggleWrapper>
      <ToggleLabel>
        <ToggleInput type="checkbox" defaultChecked={toggled} onClick={onClick} />
        <ToggleBtn toggled={toggled} />
      </ToggleLabel>
      <p>{label}</p>
    </ToggleWrapper>
  );
};
