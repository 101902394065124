import styled from "styled-components";

import { fontSize, color, device } from "theme";

export const Wrapper = styled.div`
  border-radius: 10px;
  background: #ffffff;
  padding: 5px 10px;
  position: relative;

  @media ${device.tablet} {
    padding: 12px 20px;
  }
`;

export const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const StyledTitle = styled.h4`
  font-size: ${fontSize.m_large};
  line-height: 23px;
  font-weight: 400;
  margin-bottom: 10px;
`;

export const AddInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const AddInfoColumns = styled.div`
  flex-grow: 1;
  width: 50%;

  @media ${device.tablet} {
    width: 33%;
    height: 100px;
  }
`;

export const IconWrapper = styled.div`
  width: 15px;
  height: 20px;
  margin-right: 5px;

  @media ${device.tablet} {
    width: 20px;
    height: 25px;
    margin-right: 10px;
  }
`;

export const AdvCont = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 15px;

  @media ${device.tablet} {
    width: 33%;
  }
`;

export const AdvTitle = styled.span`
  font-size: ${fontSize.small};
  line-height: 14px;
  font-weight: 400;

  @media ${device.tablet} {
    font-size: ${fontSize.regular};
    line-height: 16px;
  }
`;

export const StyledP = styled.p`
  font-size: ${fontSize.small};
  line-height: 14px;
  font-weight: 400;
  color: ${color.greayText};
  text-align: center;

  @media ${device.tablet} {
    font-size: ${fontSize.regular};
    line-height: 16px;
  }
`;

export const RatingCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  position: absolute;
  top: 12px;
  right: 12px;

  @media ${device.tablet} {
    gap: 15px;
  }
`;

export const RatingNumbers = styled.span`
  font-size: ${fontSize.regular};
  line-height: 14px;
  font-weight: 400;
  color: ${color.greayText};

  @media ${device.tablet} {
    font-size: ${fontSize.large};
    line-height: 21px;
    font-weight: 500;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;